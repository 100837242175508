<div class="login_container position-fixed w-100 h-100 d-flex align-items-center white">
    <div class="container">
        <div class="row" style="margin-bottom: 200px;">
            <div class="col-sm-12">
                <div class="login_logo text-center mb-3">
                    <a href="#"><img src="assets/images/presidency_university_logo.png" alt="" width="200"></a>
                </div>
            </div>
            <div class="col-sm-12 d-flex justify-content-center">
                <div *ngIf="step1" class="login_box w-50 rounded">
                    <input type="text" name="email" [(ngModel)]="email" class="form-control rounded p-2 border-none"
                        placeholder="Email" [ngClass]="" />
                    <div class="next-btn text-center position-relative">
                        <a (click)="reset()" class="position-absolute reset text-80 text-white z-depth-0 font-weight-500">Reset Password</a>
                        <a (click)="next()" class="btn btn-primary">Next <em class="fa fa-caret-right ml-1"></em></a>
                    </div>
                </div>
                <div *ngIf="step2" class="login_box w-50 rounded">
                    <input type="password" name="password" [(ngModel)]="password" class="form-control rounded p-2 border-none mb-2"
                    placeholder="Password" [ngClass]="" />
                    <input type="text" name="doj" [(ngModel)]="doj" class="form-control rounded p-2 border-none"
                    placeholder="YYYY-MM-DD" [owlDateTime]="parmanent" [owlDateTimeTrigger]="parmanent" />
                    <owl-date-time [pickerType]="'calendar'" #parmanent></owl-date-time>
                    <div class="next-btn text-center">
                        <a (click)="auth()" class="btn btn-primary">Next <em class="fa fa-caret-right ml-1"></em></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Login Coder Scan -->

<!-- <div class="login_code_sec">
   
    <div class="d-flex flex-wrap justify-content-center align-items-center position-absolute w-100 h-100">
        <div class="scan_dot_box w-25 h-50 rounded d-flex flex-wrap justify-content-center align-items-center">
            <div class="description_login pl-4 pr-4 pb-4">
                <p class="m-0 text-white text-justify">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </p>
            </div>
            <div class="break"></div>
            <div class="d-flex w-100 justify-content-center align-items-center">
                <div class="back_reset d-flex justify-content-between pl-4 pr-4 w-100">
                    <a href="#" class="btn btn-primary rounded-circle"><i class="fa fa-chevron-left"
                            aria-hidden="true"></i></a>
                    <button class="btn btn-primary">Reset</button>
                </div>
            </div>
            <div class="break"></div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
            <div class="dot_box d-flex w-25 justify-content-center align-items-center">
                <span class="dots white rounded-circle"></span>
            </div>
        </div>
    </div>
</div> -->