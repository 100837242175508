import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../api-client/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TemplateModel } from '../models/template.model';
import { TemplateFormBuilder } from './template.form-builder';
import { UiService } from '../ui.service';



@Component({
  selector: 'app-notification-template',
  templateUrl: './notification-template.component.html',
  styleUrls: ['./notification-template.component.css']
})
export class NotificationTemplateComponent implements OnInit {

  submitted = false;
  model = new TemplateModel();
  templateForm: any;
  templates:any;
  config = {
    toolbar :[
      { name: 'document',  items: [ 'Source' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      //{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
      //{ name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
      '/',
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ], items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
      { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      { name: 'insert', items: [ 'Table'] },
      '/',
      { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
      { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      //{ name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
      //{ name: 'others', items: [ '-' ] },
      //{ name: 'about', items: [ 'About' ] }
    ]
  }

  constructor(private _templateService: UtilityService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _toster: ToastrService,
    private _formBuilder: TemplateFormBuilder,
    private _router: Router) { }

  ngOnInit() {
    this.templateForm = this._formBuilder.getForm();
    this._uiService.changeAppTitle.next("Notification Template");
    this.getTemplates();
  }

  get f() { return this.templateForm.controls; }

  onSubmit() {
    if(!this.templateForm.controls['id'].value){
      this._toster.error("You can not add new template.");
      return false;
    }
    this.submitted = true;
    if (this.templateForm.invalid) {
      return;
    }

    this.model.deserialize(this.templateForm.value);
    this._templateService.save(this.model)
      .subscribe(
        (response: TemplateModel) => {
          this._toster.success("Template save");
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

  getTemplate(templateId:any){
    this._formBuilder.setValues(this._templateService.getTemplate(templateId));    
  }

  getTemplates(){
    this._templateService.getTemplates().subscribe((result:any)=>{
      this.templates = result;
    })
  }

  resetForm(){
    this.submitted = false;
    this.templateForm.reset();
  }

  filterTemplate(ele: any) {
    var items: any;
    if (ele.value) {
      items = this.templates.filter((x: any) => x.subject.toLowerCase().includes(ele.value.toLowerCase()));
      this.templates = items;
    } else {
      this.getTemplates();
    }
  }

  copyFrom(arg:any){

  }

}
