import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AcademiaService } from '../api-client/academia.service';
import { Subscription } from 'rxjs';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from '../api-client/exam.service';
import { config } from '../config';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css']
})
export class ExamsComponent implements OnInit, OnChanges {

  @Input() session: any;
  progrems = [];
  activeSession: any;
  exams: any;
  examDetails: any = null;
  selectedSemDetails: any = null;
  tabHeading: any;
  popUpHeading: any;
  popupVal = null;
  tabType: any;
  showRows = [1, 2, 3, 4, 5, 6, 7, 8];
  tabSelected = 3;
  newExamData = { name: null, active: false, academicSessionId: null, programs: [], id: null };
  private _academiaServiceSubscription: Subscription;
  constructor(
    private _router: Router,
    private _toster: ToastrService,
    private _examService: ExamService,
    private _academiaService: AcademiaService) {

  }

  ngOnInit() {
    this.examDetails = null;
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (this.session) {
      this.getExams(this.session);
    }
  }

  getExams(session: any) {
    this.progrems = [];
    this.showRows = [1, 2, 3, 4, 5, 6, 7, 8];
    this.tabSelected = 3;
    this.newExamData = { name: null, active: false, academicSessionId: null, programs: [], id: null };
    this.examDetails = null;
    this._academiaService.getSessionWiseExams(session.id).subscribe((result) => {
      this.exams = result;
      this.exams.push({ name: "Add New", active: false, academicSessionId: session.id, programs: [], id: 0 });
    })
  }

  openModal(ele: any) {
    this.selectedSemDetails = ele;
  }

  selectExam(exam: any) {
    if (!exam) {
      this.progrems = [];
      this.showRows = [1, 2, 3, 4, 5, 6, 7, 8];
      this.tabSelected = 3;
      return null;
    }
    this.examDetails = null;
    this._examService.getExam(exam).subscribe((result: any) => {
      this.newExamData.name = null;
      this.newExamData.id = null;
      this.newExamData.active = false;
      this.examDetails = result;
      if (result != null && result.id) {
        this.newExamData.name = result.name;
        this.newExamData.id = result.id;
        this.newExamData.active = result.active;
      }

      let getProgrems = this._academiaService.getPrograms();
      var academicLevelData = Object.entries(config.academicLevel).map(([key,value])=> ({key,value}));
      var data = [];
      for (var i = 0; i < getProgrems.length; i++) {
        var academicLevel = getProgrems[i].academicLevel;
        if (!data[academicLevel]) {
          var filterData =academicLevelData.filter((x:any)=>x.key==academicLevel);
          var title = filterData[0].value;
          data[academicLevel] = { id: academicLevel, name: title, children: [] };
        }

        data[academicLevel].children.push({
          id: getProgrems[i].id,
          name: getProgrems[i].name,
          disciplineId: getProgrems[i].disciplineId,
          academicLevel: getProgrems[i].academicLevel,
          numberOfSemesters: getProgrems[i].numberOfSemesters,
          semesterWiseExamValues: this.matchExamDetailsValue(getProgrems[i])
        });
      }
      this.progrems = data;
    })
  }

  matchExamDetailsValue(progremInfo: any) {
    if (!progremInfo) return null;
    //if (!this.examDetails) return null;
    let semesterWiseExamValues = {};
    var progrems = [];
    if (this.examDetails) {
      progrems = this.examDetails.programs
        .filter((x: any) => x.academicProgramId == progremInfo.id);
    }

    for (var i = 1; i <= progremInfo.numberOfSemesters; i++) {
      if (progrems != null && progrems.length > 0) {
        let filteredSemesters = progrems[0].semesters.filter((x: any) => x.semester == i);
        if (filteredSemesters.length > 0) {
          semesterWiseExamValues[i] = filteredSemesters[0];
          semesterWiseExamValues[i].checked = true;
          if (semesterWiseExamValues[i].examination.start) {
            semesterWiseExamValues[i].examination.start = new Date(semesterWiseExamValues[i].examination.start);
          }
          if (semesterWiseExamValues[i].examination.end) {
            semesterWiseExamValues[i].examination.end = new Date(semesterWiseExamValues[i].examination.end);
          }
          if (semesterWiseExamValues[i].examination.resultPublishDate) {
            semesterWiseExamValues[i].examination.resultPublishDate = new Date(semesterWiseExamValues[i].examination.resultPublishDate);
          }
          if (semesterWiseExamValues[i].examination.gradeCardRemarks) {
            semesterWiseExamValues[i].examination.gradeCardRemarks = semesterWiseExamValues[i].examination.gradeCardRemarks;
          }
          if (semesterWiseExamValues[i].review.start) {
            semesterWiseExamValues[i].review.start = new Date(semesterWiseExamValues[i].review.start);
          }
          if (semesterWiseExamValues[i].review.end) {
            semesterWiseExamValues[i].review.end = new Date(semesterWiseExamValues[i].review.end);
          }
          if (semesterWiseExamValues[i].review.resultPublishDate) {
            semesterWiseExamValues[i].review.resultPublishDate = new Date(semesterWiseExamValues[i].review.resultPublishDate);
          }
          if (semesterWiseExamValues[i].review.gradeCardRemarks) {
            semesterWiseExamValues[i].review.gradeCardRemarks = semesterWiseExamValues[i].review.gradeCardRemarks;
          }
        } else {
          semesterWiseExamValues[i] = {
            checked: false,
            examination: { start: null, end: null, gradeCardRemarks: null, resultPublishDate: null },
            review: { start: null, end: null, gradeCardRemarks: null, resultPublishDate: null },
            semester: i,
          };
        }
      } else {
        semesterWiseExamValues[i] = {
          checked: false,
          examination: { start: null, end: null, gradeCardRemarks: null, resultPublishDate: null },
          review: { start: null, end: null, gradeCardRemarks: null, resultPublishDate: null },
          semester: i,
        };
      }
    }
    return semesterWiseExamValues;
  }

  showRow(arg: any) {
    if (arg == 1) {
      this.tabSelected = 1;
      this.showRows = [1, 3, 5, 7];
    } else if (arg == 2) {
      this.tabSelected = 2;
      this.showRows = [2, 4, 6, 8];
    } else if (arg == 3) {
      this.tabSelected = 3;
      this.showRows = [1, 2, 3, 4, 5, 6, 7, 8];
    }
  }

  save() {
    var program = [];
    var error = [];
    //console.log(this.progrems);
    this.progrems.forEach((item: any) => {
      var children = item.children;
      for (var j = 0; j < children.length; j++) {
        var semesters = [];
        var semesterWiseExamValues = children[j].semesterWiseExamValues;
        for (var key in semesterWiseExamValues) {
          if (semesterWiseExamValues[key].checked && this.showRows.includes(parseInt(key))) {
            semesters.push({
              checked: semesterWiseExamValues[key].checked,
              semester: semesterWiseExamValues[key].semester,
              examination: {
                start: semesterWiseExamValues[key].examination.start,
                end: semesterWiseExamValues[key].examination.end,
                gradeCardRemarks: semesterWiseExamValues[key].examination.gradeCardRemarks,
                resultPublishDate: semesterWiseExamValues[key].examination.resultPublishDate
              },
              review: {
                start: semesterWiseExamValues[key].review.start,
                end: semesterWiseExamValues[key].review.end,
                gradeCardRemarks: semesterWiseExamValues[key].review.gradeCardRemarks,
                resultPublishDate: semesterWiseExamValues[key].review.resultPublishDate
              },
            });
          }
        }
        if (semesters.length > 0) {
          program.push({ academicProgramId: children[j].id, semesters: semesters });
        }
      }
    });

    if (this.newExamData.name == "" || this.newExamData.name == null) {
      this._toster.error("Exam name can not be blank");
      return;
    }
    /* for (var k = 0; k < program.length; k++) {
      var semester = program[k].semesters;
      if (semester.length == 0) {
        error.push("Semester not checked");
        break;
      }
    } */
    //console.log(program);return;

    this.newExamData.academicSessionId = this.session.id;
    this.newExamData.programs = program;
    this._examService.save(this.newExamData).subscribe((response: any) => {
      this._toster.success("Examination saved");
    });
    //console.log(this.newExamData);
  }

  copyData(ele: any, fieldType: any, fieldName: any, degree: number, type: string) {
    this.progrems.forEach((item: any, index: number) => {
      if (index == degree) {
        var children = item.children;
        for (var j = 0; j < children.length; j++) {
          var semesterWiseExamValues = children[j].semesterWiseExamValues;
          for (var key in semesterWiseExamValues) {
            if (this.showRows.includes(parseInt(key))) {
              if (fieldType == 'checkbox') {
                if (ele.target.checked) {
                  this.progrems[index].children[j].semesterWiseExamValues[key].checked = true;
                } else {
                  this.progrems[index].children[j].semesterWiseExamValues[key].checked = false;
                }
              } else {
                if (type == 'examination') {
                  if (fieldName == 'start') {
                    this.progrems[index].children[j].semesterWiseExamValues[key].examination.start = new Date(ele.value);
                  }
                  if (fieldName == 'end') {
                    this.progrems[index].children[j].semesterWiseExamValues[key].examination.end = new Date(ele.value);
                  }
                  if (fieldName == 'publish') {
                    this.progrems[index].children[j].semesterWiseExamValues[key].examination.resultPublishDate = new Date(ele.value);
                  }
                  if (fieldName == 'remarks') {
                    this.progrems[index].children[j].semesterWiseExamValues[key].examination.gradeCardRemarks = ele.target.value;
                  }
                }
                if (type == 'review') {
                  if (fieldName == 'start') {
                    this.progrems[index].children[j].semesterWiseExamValues[key].review.start = new Date(ele.value);
                  }
                  if (fieldName == 'end') {
                    this.progrems[index].children[j].semesterWiseExamValues[key].review.end = new Date(ele.value);
                  }
                  if (fieldName == 'publish') {
                    this.progrems[index].children[j].semesterWiseExamValues[key].review.resultPublishDate = new Date(ele.value);
                  }
                  if (fieldName == 'remarks') {
                    this.progrems[index].children[j].semesterWiseExamValues[key].review.gradeCardRemarks = ele.target.value;
                  }
                }
              }
            }
          }
        }
      }
    });
  }

  globalPopUp(id: number, tab: string) {
    this.tabHeading = null;
    this.popUpHeading = null;

    this.tabHeading = id;
    if (tab == 'review') {
      this.popUpHeading = "Post Publication Review (" + config.academicLevel[id] + " )";
    } else {
      this.popUpHeading = "Regular & Supplementary (" + config.academicLevel[id] + " )";
    }
    this.tabType = tab;
    this.popupVal = null;
  }

  getGlobalPopUpVal(ele: any, type: any) {
    this.copyData(ele, 'text', type, this.tabHeading, this.tabType);
  }
}
