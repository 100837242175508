import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DepartmentModel } from '../models/department.model';

@Injectable({
    providedIn: 'root'
})
export class OrganizationFormBuilder{

    private form:any;
    private validationRules = {        
        id:['',''],
        parent: ['',Validators.required],
        name: ['',Validators.required]
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:DepartmentModel){
        this.form.patchValue({
            id:response.id,
            parent:response.parent,
            name:response.name
        });
    }
}