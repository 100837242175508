import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';

import { config } from '../../app/config';
import { paperService } from '../api-client/paper.service';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UiService } from '../ui.service';
import { OrganizationService } from '../api-client/organization.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { StudentService } from '../api-client/student.service';
import { async } from '@angular/core/testing';
import { ExportAsService } from 'ngx-export-as';
import { GradeCardUtility } from '../utility/grade-card-utility';
import { ExamService } from '../api-client/exam.service';
import { CommonMethods } from '../common-methods';
import { ProgramService } from '../api-client/program.service';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit, OnChanges, OnDestroy {

  private _paperServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;
  private _academiaSessionServiceSubscription: Subscription;

  students: any;
  session: any;
  disciplines: any;
  programs: any;
  filterKeyword = "";
  filterProgram = [];
  filterSession = "";
  convocationSession = "";
  filterSemester = 0;
  filterRegistrationYear: any = null;
  filterBatchStartYear: any = null;
  filters = {};
  pageSize: number = config.paginationPerPage;
  totalItemsCount: number = 0;
  totalPageCount: number = 0;
  papersCurrentPage: any = 1;
  academiaSessions: any;
  studentCsvHeader = config.studentCsvHeader;
  semDate: any;
  studentTabulation: any;
  studentPapers: any;
  tabulationStudents = [];
  config = config;
  pprTabulationStudents = [];
  programSearchSettings = {
    singleSelection: false,
    text: "Select Programs",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    badgeShowLimit: 3
  };
  type: any;
  program: any;
  discipline: any;
  gradeCards: any;
  title: any;
  pprType: any;
  activeExam: any;
  exportsStudents: any;
  consolidateStudents: any;
  consolidateStudentsResult: any;
  programWiseSemester: any;
  examinations: any;
  examinationId = "";
  evaluatorType = "";
  commonMethodsSemName = CommonMethods;
  programeName: any;
  examinationSession: any;
  programWiseSemester2: any;
  transcriptResult: any;
  nadStudentsResult: any;

  CBCS_MAJOR: number = 304;
  convocationStudentsResult: any;
  backlogStudentsResult: any;
  allPrograms: any;
  programTitle: any;
  statisticsData: any;
  selected_program: any;
  includeOnly: number = 0;
  DSE_MAJOR: number = 32;
  SEC_MAJOR: number = 256;
  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _translate: TranslateService,
    private _studentService: StudentService,
    private _exportAsService: ExportAsService,
    private _examService: ExamService,
    private _organizationService: OrganizationService,

    private _router: Router,
    private _programService: ProgramService,) {

    this._academiaService.examination().subscribe((result: any) => {
      this.examinationSession = result;
    });

    this._academiaService.getActiveExam().subscribe((result: any) => {
      this.activeExam = result;
    });
    this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.programs = this._academiaService.getPrograms();
      this.disciplines = this._academiaService.getDisciplines();
      this.academiaSessions = this._academiaService.getAcademicSession();
    });
    this._academiaSessionServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.getExaminationsRespectiveSession();
    });




  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.getExaminationsRespectiveSession();
  }

  ngOnInit() {
    this._uiService.changeAppTitle.next("Students");
    this.academiaSessions = this._academiaService.getAcademicSession();
    this.disciplines = this._academiaService.getDisciplines();
    this.programs = this._academiaService.getPrograms();
    // console.log('this.academiaSessions',this.academiaSessions);
    this._academiaService.getActiveExam().subscribe((result: any) => {
      this.activeExam = result;
    });
    this.studentFilter();
  }


  studentFilter() {
    this.filters = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "passoutSessionId": this.convocationSession ? this.convocationSession : null,
      "programIds": this.filterProgram.map(x => x.id),
      "semester": this.filterSemester,
      "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
      "registrationYear": this.filterRegistrationYear ? this.filterRegistrationYear : 0,
      "lastExaminationId": this.examinationId ? this.examinationId : null,
      "mode": this.evaluatorType ? this.evaluatorType : 0,
      "startIndex": (this.papersCurrentPage - 1) * this.pageSize,
      "pageSize": this.pageSize,
      "include": Number(this.includeOnly),
    };
    this._academiaService.students(this.filters).subscribe((result: any) => {
      for (var i = 0; i < result.items.length; i++) {
        var sessionDetails = this.sessionDetails(result.items[i].academicSessionId);
        result.items[i].sessionName = sessionDetails ? sessionDetails.name : '';
      }
      this.students = result.items;

      this.totalItemsCount = result.totalCount;
      this.totalPageCount = Math.ceil(this.totalItemsCount / this.pageSize);
    });
  }

  migrateBlock(typy: any, student: any, data: any) {
    if (typy === 1) {
      data.migrated = student;
    } else {
      data.blocked = student;
    }

    this._studentService.saveStudent(data)
      .subscribe(
        (response: any) => {

          if (typy === 1) {
            if (student === true) {
              this._tosterService.success("Student migrated successfully");
            } else {
              this._tosterService.success("Student unmigrated successfully");
            }
          } else {
            if (student === true) {
              this._tosterService.success("Student block successfully");
            } else {
              this._tosterService.success("Student unblock successfully");
            }
          }
          return false;
        },
        error => {
          this._tosterService.error(error.message);
        }
      )


  }

  sessionDetails(sessionId: any) {
    if (this.academiaSessions == null) return null;
    var items = this.academiaSessions.filter((x: any) => x.id == sessionId);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  onPageChange(pageIndex: any) {
    this.papersCurrentPage = pageIndex;
    this.studentFilter();
  }

  redirectTo(student: any) {
    this._router.navigate([{
      outlets: { rightMainPopup: ['student-summary', student.id] }
    }]);
  }

  async getExaminationsRespectiveSession() {
    var selectedSession = this._academiaService.getActiveAcademicSession();
    if (!selectedSession) return;
    await this._academiaService.getSessionWiseExams(selectedSession.id).toPromise().then((result: any) => {
      this.examinations = result;
    })
  }

  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
    this._academiaSessionServiceSubscription.unsubscribe();
  }

  previewCsvData(fieldMaps: any, results: any) {
    this._uiService.indeterminateProgressTextSet.next('Arrange data...');
    var columns = [];
    var columnSources = [];
    var columnsWidth = {};
    this.academiaSessions = this._academiaService.getAcademicSession();
    this.disciplines = this._academiaService.getDisciplines();
    this.programs = this._academiaService.getPrograms();
    columnSources['academicSessionId'] = this.academiaSessions.map((x: any) => {
      return { id: x.id, name: x.name };
    });

    columnSources['academicProgramId'] = this.programs.map((x: any) => {
      return { id: x.id, name: x.name };
    });
    var arrangedData = results.map((value: any) => {
      var item = {};
      for (var key in fieldMaps) {
        var valueWidth = (value[fieldMaps[key]] + '').length * 15;
        item[key] = value[fieldMaps[key]];
        if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
          columnsWidth[key] = valueWidth;
        }
      }
      //debugger;
      if (item['Stream'] && item['Discipline'] && !item['academicProgramId']) {
        var disciplines = this.disciplines.filter(x => x.name.toUpperCase() == item['Discipline'].trim().toUpperCase());
        if (disciplines.length > 0) {
          var stream = config.academicLevelShort[item['Stream']];
          var programs = this.programs.filter(x => x.disciplineId == disciplines[0].id && x.academicLevel == stream);
          if (programs.length > 0) {
            item['academicProgramId'] = programs[0].id;
          }
        }
      }

      if (item['batchStartYear'] && !item['academicSessionId']) {
        var sessions = this.academiaSessions.filter(x => ((new Date(x.start)).getFullYear() == item['batchStartYear']));
        if (sessions.length > 0) {
          item['academicSessionId'] = sessions[0].id;
        }
      }
      return item;
    });

    this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
    this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
    columnsWidth = null;

    this._uiService.indeterminateProgressTextSet.next(null);

    this._uiService.openDataPreviewWindow(arrangedData, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
        }
      }
    ]);
  }

  onImportPreviewCompleted(fields: any, arrangedData: any, columns: any) {
    var data = this._uiService.getPreviewModifiedData();
    var converted = data.map((value: any) => {
      var item = {};
      for (var i = 0; i < fields.length; i++) {
        item[fields[i]] = value[i];
      }
      return item;
    });
    this._uiService.closeDataPreview();
    let worker = new Worker('../workers/import-students.worker', { name: 'student-worker', type: 'module' });

    this._uiService.startWorker(worker, converted)
      .then(() => {
        //show success message, if required
      }).catch(e => {
        console.error('worker error',e);
        if (e.validation) {
          this.onImportValidationError(e.validation, converted, columns, fields);
        }
      });
  }

  onImportValidationError(validation: any, converted: any, columns: any, fields: any) {
    this._uiService.onDataPreviewReady = () => {
      var allFields = Object.keys(converted[0]);
      for (var i = 0; i < validation.length; i++) {
        var commonErrors = [];
        for (var field in validation[i]) {
          var fieldIndex = allFields.indexOf(field);
          if (fieldIndex <= 0) {
            commonErrors = commonErrors.concat(validation[i][field]);
          } else {
            this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
          }
        }
        if (commonErrors.length > 0) {
          this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
        }
      }
    };
    this._uiService.openDataPreviewWindow(converted, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(fields, converted, columns);
        }
      }
    ]);
  }

  mapCsvField(results: any) {
    this._uiService
      .mapCsvField(results.meta.fields, this.studentCsvHeader)
      .then((maps: any) => {
        this.previewCsvData(maps, results.data);
      }).catch(() => { });
  }

  uploadFile(fileEle: any) {
    this._uiService
      .parseCsv(fileEle.files[0])
      .then((results: any) => {
        fileEle.value = null;
        this.mapCsvField(results);
      }).catch(() => {
        fileEle.value = null;
      });
  }

  updateStudentCurrentSemester() {
    if (!this.semDate) {
      this._tosterService.error("Please select date");
      return false;
    }
    this._academiaService.updateStudentCurrentSemester(this.semDate).subscribe((response: any) => {
      this._tosterService.success("Semester's updated");
      this.relode();
    })
  }

  relode() {
    this.studentFilter();
  }

  async studentsTabulation() {
    var filters = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "programIds": this.filterProgram.map(x => x.id),
      "semester": 0,
      "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
      "registrationYear": 0,
      "lastExaminationId": this.examinationId ? this.examinationId : null,
      "mode": this.evaluatorType ? this.evaluatorType : 0,
      "startIndex": 0,
      "pageSize": 0
    };
    await this._academiaService.students(filters).toPromise().then((result: any) => {
      this.tabulationStudents = result.items;
    });
  }


  async studentsGradeCardTabulation() {
    this.allPrograms = this.filterProgram;
    var receiveData = [];
    this.programTitle = '';
    for (var n = 0; n < this.allPrograms.length; n++) {
      this.programTitle = this.programTitle + this.allPrograms[n].name + ',';
      var filters = {
        "freeText": this.filterKeyword,
        "academicSessionId": this.filterSession,
        "passoutSessionId": this.convocationSession ? this.convocationSession : null,
        "programIds": [this.allPrograms[n].id],
        "semester": 0,
        "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
        "registrationYear": 0,
        "lastExaminationId": this.examinationId ? this.examinationId : null,
        "mode": this.evaluatorType ? this.evaluatorType : 0,
        "startIndex": 0,
        "pageSize": 0
      };
      await this._academiaService.students(filters).toPromise().then((result: any) => {
        receiveData = receiveData.concat(result.items);
      });
    }
    this.tabulationStudents = receiveData;
    return this.tabulationStudents;
  }


  async studentsConsolidated() {
    this.allPrograms = this.filterProgram;
    var receiveData = [];
    this.programTitle = '';
    for (var n = 0; n < this.allPrograms.length; n++) {
      this.programTitle = this.programTitle + this.allPrograms[n].name + ',';
      var filters = {
        "freeText": this.filterKeyword,
        "academicSessionId": this.filterSession,
        "passoutSessionId": this.convocationSession ? this.convocationSession : null,
        "programIds": [this.allPrograms[n].id],
        "semester": this.filterSemester,
        "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
        "registrationYear": 0,
        "startIndex": 0,
        "pageSize": 0
      };
      await this._academiaService.students(filters).toPromise().then((result: any) => {
        receiveData = receiveData.concat(result.items);
      });
    }
    this.consolidateStudents = receiveData;
    return this.consolidateStudents;
  }


  async tabulation(type: any) {
    var students = [];
    var filename = "";
    this.type = type;
    if (this.filterSemester == 0 || this.filterSemester == null) {
      this._tosterService.error("Semester can not be blank.");
      return false;
    }
    if (this.filterProgram.length == 0 || this.filterProgram.length > 1) {
      this._tosterService.error("Select one program.");
      return false;
    }
    if (this.evaluatorType == "") {
      if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
        this._tosterService.error("Batch year can not be blank.");
        return false;
      }
    }

    await this.studentsTabulation();

    var program = this.programs.filter((x: any) => x.id == this.filterProgram[0].id);

    if (this.evaluatorType && this.evaluatorType == '6') {
      var back = 'Backlog ';
    } else {
      var back = '';
    }
    if (program.length > 0) {
      this.program = program[0];
      filename = program[0].name + " " + this.filterSemester + " semester" + " " + back + type.toUpperCase();
    }

    if (!this.tabulationStudents) return;
    //console.log(this.tabulationStudents);return;
    for (var i = 0; i < this.tabulationStudents.length; i++) {
      try {
        if (this.tabulationStudents[i].blocked === false && this.tabulationStudents[i].migrated === false) {
          await this._studentService.getEvaluation(this.tabulationStudents[i].id).toPromise().then((result: any) => {
            var semesterDetails = result.semestersDetail.filter((x: any) => x.semester == this.filterSemester);
            if (semesterDetails.length > 0) {
              semesterDetails[0].courses.sort((x: any, y: any) => {
                if (x.paper.paperType == y.paper.paperType) {
                  return x.paper.code.localeCompare(y.paper.code);
                } else {
                  return (x.paper.paperType - y.paper.paperType);
                }
              });

              var review = false;
              var reviewData = [];
              for (var k = 0; k < semesterDetails[0].courses.length; k++) {
                if (semesterDetails[0].courses[k].previousEntry && Object.entries(semesterDetails[0].courses[k].previousEntry).length > 0 && semesterDetails[0].courses[k].lastEvaluatorType == 4) {
                  review = true;
                  reviewData.push(semesterDetails[0].courses[k].previousEntry);
                } else {
                  reviewData.push(semesterDetails[0].courses[k]);
                }
              }
              var sessionDetails = this.sessionDetails(result.academicSessionId);
              result.sessionName = sessionDetails ? sessionDetails.name : '';
              if (this.evaluatorType && this.evaluatorType == '6') {
                for (var n = 0; n < result.semestersDetail.length; n++) {
                  if (result.semestersDetail[n].semester == this.filterSemester) {
                    var backlog = false;
                    for (var x = 0; x < result.semestersDetail[n].courses.length; x++) {
                      if (result.semestersDetail[n].courses[x].lastAppeardInExaminationId == this.examinationId && result.semestersDetail[n].courses[x].lastEvaluatorType == 6) {
                        backlog = true;
                      }
                    }

                    if (backlog == true) {
                      students.push({ isReviewMarks: review, reviewData: reviewData, studentDetails: result, semesterDetails: semesterDetails[0] });
                    }

                  }
                }
              } else {
                students.push({ isReviewMarks: review, reviewData: reviewData, studentDetails: result, semesterDetails: semesterDetails[0] });
              }

            }
          }).catch((error: any) => {
          });
        }
      } catch (e) {
      }

    }
    this.studentTabulation = students;
    //console.log(JSON.stringify(students));return;
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "studentMarks" }, filename).subscribe(() => {
      });
    }, 1000);
    //console.log(paperCode, students);
  }

  async pprStudentsTabulation(type: any) {

    var filename = "";
    this.pprType = type;
    var exten = type.toUpperCase();
    if (this.filterSemester == 0 || this.filterSemester == null) {
      this._tosterService.error("Semester can not be blank.");
      return false;
    }
    if (this.filterProgram.length == 0 || this.filterProgram.length > 1) {
      this._tosterService.error("Select one program.");
      return false;
    }
    if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
      this._tosterService.error("Batch year can not be blank.");
      return false;
    }

    var program = this.programs.filter((x: any) => x.id == this.filterProgram[0].id);

    if (program.length > 0) {
      this.program = program[0];
      filename = program[0].name + " " + this.filterSemester + " semester PPR " + exten;
    }


    var filters = {
      "programId": this.filterProgram.map(x => x.id)[0],
      "semester": this.filterSemester,
      "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
      "examinationId": this.activeExam.id,
      "mode": 4
    };
    await this._examService.pprMarks(filters).toPromise().then((result: any) => {
      console.log(result);
      var students = [];
      for (var i = 0; i < result.length; i++) {
        if (result[i].blocked === false && result[i].migrated === false) {
          var semesterDetails = result[i].semestersDetail.filter((x: any) => x.semester == this.filterSemester);

          if (semesterDetails.length > 0) {
            semesterDetails[0].courses.sort((x: any, y: any) => {
              if (x.paper.paperType == y.paper.paperType) {
                return x.paper.code.localeCompare(y.paper.code);
              } else {
                return (x.paper.paperType - y.paper.paperType);
              }
            });
            /* var review = false;
            var reviewData = [];
            for (var k = 0; k < semesterDetails[0].courses.length; k++) {
              if (semesterDetails[0].courses[k].previousEntry && Object.entries(semesterDetails[0].courses[k].previousEntry).length > 0 && semesterDetails[0].courses[k].lastEvaluatorType == 4) {
                review = true;
                reviewData.push(semesterDetails[0].courses[k].previousEntry);
              } else {
                reviewData.push(semesterDetails[0].courses[k]);
              }
            } */
            var sessionDetails = this.sessionDetails(result[i].academicSessionId);
            result[i].sessionName = sessionDetails ? sessionDetails.name : '';
            students.push({ studentDetails: result[i], semesterDetails: semesterDetails[0] });
          }
        }
      }
      this.pprTabulationStudents = students;
      //console.log(students); return;
      setTimeout(() => {
        this._exportAsService.save({ type: "csv", elementId: "studentPpr" }, filename).subscribe(() => {
        });
      }, 1000);
    });
  }

  async gradeCard() {
    var students = [];
    var filename = "";
    if (this.filterSemester == 0 || this.filterSemester == null) {
      this._tosterService.error("Semester can not be blank.");
      return false;
    }
    if (this.filterProgram.length == 0) {
      this._tosterService.error("Select one program.");
      return false;
    }

    if (this.evaluatorType == "") {
      if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
        this._tosterService.error("Batch year can not be blank.");
        return false;
      }
    }
    await this.studentsGradeCardTabulation();
    // console.log(this.tabulationStudents);
    if (!this.tabulationStudents) return;
    var program = this.programs.filter((x: any) => x.id == this.filterProgram[0].id);

    if (program.length > 0) {
      this.program = program[0];


      this.title = this.programTitle + " " + this.filterSemester + " semester";
    }

    for (var i = 0; i < this.tabulationStudents.length; i++) {
      try {
        if (this.tabulationStudents[i].blocked === false && this.tabulationStudents[i].migrated === false) {
          await this._studentService.getEvaluation(this.tabulationStudents[i].id).toPromise().then((result: any) => {
            var semesterDetails = result.semestersDetail.filter((x: any) => x.semester == this.filterSemester);
            if (semesterDetails.length > 0) {
              semesterDetails[0].courses.sort((x: any, y: any) => {
                if (x.paper.paperType == y.paper.paperType) {
                  return x.paper.code.localeCompare(y.paper.code);
                } else {
                  return (x.paper.paperType - y.paper.paperType);
                }
              });
              var totPaperCredit = 0;
              var totMajorMarksObtained = 0;
              var totMajorMarks = 0;
              var totGenedMarksObtained = 0;
              var totGenedMarks = 0;

              var totGrandMarksObtained = 0;
              var totalEndTermMarksObtained = 0;
              var totalMajorMarksObtain = 0;
              var totalMajorMarks = 0;

              var totMarksObtained = 0;
              var totEndTermMarksObtained = 0;
              var majorMarks = 0;
              var majorMarksObtained = 0;



              for (var k = 0; k < semesterDetails[0].courses.length; k++) {


                totPaperCredit += semesterDetails[0].courses[k].paper.credit;
                semesterDetails[0].courses[k].paper.name = this.htmlToText(semesterDetails[0].courses[k].paper.name);

                if (semesterDetails[0].courses[k]) {

                  if (semesterDetails[0].courses[k].paper.paperType == 2) {
                    totMajorMarksObtained += semesterDetails[0].courses[k].totalMarksObtained;
                    totMajorMarks += semesterDetails[0].courses[k].paper.fullMarks;

                    // majorMarks += majorMarks + result.semestersDetail[0].courses[k].paper.fullMarks ;
                    // majorMarksObtained += majorMarksObtained + result.semestersDetail[0].courses[k].totalMarksObtained ;
                  }

                  if (semesterDetails[0].courses[k].paper.paperType == 4) {
                    totGenedMarksObtained += semesterDetails[0].courses[k].totalMarksObtained;
                    totGenedMarks += semesterDetails[0].courses[k].paper.fullMarks;
                  }

                  if (semesterDetails[0].courses[k].paper.paperType == this.CBCS_MAJOR) {
                    totMajorMarksObtained += semesterDetails[0].courses[k].totalMarksObtained;
                    totMajorMarks += semesterDetails[0].courses[k].paper.fullMarks;
                  }

                  // console.log('ff2', result.semestersDetail[0].courses[k].totalMarksObtained);

                  if (semesterDetails[0].courses[k].paper.paperType == 16 || semesterDetails[0].courses[k].paper.paperType == 2) {
                    majorMarks = majorMarks + semesterDetails[0].courses[k].paper.fullMarks;
                    majorMarksObtained = majorMarksObtained + semesterDetails[0].courses[k].totalMarksObtained;

                  }

                  totMarksObtained = totMarksObtained + semesterDetails[0].courses[k].paper.fullMarks;
                  totEndTermMarksObtained = totEndTermMarksObtained + semesterDetails[0].courses[k].totalMarksObtained;




                }

              }



              var discipline = this.disciplines.filter((x: any) => x.id == semesterDetails[0].courses[0].paper.disciplineId);
              if (discipline.length > 0) {
                this.discipline = discipline[0].name;

              }

              var selected_program = this.programs.filter((x: any) => x.id == result.academicProgramId);

              if (selected_program.length > 0) {
                this.selected_program = selected_program[0];

                var pro1 = selected_program[0].name.split('.')[0];
                var pro2 = selected_program[0].name.split('.')[1];
                if (pro2 == ' A') {
                  var dep2 = 'Arts';
                } else if (pro2 == 'Sc' || pro2 == ' Sc') {
                  var dep2 = 'Science';
                } else {
                  var dep2 = '';
                }


                if (pro1 == 'B') {
                  var dep1 = 'Bachelor';
                } else if (pro1 == 'M') {
                  var dep1 = 'Master';
                } else {
                  var dep1 = 'Phd';
                }

                var resultOfThe = dep1 + ' ' + 'of ' + dep2 + ' ' + config.sem[this.filterSemester] + " Semester";

              }


              var sessionDetails = this.sessionDetails(result.academicSessionId);
              result.sessionName = sessionDetails ? sessionDetails.name : '';
              result.title = resultOfThe;
              result.discipline = this.discipline;
              result.totPaperCredit = totPaperCredit;
              result.totMajorMarksObtained = totMajorMarksObtained;
              result.totMajorMarks = totMajorMarks;
              result.totGenedMarksObtained = totGenedMarksObtained;
              result.totGenedMarks = totGenedMarks;

              totGrandMarksObtained = totGrandMarksObtained + totMarksObtained;
              totalEndTermMarksObtained = totalEndTermMarksObtained + totEndTermMarksObtained;

              totalMajorMarks = totalMajorMarks + majorMarks;
              totalMajorMarksObtain = totalMajorMarksObtain + majorMarksObtained;

              result.fullMajorMarks = totalMajorMarks;
              result.fullMajorMarksObtain = totalMajorMarksObtain;

              result.totalMarks = totGrandMarksObtained;
              result.totalMarksObtain = totalEndTermMarksObtained;

              if (this.academiaSessions && this.academiaSessions.length > 0) {
                var sessionData = this.academiaSessions.filter((x: any) => x.active == true);
                result.examYear = sessionData[0].end;
                result.examSession = sessionData[0].name;
              }

              if (this.evaluatorType && this.evaluatorType == '6') {
                for (var n = 0; n < result.semestersDetail.length; n++) {
                  if (result.semestersDetail[n].semester == this.filterSemester) {
                    var backlog = false;
                    for (var x = 0; x < result.semestersDetail[n].courses.length; x++) {
                      if (result.semestersDetail[n].courses[x].lastAppeardInExaminationId == this.examinationId && result.semestersDetail[n].courses[x].lastEvaluatorType == 6) {
                        backlog = true;
                      }
                    }

                    if (backlog == true) {
                      students.push({ studentDetails: result, semesterDetails: semesterDetails[0] });
                    }

                  }
                }
              } else {
                students.push({ studentDetails: result, semesterDetails: semesterDetails[0] });
              }


            }
          }).catch((error: any) => {
            console.log('error', error);
          });
        }
      } catch (e) {
        console.log('e', e);
      }
    }
    this.gradeCards = students;
    if (this.evaluatorType && this.evaluatorType == '6') {
      var back = ' Backlog ';
    } else {
      var back = '';
    }

    if (students[0] && students[0].studentDetails && students[0].studentDetails.sessionName) {
      var sessionName = students[0].studentDetails.sessionName;
    }
    else {
      var sessionName = null;
    }

    var csvTitle = this.title + ' ' + sessionName + back + ' GradeCard';
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "studentGradeCard" }, csvTitle).subscribe(() => {
      });
    }, 100);
    //console.log({students:students});return;
    //let gcUtility = new GradeCardUtility();
    //gcUtility.generate({students:students}, filename);

  }

  async export() {
    if (this.filterSemester == 0 || this.filterSemester == null) {
      this._tosterService.error("Semester can not be blank.");
      return false;
    }
    if (this.filterProgram.length == 0 || this.filterProgram.length > 1) {
      this._tosterService.error("Select one program.");
      return false;
    }
    if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
      this._tosterService.error("Batch year can not be blank.");
      return false;
    }

    var filters = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "programIds": this.filterProgram.map(x => x.id),
      "semester": this.filterSemester,
      "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
      "registrationYear": 0,
      "startIndex": 0,
      "pageSize": 0
    };
    var filename = "";
    var program = this.programs.filter((x: any) => x.id == this.filterProgram[0].id);
    if (program.length > 0) {
      this.program = program[0];
      filename = program[0].name + " " + this.filterSemester + " semester";
    }

    await this._academiaService.students(filters).toPromise().then((result: any) => {
      for (var i = 0; i < result.items.length; i++) {
        //result.items[i].address = result.items[i].address.replace(/,/g, "|");
        result.items[i].address = "";
      }

      this.exportsStudents = result.items;
      setTimeout(() => {
        this._exportAsService.save({ type: "csv", elementId: "exportStudents" }, filename).subscribe(() => {
        });
      }, 100);

    });
  }

  async consolidatedReport() {
    var students = [];

    if (this.filterProgram.length == 0) {
      this._tosterService.error("Select one program.");
      return false;
    }
    if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
      this._tosterService.error("Batch year can not be blank.");
      return false;
    }

    await this.studentsConsolidated();

    if (!this.consolidateStudents) return;
    var descipline;
    var program = this.programs.filter((x: any) => x.id == this.filterProgram[0].id);
    var semester = [];
    for (var i = 0; i < program[0].numberOfSemesters; i++) {
      semester.push(i);
    }
    this.programWiseSemester = semester;
    for (var i = 0; i < this.consolidateStudents.length; i++) {
      try {
        await this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then((result: any) => {
          var totCgpaMajorCredit = 0;
          var totCgpaGenedCredit = 0;
          var totCgpaCredit = 0;
          var totCgpaMajorMarksObtained = 0;
          var totCgpaGenedMarksObtained = 0;
          var totCgpaMarksObtained = 0;

          var totCgpaMajorMarks = 0;
          var totMarks = 0;
          var totMarksObtained = 0;
          var totCredit = 0;
          var failInSem = [];
          var totGNDMarks = 0;

          result.semestersDetail.sort((x: any, y: any) => {
            return x.semester - y.semester;
          });

          for (var k = 0; k < result.semestersDetail.length; k++) {
            result.semestersDetail[k].courses.sort((x: any, y: any) => {
              if (x.paper.paperType == y.paper.paperType) {
                return x.paper.code.localeCompare(y.paper.code);
              } else {
                return (x.paper.paperType - y.paper.paperType);
              }
            });
            var totSgpaMajorCredit = 0;
            var totSgpaGenedCredit = 0;
            var totSgpaCredit = 0;
            var totSgpaMajorMarksObtained = 0;
            var totSgpaGenedMarksObtained = 0;
            var totSgpaMarksObtained = 0;
            var totSgpaMajorMarks = 0;
            var totalMarks = 0;
            var totalMarksObtained = 0;
            var totalCredit = 0;
            var totalGND = 0;

            for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
              //Replaceve html tags
              result.semestersDetail[k].courses[s].paper.name = this.htmlToText(result.semestersDetail[k].courses[s].paper.name);

              totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
              totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
              if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == this.CBCS_MAJOR || result.semestersDetail[k].courses[s].paper.paperType == 16 || result.semestersDetail[k].courses[s].paper.paperType == this.DSE_MAJOR || result.semestersDetail[k].courses[s].paper.paperType == this.SEC_MAJOR) {
                totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                totSgpaMajorMarks = totSgpaMajorMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
              }
              if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                totalGND = totalGND + result.semestersDetail[k].courses[s].fullMarks;
              }

              totalMarks = totalMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
              totalMarksObtained = totalMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
              totalCredit = totalCredit + result.semestersDetail[k].courses[s].paper.credit;

              if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                failInSem.push(result.semestersDetail[k].semester);
              }
            }
            result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
            result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
            result.semestersDetail[k].totSgpaCredit = totSgpaCredit;

            result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
            result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;

            result.semestersDetail[k].totSgpaMajorMarks = totSgpaMajorMarks;
            result.semestersDetail[k].totalMarks = totalMarks;
            result.semestersDetail[k].totalMarksObtained = totalMarksObtained;
            result.semestersDetail[k].totalCredit = totalCredit;
            result.semestersDetail[k].totalGND = totalGND;

            totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
            totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;

            totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
            totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;

            totGNDMarks = totGNDMarks + totalGND;

            totCgpaMajorMarks = totCgpaMajorMarks + totSgpaMajorMarks;
            totMarks = totMarks + totalMarks;
            totMarksObtained = totMarksObtained + totalMarksObtained;
            totCredit = totCredit + totalCredit;

          }
          if (failInSem.length > 0) {
            var nonSseSem;// = semester.filter(value => failInSem.includes(value));
            if (semester.length == 6) {
              nonSseSem = [1, 2, 3, 4].filter(value => failInSem.includes(value));
            } else {
              nonSseSem = [1, 2].filter(value => failInSem.includes(value));
            }
            if (nonSseSem.length > 0) {
              result.remarks = "REPEAT(" + failInSem.join("-") + ")";
            } else {
              result.remarks = "SSE(" + failInSem.join("-") + ")";
            }
          } else {
            result.remarks = "PASS";
          }

          var discipline = this.disciplines.filter((x: any) => x.id == result.semestersDetail[0].courses[0].paper.disciplineId);
          if (discipline.length > 0) {
            this.discipline = discipline[0].name;

          }
          result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
          result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;

          result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
          result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
          result.discipline = this.discipline;
          result.cgpa.totCgpaMajorMarks = totCgpaMajorMarks;
          result.cgpa.totMarks = totMarks;
          result.cgpa.totMarksObtained = totMarksObtained;
          result.cgpa.totCredit = totCredit;
          result.cgpa.totGNDMarks = totGNDMarks;
          students.push(result);
        }).catch((error: any) => {
        });

      } catch (e) {
      }

    }
    if (this.academiaSessions.length > 0) {
      var sessionData = this.academiaSessions.filter((x: any) => x.id == students[0].academicSessionId);
      var sessionName = sessionData[0].name.split('-')[0];
    }
    var title = this.programTitle + ' ' + sessionName + ' Consolidated Report';
    this.consolidateStudentsResult = students;
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "studentConsolidateReport" }, title).subscribe(() => {
      });
    }, 100);
  }

  htmlToText(content: any) {
    var htmlTags = content.toString();
    var html = htmlTags.replace(/(<([^>]+)>)/ig, '');
    var text = html.replace(/(\r\n|\n|\r)/gm, "");
    return text.replace(/,/g, " ");
  }


  async convocationReport() {
    var students = [];

    if (this.convocationSession == "" || this.convocationSession == null) {
      this._tosterService.error("Convocation session can not be blank.");
      return false;
    }
    if (this.filterProgram.length == 0) {
      this._tosterService.error("Select one program.");
      return false;
    }
    if (this.filterSemester == 0 || this.filterSemester == null) {
      this._tosterService.error("Semester can not be blank.");
      return false;
    }

    await this.studentsConsolidated();

    //  console.log(this.consolidateStudents);
    if (!this.consolidateStudents) return;
    var descipline;
    var program = this.programs.filter((x: any) => x.id == this.filterProgram[0].id);

    var semester = [];
    for (var i = 0; i < program[0].numberOfSemesters; i++) {
      semester.push(i);
    }
    this.programWiseSemester = semester;

    for (var i = 0; i < this.consolidateStudents.length; i++) {
      try {
        if (this.consolidateStudents[i].unqualified === false && this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false) {
          await this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then((result: any) => {
            var totCgpaMajorCredit = 0;
            var totCgpaGenedCredit = 0;
            var totCgpaCredit = 0;
            var totCgpaMajorMarksObtained = 0;
            var totCgpaGenedMarksObtained = 0;
            var totCgpaMarksObtained = 0;
            var failInSem = [];
            var totalGenedMarksObtained = 0;
            var totalGenedMarks = 0;
            var totalGndMarksObtained = 0;
            var totalGndMarks = 0;

            result.semestersDetail.sort((x: any, y: any) => {
              return x.semester - y.semester;
            });

            var totGrandMarksObtained = 0;
            var totalEndTermMarksObtained = 0;
            var totalMajorMarksObtain = 0;
            var totalMajorMarks = 0;
            for (var k = 0; k < result.semestersDetail.length; k++) {
              result.semestersDetail[k].courses.sort((x: any, y: any) => {
                if (x.paper.paperType == y.paper.paperType) {
                  return x.paper.code.localeCompare(y.paper.code);
                } else {
                  return (x.paper.paperType - y.paper.paperType);
                }
              });
              var totSgpaMajorCredit = 0;
              var totSgpaGenedCredit = 0;
              var totSgpaCredit = 0;
              var totSgpaMajorMarksObtained = 0;
              var totSgpaGenedMarksObtained = 0;
              var totSgpaMarksObtained = 0;
              var totMarksObtained = 0;
              var totEndTermMarksObtained = 0;
              var majorMarks = 0;
              var majorMarksObtained = 0;
              var genEdMarks = 0;
              var genEdMarksObtained = 0;

              for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                //Replaceve html tags
                result.semestersDetail[k].courses[s].paper.name = this.htmlToText(result.semestersDetail[k].courses[s].paper.name);
                totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == this.CBCS_MAJOR) {
                  totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                }
                if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                  totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;

                  genEdMarksObtained = genEdMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                  genEdMarks = genEdMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                }

                if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                  failInSem.push(result.semestersDetail[k].semester);
                }

                if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == 16) {

                  majorMarks = majorMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                  majorMarksObtained = majorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;

                }

                totMarksObtained = totMarksObtained + result.semestersDetail[k].courses[s].paper.fullMarks;
                totEndTermMarksObtained = totEndTermMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
              }
              // console.log('totMarksObtained',totMarksObtained);
              result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
              result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
              result.semestersDetail[k].totSgpaCredit = totSgpaCredit;

              result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
              result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;

              result.semestersDetail[k].totalGenedMarksObtained = totalGenedMarksObtained;
              result.semestersDetail[k].totGenedMarks = totalGenedMarks;

              totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
              totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;

              totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
              totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;

              totGrandMarksObtained = totGrandMarksObtained + totMarksObtained;
              totalEndTermMarksObtained = totalEndTermMarksObtained + totEndTermMarksObtained;

              totalMajorMarks = totalMajorMarks + majorMarks;
              totalMajorMarksObtain = totalMajorMarksObtain + majorMarksObtained;

              totalGndMarksObtained = totalGndMarksObtained + genEdMarksObtained;
              totalGndMarks = totalGndMarks + genEdMarks;


            }

            var discipline = this.disciplines.filter((x: any) => x.id == result.semestersDetail[0].courses[0].paper.disciplineId);
            if (discipline.length > 0) {
              this.discipline = discipline[0].name;

            }

            result.fullMajorMarks = totalMajorMarks;
            result.fullMajorMarksObtain = totalMajorMarksObtain;

            result.totalMarks = totGrandMarksObtained;
            result.totalMarksObtain = totalEndTermMarksObtained;

            result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
            result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;

            result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
            result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
            result.discipline = this.discipline;

            result.totalGndMarksObtained = totalGndMarksObtained;
            result.totalGndMarks = totalGndMarks;

            if (this.programs.length > 0) {
              var ppp = this.programs.filter((x: any) => x.id == result.academicProgramId);
              result.stream = ppp[0].name.split('in')[0];
            }

            if (this.academiaSessions.length > 0) {
              var sessionData = this.academiaSessions.filter((x: any) => x.id == this.convocationSession);
              result.session = sessionData[0].name;
              result.examYear = sessionData[0].end;
            }

            if (result.unqualified === false) {
              students.push(result);
            }

          }).catch((error: any) => {
          });

        }

      } catch (e) {
      }

    }
    this.convocationStudentsResult = students;
    if (this.academiaSessions && this.academiaSessions.length > 0) {
      var sessionData = this.academiaSessions.filter((x: any) => x.id == this.convocationSession);
      var sessionName = sessionData[0].name;
    }

    var csvTitle = this.programTitle + ' ' + sessionName + ' Convocation Report';
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "dataConvocation" }, csvTitle).subscribe(() => {
      });
    }, 100);
  }

  agef(a: any) {

    var p = a.split('in')[0];
    return p;
  }

  depf(e: any) {

    var d = e.split('in')[1].trim();
    if (d == 'H' || d == ' H') {
      return 'Hindi';
    } else if (d == 'Perform') {
      return 'Performing Arts';
    } else {
      return d;
    }

  }
  async backlogStudentReport(a: number, b: number, c: number) {
    var students = [];

    if (this.filterProgram.length == 0 || this.filterProgram.length > 1) {
      this._tosterService.error("Select one program.");
      return false;
    }
    if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
      this._tosterService.error("Batch year can not be blank.");
      return false;
    }
    var filters = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "programIds": this.filterProgram.map(x => x.id),
      "semester": this.filterSemester,
      "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
      "registrationYear": 0,
      "startIndex": 0,
      "pageSize": 0
    };
    await this._academiaService.students(filters).toPromise().then((result: any) => {
      this.consolidateStudents = result.items;
    });
    if (!this.consolidateStudents) return;
    var descipline;
    var program = this.programs.filter((x: any) => x.id == this.filterProgram[0].id);
    if (program.length > 0) {
      var discipline = this.disciplines.filter((x: any) => x.id == program[0].disciplineId);
      if (discipline.length > 0) {
        descipline = discipline[0].name;
      }
    }

    var semester = [];
    for (var i = 0; i < program[0].numberOfSemesters; i++) {
      semester.push(i);
    }

    this.programWiseSemester = semester;
    for (var i = 0; i < this.consolidateStudents.length; i++) {
      try {
        if (this.consolidateStudents[i].unqualified === true && this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false) {

          await this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then(async (result: any) => {
            var totCgpaMajorCredit = 0;
            var totCgpaGenedCredit = 0;
            var totCgpaCredit = 0;
            var totCgpaMajorMarksObtained = 0;
            var totCgpaGenedMarksObtained = 0;
            var totCgpaMarksObtained = 0;
            var failInSem = [];

            var backlogSem = [];

            result.semestersDetail.sort((x: any, y: any) => {
              return x.semester - y.semester;
            });


            for (var k = 0; k < result.semestersDetail.length; k++) {

              // if (result.semestersDetail[k].semester == 1 || result.semestersDetail[k].semester == 3 || result.semestersDetail[k].semester == 5) {


              result.semestersDetail[k].courses.sort((x: any, y: any) => {
                if (x.paper.paperType == y.paper.paperType) {
                  return x.paper.code.localeCompare(y.paper.code);
                } else {
                  return (x.paper.paperType - y.paper.paperType);
                }
              });
              var totSgpaMajorCredit = 0;
              var totSgpaGenedCredit = 0;
              var totSgpaCredit = 0;
              var totSgpaMajorMarksObtained = 0;
              var totSgpaGenedMarksObtained = 0;
              var totSgpaMarksObtained = 0;
              var backlogCourses = [];
              for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                //Replaceve html tags
                result.semestersDetail[k].courses[s].paper.name = this.htmlToText(result.semestersDetail[k].courses[s].paper.name);

                totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == this.CBCS_MAJOR) {
                  totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                }
                if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                  totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                }

                if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                  failInSem.push(result.semestersDetail[k].semester);
                }

                if (result.semestersDetail[k].courses[s].grade == 'F' || result.semestersDetail[k].courses[s].grade == 'AB' || result.semestersDetail[k].courses[s].grade == 'E') {
                  backlogCourses.push(result.semestersDetail[k].courses[s]);
                }

                result.semestersDetail[k].backCourses = backlogCourses;

              }
              result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
              result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
              result.semestersDetail[k].totSgpaCredit = totSgpaCredit;

              result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
              result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;

              totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
              totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;

              totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
              totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;

              if (result.semestersDetail[k].resultOverallRemarks != 'P') {

                if (result.semestersDetail[k].semester == a || result.semestersDetail[k].semester == b || result.semestersDetail[k].semester == c) {
                  backlogSem.push(result.semestersDetail[k]);
                }
              }
              result.backlogSemesterDetails = backlogSem;
              // if (result.semestersDetail[k].resultOverallRemarks == "P") {
              //   result.semestersDetail.splice(k, 1);
              // }
            }

            if (failInSem.length > 0) {
              var nonSseSem;// = semester.filter(value => failInSem.includes(value));
              if (semester.length == 6) {
                nonSseSem = [1, 2, 3, 4].filter(value => failInSem.includes(value));
              } else {
                nonSseSem = [1, 2].filter(value => failInSem.includes(value));
              }
              if (nonSseSem.length > 0) {
                result.remarks = "REPEAT(" + failInSem.join("-") + ")";
              } else {
                result.remarks = "SSE(" + failInSem.join("-") + ")";
              }
            } else {
              result.remarks = "PASS";
            }
            result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
            result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;

            result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
            result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
            result.discipline = descipline;
            if (result.backlogSemesterDetails.length > 0) {
              students.push(result);
            }

          }).catch((error: any) => {
          });
        }
      } catch (e) {
      }

    }
    this.backlogStudentsResult = students;
    if (a == 1) {
      var sem = 'Odd Sem';
    } else {
      var sem = 'Even Sem';
    }
    var csvTitle = program[0].name + ' ' + this.filterBatchStartYear + ' ' + sem + ' Backlog Reports';
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "studentBacklogReport" }, csvTitle).subscribe(() => {
      });
    }, 100);
  }


  async transcriptReport() {
    var students = [];
    if (this.filterKeyword == "" || this.filterKeyword == null) {
      this._tosterService.error("Registration No. can not be blank.");
      return false;
    }
    if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
      this._tosterService.error("Batch year can not be blank.");
      return false;
    }
    var filters = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "programIds": this.filterProgram.map(x => x.id),
      "semester": this.filterSemester,
      "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
      "registrationYear": 0,
      "startIndex": 0,
      "pageSize": 0
    };
    await this._academiaService.students(filters).toPromise().then((result: any) => {
      this.consolidateStudents = result.items;
    });
    if (!this.consolidateStudents) return;
    var descipline;
    var program = this.programs.filter((x: any) => x.id == this.consolidateStudents[0].academicProgramId);
    if (program.length > 0) {
      var discipline = this.disciplines.filter((x: any) => x.id == program[0].disciplineId);
      if (discipline.length > 0) {
        descipline = discipline[0].name;
      }
    }

    var semester = [];
    for (var i = 0; i < program[0].numberOfSemesters; i++) {
      semester.push(i);
    }
    this.programWiseSemester = semester;
    for (var i = 0; i < this.consolidateStudents.length; i++) {
      try {
        if (this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false) {
          await this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then((result: any) => {
            var totCgpaMajorCredit = 0;
            var totCgpaGenedCredit = 0;
            var totCgpaCredit = 0;
            var totCgpaMajorMarksObtained = 0;
            var totCgpaGenedMarksObtained = 0;
            var totCgpaMarksObtained = 0;
            var failInSem = [];

            result.semestersDetail.sort((x: any, y: any) => {
              return x.semester - y.semester;
            });

            for (var k = 0; k < result.semestersDetail.length; k++) {
              result.semestersDetail[k].courses.sort((x: any, y: any) => {
                if (x.paper.paperType == y.paper.paperType) {
                  return x.paper.code.localeCompare(y.paper.code);
                } else {
                  return (x.paper.paperType - y.paper.paperType);
                }
              });
              var totSgpaMajorCredit = 0;
              var totSgpaGenedCredit = 0;
              var totSgpaCredit = 0;
              var totSgpaMajorMarksObtained = 0;
              var totSgpaGenedMarksObtained = 0;
              var totSgpaMarksObtained = 0;

              for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                //Replaceve html tags
                result.semestersDetail[k].courses[s].paper.name = this.htmlToText(result.semestersDetail[k].courses[s].paper.name);

                totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == this.CBCS_MAJOR) {
                  totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                }
                if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                  totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                }

                if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                  failInSem.push(result.semestersDetail[k].semester);
                }

              }
              result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
              result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
              result.semestersDetail[k].totSgpaCredit = totSgpaCredit;

              result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
              result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;

              totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
              totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;

              totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
              totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;

            }

            result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
            result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;

            result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
            result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
            result.discipline = descipline;

            result.courseStudy = program[0].name.split('in')[0];
            students.push(result);
          }).catch((error: any) => {
          });
        }
      } catch (e) {
      }

    }
    var title = this.filterKeyword + '_' + program[0].name.split('in')[0] + '_' + descipline + ' transcript';
    this.transcriptResult = students;
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "transcriptReport" }, title).subscribe(() => {
      });
    }, 100);
  }

  async nadReport() {
    var students = [];

    if (this.filterProgram.length == 0) {
      this._tosterService.error("Select one program.");
      return false;
    }
    if (this.convocationSession == "" || this.convocationSession == null) {
      this._tosterService.error("Convocation Session can not be blank.");
      return false;
    }
    if (this.filterSemester == 0 || this.filterSemester == null) {
      this._tosterService.error("Semester can not be blank.");
      return false;
    }
    await this.studentsConsolidated();

    if (!this.consolidateStudents) return;

    var semester = [];

    for (var i = 0; i < this.consolidateStudents.length; i++) {
      try {
        if (this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false) {
          await this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then((result: any) => {
            var totCgpaMajorCredit = 0;
            var totCgpaGenedCredit = 0;
            var totCgpaCredit = 0;
            var totCgpaMajorMarksObtained = 0;
            var totCgpaGenedMarksObtained = 0;
            var totCgpaMarksObtained = 0;
            var failInSem = [];

            result.semestersDetail.sort((x: any, y: any) => {
              return x.semester - y.semester;
            });

            var program = this.programs.filter((x: any) => x.id == this.consolidateStudents[i].academicProgramId);
            if (program.length > 0) {
              this.program = program[0];
              var p1 = program[0].name.split('.')[0];
              var p2 = program[0].name.split('.')[1];
              if (p2 == ' A') {
                var d2 = 'Arts';
              } else if (p2 == 'Sc' || p2 == ' Sc') {
                var d2 = 'Science';
              } else {
                var d2 = '';
              }


              if (p1 == 'B') {
                var d1 = 'Bachelor';
              } else if (p1 == 'M') {
                var d1 = 'Master';
              } else {
                var d1 = 'Phd';
              }

              var proTitle = d1 + ' ' + 'of ' + d2;
              this.title = program[0].name + " " + this.filterSemester + " semester";

            }



            for (var k = 0; k < result.semestersDetail.length; k++) {
              result.semestersDetail[k].courses.sort((x: any, y: any) => {
                if (x.paper.paperType == y.paper.paperType) {
                  return x.paper.code.localeCompare(y.paper.code);
                } else {
                  return (x.paper.paperType - y.paper.paperType);
                }
              });
              var totSgpaMajorCredit = 0;
              var totSgpaGenedCredit = 0;
              var totSgpaCredit = 0;
              var totSgpaMajorMarksObtained = 0;
              var totSgpaGenedMarksObtained = 0;
              var totSgpaMarksObtained = 0;

              for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                //Replaceve html tags
                result.semestersDetail[k].courses[s].paper.name = this.htmlToText(result.semestersDetail[k].courses[s].paper.name);

                totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == this.CBCS_MAJOR) {
                  totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                }
                if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                  totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                }

                if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                  failInSem.push(result.semestersDetail[k].semester);
                }


              }
              result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
              result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
              result.semestersDetail[k].totSgpaCredit = totSgpaCredit;

              result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
              result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;

              totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
              totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;

              totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
              totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;

            }
            if (failInSem.length > 0) {
              var nonSseSem;// = semester.filter(value => failInSem.includes(value));
              if (semester.length == 6) {
                nonSseSem = [1, 2, 3, 4].filter(value => failInSem.includes(value));
              } else {
                nonSseSem = [1, 2].filter(value => failInSem.includes(value));
              }
              if (nonSseSem.length > 0) {
                result.remarks = "REPEAT(" + failInSem.join("-") + ")";
              } else {
                result.remarks = "SSE(" + failInSem.join("-") + ")";
              }
            } else {
              result.remarks = "PASS";
            }

            var discipline = this.disciplines.filter((x: any) => x.id == result.semestersDetail[0].courses[0].paper.disciplineId);
            if (discipline.length > 0) {
              this.discipline = discipline[0].name;

            }
            result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
            result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;

            result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
            result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
            result.discipline = this.discipline;

            result.courseName = program[0].name;
            result.programTitle = proTitle;

            if (this.academiaSessions.length > 0) {
              var sessionData = this.academiaSessions.filter((x: any) => x.id == result.academicSessionId);
              result.session = sessionData[0].end;
            }

            if (this.academiaSessions && this.academiaSessions.length > 0) {
              var sessionData = this.academiaSessions.filter((x: any) => x.active == true);
              result.examYear = sessionData[0].name.split('-')[0];
            }

            students.push(result);
          }).catch((error: any) => {
          });
        }
      } catch (e) {
      }

    }
    if (this.academiaSessions && this.academiaSessions.length > 0) {
      var sessionData = this.academiaSessions.filter((x: any) => x.id == this.convocationSession);
      var sessionName = sessionData[0].name;
    }
    var title = this.programTitle + ' ' + sessionName + ' NAD Report';
    this.nadStudentsResult = students;
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "studentNADReport" }, title).subscribe(() => {
      });
    }, 100);
  }


  async statisticsReport() {
    if (this.examinationId == "" || this.examinationId == null) {
      this._tosterService.error("Examination can not be blank.");
      return false;
    }

    var items = this.examinationSession.filter((x: any) => x.id == this.examinationId);
    var exam = items[0].name;
    await this._studentService.getStatisticsReport(this.examinationId).toPromise().then((result: any) => {
      this.statisticsData = result;
    });

    this.programWiseSemester = ['I', 'II', 'III', 'IV', 'V', 'VI'];
    var title = exam + ' StatisticsReport';
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "statisticsReport" }, title).subscribe(() => {
      });
    }, 100);


  }


  async blockReport() {
    var students = [];

    if (this.filterProgram.length == 0) {
      this._tosterService.error("Select atlist one program.");
      return false;
    }

    await this.studentsConsolidated();

    if (!this.consolidateStudents) return;
    var descipline;
    var program = this.programs.filter((x: any) => x.id == this.filterProgram[0].id);
    var semester = [];
    for (var i = 0; i < program[0].numberOfSemesters; i++) {
      semester.push(i);
    }
    this.programWiseSemester = semester;
    for (var i = 0; i < this.consolidateStudents.length; i++) {
      try {
        if (this.consolidateStudents[i].blocked === true) {
          await this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then((result: any) => {
            var totCgpaMajorCredit = 0;
            var totCgpaGenedCredit = 0;
            var totCgpaCredit = 0;
            var totCgpaMajorMarksObtained = 0;
            var totCgpaGenedMarksObtained = 0;
            var totCgpaMarksObtained = 0;

            var totCgpaMajorMarks = 0;
            var totMarks = 0;
            var totMarksObtained = 0;
            var totCredit = 0;
            var failInSem = [];
            var totGNDMarks = 0;

            result.semestersDetail.sort((x: any, y: any) => {
              return x.semester - y.semester;
            });

            for (var k = 0; k < result.semestersDetail.length; k++) {
              result.semestersDetail[k].courses.sort((x: any, y: any) => {
                if (x.paper.paperType == y.paper.paperType) {
                  return x.paper.code.localeCompare(y.paper.code);
                } else {
                  return (x.paper.paperType - y.paper.paperType);
                }
              });
              var totSgpaMajorCredit = 0;
              var totSgpaGenedCredit = 0;
              var totSgpaCredit = 0;
              var totSgpaMajorMarksObtained = 0;
              var totSgpaGenedMarksObtained = 0;
              var totSgpaMarksObtained = 0;
              var totSgpaMajorMarks = 0;
              var totalMarks = 0;
              var totalMarksObtained = 0;
              var totalCredit = 0;
              var totalGND = 0;

              for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                //Replaceve html tags
                result.semestersDetail[k].courses[s].paper.name = this.htmlToText(result.semestersDetail[k].courses[s].paper.name);

                totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == this.CBCS_MAJOR || result.semestersDetail[k].courses[s].paper.paperType == 16) {
                  totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                  totSgpaMajorMarks = totSgpaMajorMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                }
                if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                  totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                  totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                  totalGND = totalGND + result.semestersDetail[k].courses[s].fullMarks;
                }

                totalMarks = totalMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                totalMarksObtained = totalMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                totalCredit = totalCredit + result.semestersDetail[k].courses[s].paper.credit;

                if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                  failInSem.push(result.semestersDetail[k].semester);
                }


              }
              result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
              result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
              result.semestersDetail[k].totSgpaCredit = totSgpaCredit;

              result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
              result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;

              result.semestersDetail[k].totSgpaMajorMarks = totSgpaMajorMarks;
              result.semestersDetail[k].totalMarks = totalMarks;
              result.semestersDetail[k].totalMarksObtained = totalMarksObtained;
              result.semestersDetail[k].totalCredit = totalCredit;
              result.semestersDetail[k].totalGND = totalGND;

              totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
              totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;

              totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
              totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;

              totGNDMarks = totGNDMarks + totalGND;

              totCgpaMajorMarks = totCgpaMajorMarks + totSgpaMajorMarks;
              totMarks = totMarks + totalMarks;
              totMarksObtained = totMarksObtained + totalMarksObtained;
              totCredit = totCredit + totalCredit;

            }
            if (failInSem.length > 0) {
              var nonSseSem;// = semester.filter(value => failInSem.includes(value));
              if (semester.length == 6) {
                nonSseSem = [1, 2, 3, 4].filter(value => failInSem.includes(value));
              } else {
                nonSseSem = [1, 2].filter(value => failInSem.includes(value));
              }
              if (nonSseSem.length > 0) {
                result.remarks = "REPEAT(" + failInSem.join("-") + ")";
              } else {
                result.remarks = "SSE(" + failInSem.join("-") + ")";
              }
            } else {
              result.remarks = "PASS";
            }

            var discipline = this.disciplines.filter((x: any) => x.id == result.semestersDetail[0].courses[0].paper.disciplineId);
            if (discipline.length > 0) {
              this.discipline = discipline[0].name;

            }
            result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
            result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;

            result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
            result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
            result.discipline = this.discipline;
            result.cgpa.totCgpaMajorMarks = totCgpaMajorMarks;
            result.cgpa.totMarks = totMarks;
            result.cgpa.totMarksObtained = totMarksObtained;
            result.cgpa.totCredit = totCredit;
            result.cgpa.totGNDMarks = totGNDMarks;
            students.push(result);
          }).catch((error: any) => {
          });
        }
      } catch (e) {
      }

    }
    var title = this.programTitle + ' Blocked Report';
    this.consolidateStudentsResult = students;
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "studentConsolidateReport" }, title).subscribe(() => {
      });
    }, 100);
  }


  async getStudent() {
    var students = [];
    await this.studentsConsolidated();
    //  console.log(this.consolidateStudents);
    if (!this.consolidateStudents) return;
    var descipline;
    var semester = [];

    this.programWiseSemester = semester;

    for (var i = 0; i < this.consolidateStudents.length; i++) {
      try {
        if (this.consolidateStudents[i].unqualified === false && this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false) {
          await this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then((result: any) => {
            var totCgpaMajorCredit = 0;
            var totCgpaGenedCredit = 0;
            var totCgpaCredit = 0;
            var totCgpaMajorMarksObtained = 0;
            var totCgpaGenedMarksObtained = 0;
            var totCgpaMarksObtained = 0;
            var failInSem = [];
            var totalGenedMarksObtained = 0;
            var totalGenedMarks = 0;
            var totalGndMarksObtained = 0;
            var totalGndMarks = 0;

            result.semestersDetail.sort((x: any, y: any) => {
              return x.semester - y.semester;
            });
            if (this.programs.length > 0) {
              var ppp = this.programs.filter((x: any) => x.id == result.academicProgramId);
              result.stream = ppp[0].name.split('in')[0];
            }

            if (this.academiaSessions.length > 0) {
              var sessionData = this.academiaSessions.filter((x: any) => x.id == result.academicSessionId);
              result.session = sessionData[0].name;
              result.examYear = sessionData[0].end;
            }

            if (result.unqualified === false) {
              students.push(result);
            }

          }).catch((error: any) => {
          });

        }

      } catch (e) {
      }

    }
    this.convocationStudentsResult = students;


    var csvTitle = 'Students';
    setTimeout(() => {
      this._exportAsService.save({ type: "csv", elementId: "studentData" }, csvTitle).subscribe(() => {
      });
    }, 100);
  }
}

