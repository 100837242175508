import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { OrganizationService } from '../api-client/organization.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../api-client/utility.service';
declare var $: any;

@Component({
  selector: 'app-examiner-allocation-status',
  templateUrl: './examiner-allocation-status.component.html',
  styleUrls: ['./examiner-allocation-status.component.css']
})
export class ExaminerAllocationStatusComponent implements OnInit {

  departmentsHod: any;
  hods = [];
  activeDepartments = [];
  deptId: any;

  constructor(
    private _organizationService: OrganizationService,
    private _route: ActivatedRoute,
    private _tosterService: ToastrService,
    private _uiService: UiService,
    private _utilityService: UtilityService,
    private _router: Router) { }

  ngOnInit() {
    this.getHods();
    this.getActiveDepartments();
  }

  getHods() {
    this._organizationService.hods().subscribe((result: any) => {
      this.departmentsHod = result;
    })
  }

  addHods(ele: any, id: any) {
    if (ele.checked) {
      this.hods.push(id);
    } else {
      let index = this.hods.indexOf(id);
      this.hods.splice(index, 1);
    }
  }

  sendNotification(deptId: any) {
    this.deptId = deptId;
    $('#DeleteTask').modal('toggle');
  }

  invite() {
    $('#DeleteTask').modal('toggle');
    var data = { departmentId: this.deptId };
    this._utilityService.sendNotification(data).subscribe((result: any) => {
      this._tosterService.success("Notification send");
      this.getActiveDepartments();
    })
  }

  stopNotification(deptId: any) {
    var data = { departmentId: deptId };
    this._utilityService.stopNotification(data).subscribe((result: any) => {
      this._tosterService.success("Notification stop");
      this.getActiveDepartments();
    })
  }

  paperConfigure(deptId: any) {
    if (this.activeDepartments.includes(deptId)) {
      return true;
    } else {
      return false;
    }
  }

  getActiveDepartments() {
    this._utilityService.activeExaminerDepartments().subscribe((result: any) => {
      var depts = [];
      for (var i = 0; i < result.length; i++) {
        depts.push(result[i].referenceId);
      }
      this.activeDepartments = depts;
    })
  }

  redirectTo(arg: any) {
    this._router.navigateByUrl(arg);
    return false;
  }

}

