import { Deseriazable } from './deserializable.model';

export class PaperModel implements Deseriazable{
    
    id: string;
    disciplineId: string;
    name: string;
    academicSessionId:string;
    code: string;
    credit: string;
    endTermAllocatedMarks: string;
    fullMarks: string;
    groupCode: string;
    groupName: string;
    label: string;
    level: string;
    midTermAllocatedMarks: string;
    paperType: string;
    secondaryPaperType: string;
    semester: string;
    endTermCredit:string;
    midTermCredit:string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}