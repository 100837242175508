import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../api-client/organization.service';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../ui.service';
import { EmployeeService } from '../api-client/employee.service';
import { RoleModel } from '../models/role.model';
import { CommonMethods} from '../common-methods';

@Component({
  selector: 'app-privileges',
  templateUrl: './privileges.component.html',
  styleUrls: ['./privileges.component.css']
})
export class PrivilegesComponent implements OnInit {

  roles: any;
  capabilities: any;
  displayName: string;
  id: string = null;
  employees: any;
  selectCapabilities = [];
  selectedUsers = [];
  defaultForHoD:boolean = false;
  defaultForExaminer:boolean = false;

  constructor(
    private _organizationService: OrganizationService,
    private _toster: ToastrService,
    private _uiService: UiService,
    private _employeeService: EmployeeService
  ) { }

  ngOnInit() {
    this._uiService.changeAppTitle.next('Privileges');
    this.getRoles();
    this.getCapabilities();
    this.getEmployees();
  }

  filterEmployee(ele: any) {
    var items: any;
    if (ele.value) {
      items = this.employees.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
      this.employees = items;
    } else {
      this.getEmployees();
    }
  }

  getRoles() {
    this._organizationService.getRoles().subscribe((result: any) => {
      this.roles = result;
    })
  }

  manageRole(role: any) {
    this.selectCapabilities = [];
    this.selectedUsers = [];
    this._organizationService.getRole(role.id).subscribe((result: any) => {
      if (result) {
        this.selectCapabilities = result.capabilities;
        this.displayName = result.displayName;
        this.id = result.id;
        this.defaultForExaminer = result.defaultForExaminer;
        this.defaultForHoD = result.defaultForHoD;
        var users = [];
        for (var i = 0; i < result.users.length; i++) {
          users.push(result.users[i].userId);
        }
        this.selectedUsers = users;
      }
    })
  }

  getCapabilities() {
    this._organizationService.getCapabilities().subscribe((result: any) => {
      this.capabilities = result;
    })
  }

  checkedCapability(ele: any, val: number) {
    var value = Number(val);
    if (ele.target.checked) {
      this.selectCapabilities.push(value);
    } else {
      var index = this.selectCapabilities.indexOf(value);
      if (index > -1) {
        this.selectCapabilities.splice(index, 1);
      }
    }
  }

  capabilityCheckboxSelected(val: any) {
    if (this.selectCapabilities.includes(Number(val))) {
      return true;
    } else {
      return false;
    }
  }

  save() {
    if (this.displayName == "" || this.displayName == null) {
      this._toster.error("Name can not be blank.");
      return;
    }

    if (this.selectCapabilities.length == 0) {
      this._toster.error("Capabilities can not be blank.");
      return false;
    }
    var privileges = { id: this.id,defaultForHoD: this.defaultForHoD,defaultForExaminer:this.defaultForExaminer, displayName: this.displayName, name: this.displayName.replace(/\s/g, ''), capabilities: this.selectCapabilities };
    this._organizationService.saveRole(privileges).subscribe((result: any) => {
        this._toster.success("Role saved");
        return false;
    })
  }

  getEmployees() {
    var filters = {
      "freeText": "",
      "startIndex": 0,
      "pageSize": 0
    };
    this._organizationService.employees(filters).subscribe((result: any) => {
      this.employees = result.items;
    })
  }

  setUnsetUser(ele: any, val: string) {
    if(!this.id){
      this._toster.error("Please select role");
      return false;
    }
    
    if (ele.target.checked) {
      let data = {roleId:this.id,userId:val,expiryOn:null};
      this._organizationService.setRole(data).subscribe((result:any)=>{
        this._toster.success("Faculty set");
        return false;
      })
    } else {
      let data = {roleId:this.id,employeeId:val,expiryOn:null};
      this._organizationService.unsetRole(data).subscribe((result:any)=>{
        this._toster.success("Faculty unset");
        return false;
      })
    }
  }

  userCheckboxSelected(val: any) {
    if (this.selectedUsers.includes(val)) {
      return true;
    } else {
      return false;
    }
  }

  showCapabilityTitle(title:any){
    return CommonMethods.exportcsvHeader(title);
  }
}
