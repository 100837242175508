import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { AcademiaService } from '../api-client/academia.service';
import { TreeviewItem } from 'ngx-treeview';
import { config } from '../config';
var ProgramComponent = /** @class */ (function () {
    function ProgramComponent(_route, _uiService, _router, _academiaService) {
        var _this = this;
        this._route = _route;
        this._uiService = _uiService;
        this._router = _router;
        this._academiaService = _academiaService;
        this.config = {
            hasAllCheckBox: false,
            hasFilter: true,
            hasCollapseExpand: false,
            decoupleChildFromParent: false
        };
        this.rootItem = [];
        this.programeFilter = { name: '' };
        this._academiaServiceSubscription = _academiaService.onProgramsUpdated.subscribe(function (_) {
            _this.progrems = _this._academiaService.getPrograms();
            _this.convertToDataForTree(_this.progrems);
        });
    }
    ProgramComponent.prototype.ngOnInit = function () {
        this._uiService.changeAppTitle.next("Programs");
        this.progrems = this._academiaService.getPrograms();
        this.convertToDataForTree(this.progrems);
    };
    ProgramComponent.prototype.convertToDataForTree = function (rawData) {
        if (rawData == null) {
            rawData = [];
        }
        var data = [];
        /* for(var i=0; i<rawData.length; i++){
          var splited = rawData[i].name.split(' in ');
          if(!data[splited[0]]){
            data[splited[0]] = {text:splited[0], value:splited[0], children:[], collapsed:true};
          }
          data[splited[0]].children.push( {text:splited[1], value:rawData[i].id} );
        } */
        var academicLevelData = Object.entries(config.academicLevel).map(function (_a) {
            var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
            return ({ key: key, value: value });
        });
        //console.log(academicLevelData)
        for (var i = 0; i < rawData.length; i++) {
            var academicLevel = rawData[i].academicLevel;
            if (!data[academicLevel]) {
                var filterData = academicLevelData.filter(function (x) { return x.key == academicLevel; });
                var title = filterData[0].value;
                /*if(academicLevel==24){
                  title = "Under Graduate";
                }else{
                  title = "Post Graduate";
                }*/
                data[academicLevel] = { text: title, value: title, children: [], collapsed: true };
            }
            data[academicLevel].children.push({ text: rawData[i].name, value: rawData[i].id });
        }
        for (var key in data) {
            this.rootItem.push(new TreeviewItem(data[key]));
        }
    };
    ProgramComponent.prototype.onItemClieck = function (item) {
        this.selectedProgramId = item.value;
    };
    ProgramComponent.prototype.programDetails = function (program) {
        this._router.navigate([{
                outlets: { rightMainPopup: ['program-details', program.value] }
            }]);
    };
    ProgramComponent.prototype.ngOnDestroy = function () {
        this._academiaServiceSubscription.unsubscribe();
    };
    return ProgramComponent;
}());
export { ProgramComponent };
