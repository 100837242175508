import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { config } from '../../app/config';
import { PaperModel } from '../models/paper.model';

@Injectable({
  providedIn: 'root'
})
export class paperService {

  private _papers: any;

  public onPapersUpdated = new Subject();

  constructor(private _http: HttpClient) {
    _http.get(config.apiEndPointBase + "organization/departments").subscribe((result:any) => {
      result.sort((x:any,y:any)=>{
        return x.name.localeCompare(y.name);
      });
      this._papers = result;
      this.onPapersUpdated.next();
    });
  }

  public getPapers()
  {
    return this._papers;
  }

  /* public getPaper(id:any)
  {
    if(this._papers == null) return null;
    var items = this._papers.filter((x:any) => x.id == id);
    if(items.length>0){
      return items[0];
    }else{
      return null;
    }
  } */
  getPaper(paperId:string)
  {
    return this._http.get(config.apiEndPointBase + "Paper/"+paperId);
  }

  getEvaluators(paperId:string,examId:string){
    return this._http.get(config.apiEndPointBase + "paper/"+paperId+"/evaluators/"+examId);
  }

  updateMarksUploadDate(paperId:string,examinationId:string,mode:any,term:any, evaluatorId:string){
    if(term=='mid'){
      return this._http.get(config.apiEndPointBase + "examination/send-submited-mid-term-marks/"+examinationId+"/"+paperId+"/"+mode+"?evaluatorId="+evaluatorId);
    }else{
      return this._http.get(config.apiEndPointBase + "examination/send-submited-end-term-marks/"+examinationId+"/"+paperId+"/"+mode+"?evaluatorId="+evaluatorId);
    }

  }

  savePaper(data: PaperModel): Observable<PaperModel> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "paper", data, {})
        .pipe(map((response: any) => {
          return new PaperModel().deserialize(response);
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "paper", data, {})
        .pipe(map((response: any) => {
          return new PaperModel().deserialize(response);
        }));
    }
  }

}
