<!-- <div class="status-tab text-justify p-2 pr-2">
    <div class="d-flex justify-content-between align-items-center">
        <div class="h4 m-0">Marks Submission1</div>
        <a href="#" class="btn text-color z-depth-0 text-100 font-weight-500">
            <i class="fa fa-play" aria-hidden="true"></i>
        </a>
    </div>
    <div class="">
        <ul class="">
            <li class="text-100 d-flex justify-content-between align-items-center p-0 pt-2 pb-2">
                <span class="d-flex align-items-center"><input name="" type="checkbox" class="mr-2"> Bengali</span>
                <span class="badge gray-badge p-1 font-weight-500">2/10</span>
            </li>
            <li class="text-100 d-flex justify-content-between align-items-center p-0 pt-2 pb-2">
                <span class="d-flex align-items-center"><input name="" type="checkbox" class="mr-2"> English</span>
                <span class="badge gray-badge p-1 font-weight-500">5/10</span>
            </li>
            <li class="text-100 d-flex justify-content-between align-items-center p-0 pt-2 pb-2">
                <span class="d-flex align-items-center"><input name="" type="checkbox" class="mr-2"> History</span>
                <span class="badge green-badge p-1 font-weight-500">7/10</span>
            </li>
            <li class="text-100 d-flex justify-content-between align-items-center p-0 pt-2 pb-2">
                <span class="d-flex align-items-center"><input name="" type="checkbox" class="mr-2"> Geography</span>
                <span class="badge green-badge p-1 font-weight-500">10/10</span>
            </li>
        </ul>
    </div>
</div> -->

<div class="student_details_section d-flex">
    <div class="left_bg w-25 p-4">
        <h5>{{ 'Filters' | translate }} :</h5>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterExam" class="form-control" disabled>
                <option value="">{{ 'Examination' | translate }}</option>
                <option *ngFor="let exam of exams" [value]="exam.id">
                    {{exam.name}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterDiscipline" class="form-control">
                <option value="">{{ 'Discipline' | translate }}</option>
                <option *ngFor="let discipline of disciplines" [value]="discipline.id">
                    {{discipline.name}}</option>
            </select>
        </div>
        <div class="text-right">
            <button (click)="paperFilter()" class="btn btn-primary font-weight-500 text-80 p-1 pr-2 pl-2">{{ 'Apply' |
                translate }} <em aria-hidden="true" class="fa fa-arrow-right"></em></button>
        </div>

    </div>
    <div class="p-4 w-75">
        <div class="d-flex justify-content-between">
            <div *ngIf="filterExam">
                <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                    <input type="file" (change)="uploadFile($event.target)" class="hidden-file" id="importStudents">
                </em>
                <label for="importStudents" class="m-0">{{ 'Import backlog or readmite or review' | translate }}</label>
            </div>
            <!-- <h4>Papers</h4> -->
            <div *ngIf="papers.length>0" class="reply_cont d-flex justify-content-end align-items-ceter">
                <a class="ml-2 mr-2 font-weight-500" (click)="exportPDF()"><em
                        class="fa fa-file-pdf-o p-2 rounded-circle"></em>
                    <span>{{ 'Download PDF' | translate }}</span></a>
                <input type="checkbox" class="mr-1 mb-2" (change)="selectAll($event)" />
                <p class="mt-1">Select All</p>

            </div>



        </div>

        <div class="d-flex justify-content-between">
            <div *ngIf="filterExam">

                <label for="importStudents" class="m-0 ml-3">Send request for marks entry</label>
            </div>
            <div *ngIf="papers.length>0" class="reply_cont d-flex justify-content-end align-items-ceter">
                <div class="ml-3"><button (click)="sendMultiInvitation(true,2)"
                        class="btn btn-primary font-weight-500 text-80"> Mid Term </button></div>
                <div class="ml-3"><button (click)="sendMultiInvitation(false,2)"
                        class="btn btn-primary font-weight-500 text-80"> End Term </button></div>
                <div class="ml-3"><button (click)="sendMultiInvitation(false,4)"
                        class="btn btn-primary font-weight-500 text-80"> As Reviewer </button></div>
            </div>
        </div>
        <div id="downloadPDF" class="text-80">
            <ng-container *ngIf="papers.length>0; else nodatafound">
                <ng-container *ngFor="let item of papers; let i = index;">
                    <div class="p-1 pr-2 pl-2 text-100">
                        <div class="row white p-2">
                            <div class="col-sm-4 d-flex align-items-start">
                                <p class="m-0 font-weight-500">{{pdfName}}</p>
                            </div>
                            <div class="col-sm-4 d-flex align-items-start">
                                <input type="checkbox" class="mr-1 mt-1" [(ngModel)]="papers[i][1][0].isChecked" />
                                <p class="m-0 font-weight-500">{{papers[i][0]}}</p>
                                <!-- <div class="col">
                            <button (click)="sendInvitation(item)" class="btn btn-primary font-weight-500 text-80">Send Request</button>
                        </div> -->
                            </div>

                            <div class="col-sm-2 p-0">
                                <p class="m-0 font-weight-500 text-center">Mid Term Marks</p>
                            </div>
                            <div class="col-sm-2 p-0">
                                <p class="m-0 font-weight-500 text-center">End Term Marks</p>
                            </div>
                        </div>
                    </div>




                    <div class="row white p-2">
                        <div class="col-sm-3 d-flex align-items-start">
                            <p class="m-0 font-weight-500">{{ 'Name' | translate }}</p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 font-weight-500">{{ 'Codes' | translate }}</p>
                        </div>
                        <div class="col-sm-3">
                            <p class="m-0 font-weight-500">{{ 'Session' | translate }}</p>
                        </div>
                        <!-- <div class="col-sm-2">

                            <p class="m-0 font-weight-500"> {{papers[i][0]}}</p>

                        </div> -->
                        <div class="col-sm-1 p-0">
                            <p class="m-0 font-weight-500 text-center">{{ 'Type' | translate }}</p>
                        </div>
                        <div class="col-sm-2 p-0">
                            <p class="m-0 font-weight-500 text-center">{{ 'Submitted On' | translate }}</p>
                        </div>
                        <div class="col-sm-2 p-0">
                            <p class="m-0 font-weight-500 text-center">{{ 'Submitted On' | translate }}</p>
                        </div>
                    </div>

                    <div class="p-1 pr-2 pl-2">


                        <div *ngFor="let paper of papers[i][1]; index as i;" (click)="redirectTo(paper.item)"
                            class="row item-row text-80 p-2">

                            <div class="col-sm-3 d-flex align-items-start">
                                <p class="m-0 font-weight-500">{{paper?.item?.paperName}}</p>
                            </div>
                            <div class="col-sm-1">
                                <p class="m-0 font-weight-500">{{paper?.item?.paperCode}}</p>
                            </div>
                            <div class="col-sm-3">
                                <p class="m-0 font-weight-500">{{paper?.item?.paperSessionName}}</p>
                            </div>
                            <!-- <div class="col-sm-2">
                                <p class="m-0 font-weight-500">{{paper?.item?.examinerName}}</p>
                            </div> -->
                            <div class="col-sm-1">
                                <p class="m-0 font-weight-500 text-center">{{env.evaluatorType[paper?.item?.type]}}</p>
                            </div>
                            <div class="col-sm-2 p-0">
                                <p class="m-0 font-weight-500 text-center">
                                    {{paper?.item?.midTermMarksSubmitedOn | date:'dd-MMM-yyyy'}}</p>
                            </div>
                            <div class="col-sm-2 p-0">
                                <p class="m-0 font-weight-500 text-center">
                                    {{paper?.item?.endTermMarksSubmitedOn | date:'dd-MMM-yyyy'}}</p>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <ng-template #nodatafound><span class="font-weight-600 d-block blue-grey lighten-5 p-2 text-100 text-center">No
            Result
            Found</span></ng-template>
</div>
