import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AcademiaService } from '../api-client/academia.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
  public navClass: String = '';

  @Input() title: string;

  sessions: any;
  selectedSession: any;
  langName: any = "English";
  private _academiaServiceSubscription: Subscription;

  constructor(
    private _translate: TranslateService,
    private _router: Router,
    private _academiaService: AcademiaService) {
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.sessions = this._academiaService.getAcademicSession();
      this.selectedSession = this._academiaService.getActiveAcademicSession();
      this._academiaService.setSelectedSession(this.selectedSession);
    });

    // window.onbeforeunload = function () {
    //   sessionStorage.removeItem("token");
    //   sessionStorage.clear();
    // };
  }

  ngOnInit() {
    this.sessions = this._academiaService.getAcademicSession();
    this.selectedSession = this._academiaService.getActiveAcademicSession();
  }

  toggleNavMenu() {
    this._router.navigateByUrl('/menu');
    //this.navClass = (this.navClass == 'nav-open')?'':'nav-open';
  }

  redirectTo(arg: any) {
    this._router.navigateByUrl(arg);
    //this.toggleNavMenu();
  }

  selectSession(ele: any) {
    this._academiaService.setSelectedSession(ele);
    this.selectedSession = ele;
  }

  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
  }

  changeLanguage(langCode: any, langName: any) {
    this._translate.use(langCode);
    this.langName = langName;
  }

  logout() {
    sessionStorage.removeItem("token");
    this._router.navigate(["/login"]);
  }

}
