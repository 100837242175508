<div class="login_container position-fixed w-100 h-100 d-flex align-items-center white">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="login_logo text-center mb-3">
                    <a href="#"><img src="assets/images/logo.png" alt="" width="150"></a>
                </div>
            </div>
            <div class="col-sm-12 d-flex justify-content-center">
                <div class="login_box w-50 rounded">
                    <input type="text" name="email" [(ngModel)]="email" class="form-control rounded p-2 border-none"
                        placeholder="Email" [ngClass]="" />
                    <div class="next-btn text-center">
                            <a (click)="login()" class="z-depth-0">Login <em class="fa fa-caret-right ml-1"></em></a>
                        <a (click)="send()" class="btn btn-primary">Send <em class="fa fa-caret-right ml-1"></em></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Login Coder Scan -->

