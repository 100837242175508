<div class="status-tab text-justify p-2 pr-2">
    <div class="d-flex justify-content-between align-items-center">
        <div class="h4 m-0">Examiner Allocation</div>
        <div><a class="text-80 font-weight-600 text-color" (click)="redirectTo('examiner-allocation')"><em
                    class="fa fa-eye pr-1 text-100"></em> View All</a></div>
        <!-- <a class="btn text-color z-depth-0 text-100 font-weight-500">
            <i class="fa fa-play" aria-hidden="true"></i>
        </a> -->
    </div>
    <div class="mt-2">
        <div class="scroll_div">
            <ul>
                <li *ngFor="let dept of departmentsHod" class="text-100 p-0 pt-2 pb-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <span class="d-flex align-items-center"><em [ngClass]="paperConfigure(dept.id)?'active_row':''"
                                class="fa fa-circle row_icon mr-2 text-80"></em> {{dept.name}}</span>
                        <!-- <span class="badge gray-badge p-1 font-weight-500">10</span> -->
                        <a *ngIf="!paperConfigure(dept.id)" (click)="sendNotification(dept.id)"> <i
                                class="fa fa-paper-plane"></i></a>
                        <a *ngIf="paperConfigure(dept.id)" (click)="stopNotification(dept.id)"> <i
                                class="fa fa-stop-circle text-danger"></i></a>
                    </div>
                    <span class="text-sm-left text-100 text-color"><em
                            class="fa fa-tags pr-2"></em>{{dept.hoD.name}}</span>

                </li>
            </ul>
        </div>
    </div>

    <div class="modal fade" id="DeleteTask" tabindex="-1" role="dialog" aria-labelledby="DeleteTaskModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="DeleteTaskModalLabel">Invite</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <h2>Are you sure</h2>
                        <p>Do you want to invite HOD to start allocation of examiner ?</p>
                        <button type="button" data-dismiss="modal"
                            class="btn btn-sm btn-outline-dark mr-4">Cancel</button>
                        <button type="button" class="btn btn-sm btn-success del-btn mr-4"
                            (click)="invite()">Invite</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>