<div class="student_details_section d-flex">
    <div class="left_bg w-25">
        <app-paper-summary [displayDetailIcon]="false"></app-paper-summary>
    </div>
    <div class="p-4 w-75">

        <h4 *ngIf="discipline && session">{{discipline.name}} - {{paper.code}} ({{session.name}})</h4>
        <div class="form-row mb-4">
              <div class="col"><input type="text"  (change)="resetPapers()" class="form-control" [(ngModel)]="filterPaperCode" placeholder="Paper Code"></div>
              <div class="col"><select class="form-control" (change)="resetPapers()"  [(ngModel)]="filterPaperSession">
                          <option  value="">Select session</option>
                          <option *ngFor="let session of sessions" [value]="session.id">{{session.name}}</option>
                        </select></div>
              <div class="col" *ngIf="papers.length>1"><select class="form-control" (change)="redirectToPaper($event.target.value)">
                    <option  value="">Select paper</option>
                    <option *ngFor="let paper of papers" [value]="paper.id">{{paper.name}}</option>
                </select></div>
              <div class="col text-right"><button (click)="switchPaper()" class="btn btn-primary font-weight-500 text-80">Switch Paper</button></div>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="examiners-tab" data-toggle="tab" href="#examiners" role="tab"
                    aria-controls="examiners" aria-selected="true">{{ 'Examiners & Reviewers' | translate }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="studentsList-tab" data-toggle="tab" href="#studentsList" role="tab"
                    aria-controls="studentsList" aria-selected="false">{{ 'Students' | translate }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="backlogStudentsList-tab" data-toggle="tab" href="#backlogStudentsList" role="tab"
                    aria-controls="backlogStudentsList" aria-selected="false">{{ 'Backlog Students' | translate }}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="examiners" role="tabpanel" aria-labelledby="examiners-tab">
                <app-examiners [paper]="paper"></app-examiners>
            </div>
            <div class="tab-pane fade" id="studentsList" role="tabpanel" aria-labelledby="studentsList-tab">
                <app-paper-wise-students [paper]="paper"></app-paper-wise-students>
            </div>
            <div class="tab-pane fade" id="backlogStudentsList" role="tabpanel" aria-labelledby="backlogStudentsList-tab">
                <app-paper-wise-backlog-students [paper]="paper"></app-paper-wise-backlog-students>
            </div>
        </div>
    </div>
</div>