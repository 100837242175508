/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-students.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./search-students.component";
import * as i5 from "../api-client/academia.service";
import * as i6 from "@angular/router";
import * as i7 from "../ui.service";
import * as i8 from "../api-client/paper.service";
import * as i9 from "ngx-export-as";
import * as i10 from "ngx-toastr";
var styles_SearchStudentsComponent = [i0.styles];
var RenderType_SearchStudentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchStudentsComponent, data: {} });
export { RenderType_SearchStudentsComponent as RenderType_SearchStudentsComponent };
function View_SearchStudentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "h5 m-0 pb-2 d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "em", [["class", "fa fa-check-square-o mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
function View_SearchStudentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignStudent(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "h5 m-0 pb-2 d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "em", [["class", "fa fa-square-o mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
function View_SearchStudentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchStudentsComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchStudentsComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.assigned; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_v.context.$implicit.assigned; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SearchStudentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "d-flex p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "input", [["class", "form-control"], ["placeholder", "Search by reg.no or name"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.freeText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(5, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(7, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary font-weight-400 ml-1 text-80 p-1 pr-2 pl-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.studentSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Apply"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "scroll_div"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchStudentsComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.freeText; _ck(_v, 5, 0, currVal_7); var currVal_8 = _co.students; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_SearchStudentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-students", [], null, null, null, View_SearchStudentsComponent_0, RenderType_SearchStudentsComponent)), i1.ɵdid(1, 114688, null, 0, i4.SearchStudentsComponent, [i5.AcademiaService, i6.ActivatedRoute, i6.Router, i7.UiService, i8.paperService, i9.ExportAsService, i10.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchStudentsComponentNgFactory = i1.ɵccf("app-search-students", i4.SearchStudentsComponent, View_SearchStudentsComponent_Host_0, {}, {}, []);
export { SearchStudentsComponentNgFactory as SearchStudentsComponentNgFactory };
