import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { UiService } from '../ui.service';
import { config } from '../config';
import { paperService } from '../api-client/paper.service';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExportAsService } from 'ngx-export-as';
import { ExamService } from '../api-client/exam.service';
var MarksSubmissionStatusComponent = /** @class */ (function () {
    function MarksSubmissionStatusComponent(_paperService, _academiaService, _route, _uiService, _examService, _tosterService, _exportAsService, _router) {
        var _this = this;
        this._paperService = _paperService;
        this._academiaService = _academiaService;
        this._route = _route;
        this._uiService = _uiService;
        this._examService = _examService;
        this._tosterService = _tosterService;
        this._exportAsService = _exportAsService;
        this._router = _router;
        this.papers = [];
        this.filterDiscipline = "";
        this.filterSemester = 0;
        this.filterLevel = 0;
        this.filterSession = "";
        this.filterExam = "";
        this.filters = {};
        this.marksEntryHeader = config.marksEntryHeader;
        this.env = config;
        this._academiaServiceSubscription = this._academiaService.onAcademicSessionUpdate.subscribe(function (_) {
            var activeSession = _this._academiaService.getActiveAcademicSession();
            if (_this.selectedSession == null || _this.selectedSession.id != activeSession.id) {
                _this.selectedSession = _this._academiaService.getActiveAcademicSession();
                _this.getExams(_this.selectedSession);
            }
        });
        this._academiaService.onDisciplinesUpdated.subscribe(function (_) {
            _this.disciplines = _this._academiaService.getDisciplines();
        });
    }
    MarksSubmissionStatusComponent.prototype.ngOnInit = function () {
        this._uiService.changeAppTitle.next("Marks Submission Status");
        var activeSession = this._academiaService.getActiveAcademicSession();
        if (this.selectedSession == null || (this.selectedSession && this.selectedSession.id != activeSession.id)) {
            this.selectedSession = this._academiaService.getActiveAcademicSession();
            this.getExams(this.selectedSession);
            this.disciplines = this._academiaService.getDisciplines();
        }
    };
    MarksSubmissionStatusComponent.prototype.paperFilter = function () {
        var _this = this;
        if (this.filterExam == null || this.filterExam == "") {
            this._tosterService.error("Examination  can not be blank.");
            return;
        }
        if (this.filterDiscipline == null || this.filterDiscipline == "") {
            this._tosterService.error("Discipline can not be blank.");
            return;
        }
        this._examService.evaluatorsByDiscipline(this.filterExam, this.filterDiscipline).subscribe(function (result) {
            // this.papers = result;
            _this.apiPapers = result;
            var dis = _this.disciplines.filter(function (x) { return x.id == _this.filterDiscipline; });
            var exam = _this.exams.filter(function (x) { return x.id == _this.filterExam; });
            if (dis.length > 0 && exam.length > 0) {
                _this.pdfName = dis[0].name + " | " + exam[0].name;
            }
            var grouped = _this.groupBy(result, false, function (relation) { return relation.examinerName; });
            _this.papers = _this.mapEntries(grouped);
        });
    };
    MarksSubmissionStatusComponent.prototype.mapEntries = function (grouped) { return Array.from(grouped.entries()); };
    MarksSubmissionStatusComponent.prototype.groupBy = function (list, checked, keyGetter) {
        var map = new Map();
        var temp = [];
        list.forEach(function (item) {
            var key = keyGetter(item);
            var collection = map.get(key);
            var data = {
                item: item,
                isChecked: checked
            };
            temp.push(data);
            if (!collection) {
                map.set(key, [data]);
            }
            else {
                collection.push(data);
            }
        });
        return map;
    };
    MarksSubmissionStatusComponent.prototype.getExams = function (session) {
        var _this = this;
        if (!session) {
            return;
        }
        this._academiaService.getSessionWiseExams(session.id).subscribe(function (result) {
            _this.exams = result;
            var activeExam = _this.exams.filter(function (x) { return x.active === true; });
            if (activeExam && activeExam.length > 0) {
                _this.filterExam = activeExam[0].id;
            }
        });
    };
    MarksSubmissionStatusComponent.prototype.redirectTo = function (paper) {
        this._router.navigate(["paper/" + paper.paperId]);
        return;
    };
    MarksSubmissionStatusComponent.prototype.exportPDF = function () {
        var _this = this;
        setTimeout(function () {
            _this._exportAsService.save({ type: "pdf", elementId: "downloadPDF" }, _this.pdfName).subscribe(function () {
            });
        }, 10);
    };
    MarksSubmissionStatusComponent.prototype.previewCsvData = function (fieldMaps, results) {
        var _this = this;
        this._uiService.indeterminateProgressTextSet.next('Arrange data...');
        var columns = [];
        var columnsWidth = {};
        var columnSources = [];
        columnSources['paperType'] = Object.keys(config.paperType).map(function (key) {
            return { id: Number(key), name: config.paperType[key] };
        });
        columnSources['level'] = Object.keys(config.academicLevel).map(function (key) {
            return { id: Number(key), name: config.academicLevel[key] };
        });
        columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map(function (key) {
            return { id: Number(key), name: config.secondaryPaperType[key] };
        });
        columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map(function (x) {
            return { id: x.id, name: x.name };
        });
        columnSources['disciplineId'] = this._academiaService.getDisciplines().map(function (x) {
            return { id: x.id, name: x.name };
        });
        var arrangedData = results.map(function (value) {
            var item = {};
            for (var key in fieldMaps) {
                var valueWidth = (value[fieldMaps[key]] + '').length * 15;
                item[key] = value[fieldMaps[key]];
                if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
                    columnsWidth[key] = valueWidth;
                }
            }
            return item;
        });
        this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
        this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
        columnsWidth = null;
        this._uiService.indeterminateProgressTextSet.next(null);
        this._uiService.openDataPreviewWindow(arrangedData, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: function () {
                    _this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
                }
            }
        ]);
    };
    MarksSubmissionStatusComponent.prototype.onImportPreviewCompleted = function (fields, arrangedData, columns) {
        var _this = this;
        var data = this._uiService.getPreviewModifiedData();
        var converted = data.map(function (value) {
            var item = {};
            for (var i = 0; i < fields.length; i++) {
                item[fields[i]] = value[i];
            }
            return item;
        });
        this._uiService.closeDataPreview();
        var worker = new Worker('../workers/import-backlog-marks.worker', { name: 'backlog-marks-worker', type: 'module' });
        this._uiService.startWorker(worker, { values: converted, examination: this.filterExam, token: sessionStorage.getItem("token") })
            .then(function () {
            //show success message, if required
        }).catch(function (e) {
            if (e.validation) {
                _this.onImportValidationError(e.validation, converted, columns, fields);
            }
        });
    };
    MarksSubmissionStatusComponent.prototype.onImportValidationError = function (validation, converted, columns, fields) {
        var _this = this;
        this._uiService.onDataPreviewReady = function () {
            var allFields = Object.keys(converted[0]);
            for (var i = 0; i < validation.length; i++) {
                var commonErrors = [];
                for (var field in validation[i]) {
                    var fieldIndex = allFields.indexOf(field);
                    if (fieldIndex <= 0) {
                        commonErrors = commonErrors.concat(validation[i][field]);
                    }
                    else {
                        _this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
                    }
                }
                if (commonErrors.length > 0) {
                    _this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
                }
            }
        };
        this._uiService.openDataPreviewWindow(converted, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: function () {
                    _this.onImportPreviewCompleted(fields, converted, columns);
                }
            }
        ]);
    };
    MarksSubmissionStatusComponent.prototype.mapCsvField = function (results) {
        var _this = this;
        this._uiService
            .mapCsvField(results.meta.fields, this.marksEntryHeader)
            .then(function (maps) {
            _this.previewCsvData(maps, results.data);
        }).catch(function () { });
    };
    MarksSubmissionStatusComponent.prototype.uploadFile = function (fileEle) {
        var _this = this;
        this._uiService
            .parseCsv(fileEle.files[0])
            .then(function (results) {
            fileEle.value = null;
            _this.mapCsvField(results);
        }).catch(function () {
            fileEle.value = null;
        });
    };
    MarksSubmissionStatusComponent.prototype.sendInvitation = function (getData) {
        var data = {
            evaluatorId: getData[1][0].item.examinerId,
            examinationId: getData[1][0].item.examinationId,
            mode: getData[1][0].item.type,
            forMidTermMarksEntry: true
        };
        this._examService.sendMarksEntryRequest(data).subscribe(function (result) {
            var res = result.data;
        }, function (error) {
            console.error(error.message);
        });
    };
    MarksSubmissionStatusComponent.prototype.sendMultiInvitation = function (flag, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var paper, select;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        paper = this.papers.filter(function (x) { return x[1][0].isChecked == true; });
                        return [4 /*yield*/, paper.forEach(function (data, index) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var checkType, sub, send;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            checkType = data[1].filter(function (x) { return x.item.type == type; });
                                            if (!(checkType.length > 0)) return [3 /*break*/, 2];
                                            sub = {
                                                evaluatorId: data[1][0].item.examinerId,
                                                examinationId: data[1][0].item.examinationId,
                                                mode: type,
                                                forMidTermMarksEntry: flag
                                            };
                                            return [4 /*yield*/, this._examService.sendMarksEntryRequest(sub).toPromise()];
                                        case 1:
                                            send = _a.sent();
                                            _a.label = 2;
                                        case 2: return [4 /*yield*/, this._tosterService.success("Request send successfully")];
                                        case 3:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        select = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MarksSubmissionStatusComponent.prototype.selectAll = function (e) {
        var grouped = this.groupBy(this.apiPapers, e.target.checked, function (relation) { return relation.examinerName; });
        this.papers = this.mapEntries(grouped);
    };
    MarksSubmissionStatusComponent.prototype.ngOnDestroy = function () {
        this._academiaServiceSubscription.unsubscribe();
    };
    return MarksSubmissionStatusComponent;
}());
export { MarksSubmissionStatusComponent };
