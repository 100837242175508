import { OnInit, OnDestroy } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ProgramModel } from '../models/program.model';
import { ProgramFormBuilder } from './program.form-builder';
import { ProgramService } from '../api-client/program.service';
import { ToastrService } from 'ngx-toastr';
import { config } from '../config';
var ProgramDetailsComponent = /** @class */ (function () {
    function ProgramDetailsComponent(_academiaService, _route, _uiService, _toster, _programService, _formBuilder, _router) {
        var _this = this;
        this._academiaService = _academiaService;
        this._route = _route;
        this._uiService = _uiService;
        this._toster = _toster;
        this._programService = _programService;
        this._formBuilder = _formBuilder;
        this._router = _router;
        this.program = {};
        this.editMode = false;
        this.submitted = false;
        this.model = new ProgramModel();
        this.academicLevels = config.academicLevel;
        this._academiaServiceDisciplineSubscription = this._academiaService.onDisciplinesUpdated.subscribe(function (_) {
            _this.disciplines = _this._academiaService.getDisciplines();
        });
    }
    Object.defineProperty(ProgramDetailsComponent.prototype, "f", {
        get: function () { return this.programForm.controls; },
        enumerable: true,
        configurable: true
    });
    ProgramDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(config.academicLevel);
        this.programForm = this._formBuilder.getForm();
        this.disciplines = this._academiaService.getDisciplines();
        this._route.params.subscribe(function (params) {
            _this.programId = params.id;
            _this.resetForm();
            _this.getProgram(params.id);
        });
    };
    ProgramDetailsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.programForm.invalid) {
            return;
        }
        this.model.deserialize(this.programForm.value);
        this._programService.saveProgram(this.model)
            .subscribe(function (response) {
            _this._toster.success("Program saved");
            _this.editMode = true;
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    ProgramDetailsComponent.prototype.editProgram = function () {
        this.editMode = false;
    };
    ProgramDetailsComponent.prototype.getProgram = function (ProgramId) {
        var _this = this;
        this._programService.getProgram(ProgramId).subscribe(function (result) {
            _this._formBuilder.setValues(result);
        });
    };
    ProgramDetailsComponent.prototype.ngOnDestroy = function () {
        this._academiaServiceDisciplineSubscription.unsubscribe();
    };
    ProgramDetailsComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.programForm.reset();
    };
    return ProgramDetailsComponent;
}());
export { ProgramDetailsComponent };
