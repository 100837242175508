<ng-template #na><span> NA</span></ng-template>
<form [formGroup]="paperForm" (ngSubmit)="onSubmit()">
    <div class="p-3 vw-25" style="overflow-y: auto;">
        <div class="d-flex icon-set justify-content-around p-2 mb-2">
            <div (click)="redirectToPaperDetails(paper)" class="text-color">
                <em class="fa fa-graduation-cap"></em>
                {{ 'Detail' | translate }}
            </div>
            <!-- <div class="text-dark">
                <em class="fa fa-share-square"></em>
                {{ 'Left away' | translate }}
            </div>
            <div class="text-danger">
                <em class="fa fa-ban"></em>
                {{ 'Block' | translate }}
            </div> -->
            <div (click)="editPaper()" *ngIf="editMode" class="text-default">
                <em class="fa fa-pencil"></em>
                {{ 'Edit' | translate }}
            </div>
            <div *ngIf="!editMode" class="text-default">
                <button class="btn text-100 p-0 z-depth-0 text-default"><em class="fa fa-save"></em>
                    <span>{{ 'Save' | translate }}</span></button>
            </div>
        </div>
        <div class="scroll_div">
            <div class="h4">
                <textarea class="w-100  form-control" type="text" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }"></textarea>
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">{{ 'Name is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Group' | translate }}:</div>
                <div class=""><input type="text" class="w-100  form-control" formControlName="groupCode"
                        [ngClass]="{ 'is-invalid': submitted && f.groupCode.errors }"></div>
                <div *ngIf="submitted && f.groupCode.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.groupCode.errors.required">{{ 'Group code is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Codes' | translate }}:</div>
                <div class=""><input type="text" class="w-100  form-control" formControlName="code"
                        [ngClass]="{ 'is-invalid': submitted && f.code.errors }" ></div>
                <div *ngIf="submitted && f.code.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.code.errors.required">{{ 'Code is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Session' | translate }}:</div>
                <div class=""><select formControlName="academicSessionId"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.academicSessionId.errors }">
                        <option value="">{{ 'Select Session' | translate }}</option>
                        <option *ngFor="let session of sessions" [value]="session.id">{{session.name}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.academicSessionId.errors" style="display: block;" class="invalid-feedback">
                        <div *ngIf="f.academicSessionId.errors.required">{{ 'Session is required' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Discipline' | translate }}:</div>
                <div class=""><select formControlName="disciplineId"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.disciplineId.errors }">
                        <option value="">{{ 'Select Discipline' | translate }}</option>
                        <option *ngFor="let discipline of disciplines" [value]="discipline.id">{{discipline.name}}</option>
                    </select>
                    <div *ngIf="submitted && f.disciplineId.errors" class="invalid-feedback">
                        <div *ngIf="f.disciplineId.errors.required">{{ 'Discipline is required' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Academic level' | translate }}:</div>
                <div class=""><select formControlName="level"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.level.errors }">
                        <option value="">{{ 'Select academic level' | translate }}</option>
                        <option *ngFor="let academiclevel of env.academicLevel | keyvalue" [value]="academiclevel.key">{{academiclevel.value}}</option>
                    </select>
                    <div *ngIf="submitted && f.level.errors" class="invalid-feedback">
                        <div *ngIf="f.level.errors.required">{{ 'Academic Level is required' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Paper Type' | translate }}:</div>
                <div class=""><select  formControlName="paperType" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.paperType.errors }">
                        <option value="">{{ 'Select paper Type' | translate }}</option>
                        <option *ngFor="let papertype of env.paperType | keyvalue" [value]="papertype.key">
                            {{papertype.value}}
                        </option>
                    </select>
                </div>
                <div *ngIf="submitted && f.paperType.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.paperType.errors.required">{{ 'Paper Type is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Secondery Paper Type' | translate }}:</div>
                <div class=""><select  formControlName="secondaryPaperType" class="form-control">
                        <option value="">{{ 'Select paper Type' | translate }}</option>
                        <option *ngFor="let secondaryPaper of env.secondaryPaperType | keyvalue" [value]="secondaryPaper.key">
                            {{secondaryPaper.value}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Semester' | translate }}:</div>
                <div class=""><input type="number" formControlName="semester"
                        [ngClass]="{ 'is-invalid': submitted && f.semester.errors }" class="w-100  form-control"
                        ></div>
                <div *ngIf="submitted && f.semester.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.semester.errors.required">{{ 'Semester is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Full Marks' | translate }}:</div>
                <div class=""><input type="number" formControlName="fullMarks"
                        [ngClass]="{ 'is-invalid': submitted && f.fullMarks.errors }" class="w-100  form-control"
                        ></div>
                <div *ngIf="submitted && f.fullMarks.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.fullMarks.errors.required">{{ 'Full Marks is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Mid Term Allocated Marks' | translate }}:</div>
                <div class=""><input type="number" formControlName="midTermAllocatedMarks"
                        [ngClass]="{ 'is-invalid': submitted && f.midTermAllocatedMarks.errors }"
                        class="w-100  form-control" ></div>
                <div *ngIf="submitted && f.midTermAllocatedMarks.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.midTermAllocatedMarks.errors.required">{{ 'Mid Term Allocated Marks is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'End Term Allocated Marks' | translate }}:</div>
                <div class=""><input type="number" formControlName="endTermAllocatedMarks"
                        [ngClass]="{ 'is-invalid': submitted && f.endTermAllocatedMarks.errors }"
                        class="w-100  form-control" ></div>
                <div *ngIf="submitted && f.endTermAllocatedMarks.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.endTermAllocatedMarks.errors.required">{{ 'End Term Allocated Marks is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Credit' | translate }}:</div>
                <div class=""><input type="number" formControlName="credit"
                        [ngClass]="{ 'is-invalid': submitted && f.credit.errors }" class="w-100  form-control"
                        ></div>
                <div *ngIf="submitted && f.credit.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.credit.errors.required">{{ 'Credit is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Mid Term Credit' | translate }}:</div>
                <div class=""><input type="number" formControlName="midTermCredit"
                        class="w-100  form-control" ></div>                
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'End Term Credit' | translate }}:</div>
                <div class=""><input type="number" formControlName="endTermCredit"
                        class="w-100  form-control" ></div>
                
            </div>
        </div>
    </div>
    <input type="hidden"  formControlName="id" >
</form>