import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { AcademiaService } from '../api-client/academia.service';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'ngx-filter-pipe';
import { TreeviewItem } from 'ngx-treeview';
import { CommonMethods } from '../common-methods';
import { config } from '../config';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css']
})
export class ProgramComponent implements OnInit, OnDestroy {

  config = {
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false
  };

  rootItem:TreeviewItem[] = [];

  progrems: any;
  private _academiaServiceSubscription: Subscription;
  programeFilter: any = { name: '' };
  selectedProgramId:any;
  
  constructor(
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _router: Router,
    private _academiaService: AcademiaService) { 
      this._academiaServiceSubscription = _academiaService.onProgramsUpdated.subscribe(_ => {
        this.progrems=this._academiaService.getPrograms();
        this.convertToDataForTree(this.progrems);
      });
    }

    ngOnInit() {
      this._uiService.changeAppTitle.next("Programs");
      this.progrems=this._academiaService.getPrograms();
      this.convertToDataForTree(this.progrems);
    }

    convertToDataForTree(rawData:any)
    {
      if(rawData == null){
        rawData=[];
      }

      var data = [];
      /* for(var i=0; i<rawData.length; i++){
        var splited = rawData[i].name.split(' in ');
        if(!data[splited[0]]){
          data[splited[0]] = {text:splited[0], value:splited[0], children:[], collapsed:true};          
        }
        data[splited[0]].children.push( {text:splited[1], value:rawData[i].id} );
      } */
      var academicLevelData = Object.entries(config.academicLevel).map(([key,value])=> ({key,value}));
      //console.log(academicLevelData)
      for(var i=0; i<rawData.length; i++){
        var academicLevel = rawData[i].academicLevel;
        if(!data[academicLevel]){
          var filterData =academicLevelData.filter((x:any)=>x.key==academicLevel);
          var title = filterData[0].value;
          /*if(academicLevel==24){
            title = "Under Graduate";
          }else{
            title = "Post Graduate";
          }*/
          data[academicLevel] = {text:title, value:title, children:[], collapsed:true};          
        }
        data[academicLevel].children.push( {text:rawData[i].name, value:rawData[i].id} );
      }

      for(var key in data){        
        this.rootItem.push(new TreeviewItem(data[key]));
      }
    }

    onItemClieck(item:any){
      this.selectedProgramId = item.value;
    }
  
    programDetails(program:any){
      this._router.navigate([{
        outlets: { rightMainPopup: ['program-details', program.value] }
      }]);
    }
    
    ngOnDestroy(): void {
      this._academiaServiceSubscription.unsubscribe();
    }

}
