<div class="w-100">
    <div class="d-flex p-2">
        <input type="text" class="form-control" [(ngModel)]="freeText" placeholder="Search by reg.no or name">
        <button (click)="studentSearch()" class="btn btn-primary font-weight-400 ml-1 text-80 p-1 pr-2 pl-2">Apply</button>       
    </div>
    <div class="scroll_div">
        <div *ngFor="let student of students" class="col-sm-12" >
            <div class="d-flex justify-content-between">
                <a *ngIf="student.assigned"><span class="h5 m-0 pb-2 d-flex"><em class="fa fa-check-square-o mr-1"></em>{{student.name}}</span></a>
                <a *ngIf="!student.assigned" (click)="assignStudent(student)"><span class="h5 m-0 pb-2 d-flex"><em  class="fa fa-square-o mr-1"></em> {{student.name}}</span></a>
                
            </div>
        </div>
    </div>
</div>