import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../../app/config';
import { StaffModel } from '../models/staff.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  constructor(private _http: HttpClient) {

  }

  public getDisciplines(departmentId: string) {
    return this._http.get(config.apiEndPointBase + "department/" + departmentId + "/disciplines");
  }

  public getEmployees(departmentId: string) {
    return this._http.get(config.apiEndPointBase + "department/" + departmentId + "/employees");
  }

  getEmployee(employeeId: string) {
    return this._http.get(config.apiEndPointBase + "employee/" + employeeId);
  }

  getDepartment(departmentId: string) {
    return this._http.get(config.apiEndPointBase + "department/" + departmentId);
  }

  linkUnlinkEmployee(data: any, type: any): Observable<any> {
    return this._http
      .put(config.apiEndPointBase + "department/" + data.departmentId + "/employee/" + type + "/" + data.employeeId, {}, {})
      .pipe(map((response: any) => {
        return response;
      }));
  }

  markeAsHod(data: any): Observable<any> {
    return this._http
      .put(config.apiEndPointBase + "department/"+data.departmentId+"/mark-hod/"+data.employeeId, {}, {})
      .pipe(map((response: any) => {        
        return response;
      }));
  }

}
