import { OnInit } from '@angular/core';
import { StaffModel } from '../models/staff.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { staffFormBuilder } from './staff.form-builder';
import { DepartmentService } from '../api-client/department.service';
import { EmployeeService } from '../api-client/employee.service';
import { config } from '../config';
var StaffDetailsComponent = /** @class */ (function () {
    function StaffDetailsComponent(_route, _toster, _departmentService, _formBuilder, _employeeSearvice, _router) {
        this._route = _route;
        this._toster = _toster;
        this._departmentService = _departmentService;
        this._formBuilder = _formBuilder;
        this._employeeSearvice = _employeeSearvice;
        this._router = _router;
        this.editMode = false;
        this.submitted = false;
        this.model = new StaffModel();
        this.settings = config;
    }
    StaffDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.staffForm = this._formBuilder.getForm();
        this._route.params.subscribe(function (params) {
            _this.staffId = params.id;
            _this.resetForm();
            _this.staffDetails();
        });
    };
    Object.defineProperty(StaffDetailsComponent.prototype, "f", {
        get: function () { return this.staffForm.controls; },
        enumerable: true,
        configurable: true
    });
    StaffDetailsComponent.prototype.staffDetails = function () {
        var _this = this;
        if (this.staffId == null)
            return;
        this._departmentService.getEmployee(this.staffId)
            .subscribe(function (result) {
            _this._formBuilder.setValues(result);
        });
    };
    StaffDetailsComponent.prototype.ngOnDestroy = function () {
    };
    StaffDetailsComponent.prototype.editPaper = function () {
        this.editMode = false;
    };
    StaffDetailsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.staffForm.invalid) {
            return;
        }
        this.model.deserialize(this.staffForm.value);
        this._employeeSearvice.saveEmployee(this.model)
            .subscribe(function (response) {
            _this._toster.success("Faculty saved");
            _this.editMode = true;
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    StaffDetailsComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.staffForm.reset();
    };
    return StaffDetailsComponent;
}());
export { StaffDetailsComponent };
