<ng-template #na><span> NA</span></ng-template>
<form [formGroup]="sessionForm" (ngSubmit)="onSubmit()">
    <div class="p-3 vw-25">
        <div class="d-flex icon-set justify-content-around p-2 mb-2">
            <div class="text-danger">
                <em class="fa fa-trash"></em>
                {{ 'Delete' | translate }}
            </div>
            <div class="text-warning">
                <em class="fa fa-ban"></em>
                {{ 'Block' | translate }}
            </div>
            <div (click)="editSession()" *ngIf="editMode" class="text-default">
                <em class="fa fa-pencil"></em>
                {{ 'Edit' | translate }}
            </div>
            <div *ngIf="!editMode" class="text-default">
                <button class="btn text-100 p-0 z-depth-0 text-default"><em class="fa fa-save"></em>
                    <span>{{ 'Save' | translate }}</span></button>

            </div>
        </div>

        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'Name' | translate }}:</div>
            <div class=""><input type="text" class="w-100  form-control" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">{{ 'Name is required' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'Start Date' | translate }}:</div>
            <div class=""><input type="text" class="w-100  form-control" formControlName="start" [owlDateTime]="start"
                    [owlDateTimeTrigger]="start" />
                <owl-date-time [pickerType]="'calendar'" #start></owl-date-time>
                <div *ngIf="submitted && f.start.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.start.errors.required">{{ 'Start date is required' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'End Date' | translate }}:</div>
            <div class=""><input type="text" class="w-100 form-control" formControlName="end" [owlDateTime]="end"
                    [owlDateTimeTrigger]="end" />
                <owl-date-time [pickerType]="'calendar'" #end></owl-date-time>
                <div *ngIf="submitted && f.end.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.end.errors.required">{{ 'End date is required' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'Status' | translate }}</div>
            <div class="">
                <select [disabled]="editMode" formControlName="active" class="form-control">
                    <option value="">{{ 'Select caste' | translate }}</option>
                    <option *ngFor="let status of settings.sessionStatus | keyvalue" [value]="status.key">{{status.value}}</option>
                </select>
            </div>           
        </div>
    </div>
    <input type="hidden" formControlName="id">
</form>