import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { paperService } from '../api-client/paper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AcademiaService } from '../api-client/academia.service';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-paper',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.css']
})
export class PaperComponent implements OnInit, OnDestroy {

  private _paperServiceSubscription: Subscription;
  paperId: any;
  paper: any;
  session: any;
  discipline: any;
  paperSession: any;
  sessions: any;
  filterPaperCode = "";
  filterPaperSession = "";
  papers = [];

  private _academiaServiceSubscription: Subscription;

  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _tosterService:ToastrService,
    private _router: Router, ) {
    this._paperServiceSubscription = _paperService.onPapersUpdated.subscribe(_ => {
      this.paperDetails();
    });
    this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.sessions = this._academiaService.getAcademicSession();
    });

  }

  ngOnInit() {
    this.sessions = this._academiaService.getAcademicSession();
    this._route.params.subscribe(params => {
      this.paperId = params.id;
      if (this.paperId)
        this.paperDetails();
    })
  }

  paperDetails() {
    if (this.paperId == null) return;
    this._paperService.getPaper(this.paperId)
      .subscribe((result: any) => {
        this.paper = result;
        this._uiService.changeAppTitle.next("Paper: " + result.name);
        this.session = this._academiaService.getSessionDetails(result.academicSessionId);
        this.paperSession = { "paperId": this.paperId, "session": this.session };
        this.discipline = this._academiaService.getDiscipline(result.disciplineId);
        this._uiService.setpaperInDisciplineSession({ discipline: this.discipline, session: this.session });
      });
  }

  switchPaper() {
    if (this.filterPaperCode == null || this.filterPaperCode == "") {
      this._tosterService.error("Paper code can not be blank.");
      return false;
    }

    if (this.filterPaperSession == null || this.filterPaperSession == "") {
      this._tosterService.error("Session can not be blank.");
      return false;
    }
    
    var filter = {
      freeText: this.filterPaperCode,
      academicSessionId: this.filterPaperSession,
      disciplineIds: [],
      semester: 0,
      levels: [],
      startIndex: 0,
      pageSize: 0
    }

    this._academiaService.papers(filter).subscribe((result: any) => {
      if (result.items.length > 0) {
        if (result.items.length > 1) {
          this.papers = result.items;
        } else {
          //window.location.href="/paper/"+result.items[0].id;
          this._router.navigate(["/paper/" + result.items[0].id]);
        }
      } else {
        this._tosterService.error("Paper not found.");
      }
    });
  }

  resetPapers(){
    this.papers = [];
  }

  redirectToPaper(paperId: any) {
    //window.location.href="/paper/"+paperId;
    this._router.navigate(["/paper/" + paperId]);
  }

  ngOnDestroy(): void {
    this._paperServiceSubscription.unsubscribe();
  }

}
