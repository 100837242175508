import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StudentService } from '../api-client/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentModel } from '../models/student.model';
import { ToastrService } from 'ngx-toastr';
import { AcademiaService } from '../api-client/academia.service';
import { studentFormBuilder } from './student.form-builder';
import { NotifyService } from '../api-client/notify.service';
import { config } from '../config';

@Component({
  selector: 'app-student-summary',
  templateUrl: './student-summary.component.html',
  styleUrls: ['./student-summary.component.css']
})
export class StudentSummaryComponent implements OnInit, OnChanges, OnDestroy {

  @Input() studentId: any;
  @Input() displayDetailIcon: boolean = true;
  private _studentServiceSubscription: Subscription;
  private _academiaSessionServiceSubscription: Subscription;
  private _academiaProgramServiceSubscription: Subscription;
  student:any = {};
  editMode: boolean = false;
  submitted = false;
  model = new StudentModel();
  studentForm: any;
  sessions: any;
  programs: any;
  settings = config;

  constructor(
    private _studentService: StudentService,
    private _route: ActivatedRoute,
    private _toster: ToastrService,
    private _academiaService: AcademiaService,
    private _formBuilder: studentFormBuilder,
    private _router: Router,
    private _notifyService: NotifyService
  ) {
    this._academiaSessionServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.sessions = this._academiaService.getAcademicSession();
    });
    this._academiaProgramServiceSubscription = _academiaService.onProgramsUpdated.subscribe(_ => {
      this.programs = this._academiaService.getPrograms();
    });
    this._studentServiceSubscription = _studentService.onStudentsUpdated.subscribe(_ => {
      this.studentDetails();
    });
  }

  get f() { return this.studentForm.controls; }

  ngOnInit() {

    this.studentForm = this._formBuilder.getForm();
    this.sessions = this._academiaService.getAcademicSession();
    this.programs = this._academiaService.getPrograms();
    this._route.params.subscribe(params => {
      this.studentId = params.id;
      this.resetForm();
      this.studentDetails();
    })
    this._notifyService.onStudentInfUpdateNotify.subscribe((student) => {
      if (student.id == this.studentId) {
        //this._toster.error("Someone update same student");
        this._formBuilder.setValues(student);
        return;
      }
    });
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.studentDetails();
  }

  studentDetails() {
    if (this.studentId == null) return;
    this._studentService.getStudent(this.studentId)
      .subscribe((result: any) => {
        this.student = result;
        this._formBuilder.setValues(result);
      });
  }

  redirectToStudentDetails(student: any) {
    this._router.navigateByUrl("/student/" + student.id);
  }

  ngOnDestroy(): void {
    this._studentServiceSubscription.unsubscribe();
    this._academiaSessionServiceSubscription.unsubscribe();
    this._academiaProgramServiceSubscription.unsubscribe();
  }

  editPaper() {
    this.editMode = false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.studentForm.invalid) {
      this._toster.error("Validation Error");
      return;
    }
    this.model.deserialize(this.studentForm.value);
    if(this.model.blocked === null){
      this.model.blocked = false;
    }
    if(this.model.migrated === null){
      this.model.migrated = false;
    }
    if(this.model.discontinued === null){
      this.model.discontinued = false;
    }
    this._studentService.saveStudent(this.model)
      .subscribe(
        (response: StudentModel) => {
          this._toster.success("Student saved");
          this._notifyService.studentUpdated(this.model);
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

  resetForm() {
    this.submitted = false;
    this.studentForm.reset();
  }

  migrateBlock(typy: any, student: any) {
    this.submitted = true;
    if (typy === 1) {
      this.studentForm.get('migrated').setValue(student);
    } else {
      this.studentForm.get('blocked').setValue(student);
    }
    this.model.deserialize(this.studentForm.value);
    console.log(this.model);
    this._studentService.saveStudent(this.model)
      .subscribe(
        (response: StudentModel) => {

          if (typy === 1) {
            if (student === true) {
              this._toster.success("Student migrated successfully");
            } else {
              this._toster.success("Student unmigrated successfully");
            }
          } else {
            if (student === true) {
              this._toster.success("Student block successfully");
            } else {
              this._toster.success("Student unblock successfully");
            }
          }


          this._notifyService.studentUpdated(this.model);
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

}
