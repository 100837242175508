<div class="privileges_section">
    <div class="d-flex">
        <div class="left_bg w-25 p-4">
            <div class="h5 m-0 d-flex justify-content-between border-bottom align-items-center pl-2 overflow-hidden">
                <span class="font-weight-600">Roles</span>
                <a (click)="manageRole({id:0})" class="p-1 text-22px text-color">
                    <em class="fa fa-plus-square-o"></em>
                </a>
            </div>
            <ul>
                <li *ngFor="let role of roles"
                    class="border-bottom p-2 d-flex justify-content-between align-items-center"
                    (click)="manageRole(role)">
                    <span>{{role.displayName}}</span>
                </li>
            </ul>
        </div>
        <div class="w-50 p-4 text-80 font-weight-500">
            <div class="d-flex align-items-center mt-4">
                <label class="m-0 mr-2">Default for HOD</label>
                <input [(ngModel)]="defaultForHoD" type="checkbox" class="form-control w-auto">
            </div>
            <div class="d-flex align-items-center mt-4">
                <label class="m-0 mr-2">Default for Examiner</label>
                <input [(ngModel)]="defaultForExaminer" type="checkbox" class="form-control w-auto">
            </div>
            <div class="d-flex align-items-center mt-4">
                <label class="m-0 mr-2">Name</label>
                <input [(ngModel)]="displayName" type="text" class="form-control w-auto">
            </div>
            <ul class="mt-3">
                <li *ngFor="let capability of capabilities|keyvalue;" class="d-flex align-items-center text-100 mb-2">
                    <input type="checkbox" [checked]="capabilityCheckboxSelected(capability.key)"
                        (click)="checkedCapability($event,capability.key)" class="mr-2">
                    <label class="m-0">{{showCapabilityTitle(capability.value)}}</label>
                </li>

            </ul>
            <div class="date_section mt-3 d-flex align-items-center">
                <button (click)="save()" class="btn btn-primary font-weight-500 text-100 p-1 pr-2 pl-2">Save</button>
            </div>
        </div>
        <div class="left_bg w-25 p-4">
            <div class="h5 m-0 d-flex border-bottom pb-2">
                <span class="font-weight-600">Faculties</span>
            </div>
            <input type="text" class="form-control" placeholder="Search faculty"
                (keyup)="filterEmployee($event.target)">
            <ul class="mt-3">
                <li *ngFor="let employee of employees;" class="d-flex align-items-center text-80 mb-2">
                    <input [checked]="userCheckboxSelected(employee.id)" (click)="setUnsetUser($event,employee.id)"
                        type="checkbox" class="mr-2">
                    <label class="m-0">{{employee.name}}</label>
                </li>
            </ul>
        </div>
    </div>
</div>