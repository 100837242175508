import { Component, OnInit, OnDestroy } from '@angular/core';
import { UiService } from './ui.service';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [

  ]
})
export class AppComponent implements OnInit, OnDestroy {


  title: any;
  quickItems:any;
  quickItemsShown = false;

  private _titleChangeSubscription: Subscription;
  private _quickItemsSetSubscription: Subscription;
  private _headerShowSubscription: Subscription;
  private _indeterminateProgressTextSetSubscription: Subscription;
  showHeader: boolean = true;
  indeterminateProgressText:any = null;

  constructor(
    private _uiService: UiService,
    private _router:Router,
    private _route:ActivatedRoute,
    private _toster: ToastrService,
    private _translate: TranslateService
  ) {
    this._translate.setDefaultLang('en_US');
    this._uiService.closeCenterPopup = this.closeCenterPopup;

  }

  ngOnInit(): void {    
    this._router.events.subscribe((url:any) => {
      let getUrl = this._router.url;
      let explode = getUrl.split("/");
      if(explode.indexOf("login")>-1 || explode.indexOf("reset-password")>-1){
        this.showHeader = false;
      }
    });

    this._titleChangeSubscription = this._uiService.changeAppTitle.subscribe(title => {
      setTimeout(() => {
        this.title = title;
      }, 10);
    });
    this._quickItemsSetSubscription = this._uiService.quickItemsSet.subscribe(items => {
      //this.quickItems = items;
    });
    this._headerShowSubscription = this._uiService.showHeader.subscribe(status => {
      setTimeout(() => {
        console.log(status);
      this.showHeader = status;
      if(!this.showHeader){        
        document.querySelector("body").classList.add("p-0");
      }else{
        document.querySelector("body").classList.remove("p-0");
      }
        
      },10);
    });

    this._indeterminateProgressTextSetSubscription = this._uiService.indeterminateProgressTextSet.subscribe(text => {
      this.setIndeterminateProgressText(text);
    });
  }

  closeQuickSummary() {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            rightMainPopup: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  closeCenterPopup()
  {
    this._router.navigate(
      [
        "../",
        {
          outlets: {
            centerPopUp: null
          }
        }
      ],
      {
        relativeTo: this._route
      }
    );
  }

  toggleQuickItems()
  {
    this.quickItemsShown = ! this.quickItemsShown;
  }

  onQuickActionClick(item:any)
  {
    item.action();
  }

  setIndeterminateProgressText(text:any)
  {
    this.indeterminateProgressText = text;
    setTimeout(() => {
      document.querySelector('.indeterminateProgressText').innerHTML = text;
      //this._toster.info(text);
    }, 10);
  }

  ngOnDestroy(): void {
    this._titleChangeSubscription.unsubscribe();
    this._headerShowSubscription.unsubscribe();
    this._indeterminateProgressTextSetSubscription.unsubscribe();
    this._quickItemsSetSubscription.unsubscribe();
  }
}
