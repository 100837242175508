import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { config } from '../config';
import { UiService } from '../ui.service';
import { DisciplineService } from '../api-client/discipline.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from '../api-client/utility.service';
import { OrganizationService } from '../api-client/organization.service';
import { ExportAsService } from 'ngx-export-as';
var HodWiseDisciplineComponent = /** @class */ (function () {
    function HodWiseDisciplineComponent(_academiaService, _disciplineService, _router, _tosterService, _utilityService, _organizationService, _exportAsService, _uiService) {
        var _this = this;
        this._academiaService = _academiaService;
        this._disciplineService = _disciplineService;
        this._router = _router;
        this._tosterService = _tosterService;
        this._utilityService = _utilityService;
        this._organizationService = _organizationService;
        this._exportAsService = _exportAsService;
        this._uiService = _uiService;
        this.env = config;
        this.totalCount = 0;
        this.disciplineSearchSettings = {
            singleSelection: false,
            text: "Select Discipline",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            badgeShowLimit: 3
        };
        this.filterKeyword = "";
        this.filterDiscipline = [];
        this.filterSemester = 0;
        this.filterLevel = 0;
        this.filterSession = "";
        this.filters = {};
        this.showSemesters = [];
        this.examinerHeader = config.examinerHeader;
        this.params = {
            "freeText": "",
            "startIndex": 0,
            "pageSize": 0
        };
        this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(function (_) {
            _this.academiaSessions = _this._academiaService.getAcademicSession();
            _this.activeSession = _this._academiaService.getActiveAcademicSession();
            _this.disciplines = _this._academiaService.getDisciplines();
            _this.filterSession = _this.activeSession.id;
        });
        this._organizationService.employees(this.params).subscribe(function (result) {
            _this.employees = result.items;
        });
    }
    HodWiseDisciplineComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.disciplines = this._academiaService.getDisciplines();
                        this._uiService.changeAppTitle.next("Disciplines");
                        return [4 /*yield*/, this._academiaService.getActiveExam().toPromise().then(function (result) {
                                _this.activeExam = result;
                                _this.showSemesters = [];
                                for (var i = 0; i < result.programs.length; i++) {
                                    for (var s = 0; s < result.programs[i].semesters.length; s++) {
                                        if (!_this.showSemesters.includes(result.programs[i].semesters[s].semester)) {
                                            _this.showSemesters.push(result.programs[i].semesters[s].semester);
                                        }
                                    }
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.academiaSessions = this._academiaService.getAcademicSession();
                        this.activeSession = this._academiaService.getActiveAcademicSession();
                        if (this.activeSession) {
                            this.filterSession = this.activeSession.id;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    HodWiseDisciplineComponent.prototype.paperFilter = function () {
        var _this = this;
        if (this.filterDiscipline.length == 0) {
            this._tosterService.error("Please select discipline.");
            return false;
        }
        var level = [];
        if (this.filterLevel) {
            level.push(Number(this.filterLevel));
        }
        var data = {
            "freeText": this.filterKeyword,
            "academicSessionId": this.filterSession,
            "disciplineIds": this.filterDiscipline.map(function (x) { return x.id; }),
            "semester": this.filterSemester,
            "levels": level,
            "startIndex": 0,
            "pageSize": 0
        };
        var heading = "";
        var session = this.academiaSessions.filter(function (x) { return x.id == _this.filterSession; });
        if (this.filterLevel) {
            heading += this.env.academicLevel[this.filterLevel] + " ";
        }
        if (this.filterDiscipline && this.filterDiscipline[0]) {
            heading += this.filterDiscipline[0].name + " ";
        }
        if (session.length > 0) {
            heading += session[0].name + " ";
        }
        if (this.filterSemester) {
            heading += "semester " + this.filterSemester;
        }
        this.exportHeading = heading;
        this._academiaService.papers(data).subscribe(function (result) {
            _this.papers = {};
            _this.totalCount = result.totalCount;
            var result = result.items;
            for (var i = 0; i < result.length; i++) {
                if (!_this.papers[result[i].semester] && _this.showSemesters.includes(result[i].semester)) {
                    _this.papers[result[i].semester] = [];
                }
                if (_this.papers[result[i].semester]) {
                    _this.papers[result[i].semester].push(result[i]);
                }
            }
            //console.log(this.papers);
            /* for (var i = 0; i < result.items.length; i++) {
              result.items[i].sessionName = this._academiaService.getSessionDetails(result.items[i].academicSessionId).name;
            } */
            //this.papers = result.items;
        });
    };
    HodWiseDisciplineComponent.prototype.unsetEvalutors = function (paper) {
        this.paperDetails = paper;
        $('#DeleteTask').modal('toggle');
        var examinationId = paper.evaluators[0].examinationId;
        paper['examinationId'] = examinationId;
        // this._utilityService.unsetEvalutors(paper).subscribe((result:any)=>{
        //   this._tosterService.success("Evalutors unset");
        //   return false;
        // })
    };
    HodWiseDisciplineComponent.prototype.unset = function () {
        var _this = this;
        var examinationId = this.paperDetails.evaluators[0].examinationId;
        this.paperDetails['examinationId'] = examinationId;
        this._utilityService.unsetEvalutors(this.paperDetails).subscribe(function (result) {
            _this._tosterService.success("Evalutors unset");
            return false;
        });
    };
    /* paperConfigure(paper: any) {
      this._router.navigate(["paper/"+paper.id]);
      return false;
    } */
    HodWiseDisciplineComponent.prototype.previewCsvData = function (fieldMaps, results) {
        var _this = this;
        this._uiService.indeterminateProgressTextSet.next('Arrange data...');
        var columns = [];
        var columnsWidth = {};
        var columnSources = [];
        /* columnSources['paperType'] = Object.keys(config.paperType).map((key) => {
          return {id:Number(key),name:config.paperType[key]};
        });
        
        columnSources['level'] = Object.keys(config.academicLevel).map((key) => {
          return {id:Number(key),name:config.academicLevel[key]};
        });
        
        columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map((key) => {
          return {id:Number(key),name:config.secondaryPaperType[key]};
        }); */
        columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map(function (x) {
            return { id: x.id, name: x.name };
        });
        /* columnSources['disciplineId'] = this._academiaService.getDisciplines().map((x:any)=>{
          return {id:x.id, name:x.name};
        }); */
        columnSources['examinerId'] = this.employees.map(function (x) {
            return { id: x.id, name: x.email };
        });
        columnSources['examinationId'] = [{ id: this.activeExam.id, name: this.activeExam.name }];
        columnSources['mode'] = [{ id: 2, name: "Examiner" }, { id: 4, name: "Reviewer" }];
        var arrangedData = results.map(function (value) {
            var item = {};
            for (var key in fieldMaps) {
                var valueWidth = (value[fieldMaps[key]] + '').length * 15;
                item[key] = value[fieldMaps[key]];
                if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
                    columnsWidth[key] = valueWidth;
                }
            }
            /* if(item['level'] && item['level'].length==2){
              var levelName = Object.values(config.academicLevelShort);
              var levelKey = Object.keys(config.academicLevelShort);
              if(levelKey.indexOf(item['level'])>-1){
                item['level'] = levelName[levelKey.indexOf(item['level'])];
              }
            } */
            if (!item['examinationId']) {
                item['examinationId'] = _this.activeExam.id;
            }
            return item;
        });
        this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
        this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
        columnsWidth = null;
        this._uiService.indeterminateProgressTextSet.next(null);
        this._uiService.openDataPreviewWindow(arrangedData, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: function () {
                    _this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
                }
            }
        ]);
    };
    HodWiseDisciplineComponent.prototype.onImportPreviewCompleted = function (fields, arrangedData, columns) {
        var _this = this;
        var data = this._uiService.getPreviewModifiedData();
        var converted = data.map(function (value) {
            var item = {};
            for (var i = 0; i < fields.length; i++) {
                item[fields[i]] = value[i];
            }
            return item;
        });
        this._uiService.closeDataPreview();
        var worker = new Worker('../workers/import-examiners.worker', { name: 'examiner-paper-worker', type: 'module' });
        this._uiService.startWorker(worker, { values: converted, sessions: this.academiaSessions, token: sessionStorage.getItem("token") })
            .then(function () {
            //show success message, if required
        }).catch(function (e) {
            if (e.validation) {
                _this.onImportValidationError(e.validation, converted, columns, fields);
            }
        });
    };
    HodWiseDisciplineComponent.prototype.onImportValidationError = function (validation, converted, columns, fields) {
        var _this = this;
        this._uiService.onDataPreviewReady = function () {
            var allFields = Object.keys(converted[0]);
            for (var i = 0; i < validation.length; i++) {
                var commonErrors = [];
                for (var field in validation[i]) {
                    var fieldIndex = allFields.indexOf(field);
                    if (fieldIndex <= 0) {
                        commonErrors = commonErrors.concat(validation[i][field]);
                    }
                    else {
                        _this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
                    }
                }
                if (commonErrors.length > 0) {
                    _this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
                }
            }
        };
        this._uiService.openDataPreviewWindow(converted, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: function () {
                    _this.onImportPreviewCompleted(fields, converted, columns);
                }
            }
        ]);
    };
    HodWiseDisciplineComponent.prototype.mapCsvField = function (results) {
        var _this = this;
        this._uiService
            .mapCsvField(results.meta.fields, this.examinerHeader)
            .then(function (maps) {
            _this.previewCsvData(maps, results.data);
        }).catch(function () { });
    };
    HodWiseDisciplineComponent.prototype.uploadFile = function (fileEle) {
        var _this = this;
        this._uiService
            .parseCsv(fileEle.files[0])
            .then(function (results) {
            fileEle.value = null;
            _this.mapCsvField(results);
        }).catch(function () {
            fileEle.value = null;
        });
    };
    HodWiseDisciplineComponent.prototype.export = function (type, elementId) {
        var _this = this;
        var configs = { type: type, elementId: elementId };
        setTimeout(function () {
            _this._exportAsService.save(configs, _this.exportHeading).subscribe(function () {
            });
        }, 1000);
    };
    return HodWiseDisciplineComponent;
}());
export { HodWiseDisciplineComponent };
