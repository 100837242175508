<div class="home_section">
    <div class="d-flex justify-content-between">
        <div class="status-section d-flex w-25 z-depth-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link h4 text-80" id="marksSubmission-tab" data-toggle="tab"
                        (click)="redirectTo('marks-submission-status')" href="#marksSubmission" role="tab"
                        aria-controls="marksSubmission" aria-selected="false">Marks&nbsp;Submission</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link h4 text-80" id="examinerAllocation-tab" data-toggle="tab"
                        href="#examinerAllocation" role="tab" aria-controls="examinerAllocation"
                        aria-selected="true">Examiner&nbsp;Allocation</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link h4 text-80 active" id="loggedInUser-tab" data-toggle="tab" href="#loggedInUser"
                        role="tab" aria-controls="loggedInUser" aria-selected="false">Logged&nbsp;In&nbsp;User</a>
                </li>
            </ul>
            <div class="tab-content w-100" id="myTabContent">
                <div class="tab-pane fade" id="marksSubmission" role="tabpanel" aria-labelledby="marksSubmission-tab">
                    <app-marks-submission-status></app-marks-submission-status>
                </div>
                <div class="tab-pane fade" id="examinerAllocation" role="tabpanel"
                    aria-labelledby="examinerAllocation-tab">
                    <app-examiner-allocation-status></app-examiner-allocation-status>
                </div>
                <div class="tab-pane fade show active" id="loggedInUser" role="tabpanel"
                    aria-labelledby="loggedInUser-tab">
                    <app-logged-in-user-status></app-logged-in-user-status>
                </div>
            </div>
        </div>
        <div class="w-75">
            <!-- <div class="row mt-5 ml-2 mr-2">
                <div class="col-lg-12">
                    <div class="card" >
                        <div class="card-body">
                            <a [routerLink]="['/invite-hod']"><h5 class="card-title">Invite HOD to assign examiners</h5></a>
                            <p class="card-text"></p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>