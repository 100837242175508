/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./organization.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/ngx-treeview/src/treeview.component.ngfactory";
import * as i4 from "ngx-treeview/src/treeview.component";
import * as i5 from "ngx-treeview/src/treeview-i18n";
import * as i6 from "ngx-treeview/src/treeview-config";
import * as i7 from "ngx-treeview/src/treeview-event-parser";
import * as i8 from "./organization.component";
import * as i9 from "@angular/router";
import * as i10 from "../ui.service";
import * as i11 from "../api-client/organization.service";
var styles_OrganizationComponent = [i0.styles];
var RenderType_OrganizationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrganizationComponent, data: {} });
export { RenderType_OrganizationComponent as RenderType_OrganizationComponent };
function View_OrganizationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa"]], [[2, "fa-caret-right", null], [2, "fa-caret-down", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.context.onCollapseExpand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.item.collapsed; var currVal_1 = !_v.parent.context.item.collapsed; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_OrganizationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "form-inline row-item"]], [[2, "selected", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "d-flex justify-content-between w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClieck(_v.context.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "em", [["class", "fa fa-pencil"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.organizationDetails(_v.context.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_1 = _v.context.item.children; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedId == _v.context.item.value); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.item.text; _ck(_v, 5, 0, currVal_2); }); }
export function View_OrganizationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["itemTemplate", 2]], null, 0, null, View_OrganizationComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "left_tree p-3 border position-relative text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "text-color text-22px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.organizationDetails({ value: 0 }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "em", [["class", "fa fa-plus-square-o"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ngx-treeview", [], null, [[null, "selectedChange"], [null, "filterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedChange" === en)) {
        var pd_0 = (_co.onSelectedChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterChange" === en)) {
        var pd_1 = (_co.onFilterChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_TreeviewComponent_0, i3.RenderType_TreeviewComponent)), i1.ɵdid(5, 573440, null, 0, i4.TreeviewComponent, [i5.TreeviewI18n, i6.TreeviewConfig, i7.TreeviewEventParser], { itemTemplate: [0, "itemTemplate"], items: [1, "items"], config: [2, "config"] }, { selectedChange: "selectedChange", filterChange: "filterChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 0); var currVal_1 = _co.rootItem; var currVal_2 = _co.config; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_OrganizationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-organization", [], null, null, null, View_OrganizationComponent_0, RenderType_OrganizationComponent)), i1.ɵdid(1, 245760, null, 0, i8.OrganizationComponent, [i9.Router, i9.ActivatedRoute, i10.UiService, i11.OrganizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrganizationComponentNgFactory = i1.ɵccf("app-organization", i8.OrganizationComponent, View_OrganizationComponent_Host_0, {}, {}, []);
export { OrganizationComponentNgFactory as OrganizationComponentNgFactory };
