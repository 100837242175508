import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TreeviewItem } from 'ngx-treeview/src/treeview-item';
import { OrganizationService } from '../api-client/organization.service';
import { SubjectSubscriber } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit, OnDestroy {

  config = {
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false
  };

  rootItem: TreeviewItem[] = [];
  selectedId: any;

  private _organizationServiceSubscription: Subscription;
  private _detailOutletUpdateForIdSubscriber: Subscription;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _organizationService: OrganizationService) {

    this._uiService.changeAppTitle.next('Organization');
    this._detailOutletUpdateForIdSubscriber = this._uiService.detailOutletUpdateForId.subscribe(departmentId => {
      this.selectedId = departmentId;
    });

    this._organizationServiceSubscription = _organizationService.onDepartmentsUpdated.subscribe(_ => {
      this.rootItem = [];
      this.convertToDataForTree(_organizationService.getDepartments(), null);
    });
  }

  convertToDataForTree(rawData: any, parentNode: any) {
    var items = rawData.filter((x: any) => x.parent == (parentNode != null ? parentNode.value : null));
    for (var i = 0; i < items.length; i++) {
      var node = {
        text: items[i].name, value: items[i].id, children: []
      };

      this.convertToDataForTree(rawData, node);

      if (parentNode == null) {
        this.rootItem.push(new TreeviewItem(node));
      } else {
        parentNode.children.push(node);
      }
    }
  }

  ngOnInit() {
    var departments = this._organizationService.getDepartments();
    if (departments != null) {
      this.convertToDataForTree(departments, null);
    }
  }

  onItemClieck(item: any) {
    this.selectedId = item.value;
    this._router.navigate([{
      outlets: { detailSection: ['department', item.value] }
    }]);
  }

  organizationDetails(organization: any) {
    //this._router.navigate(["paper/" + paper.id]);
    this._router.navigate([{
      outlets: { rightMainPopup: ['organization-details', organization.value] }
    }]);
  }

  onSelectedChange(event: any) {

  }

  onFilterChange(event: any) {

  }

  organizationDelete(department:any){
    
  }

  ngOnDestroy(): void {
    this._detailOutletUpdateForIdSubscriber.unsubscribe();
    this._organizationServiceSubscription.unsubscribe();
  }
}
