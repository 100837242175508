import { Injectable } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { config } from '../config';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  private _hubConnection: HubConnection;

  public onStudentInfUpdateNotify:Subject<any> = new Subject<any>();
  public onPaperInfoUpdateNotify:Subject<any> = new Subject<any>();
  public onSessionInfoUpdateNotify:Subject<any> = new Subject<any>();


  constructor() { 
    var url = config.apiEndPointBase.replace('api/','');
    this._hubConnection = new signalR
      .HubConnectionBuilder()
      .withUrl(`${url}notify`,{
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    // this._hubConnection
    //   .start()
    //   .then(() => this.configNotifyListener())
    //   .catch((e) => alert(e));
  }

  private configNotifyListener()
  {
    // this._hubConnection.on('StudentInfoUpdated', this.onStudentInfoUpdated.bind(this));
    // this._hubConnection.on('PaperInfoUpdated', this.onPaperInfoUpdated.bind(this));
    // this._hubConnection.on('SessionInfoUpdated', this.onSessionInfoUpdated.bind(this));
  }

  private onStudentInfoUpdated(student:any)
  {
    this.onStudentInfUpdateNotify.next(student);
  }

  private onPaperInfoUpdated(paper:any)
  {
    this.onPaperInfoUpdateNotify.next(paper);
  }

  private onSessionInfoUpdated(session:any)
  {
    this.onSessionInfoUpdateNotify.next(session);
  }

  studentUpdated(student:any)
  {
    this._hubConnection
      .invoke("StudentInfoUpdated", student)
      .then((response)=>console.log(response))
      .catch((error)=>console.error(error));
  }

  paperUpdated(paper:any)
  {
    this._hubConnection
      .invoke("PaperInfoUpdated", paper)
      .then((response)=>console.log(response))
      .catch((error)=>console.error(error));
  }

  sessionUpdated(session:any)
  {
    this._hubConnection
      .invoke("SessionInfoUpdated", session)
      .then((response)=>console.log(response))
      .catch((error)=>console.error(error));
  }
}
