<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand">
  <div class="form-inline row-item" [class.selected]="selectedId==item.value">
    <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa"
      [class.fa-caret-right]="item.collapsed" [class.fa-caret-down]="!item.collapsed"></i>
    <div class="d-flex justify-content-between w-100"><a  (click)="onItemClieck(item)">{{item.text}}</a> <span><em (click)="organizationDetails(item)" class="fa fa-pencil"></em></span></div>
  </div>
</ng-template>

<div class="left_tree p-3 border position-relative text-right">  
  <a (click)="organizationDetails({value:0})" class="text-color text-22px">
      <em  class="fa fa-plus-square-o"></em>
    </a>
  <ngx-treeview [config]="config" [items]="rootItem" [itemTemplate]="itemTemplate"
    (selectedChange)="onSelectedChange($event)" (filterChange)="onFilterChange($event)">
  </ngx-treeview>
</div>