<ng-template #noRecordFound>
    <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>No record found</strong></p>
</ng-template>

<ng-container *ngIf="!isEmpty(); else noRecordFound">
    <div class="d-flex justify-content-end">
        <!-- <h4>Papers</h4> -->
        <div class="reply_cont d-flex align-items-ceter">
            <a class="ml-2 mr-2 font-weight-500" (click)="exportCsv()"><em
                    class="fa fa-file-excel-o p-2 rounded-circle"></em>
                <span>{{ 'Export CSV' | translate }}</span></a>
            <a class="ml-2 mr-2 font-weight-500" (click)="export('pdf','semesterWiseStudent','studentSemester')"><em
                    class="fa fa-file-pdf-o p-2 rounded-circle" aria-hidden="true"></em> <span>{{ 'Export PDF' | translate }}</span></a>

        </div>
    </div>
    <div id="semesterWiseStudent">
        <h5 *ngIf="showHeader" class="text-center p-2">{{heading}}</h5>
        <div class="row text-100 grey lighten-3">
            <div class="col-sm-2">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Reg. No.' | translate }}</p>
            </div>
            <div class="col-sm-1">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Batch' | translate }}</p>
            </div>
            <div class="col-sm-2">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500 text-center" title="Class Roll Number">{{ 'CRN' | translate }}</p>
            </div>
            <div class="col-sm-2">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500 text-center" title="Examination Roll Number">{{ 'ERN' | translate }}
                </p>
            </div>
            <div class="col-sm-5">
                <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Name' | translate }}</p>
            </div>
        </div>
        <ng-container *ngFor="let student of students | paginate: {id: 'studentsPagination',
        itemsPerPage: pageSize,
        currentPage: currentPage,
        totalItems: totalItemsCount}; let i=index">
            <ng-container *ngIf="i==0 || students[i-1].currentSemester!=students[i].currentSemester">
                <ng-container *ngIf="student.currentSemester==-1">
                    <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>{{ 'Casual' | translate }}</strong></p>
                </ng-container>
                <ng-container *ngIf="student.currentSemester!=-1">
                    <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>{{ 'Semester' | translate }} : {{student.currentSemester}}</strong></p>
                </ng-container>
            </ng-container>
            <div class="row item-row" (click)="openSummary(student)">
                <div class="col-sm-2">
                    <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1">{{student.registrationNumber}}</p>
                </div>
                <div class="col-sm-1">
                    <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center">{{student.batchStartYear}}</p>
                </div>
                <div class="col-sm-2">
                    <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center">{{student.classRollNumber}}</p>
                </div>
                <div class="col-sm-2">
                    <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center">{{student.examinationRollNumber}}</p>
                </div>
                <div class="col-sm-5">
                    <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1">{{student.name}}</p>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="d-flex p-0 pt-3 justify-content-end">
        <pagination-controls id="studentsPagination" (pageChange)="onPageChange($event)"></pagination-controls>
    </div>

</ng-container>