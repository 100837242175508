import * as signalR from '@aspnet/signalr';
import { config } from '../config';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var NotifyService = /** @class */ (function () {
    function NotifyService() {
        this.onStudentInfUpdateNotify = new Subject();
        this.onPaperInfoUpdateNotify = new Subject();
        this.onSessionInfoUpdateNotify = new Subject();
        var url = config.apiEndPointBase.replace('api/', '');
        this._hubConnection = new signalR
            .HubConnectionBuilder()
            .withUrl(url + "notify", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        })
            .build();
        // this._hubConnection
        //   .start()
        //   .then(() => this.configNotifyListener())
        //   .catch((e) => alert(e));
    }
    NotifyService.prototype.configNotifyListener = function () {
        // this._hubConnection.on('StudentInfoUpdated', this.onStudentInfoUpdated.bind(this));
        // this._hubConnection.on('PaperInfoUpdated', this.onPaperInfoUpdated.bind(this));
        // this._hubConnection.on('SessionInfoUpdated', this.onSessionInfoUpdated.bind(this));
    };
    NotifyService.prototype.onStudentInfoUpdated = function (student) {
        this.onStudentInfUpdateNotify.next(student);
    };
    NotifyService.prototype.onPaperInfoUpdated = function (paper) {
        this.onPaperInfoUpdateNotify.next(paper);
    };
    NotifyService.prototype.onSessionInfoUpdated = function (session) {
        this.onSessionInfoUpdateNotify.next(session);
    };
    NotifyService.prototype.studentUpdated = function (student) {
        this._hubConnection
            .invoke("StudentInfoUpdated", student)
            .then(function (response) { return console.log(response); })
            .catch(function (error) { return console.error(error); });
    };
    NotifyService.prototype.paperUpdated = function (paper) {
        this._hubConnection
            .invoke("PaperInfoUpdated", paper)
            .then(function (response) { return console.log(response); })
            .catch(function (error) { return console.error(error); });
    };
    NotifyService.prototype.sessionUpdated = function (session) {
        this._hubConnection
            .invoke("SessionInfoUpdated", session)
            .then(function (response) { return console.log(response); })
            .catch(function (error) { return console.error(error); });
    };
    NotifyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotifyService_Factory() { return new NotifyService(); }, token: NotifyService, providedIn: "root" });
    return NotifyService;
}());
export { NotifyService };
