import { Injectable } from '@angular/core';
import { config } from '../../app/config';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // let authDecode = localStorage.getItem("token");
    let authDecode = sessionStorage.getItem("token");
    if (authDecode) {
      return true;
    }
    this._router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    return false;

  }
}
