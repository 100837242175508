import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../../app/config';
import { StudentModel } from '../models/student.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  private _students: any;

  public onStudentsUpdated = new Subject();

  constructor(private _http: HttpClient) {
    _http.get(config.apiEndPointBase + "organization/departments").subscribe((result: any) => {
      result.sort((x: any, y: any) => {
        return x.name.localeCompare(y.name);
      });
      this._students = result;
      this.onStudentsUpdated.next();
    });
  }

  public getStudents() {
    return this._students;
  }

  public getStudent(studentId: any) {
    return this._http.get(config.apiEndPointBase + "student/" + studentId);
  }

  public getEvaluation(studentId: string) {
    return this._http.get(config.apiEndPointBase + "student/" + studentId + "/evaluation");
  }

  getHistory(studentId: any) {
    return this._http.get(config.apiEndPointBase + "student/" + studentId + "/marks-history");
  }

  saveStudent(data: StudentModel): Observable<StudentModel> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "student", data, {})
        .pipe(map((response: any) => {
          return new StudentModel().deserialize(response);
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "student", data, {})
        .pipe(map((response: any) => {
          return new StudentModel().deserialize(response);
        }));
    }
  }


  getStatisticsReport(examinationId: any) {
    return this._http.get(config.apiEndPointBase + "Examination/statistics/" + examinationId);
  }

  public getStudentSemisterPassExam(studentId: string) {
    return this._http.get(config.apiEndPointBase + "Student/" + studentId + "/semester-pass-on-examination");
  }

}
