/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header-menu/header-menu.component.ngfactory";
import * as i3 from "./header-menu/header-menu.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/router";
import * as i6 from "./api-client/academia.service";
import * as i7 from "@angular/common";
import * as i8 from "./app.component";
import * as i9 from "./ui.service";
import * as i10 from "ngx-toastr";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header-menu", [], null, null, null, i2.View_HeaderMenuComponent_0, i2.RenderType_HeaderMenuComponent)), i1.ɵdid(2, 245760, null, 0, i3.HeaderMenuComponent, [i4.TranslateService, i5.Router, i6.AcademiaService], { title: [0, "title"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.icon, " text-color m-1 mr-2"); _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex pl-2 pr-2 pt-2 pb-1 border-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onQuickActionClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.icon; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.text; _ck(_v, 4, 0, currVal_1); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "quick-items z-depth-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "quick-items-header d-flex justify-content-between"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleQuickItems() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "item_head font-weight-600"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quick Items"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "em", [["class", "fa fa-expand p-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "quick-items-body"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(7, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.quickItems; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.quickItemsShown; _ck(_v, 5, 0, currVal_0); }); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [["name", "detailSection"]], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "detailSection"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "quick-summary z-depth-1"]], null, null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "show-quick-summary": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "my-custom-scrollbar my-custom-scrollbar-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 16777216, null, null, 1, "router-outlet", [["name", "rightMainPopup"]], null, null, null, null, null)), i1.ɵdid(13, 212992, [["rightMainPopupOutlet", 4]], 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "rightMainPopup"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "em", [["class", "close fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeQuickSummary() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 8, "div", [["class", "center-popup"]], null, null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(17, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(18, { "show-center-popup": 0 }), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["class", "center-popup-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "center-popup-container z-depth-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 16777216, null, null, 1, "router-outlet", [["name", "centerPopUp"]], null, null, null, null, null)), i1.ɵdid(22, 212992, [["centerPopUpOutlet", 4]], 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "centerPopUp"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 0, "em", [["class", "close fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeCenterPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(25, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 7, "div", [["class", "status-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 6, "div", [["class", "indeterminate-progress d-none align-items-center ml-2 mr-2"]], null, null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(29, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(30, { "d-flex": 0 }), (_l()(), i1.ɵeld(31, 0, null, null, 1, "em", [["class", "material-icons mr-1 spin"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["cached"])), (_l()(), i1.ɵeld(33, 0, null, null, 0, "span", [["class", "indeterminateProgressText"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showHeader; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); _ck(_v, 6, 0); var currVal_1 = "quick-summary z-depth-1"; var currVal_2 = _ck(_v, 10, 0, i1.ɵnov(_v, 13).isActivated); _ck(_v, 9, 0, currVal_1, currVal_2); _ck(_v, 13, 0); var currVal_3 = "center-popup"; var currVal_4 = _ck(_v, 18, 0, i1.ɵnov(_v, 22).isActivated); _ck(_v, 17, 0, currVal_3, currVal_4); _ck(_v, 22, 0); var currVal_5 = (_co.quickItems && _co.quickItems.length); _ck(_v, 25, 0, currVal_5); var currVal_6 = "indeterminate-progress d-none align-items-center ml-2 mr-2"; var currVal_7 = _ck(_v, 30, 0, (_co.indeterminateProgressText != null)); _ck(_v, 29, 0, currVal_6, currVal_7); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i8.AppComponent, [i9.UiService, i5.Router, i5.ActivatedRoute, i10.ToastrService, i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
