import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { config } from '../../app/config';
import { PaperModel } from '../models/paper.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var paperService = /** @class */ (function () {
    function paperService(_http) {
        var _this = this;
        this._http = _http;
        this.onPapersUpdated = new Subject();
        _http.get(config.apiEndPointBase + "organization/departments").subscribe(function (result) {
            result.sort(function (x, y) {
                return x.name.localeCompare(y.name);
            });
            _this._papers = result;
            _this.onPapersUpdated.next();
        });
    }
    paperService.prototype.getPapers = function () {
        return this._papers;
    };
    /* public getPaper(id:any)
    {
      if(this._papers == null) return null;
      var items = this._papers.filter((x:any) => x.id == id);
      if(items.length>0){
        return items[0];
      }else{
        return null;
      }
    } */
    paperService.prototype.getPaper = function (paperId) {
        return this._http.get(config.apiEndPointBase + "Paper/" + paperId);
    };
    paperService.prototype.getEvaluators = function (paperId, examId) {
        return this._http.get(config.apiEndPointBase + "paper/" + paperId + "/evaluators/" + examId);
    };
    paperService.prototype.updateMarksUploadDate = function (paperId, examinationId, mode, term, evaluatorId) {
        if (term == 'mid') {
            return this._http.get(config.apiEndPointBase + "examination/send-submited-mid-term-marks/" + examinationId + "/" + paperId + "/" + mode + "?evaluatorId=" + evaluatorId);
        }
        else {
            return this._http.get(config.apiEndPointBase + "examination/send-submited-end-term-marks/" + examinationId + "/" + paperId + "/" + mode + "?evaluatorId=" + evaluatorId);
        }
    };
    paperService.prototype.savePaper = function (data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "paper", data, {})
                .pipe(map(function (response) {
                return new PaperModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "paper", data, {})
                .pipe(map(function (response) {
                return new PaperModel().deserialize(response);
            }));
        }
    };
    paperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function paperService_Factory() { return new paperService(i0.ɵɵinject(i1.HttpClient)); }, token: paperService, providedIn: "root" });
    return paperService;
}());
export { paperService };
