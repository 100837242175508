import { Component, OnInit } from '@angular/core';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  menuItems = [
    {title:'Organization', url:'/organization', icon:'fa-sitemap'},
    {title:'Programs', url:'/programs', icon:'fa-trophy'},
    {title:'Sessions', url:'/sessions', icon:'fa-info'},
    {title:'Papers', url:'/papers', icon:'fa-file-text-o'},
    {title:'Students', url:'/students', icon:'fa-user'},
    {title:'Notification Template', url:'/notification-template', icon:'fa-bell'},
    {title:'Privileges', url:'/privileges', icon:'fa-lock'},
    {title:'Faculties', url:'/staffs', icon:'fa-user'},

  ]

  constructor(
    private _uiService: UiService
  ) { }

  ngOnInit() {
    this._uiService.changeAppTitle.next('Main Menu');
  }

}
