import * as tslib_1 from "tslib";
import { OnInit, OnChanges, OnDestroy } from '@angular/core';
import { config } from '../../app/config';
import { paperService } from '../api-client/paper.service';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { OrganizationService } from '../api-client/organization.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { StudentService } from '../api-client/student.service';
import { ExportAsService } from 'ngx-export-as';
import { ExamService } from '../api-client/exam.service';
import { CommonMethods } from '../common-methods';
import { ProgramService } from '../api-client/program.service';
var StudentsComponent = /** @class */ (function () {
    function StudentsComponent(_paperService, _academiaService, _route, _tosterService, _uiService, _translate, _studentService, _exportAsService, _examService, _organizationService, _router, _programService) {
        var _this = this;
        this._paperService = _paperService;
        this._academiaService = _academiaService;
        this._route = _route;
        this._tosterService = _tosterService;
        this._uiService = _uiService;
        this._translate = _translate;
        this._studentService = _studentService;
        this._exportAsService = _exportAsService;
        this._examService = _examService;
        this._organizationService = _organizationService;
        this._router = _router;
        this._programService = _programService;
        this.filterKeyword = "";
        this.filterProgram = [];
        this.filterSession = "";
        this.convocationSession = "";
        this.filterSemester = 0;
        this.filterRegistrationYear = null;
        this.filterBatchStartYear = null;
        this.filters = {};
        this.pageSize = config.paginationPerPage;
        this.totalItemsCount = 0;
        this.totalPageCount = 0;
        this.papersCurrentPage = 1;
        this.studentCsvHeader = config.studentCsvHeader;
        this.tabulationStudents = [];
        this.config = config;
        this.pprTabulationStudents = [];
        this.programSearchSettings = {
            singleSelection: false,
            text: "Select Programs",
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            enableSearchFilter: true,
            badgeShowLimit: 3
        };
        this.examinationId = "";
        this.evaluatorType = "";
        this.commonMethodsSemName = CommonMethods;
        this.CBCS_MAJOR = 304;
        this.includeOnly = 0;
        this.DSE_MAJOR = 32;
        this.SEC_MAJOR = 256;
        this._academiaService.examination().subscribe(function (result) {
            _this.examinationSession = result;
        });
        this._academiaService.getActiveExam().subscribe(function (result) {
            _this.activeExam = result;
        });
        this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(function (_) {
            _this.programs = _this._academiaService.getPrograms();
            _this.disciplines = _this._academiaService.getDisciplines();
            _this.academiaSessions = _this._academiaService.getAcademicSession();
        });
        this._academiaSessionServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(function (_) {
            _this.getExaminationsRespectiveSession();
        });
    }
    StudentsComponent.prototype.ngOnChanges = function (changes) {
        this.getExaminationsRespectiveSession();
    };
    StudentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._uiService.changeAppTitle.next("Students");
        this.academiaSessions = this._academiaService.getAcademicSession();
        this.disciplines = this._academiaService.getDisciplines();
        this.programs = this._academiaService.getPrograms();
        // console.log('this.academiaSessions',this.academiaSessions);
        this._academiaService.getActiveExam().subscribe(function (result) {
            _this.activeExam = result;
        });
        this.studentFilter();
    };
    StudentsComponent.prototype.studentFilter = function () {
        var _this = this;
        this.filters = {
            "freeText": this.filterKeyword,
            "academicSessionId": this.filterSession,
            "passoutSessionId": this.convocationSession ? this.convocationSession : null,
            "programIds": this.filterProgram.map(function (x) { return x.id; }),
            "semester": this.filterSemester,
            "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
            "registrationYear": this.filterRegistrationYear ? this.filterRegistrationYear : 0,
            "lastExaminationId": this.examinationId ? this.examinationId : null,
            "mode": this.evaluatorType ? this.evaluatorType : 0,
            "startIndex": (this.papersCurrentPage - 1) * this.pageSize,
            "pageSize": this.pageSize,
            "include": Number(this.includeOnly),
        };
        this._academiaService.students(this.filters).subscribe(function (result) {
            for (var i = 0; i < result.items.length; i++) {
                var sessionDetails = _this.sessionDetails(result.items[i].academicSessionId);
                result.items[i].sessionName = sessionDetails ? sessionDetails.name : '';
            }
            _this.students = result.items;
            _this.totalItemsCount = result.totalCount;
            _this.totalPageCount = Math.ceil(_this.totalItemsCount / _this.pageSize);
        });
    };
    StudentsComponent.prototype.migrateBlock = function (typy, student, data) {
        var _this = this;
        if (typy === 1) {
            data.migrated = student;
        }
        else {
            data.blocked = student;
        }
        this._studentService.saveStudent(data)
            .subscribe(function (response) {
            if (typy === 1) {
                if (student === true) {
                    _this._tosterService.success("Student migrated successfully");
                }
                else {
                    _this._tosterService.success("Student unmigrated successfully");
                }
            }
            else {
                if (student === true) {
                    _this._tosterService.success("Student block successfully");
                }
                else {
                    _this._tosterService.success("Student unblock successfully");
                }
            }
            return false;
        }, function (error) {
            _this._tosterService.error(error.message);
        });
    };
    StudentsComponent.prototype.sessionDetails = function (sessionId) {
        if (this.academiaSessions == null)
            return null;
        var items = this.academiaSessions.filter(function (x) { return x.id == sessionId; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    StudentsComponent.prototype.onPageChange = function (pageIndex) {
        this.papersCurrentPage = pageIndex;
        this.studentFilter();
    };
    StudentsComponent.prototype.redirectTo = function (student) {
        this._router.navigate([{
                outlets: { rightMainPopup: ['student-summary', student.id] }
            }]);
    };
    StudentsComponent.prototype.getExaminationsRespectiveSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selectedSession;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedSession = this._academiaService.getActiveAcademicSession();
                        if (!selectedSession)
                            return [2 /*return*/];
                        return [4 /*yield*/, this._academiaService.getSessionWiseExams(selectedSession.id).toPromise().then(function (result) {
                                _this.examinations = result;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.ngOnDestroy = function () {
        this._academiaServiceSubscription.unsubscribe();
        this._academiaSessionServiceSubscription.unsubscribe();
    };
    StudentsComponent.prototype.previewCsvData = function (fieldMaps, results) {
        var _this = this;
        this._uiService.indeterminateProgressTextSet.next('Arrange data...');
        var columns = [];
        var columnSources = [];
        var columnsWidth = {};
        this.academiaSessions = this._academiaService.getAcademicSession();
        this.disciplines = this._academiaService.getDisciplines();
        this.programs = this._academiaService.getPrograms();
        columnSources['academicSessionId'] = this.academiaSessions.map(function (x) {
            return { id: x.id, name: x.name };
        });
        columnSources['academicProgramId'] = this.programs.map(function (x) {
            return { id: x.id, name: x.name };
        });
        var arrangedData = results.map(function (value) {
            var item = {};
            for (var key in fieldMaps) {
                var valueWidth = (value[fieldMaps[key]] + '').length * 15;
                item[key] = value[fieldMaps[key]];
                if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
                    columnsWidth[key] = valueWidth;
                }
            }
            //debugger;
            if (item['Stream'] && item['Discipline'] && !item['academicProgramId']) {
                var disciplines = _this.disciplines.filter(function (x) { return x.name.toUpperCase() == item['Discipline'].trim().toUpperCase(); });
                if (disciplines.length > 0) {
                    var stream = config.academicLevelShort[item['Stream']];
                    var programs = _this.programs.filter(function (x) { return x.disciplineId == disciplines[0].id && x.academicLevel == stream; });
                    if (programs.length > 0) {
                        item['academicProgramId'] = programs[0].id;
                    }
                }
            }
            if (item['batchStartYear'] && !item['academicSessionId']) {
                var sessions = _this.academiaSessions.filter(function (x) { return ((new Date(x.start)).getFullYear() == item['batchStartYear']); });
                if (sessions.length > 0) {
                    item['academicSessionId'] = sessions[0].id;
                }
            }
            return item;
        });
        this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
        this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
        columnsWidth = null;
        this._uiService.indeterminateProgressTextSet.next(null);
        this._uiService.openDataPreviewWindow(arrangedData, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: function () {
                    _this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
                }
            }
        ]);
    };
    StudentsComponent.prototype.onImportPreviewCompleted = function (fields, arrangedData, columns) {
        var _this = this;
        var data = this._uiService.getPreviewModifiedData();
        var converted = data.map(function (value) {
            var item = {};
            for (var i = 0; i < fields.length; i++) {
                item[fields[i]] = value[i];
            }
            return item;
        });
        this._uiService.closeDataPreview();
        var worker = new Worker('../workers/import-students.worker', { name: 'student-worker', type: 'module' });
        this._uiService.startWorker(worker, converted)
            .then(function () {
            //show success message, if required
        }).catch(function (e) {
            console.error('worker error', e);
            if (e.validation) {
                _this.onImportValidationError(e.validation, converted, columns, fields);
            }
        });
    };
    StudentsComponent.prototype.onImportValidationError = function (validation, converted, columns, fields) {
        var _this = this;
        this._uiService.onDataPreviewReady = function () {
            var allFields = Object.keys(converted[0]);
            for (var i = 0; i < validation.length; i++) {
                var commonErrors = [];
                for (var field in validation[i]) {
                    var fieldIndex = allFields.indexOf(field);
                    if (fieldIndex <= 0) {
                        commonErrors = commonErrors.concat(validation[i][field]);
                    }
                    else {
                        _this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
                    }
                }
                if (commonErrors.length > 0) {
                    _this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
                }
            }
        };
        this._uiService.openDataPreviewWindow(converted, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: function () {
                    _this.onImportPreviewCompleted(fields, converted, columns);
                }
            }
        ]);
    };
    StudentsComponent.prototype.mapCsvField = function (results) {
        var _this = this;
        this._uiService
            .mapCsvField(results.meta.fields, this.studentCsvHeader)
            .then(function (maps) {
            _this.previewCsvData(maps, results.data);
        }).catch(function () { });
    };
    StudentsComponent.prototype.uploadFile = function (fileEle) {
        var _this = this;
        this._uiService
            .parseCsv(fileEle.files[0])
            .then(function (results) {
            fileEle.value = null;
            _this.mapCsvField(results);
        }).catch(function () {
            fileEle.value = null;
        });
    };
    StudentsComponent.prototype.updateStudentCurrentSemester = function () {
        var _this = this;
        if (!this.semDate) {
            this._tosterService.error("Please select date");
            return false;
        }
        this._academiaService.updateStudentCurrentSemester(this.semDate).subscribe(function (response) {
            _this._tosterService.success("Semester's updated");
            _this.relode();
        });
    };
    StudentsComponent.prototype.relode = function () {
        this.studentFilter();
    };
    StudentsComponent.prototype.studentsTabulation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filters = {
                            "freeText": this.filterKeyword,
                            "academicSessionId": this.filterSession,
                            "programIds": this.filterProgram.map(function (x) { return x.id; }),
                            "semester": 0,
                            "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
                            "registrationYear": 0,
                            "lastExaminationId": this.examinationId ? this.examinationId : null,
                            "mode": this.evaluatorType ? this.evaluatorType : 0,
                            "startIndex": 0,
                            "pageSize": 0
                        };
                        return [4 /*yield*/, this._academiaService.students(filters).toPromise().then(function (result) {
                                _this.tabulationStudents = result.items;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.studentsGradeCardTabulation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var receiveData, n, filters;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.allPrograms = this.filterProgram;
                        receiveData = [];
                        this.programTitle = '';
                        n = 0;
                        _a.label = 1;
                    case 1:
                        if (!(n < this.allPrograms.length)) return [3 /*break*/, 4];
                        this.programTitle = this.programTitle + this.allPrograms[n].name + ',';
                        filters = {
                            "freeText": this.filterKeyword,
                            "academicSessionId": this.filterSession,
                            "passoutSessionId": this.convocationSession ? this.convocationSession : null,
                            "programIds": [this.allPrograms[n].id],
                            "semester": 0,
                            "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
                            "registrationYear": 0,
                            "lastExaminationId": this.examinationId ? this.examinationId : null,
                            "mode": this.evaluatorType ? this.evaluatorType : 0,
                            "startIndex": 0,
                            "pageSize": 0
                        };
                        return [4 /*yield*/, this._academiaService.students(filters).toPromise().then(function (result) {
                                receiveData = receiveData.concat(result.items);
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        n++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.tabulationStudents = receiveData;
                        return [2 /*return*/, this.tabulationStudents];
                }
            });
        });
    };
    StudentsComponent.prototype.studentsConsolidated = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var receiveData, n, filters;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.allPrograms = this.filterProgram;
                        receiveData = [];
                        this.programTitle = '';
                        n = 0;
                        _a.label = 1;
                    case 1:
                        if (!(n < this.allPrograms.length)) return [3 /*break*/, 4];
                        this.programTitle = this.programTitle + this.allPrograms[n].name + ',';
                        filters = {
                            "freeText": this.filterKeyword,
                            "academicSessionId": this.filterSession,
                            "passoutSessionId": this.convocationSession ? this.convocationSession : null,
                            "programIds": [this.allPrograms[n].id],
                            "semester": this.filterSemester,
                            "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
                            "registrationYear": 0,
                            "startIndex": 0,
                            "pageSize": 0
                        };
                        return [4 /*yield*/, this._academiaService.students(filters).toPromise().then(function (result) {
                                receiveData = receiveData.concat(result.items);
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        n++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.consolidateStudents = receiveData;
                        return [2 /*return*/, this.consolidateStudents];
                }
            });
        });
    };
    StudentsComponent.prototype.tabulation = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, filename, program, back, back, i, e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        filename = "";
                        this.type = type;
                        if (this.filterSemester == 0 || this.filterSemester == null) {
                            this._tosterService.error("Semester can not be blank.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterProgram.length == 0 || this.filterProgram.length > 1) {
                            this._tosterService.error("Select one program.");
                            return [2 /*return*/, false];
                        }
                        if (this.evaluatorType == "") {
                            if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
                                this._tosterService.error("Batch year can not be blank.");
                                return [2 /*return*/, false];
                            }
                        }
                        return [4 /*yield*/, this.studentsTabulation()];
                    case 1:
                        _a.sent();
                        program = this.programs.filter(function (x) { return x.id == _this.filterProgram[0].id; });
                        if (this.evaluatorType && this.evaluatorType == '6') {
                            back = 'Backlog ';
                        }
                        else {
                            back = '';
                        }
                        if (program.length > 0) {
                            this.program = program[0];
                            filename = program[0].name + " " + this.filterSemester + " semester" + " " + back + type.toUpperCase();
                        }
                        if (!this.tabulationStudents)
                            return [2 /*return*/];
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.tabulationStudents.length)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        if (!(this.tabulationStudents[i].blocked === false && this.tabulationStudents[i].migrated === false)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._studentService.getEvaluation(this.tabulationStudents[i].id).toPromise().then(function (result) {
                                var semesterDetails = result.semestersDetail.filter(function (x) { return x.semester == _this.filterSemester; });
                                if (semesterDetails.length > 0) {
                                    semesterDetails[0].courses.sort(function (x, y) {
                                        if (x.paper.paperType == y.paper.paperType) {
                                            return x.paper.code.localeCompare(y.paper.code);
                                        }
                                        else {
                                            return (x.paper.paperType - y.paper.paperType);
                                        }
                                    });
                                    var review = false;
                                    var reviewData = [];
                                    for (var k = 0; k < semesterDetails[0].courses.length; k++) {
                                        if (semesterDetails[0].courses[k].previousEntry && Object.entries(semesterDetails[0].courses[k].previousEntry).length > 0 && semesterDetails[0].courses[k].lastEvaluatorType == 4) {
                                            review = true;
                                            reviewData.push(semesterDetails[0].courses[k].previousEntry);
                                        }
                                        else {
                                            reviewData.push(semesterDetails[0].courses[k]);
                                        }
                                    }
                                    var sessionDetails = _this.sessionDetails(result.academicSessionId);
                                    result.sessionName = sessionDetails ? sessionDetails.name : '';
                                    if (_this.evaluatorType && _this.evaluatorType == '6') {
                                        for (var n = 0; n < result.semestersDetail.length; n++) {
                                            if (result.semestersDetail[n].semester == _this.filterSemester) {
                                                var backlog = false;
                                                for (var x = 0; x < result.semestersDetail[n].courses.length; x++) {
                                                    if (result.semestersDetail[n].courses[x].lastAppeardInExaminationId == _this.examinationId && result.semestersDetail[n].courses[x].lastEvaluatorType == 6) {
                                                        backlog = true;
                                                    }
                                                }
                                                if (backlog == true) {
                                                    students.push({ isReviewMarks: review, reviewData: reviewData, studentDetails: result, semesterDetails: semesterDetails[0] });
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        students.push({ isReviewMarks: review, reviewData: reviewData, studentDetails: result, semesterDetails: semesterDetails[0] });
                                    }
                                }
                            }).catch(function (error) {
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_1 = _a.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8:
                        this.studentTabulation = students;
                        //console.log(JSON.stringify(students));return;
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "studentMarks" }, filename).subscribe(function () {
                            });
                        }, 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.pprStudentsTabulation = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filename, exten, program, filters;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        filename = "";
                        this.pprType = type;
                        exten = type.toUpperCase();
                        if (this.filterSemester == 0 || this.filterSemester == null) {
                            this._tosterService.error("Semester can not be blank.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterProgram.length == 0 || this.filterProgram.length > 1) {
                            this._tosterService.error("Select one program.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
                            this._tosterService.error("Batch year can not be blank.");
                            return [2 /*return*/, false];
                        }
                        program = this.programs.filter(function (x) { return x.id == _this.filterProgram[0].id; });
                        if (program.length > 0) {
                            this.program = program[0];
                            filename = program[0].name + " " + this.filterSemester + " semester PPR " + exten;
                        }
                        filters = {
                            "programId": this.filterProgram.map(function (x) { return x.id; })[0],
                            "semester": this.filterSemester,
                            "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
                            "examinationId": this.activeExam.id,
                            "mode": 4
                        };
                        return [4 /*yield*/, this._examService.pprMarks(filters).toPromise().then(function (result) {
                                console.log(result);
                                var students = [];
                                for (var i = 0; i < result.length; i++) {
                                    if (result[i].blocked === false && result[i].migrated === false) {
                                        var semesterDetails = result[i].semestersDetail.filter(function (x) { return x.semester == _this.filterSemester; });
                                        if (semesterDetails.length > 0) {
                                            semesterDetails[0].courses.sort(function (x, y) {
                                                if (x.paper.paperType == y.paper.paperType) {
                                                    return x.paper.code.localeCompare(y.paper.code);
                                                }
                                                else {
                                                    return (x.paper.paperType - y.paper.paperType);
                                                }
                                            });
                                            /* var review = false;
                                            var reviewData = [];
                                            for (var k = 0; k < semesterDetails[0].courses.length; k++) {
                                              if (semesterDetails[0].courses[k].previousEntry && Object.entries(semesterDetails[0].courses[k].previousEntry).length > 0 && semesterDetails[0].courses[k].lastEvaluatorType == 4) {
                                                review = true;
                                                reviewData.push(semesterDetails[0].courses[k].previousEntry);
                                              } else {
                                                reviewData.push(semesterDetails[0].courses[k]);
                                              }
                                            } */
                                            var sessionDetails = _this.sessionDetails(result[i].academicSessionId);
                                            result[i].sessionName = sessionDetails ? sessionDetails.name : '';
                                            students.push({ studentDetails: result[i], semesterDetails: semesterDetails[0] });
                                        }
                                    }
                                }
                                _this.pprTabulationStudents = students;
                                //console.log(students); return;
                                setTimeout(function () {
                                    _this._exportAsService.save({ type: "csv", elementId: "studentPpr" }, filename).subscribe(function () {
                                    });
                                }, 1000);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.gradeCard = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, filename, program, i, e_2, back, back, sessionName, sessionName, csvTitle;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        filename = "";
                        if (this.filterSemester == 0 || this.filterSemester == null) {
                            this._tosterService.error("Semester can not be blank.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterProgram.length == 0) {
                            this._tosterService.error("Select one program.");
                            return [2 /*return*/, false];
                        }
                        if (this.evaluatorType == "") {
                            if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
                                this._tosterService.error("Batch year can not be blank.");
                                return [2 /*return*/, false];
                            }
                        }
                        return [4 /*yield*/, this.studentsGradeCardTabulation()];
                    case 1:
                        _a.sent();
                        // console.log(this.tabulationStudents);
                        if (!this.tabulationStudents)
                            return [2 /*return*/];
                        program = this.programs.filter(function (x) { return x.id == _this.filterProgram[0].id; });
                        if (program.length > 0) {
                            this.program = program[0];
                            this.title = this.programTitle + " " + this.filterSemester + " semester";
                        }
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.tabulationStudents.length)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        if (!(this.tabulationStudents[i].blocked === false && this.tabulationStudents[i].migrated === false)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._studentService.getEvaluation(this.tabulationStudents[i].id).toPromise().then(function (result) {
                                var semesterDetails = result.semestersDetail.filter(function (x) { return x.semester == _this.filterSemester; });
                                if (semesterDetails.length > 0) {
                                    semesterDetails[0].courses.sort(function (x, y) {
                                        if (x.paper.paperType == y.paper.paperType) {
                                            return x.paper.code.localeCompare(y.paper.code);
                                        }
                                        else {
                                            return (x.paper.paperType - y.paper.paperType);
                                        }
                                    });
                                    var totPaperCredit = 0;
                                    var totMajorMarksObtained = 0;
                                    var totMajorMarks = 0;
                                    var totGenedMarksObtained = 0;
                                    var totGenedMarks = 0;
                                    var totGrandMarksObtained = 0;
                                    var totalEndTermMarksObtained = 0;
                                    var totalMajorMarksObtain = 0;
                                    var totalMajorMarks = 0;
                                    var totMarksObtained = 0;
                                    var totEndTermMarksObtained = 0;
                                    var majorMarks = 0;
                                    var majorMarksObtained = 0;
                                    for (var k = 0; k < semesterDetails[0].courses.length; k++) {
                                        totPaperCredit += semesterDetails[0].courses[k].paper.credit;
                                        semesterDetails[0].courses[k].paper.name = _this.htmlToText(semesterDetails[0].courses[k].paper.name);
                                        if (semesterDetails[0].courses[k]) {
                                            if (semesterDetails[0].courses[k].paper.paperType == 2) {
                                                totMajorMarksObtained += semesterDetails[0].courses[k].totalMarksObtained;
                                                totMajorMarks += semesterDetails[0].courses[k].paper.fullMarks;
                                                // majorMarks += majorMarks + result.semestersDetail[0].courses[k].paper.fullMarks ;
                                                // majorMarksObtained += majorMarksObtained + result.semestersDetail[0].courses[k].totalMarksObtained ;
                                            }
                                            if (semesterDetails[0].courses[k].paper.paperType == 4) {
                                                totGenedMarksObtained += semesterDetails[0].courses[k].totalMarksObtained;
                                                totGenedMarks += semesterDetails[0].courses[k].paper.fullMarks;
                                            }
                                            if (semesterDetails[0].courses[k].paper.paperType == _this.CBCS_MAJOR) {
                                                totMajorMarksObtained += semesterDetails[0].courses[k].totalMarksObtained;
                                                totMajorMarks += semesterDetails[0].courses[k].paper.fullMarks;
                                            }
                                            // console.log('ff2', result.semestersDetail[0].courses[k].totalMarksObtained);
                                            if (semesterDetails[0].courses[k].paper.paperType == 16 || semesterDetails[0].courses[k].paper.paperType == 2) {
                                                majorMarks = majorMarks + semesterDetails[0].courses[k].paper.fullMarks;
                                                majorMarksObtained = majorMarksObtained + semesterDetails[0].courses[k].totalMarksObtained;
                                            }
                                            totMarksObtained = totMarksObtained + semesterDetails[0].courses[k].paper.fullMarks;
                                            totEndTermMarksObtained = totEndTermMarksObtained + semesterDetails[0].courses[k].totalMarksObtained;
                                        }
                                    }
                                    var discipline = _this.disciplines.filter(function (x) { return x.id == semesterDetails[0].courses[0].paper.disciplineId; });
                                    if (discipline.length > 0) {
                                        _this.discipline = discipline[0].name;
                                    }
                                    var selected_program = _this.programs.filter(function (x) { return x.id == result.academicProgramId; });
                                    if (selected_program.length > 0) {
                                        _this.selected_program = selected_program[0];
                                        var pro1 = selected_program[0].name.split('.')[0];
                                        var pro2 = selected_program[0].name.split('.')[1];
                                        if (pro2 == ' A') {
                                            var dep2 = 'Arts';
                                        }
                                        else if (pro2 == 'Sc' || pro2 == ' Sc') {
                                            var dep2 = 'Science';
                                        }
                                        else {
                                            var dep2 = '';
                                        }
                                        if (pro1 == 'B') {
                                            var dep1 = 'Bachelor';
                                        }
                                        else if (pro1 == 'M') {
                                            var dep1 = 'Master';
                                        }
                                        else {
                                            var dep1 = 'Phd';
                                        }
                                        var resultOfThe = dep1 + ' ' + 'of ' + dep2 + ' ' + config.sem[_this.filterSemester] + " Semester";
                                    }
                                    var sessionDetails = _this.sessionDetails(result.academicSessionId);
                                    result.sessionName = sessionDetails ? sessionDetails.name : '';
                                    result.title = resultOfThe;
                                    result.discipline = _this.discipline;
                                    result.totPaperCredit = totPaperCredit;
                                    result.totMajorMarksObtained = totMajorMarksObtained;
                                    result.totMajorMarks = totMajorMarks;
                                    result.totGenedMarksObtained = totGenedMarksObtained;
                                    result.totGenedMarks = totGenedMarks;
                                    totGrandMarksObtained = totGrandMarksObtained + totMarksObtained;
                                    totalEndTermMarksObtained = totalEndTermMarksObtained + totEndTermMarksObtained;
                                    totalMajorMarks = totalMajorMarks + majorMarks;
                                    totalMajorMarksObtain = totalMajorMarksObtain + majorMarksObtained;
                                    result.fullMajorMarks = totalMajorMarks;
                                    result.fullMajorMarksObtain = totalMajorMarksObtain;
                                    result.totalMarks = totGrandMarksObtained;
                                    result.totalMarksObtain = totalEndTermMarksObtained;
                                    if (_this.academiaSessions && _this.academiaSessions.length > 0) {
                                        var sessionData = _this.academiaSessions.filter(function (x) { return x.active == true; });
                                        result.examYear = sessionData[0].end;
                                        result.examSession = sessionData[0].name;
                                    }
                                    if (_this.evaluatorType && _this.evaluatorType == '6') {
                                        for (var n = 0; n < result.semestersDetail.length; n++) {
                                            if (result.semestersDetail[n].semester == _this.filterSemester) {
                                                var backlog = false;
                                                for (var x = 0; x < result.semestersDetail[n].courses.length; x++) {
                                                    if (result.semestersDetail[n].courses[x].lastAppeardInExaminationId == _this.examinationId && result.semestersDetail[n].courses[x].lastEvaluatorType == 6) {
                                                        backlog = true;
                                                    }
                                                }
                                                if (backlog == true) {
                                                    students.push({ studentDetails: result, semesterDetails: semesterDetails[0] });
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        students.push({ studentDetails: result, semesterDetails: semesterDetails[0] });
                                    }
                                }
                            }).catch(function (error) {
                                console.log('error', error);
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_2 = _a.sent();
                        console.log('e', e_2);
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8:
                        this.gradeCards = students;
                        if (this.evaluatorType && this.evaluatorType == '6') {
                            back = ' Backlog ';
                        }
                        else {
                            back = '';
                        }
                        if (students[0] && students[0].studentDetails && students[0].studentDetails.sessionName) {
                            sessionName = students[0].studentDetails.sessionName;
                        }
                        else {
                            sessionName = null;
                        }
                        csvTitle = this.title + ' ' + sessionName + back + ' GradeCard';
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "studentGradeCard" }, csvTitle).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.export = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filters, filename, program;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.filterSemester == 0 || this.filterSemester == null) {
                            this._tosterService.error("Semester can not be blank.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterProgram.length == 0 || this.filterProgram.length > 1) {
                            this._tosterService.error("Select one program.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
                            this._tosterService.error("Batch year can not be blank.");
                            return [2 /*return*/, false];
                        }
                        filters = {
                            "freeText": this.filterKeyword,
                            "academicSessionId": this.filterSession,
                            "programIds": this.filterProgram.map(function (x) { return x.id; }),
                            "semester": this.filterSemester,
                            "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
                            "registrationYear": 0,
                            "startIndex": 0,
                            "pageSize": 0
                        };
                        filename = "";
                        program = this.programs.filter(function (x) { return x.id == _this.filterProgram[0].id; });
                        if (program.length > 0) {
                            this.program = program[0];
                            filename = program[0].name + " " + this.filterSemester + " semester";
                        }
                        return [4 /*yield*/, this._academiaService.students(filters).toPromise().then(function (result) {
                                for (var i = 0; i < result.items.length; i++) {
                                    //result.items[i].address = result.items[i].address.replace(/,/g, "|");
                                    result.items[i].address = "";
                                }
                                _this.exportsStudents = result.items;
                                setTimeout(function () {
                                    _this._exportAsService.save({ type: "csv", elementId: "exportStudents" }, filename).subscribe(function () {
                                    });
                                }, 100);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.consolidatedReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, descipline, program, semester, i, i, e_3, sessionData, sessionName, title;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        if (this.filterProgram.length == 0) {
                            this._tosterService.error("Select one program.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
                            this._tosterService.error("Batch year can not be blank.");
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.studentsConsolidated()];
                    case 1:
                        _a.sent();
                        if (!this.consolidateStudents)
                            return [2 /*return*/];
                        program = this.programs.filter(function (x) { return x.id == _this.filterProgram[0].id; });
                        semester = [];
                        for (i = 0; i < program[0].numberOfSemesters; i++) {
                            semester.push(i);
                        }
                        this.programWiseSemester = semester;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.consolidateStudents.length)) return [3 /*break*/, 7];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then(function (result) {
                                var totCgpaMajorCredit = 0;
                                var totCgpaGenedCredit = 0;
                                var totCgpaCredit = 0;
                                var totCgpaMajorMarksObtained = 0;
                                var totCgpaGenedMarksObtained = 0;
                                var totCgpaMarksObtained = 0;
                                var totCgpaMajorMarks = 0;
                                var totMarks = 0;
                                var totMarksObtained = 0;
                                var totCredit = 0;
                                var failInSem = [];
                                var totGNDMarks = 0;
                                result.semestersDetail.sort(function (x, y) {
                                    return x.semester - y.semester;
                                });
                                for (var k = 0; k < result.semestersDetail.length; k++) {
                                    result.semestersDetail[k].courses.sort(function (x, y) {
                                        if (x.paper.paperType == y.paper.paperType) {
                                            return x.paper.code.localeCompare(y.paper.code);
                                        }
                                        else {
                                            return (x.paper.paperType - y.paper.paperType);
                                        }
                                    });
                                    var totSgpaMajorCredit = 0;
                                    var totSgpaGenedCredit = 0;
                                    var totSgpaCredit = 0;
                                    var totSgpaMajorMarksObtained = 0;
                                    var totSgpaGenedMarksObtained = 0;
                                    var totSgpaMarksObtained = 0;
                                    var totSgpaMajorMarks = 0;
                                    var totalMarks = 0;
                                    var totalMarksObtained = 0;
                                    var totalCredit = 0;
                                    var totalGND = 0;
                                    for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                                        //Replaceve html tags
                                        result.semestersDetail[k].courses[s].paper.name = _this.htmlToText(result.semestersDetail[k].courses[s].paper.name);
                                        totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                                        totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == _this.CBCS_MAJOR || result.semestersDetail[k].courses[s].paper.paperType == 16 || result.semestersDetail[k].courses[s].paper.paperType == _this.DSE_MAJOR || result.semestersDetail[k].courses[s].paper.paperType == _this.SEC_MAJOR) {
                                            totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            totSgpaMajorMarks = totSgpaMajorMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                                        }
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                                            totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            totalGND = totalGND + result.semestersDetail[k].courses[s].fullMarks;
                                        }
                                        totalMarks = totalMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                                        totalMarksObtained = totalMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        totalCredit = totalCredit + result.semestersDetail[k].courses[s].paper.credit;
                                        if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                                            failInSem.push(result.semestersDetail[k].semester);
                                        }
                                    }
                                    result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
                                    result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
                                    result.semestersDetail[k].totSgpaCredit = totSgpaCredit;
                                    result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
                                    result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;
                                    result.semestersDetail[k].totSgpaMajorMarks = totSgpaMajorMarks;
                                    result.semestersDetail[k].totalMarks = totalMarks;
                                    result.semestersDetail[k].totalMarksObtained = totalMarksObtained;
                                    result.semestersDetail[k].totalCredit = totalCredit;
                                    result.semestersDetail[k].totalGND = totalGND;
                                    totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
                                    totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;
                                    totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
                                    totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;
                                    totGNDMarks = totGNDMarks + totalGND;
                                    totCgpaMajorMarks = totCgpaMajorMarks + totSgpaMajorMarks;
                                    totMarks = totMarks + totalMarks;
                                    totMarksObtained = totMarksObtained + totalMarksObtained;
                                    totCredit = totCredit + totalCredit;
                                }
                                if (failInSem.length > 0) {
                                    var nonSseSem; // = semester.filter(value => failInSem.includes(value));
                                    if (semester.length == 6) {
                                        nonSseSem = [1, 2, 3, 4].filter(function (value) { return failInSem.includes(value); });
                                    }
                                    else {
                                        nonSseSem = [1, 2].filter(function (value) { return failInSem.includes(value); });
                                    }
                                    if (nonSseSem.length > 0) {
                                        result.remarks = "REPEAT(" + failInSem.join("-") + ")";
                                    }
                                    else {
                                        result.remarks = "SSE(" + failInSem.join("-") + ")";
                                    }
                                }
                                else {
                                    result.remarks = "PASS";
                                }
                                var discipline = _this.disciplines.filter(function (x) { return x.id == result.semestersDetail[0].courses[0].paper.disciplineId; });
                                if (discipline.length > 0) {
                                    _this.discipline = discipline[0].name;
                                }
                                result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
                                result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;
                                result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
                                result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
                                result.discipline = _this.discipline;
                                result.cgpa.totCgpaMajorMarks = totCgpaMajorMarks;
                                result.cgpa.totMarks = totMarks;
                                result.cgpa.totMarksObtained = totMarksObtained;
                                result.cgpa.totCredit = totCredit;
                                result.cgpa.totGNDMarks = totGNDMarks;
                                students.push(result);
                            }).catch(function (error) {
                            })];
                    case 4:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_3 = _a.sent();
                        return [3 /*break*/, 6];
                    case 6:
                        i++;
                        return [3 /*break*/, 2];
                    case 7:
                        if (this.academiaSessions.length > 0) {
                            sessionData = this.academiaSessions.filter(function (x) { return x.id == students[0].academicSessionId; });
                            sessionName = sessionData[0].name.split('-')[0];
                        }
                        title = this.programTitle + ' ' + sessionName + ' Consolidated Report';
                        this.consolidateStudentsResult = students;
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "studentConsolidateReport" }, title).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.htmlToText = function (content) {
        var htmlTags = content.toString();
        var html = htmlTags.replace(/(<([^>]+)>)/ig, '');
        var text = html.replace(/(\r\n|\n|\r)/gm, "");
        return text.replace(/,/g, " ");
    };
    StudentsComponent.prototype.convocationReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, descipline, program, semester, i, i, e_4, sessionData, sessionName, csvTitle;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        if (this.convocationSession == "" || this.convocationSession == null) {
                            this._tosterService.error("Convocation session can not be blank.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterProgram.length == 0) {
                            this._tosterService.error("Select one program.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterSemester == 0 || this.filterSemester == null) {
                            this._tosterService.error("Semester can not be blank.");
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.studentsConsolidated()];
                    case 1:
                        _a.sent();
                        //  console.log(this.consolidateStudents);
                        if (!this.consolidateStudents)
                            return [2 /*return*/];
                        program = this.programs.filter(function (x) { return x.id == _this.filterProgram[0].id; });
                        semester = [];
                        for (i = 0; i < program[0].numberOfSemesters; i++) {
                            semester.push(i);
                        }
                        this.programWiseSemester = semester;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.consolidateStudents.length)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        if (!(this.consolidateStudents[i].unqualified === false && this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then(function (result) {
                                var totCgpaMajorCredit = 0;
                                var totCgpaGenedCredit = 0;
                                var totCgpaCredit = 0;
                                var totCgpaMajorMarksObtained = 0;
                                var totCgpaGenedMarksObtained = 0;
                                var totCgpaMarksObtained = 0;
                                var failInSem = [];
                                var totalGenedMarksObtained = 0;
                                var totalGenedMarks = 0;
                                var totalGndMarksObtained = 0;
                                var totalGndMarks = 0;
                                result.semestersDetail.sort(function (x, y) {
                                    return x.semester - y.semester;
                                });
                                var totGrandMarksObtained = 0;
                                var totalEndTermMarksObtained = 0;
                                var totalMajorMarksObtain = 0;
                                var totalMajorMarks = 0;
                                for (var k = 0; k < result.semestersDetail.length; k++) {
                                    result.semestersDetail[k].courses.sort(function (x, y) {
                                        if (x.paper.paperType == y.paper.paperType) {
                                            return x.paper.code.localeCompare(y.paper.code);
                                        }
                                        else {
                                            return (x.paper.paperType - y.paper.paperType);
                                        }
                                    });
                                    var totSgpaMajorCredit = 0;
                                    var totSgpaGenedCredit = 0;
                                    var totSgpaCredit = 0;
                                    var totSgpaMajorMarksObtained = 0;
                                    var totSgpaGenedMarksObtained = 0;
                                    var totSgpaMarksObtained = 0;
                                    var totMarksObtained = 0;
                                    var totEndTermMarksObtained = 0;
                                    var majorMarks = 0;
                                    var majorMarksObtained = 0;
                                    var genEdMarks = 0;
                                    var genEdMarksObtained = 0;
                                    for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                                        //Replaceve html tags
                                        result.semestersDetail[k].courses[s].paper.name = _this.htmlToText(result.semestersDetail[k].courses[s].paper.name);
                                        totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                                        totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == _this.CBCS_MAJOR) {
                                            totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        }
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                                            totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            genEdMarksObtained = genEdMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            genEdMarks = genEdMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                                        }
                                        if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                                            failInSem.push(result.semestersDetail[k].semester);
                                        }
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == 16) {
                                            majorMarks = majorMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                                            majorMarksObtained = majorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        }
                                        totMarksObtained = totMarksObtained + result.semestersDetail[k].courses[s].paper.fullMarks;
                                        totEndTermMarksObtained = totEndTermMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                    }
                                    // console.log('totMarksObtained',totMarksObtained);
                                    result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
                                    result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
                                    result.semestersDetail[k].totSgpaCredit = totSgpaCredit;
                                    result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
                                    result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;
                                    result.semestersDetail[k].totalGenedMarksObtained = totalGenedMarksObtained;
                                    result.semestersDetail[k].totGenedMarks = totalGenedMarks;
                                    totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
                                    totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;
                                    totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
                                    totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;
                                    totGrandMarksObtained = totGrandMarksObtained + totMarksObtained;
                                    totalEndTermMarksObtained = totalEndTermMarksObtained + totEndTermMarksObtained;
                                    totalMajorMarks = totalMajorMarks + majorMarks;
                                    totalMajorMarksObtain = totalMajorMarksObtain + majorMarksObtained;
                                    totalGndMarksObtained = totalGndMarksObtained + genEdMarksObtained;
                                    totalGndMarks = totalGndMarks + genEdMarks;
                                }
                                var discipline = _this.disciplines.filter(function (x) { return x.id == result.semestersDetail[0].courses[0].paper.disciplineId; });
                                if (discipline.length > 0) {
                                    _this.discipline = discipline[0].name;
                                }
                                result.fullMajorMarks = totalMajorMarks;
                                result.fullMajorMarksObtain = totalMajorMarksObtain;
                                result.totalMarks = totGrandMarksObtained;
                                result.totalMarksObtain = totalEndTermMarksObtained;
                                result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
                                result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;
                                result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
                                result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
                                result.discipline = _this.discipline;
                                result.totalGndMarksObtained = totalGndMarksObtained;
                                result.totalGndMarks = totalGndMarks;
                                if (_this.programs.length > 0) {
                                    var ppp = _this.programs.filter(function (x) { return x.id == result.academicProgramId; });
                                    result.stream = ppp[0].name.split('in')[0];
                                }
                                if (_this.academiaSessions.length > 0) {
                                    var sessionData = _this.academiaSessions.filter(function (x) { return x.id == _this.convocationSession; });
                                    result.session = sessionData[0].name;
                                    result.examYear = sessionData[0].end;
                                }
                                if (result.unqualified === false) {
                                    students.push(result);
                                }
                            }).catch(function (error) {
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_4 = _a.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8:
                        this.convocationStudentsResult = students;
                        if (this.academiaSessions && this.academiaSessions.length > 0) {
                            sessionData = this.academiaSessions.filter(function (x) { return x.id == _this.convocationSession; });
                            sessionName = sessionData[0].name;
                        }
                        csvTitle = this.programTitle + ' ' + sessionName + ' Convocation Report';
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "dataConvocation" }, csvTitle).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.agef = function (a) {
        var p = a.split('in')[0];
        return p;
    };
    StudentsComponent.prototype.depf = function (e) {
        var d = e.split('in')[1].trim();
        if (d == 'H' || d == ' H') {
            return 'Hindi';
        }
        else if (d == 'Perform') {
            return 'Performing Arts';
        }
        else {
            return d;
        }
    };
    StudentsComponent.prototype.backlogStudentReport = function (a, b, c) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, filters, descipline, program, discipline, semester, i, i, e_5, sem, sem, csvTitle;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        if (this.filterProgram.length == 0 || this.filterProgram.length > 1) {
                            this._tosterService.error("Select one program.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
                            this._tosterService.error("Batch year can not be blank.");
                            return [2 /*return*/, false];
                        }
                        filters = {
                            "freeText": this.filterKeyword,
                            "academicSessionId": this.filterSession,
                            "programIds": this.filterProgram.map(function (x) { return x.id; }),
                            "semester": this.filterSemester,
                            "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
                            "registrationYear": 0,
                            "startIndex": 0,
                            "pageSize": 0
                        };
                        return [4 /*yield*/, this._academiaService.students(filters).toPromise().then(function (result) {
                                _this.consolidateStudents = result.items;
                            })];
                    case 1:
                        _a.sent();
                        if (!this.consolidateStudents)
                            return [2 /*return*/];
                        program = this.programs.filter(function (x) { return x.id == _this.filterProgram[0].id; });
                        if (program.length > 0) {
                            discipline = this.disciplines.filter(function (x) { return x.id == program[0].disciplineId; });
                            if (discipline.length > 0) {
                                descipline = discipline[0].name;
                            }
                        }
                        semester = [];
                        for (i = 0; i < program[0].numberOfSemesters; i++) {
                            semester.push(i);
                        }
                        this.programWiseSemester = semester;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.consolidateStudents.length)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        if (!(this.consolidateStudents[i].unqualified === true && this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var totCgpaMajorCredit, totCgpaGenedCredit, totCgpaCredit, totCgpaMajorMarksObtained, totCgpaGenedMarksObtained, totCgpaMarksObtained, failInSem, backlogSem, k, totSgpaMajorCredit, totSgpaGenedCredit, totSgpaCredit, totSgpaMajorMarksObtained, totSgpaGenedMarksObtained, totSgpaMarksObtained, backlogCourses, s, nonSseSem;
                                return tslib_1.__generator(this, function (_a) {
                                    totCgpaMajorCredit = 0;
                                    totCgpaGenedCredit = 0;
                                    totCgpaCredit = 0;
                                    totCgpaMajorMarksObtained = 0;
                                    totCgpaGenedMarksObtained = 0;
                                    totCgpaMarksObtained = 0;
                                    failInSem = [];
                                    backlogSem = [];
                                    result.semestersDetail.sort(function (x, y) {
                                        return x.semester - y.semester;
                                    });
                                    for (k = 0; k < result.semestersDetail.length; k++) {
                                        // if (result.semestersDetail[k].semester == 1 || result.semestersDetail[k].semester == 3 || result.semestersDetail[k].semester == 5) {
                                        result.semestersDetail[k].courses.sort(function (x, y) {
                                            if (x.paper.paperType == y.paper.paperType) {
                                                return x.paper.code.localeCompare(y.paper.code);
                                            }
                                            else {
                                                return (x.paper.paperType - y.paper.paperType);
                                            }
                                        });
                                        totSgpaMajorCredit = 0;
                                        totSgpaGenedCredit = 0;
                                        totSgpaCredit = 0;
                                        totSgpaMajorMarksObtained = 0;
                                        totSgpaGenedMarksObtained = 0;
                                        totSgpaMarksObtained = 0;
                                        backlogCourses = [];
                                        for (s = 0; s < result.semestersDetail[k].courses.length; s++) {
                                            //Replaceve html tags
                                            result.semestersDetail[k].courses[s].paper.name = this.htmlToText(result.semestersDetail[k].courses[s].paper.name);
                                            totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == this.CBCS_MAJOR) {
                                                totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                                                totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            }
                                            if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                                                totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                                                totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            }
                                            if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                                                failInSem.push(result.semestersDetail[k].semester);
                                            }
                                            if (result.semestersDetail[k].courses[s].grade == 'F' || result.semestersDetail[k].courses[s].grade == 'AB' || result.semestersDetail[k].courses[s].grade == 'E') {
                                                backlogCourses.push(result.semestersDetail[k].courses[s]);
                                            }
                                            result.semestersDetail[k].backCourses = backlogCourses;
                                        }
                                        result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
                                        result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
                                        result.semestersDetail[k].totSgpaCredit = totSgpaCredit;
                                        result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
                                        result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;
                                        totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
                                        totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;
                                        totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
                                        totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;
                                        if (result.semestersDetail[k].resultOverallRemarks != 'P') {
                                            if (result.semestersDetail[k].semester == a || result.semestersDetail[k].semester == b || result.semestersDetail[k].semester == c) {
                                                backlogSem.push(result.semestersDetail[k]);
                                            }
                                        }
                                        result.backlogSemesterDetails = backlogSem;
                                        // if (result.semestersDetail[k].resultOverallRemarks == "P") {
                                        //   result.semestersDetail.splice(k, 1);
                                        // }
                                    }
                                    if (failInSem.length > 0) {
                                        if (semester.length == 6) {
                                            nonSseSem = [1, 2, 3, 4].filter(function (value) { return failInSem.includes(value); });
                                        }
                                        else {
                                            nonSseSem = [1, 2].filter(function (value) { return failInSem.includes(value); });
                                        }
                                        if (nonSseSem.length > 0) {
                                            result.remarks = "REPEAT(" + failInSem.join("-") + ")";
                                        }
                                        else {
                                            result.remarks = "SSE(" + failInSem.join("-") + ")";
                                        }
                                    }
                                    else {
                                        result.remarks = "PASS";
                                    }
                                    result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
                                    result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;
                                    result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
                                    result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
                                    result.discipline = descipline;
                                    if (result.backlogSemesterDetails.length > 0) {
                                        students.push(result);
                                    }
                                    return [2 /*return*/];
                                });
                            }); }).catch(function (error) {
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_5 = _a.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8:
                        this.backlogStudentsResult = students;
                        if (a == 1) {
                            sem = 'Odd Sem';
                        }
                        else {
                            sem = 'Even Sem';
                        }
                        csvTitle = program[0].name + ' ' + this.filterBatchStartYear + ' ' + sem + ' Backlog Reports';
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "studentBacklogReport" }, csvTitle).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.transcriptReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, filters, descipline, program, discipline, semester, i, i, e_6, title;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        if (this.filterKeyword == "" || this.filterKeyword == null) {
                            this._tosterService.error("Registration No. can not be blank.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterBatchStartYear == "" || this.filterBatchStartYear == null) {
                            this._tosterService.error("Batch year can not be blank.");
                            return [2 /*return*/, false];
                        }
                        filters = {
                            "freeText": this.filterKeyword,
                            "academicSessionId": this.filterSession,
                            "programIds": this.filterProgram.map(function (x) { return x.id; }),
                            "semester": this.filterSemester,
                            "batchStartYear": this.filterBatchStartYear ? this.filterBatchStartYear : 0,
                            "registrationYear": 0,
                            "startIndex": 0,
                            "pageSize": 0
                        };
                        return [4 /*yield*/, this._academiaService.students(filters).toPromise().then(function (result) {
                                _this.consolidateStudents = result.items;
                            })];
                    case 1:
                        _a.sent();
                        if (!this.consolidateStudents)
                            return [2 /*return*/];
                        program = this.programs.filter(function (x) { return x.id == _this.consolidateStudents[0].academicProgramId; });
                        if (program.length > 0) {
                            discipline = this.disciplines.filter(function (x) { return x.id == program[0].disciplineId; });
                            if (discipline.length > 0) {
                                descipline = discipline[0].name;
                            }
                        }
                        semester = [];
                        for (i = 0; i < program[0].numberOfSemesters; i++) {
                            semester.push(i);
                        }
                        this.programWiseSemester = semester;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.consolidateStudents.length)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        if (!(this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then(function (result) {
                                var totCgpaMajorCredit = 0;
                                var totCgpaGenedCredit = 0;
                                var totCgpaCredit = 0;
                                var totCgpaMajorMarksObtained = 0;
                                var totCgpaGenedMarksObtained = 0;
                                var totCgpaMarksObtained = 0;
                                var failInSem = [];
                                result.semestersDetail.sort(function (x, y) {
                                    return x.semester - y.semester;
                                });
                                for (var k = 0; k < result.semestersDetail.length; k++) {
                                    result.semestersDetail[k].courses.sort(function (x, y) {
                                        if (x.paper.paperType == y.paper.paperType) {
                                            return x.paper.code.localeCompare(y.paper.code);
                                        }
                                        else {
                                            return (x.paper.paperType - y.paper.paperType);
                                        }
                                    });
                                    var totSgpaMajorCredit = 0;
                                    var totSgpaGenedCredit = 0;
                                    var totSgpaCredit = 0;
                                    var totSgpaMajorMarksObtained = 0;
                                    var totSgpaGenedMarksObtained = 0;
                                    var totSgpaMarksObtained = 0;
                                    for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                                        //Replaceve html tags
                                        result.semestersDetail[k].courses[s].paper.name = _this.htmlToText(result.semestersDetail[k].courses[s].paper.name);
                                        totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                                        totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == _this.CBCS_MAJOR) {
                                            totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        }
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                                            totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        }
                                        if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                                            failInSem.push(result.semestersDetail[k].semester);
                                        }
                                    }
                                    result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
                                    result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
                                    result.semestersDetail[k].totSgpaCredit = totSgpaCredit;
                                    result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
                                    result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;
                                    totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
                                    totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;
                                    totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
                                    totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;
                                }
                                result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
                                result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;
                                result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
                                result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
                                result.discipline = descipline;
                                result.courseStudy = program[0].name.split('in')[0];
                                students.push(result);
                            }).catch(function (error) {
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_6 = _a.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8:
                        title = this.filterKeyword + '_' + program[0].name.split('in')[0] + '_' + descipline + ' transcript';
                        this.transcriptResult = students;
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "transcriptReport" }, title).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.nadReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, semester, i, e_7, sessionData, sessionName, title;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        if (this.filterProgram.length == 0) {
                            this._tosterService.error("Select one program.");
                            return [2 /*return*/, false];
                        }
                        if (this.convocationSession == "" || this.convocationSession == null) {
                            this._tosterService.error("Convocation Session can not be blank.");
                            return [2 /*return*/, false];
                        }
                        if (this.filterSemester == 0 || this.filterSemester == null) {
                            this._tosterService.error("Semester can not be blank.");
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.studentsConsolidated()];
                    case 1:
                        _a.sent();
                        if (!this.consolidateStudents)
                            return [2 /*return*/];
                        semester = [];
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.consolidateStudents.length)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        if (!(this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then(function (result) {
                                var totCgpaMajorCredit = 0;
                                var totCgpaGenedCredit = 0;
                                var totCgpaCredit = 0;
                                var totCgpaMajorMarksObtained = 0;
                                var totCgpaGenedMarksObtained = 0;
                                var totCgpaMarksObtained = 0;
                                var failInSem = [];
                                result.semestersDetail.sort(function (x, y) {
                                    return x.semester - y.semester;
                                });
                                var program = _this.programs.filter(function (x) { return x.id == _this.consolidateStudents[i].academicProgramId; });
                                if (program.length > 0) {
                                    _this.program = program[0];
                                    var p1 = program[0].name.split('.')[0];
                                    var p2 = program[0].name.split('.')[1];
                                    if (p2 == ' A') {
                                        var d2 = 'Arts';
                                    }
                                    else if (p2 == 'Sc' || p2 == ' Sc') {
                                        var d2 = 'Science';
                                    }
                                    else {
                                        var d2 = '';
                                    }
                                    if (p1 == 'B') {
                                        var d1 = 'Bachelor';
                                    }
                                    else if (p1 == 'M') {
                                        var d1 = 'Master';
                                    }
                                    else {
                                        var d1 = 'Phd';
                                    }
                                    var proTitle = d1 + ' ' + 'of ' + d2;
                                    _this.title = program[0].name + " " + _this.filterSemester + " semester";
                                }
                                for (var k = 0; k < result.semestersDetail.length; k++) {
                                    result.semestersDetail[k].courses.sort(function (x, y) {
                                        if (x.paper.paperType == y.paper.paperType) {
                                            return x.paper.code.localeCompare(y.paper.code);
                                        }
                                        else {
                                            return (x.paper.paperType - y.paper.paperType);
                                        }
                                    });
                                    var totSgpaMajorCredit = 0;
                                    var totSgpaGenedCredit = 0;
                                    var totSgpaCredit = 0;
                                    var totSgpaMajorMarksObtained = 0;
                                    var totSgpaGenedMarksObtained = 0;
                                    var totSgpaMarksObtained = 0;
                                    for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                                        //Replaceve html tags
                                        result.semestersDetail[k].courses[s].paper.name = _this.htmlToText(result.semestersDetail[k].courses[s].paper.name);
                                        totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                                        totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == _this.CBCS_MAJOR) {
                                            totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        }
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                                            totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        }
                                        if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                                            failInSem.push(result.semestersDetail[k].semester);
                                        }
                                    }
                                    result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
                                    result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
                                    result.semestersDetail[k].totSgpaCredit = totSgpaCredit;
                                    result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
                                    result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;
                                    totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
                                    totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;
                                    totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
                                    totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;
                                }
                                if (failInSem.length > 0) {
                                    var nonSseSem; // = semester.filter(value => failInSem.includes(value));
                                    if (semester.length == 6) {
                                        nonSseSem = [1, 2, 3, 4].filter(function (value) { return failInSem.includes(value); });
                                    }
                                    else {
                                        nonSseSem = [1, 2].filter(function (value) { return failInSem.includes(value); });
                                    }
                                    if (nonSseSem.length > 0) {
                                        result.remarks = "REPEAT(" + failInSem.join("-") + ")";
                                    }
                                    else {
                                        result.remarks = "SSE(" + failInSem.join("-") + ")";
                                    }
                                }
                                else {
                                    result.remarks = "PASS";
                                }
                                var discipline = _this.disciplines.filter(function (x) { return x.id == result.semestersDetail[0].courses[0].paper.disciplineId; });
                                if (discipline.length > 0) {
                                    _this.discipline = discipline[0].name;
                                }
                                result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
                                result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;
                                result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
                                result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
                                result.discipline = _this.discipline;
                                result.courseName = program[0].name;
                                result.programTitle = proTitle;
                                if (_this.academiaSessions.length > 0) {
                                    var sessionData = _this.academiaSessions.filter(function (x) { return x.id == result.academicSessionId; });
                                    result.session = sessionData[0].end;
                                }
                                if (_this.academiaSessions && _this.academiaSessions.length > 0) {
                                    var sessionData = _this.academiaSessions.filter(function (x) { return x.active == true; });
                                    result.examYear = sessionData[0].name.split('-')[0];
                                }
                                students.push(result);
                            }).catch(function (error) {
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_7 = _a.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8:
                        if (this.academiaSessions && this.academiaSessions.length > 0) {
                            sessionData = this.academiaSessions.filter(function (x) { return x.id == _this.convocationSession; });
                            sessionName = sessionData[0].name;
                        }
                        title = this.programTitle + ' ' + sessionName + ' NAD Report';
                        this.nadStudentsResult = students;
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "studentNADReport" }, title).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.statisticsReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var items, exam, title;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.examinationId == "" || this.examinationId == null) {
                            this._tosterService.error("Examination can not be blank.");
                            return [2 /*return*/, false];
                        }
                        items = this.examinationSession.filter(function (x) { return x.id == _this.examinationId; });
                        exam = items[0].name;
                        return [4 /*yield*/, this._studentService.getStatisticsReport(this.examinationId).toPromise().then(function (result) {
                                _this.statisticsData = result;
                            })];
                    case 1:
                        _a.sent();
                        this.programWiseSemester = ['I', 'II', 'III', 'IV', 'V', 'VI'];
                        title = exam + ' StatisticsReport';
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "statisticsReport" }, title).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.blockReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, descipline, program, semester, i, i, e_8, title;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        if (this.filterProgram.length == 0) {
                            this._tosterService.error("Select atlist one program.");
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.studentsConsolidated()];
                    case 1:
                        _a.sent();
                        if (!this.consolidateStudents)
                            return [2 /*return*/];
                        program = this.programs.filter(function (x) { return x.id == _this.filterProgram[0].id; });
                        semester = [];
                        for (i = 0; i < program[0].numberOfSemesters; i++) {
                            semester.push(i);
                        }
                        this.programWiseSemester = semester;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.consolidateStudents.length)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        if (!(this.consolidateStudents[i].blocked === true)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then(function (result) {
                                var totCgpaMajorCredit = 0;
                                var totCgpaGenedCredit = 0;
                                var totCgpaCredit = 0;
                                var totCgpaMajorMarksObtained = 0;
                                var totCgpaGenedMarksObtained = 0;
                                var totCgpaMarksObtained = 0;
                                var totCgpaMajorMarks = 0;
                                var totMarks = 0;
                                var totMarksObtained = 0;
                                var totCredit = 0;
                                var failInSem = [];
                                var totGNDMarks = 0;
                                result.semestersDetail.sort(function (x, y) {
                                    return x.semester - y.semester;
                                });
                                for (var k = 0; k < result.semestersDetail.length; k++) {
                                    result.semestersDetail[k].courses.sort(function (x, y) {
                                        if (x.paper.paperType == y.paper.paperType) {
                                            return x.paper.code.localeCompare(y.paper.code);
                                        }
                                        else {
                                            return (x.paper.paperType - y.paper.paperType);
                                        }
                                    });
                                    var totSgpaMajorCredit = 0;
                                    var totSgpaGenedCredit = 0;
                                    var totSgpaCredit = 0;
                                    var totSgpaMajorMarksObtained = 0;
                                    var totSgpaGenedMarksObtained = 0;
                                    var totSgpaMarksObtained = 0;
                                    var totSgpaMajorMarks = 0;
                                    var totalMarks = 0;
                                    var totalMarksObtained = 0;
                                    var totalCredit = 0;
                                    var totalGND = 0;
                                    for (var s = 0; s < result.semestersDetail[k].courses.length; s++) {
                                        //Replaceve html tags
                                        result.semestersDetail[k].courses[s].paper.name = _this.htmlToText(result.semestersDetail[k].courses[s].paper.name);
                                        totSgpaCredit = totSgpaCredit + result.semestersDetail[k].courses[s].paper.credit;
                                        totSgpaMarksObtained = totSgpaMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 2 || result.semestersDetail[k].courses[s].paper.paperType == _this.CBCS_MAJOR || result.semestersDetail[k].courses[s].paper.paperType == 16) {
                                            totSgpaMajorCredit = totSgpaMajorCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaMajorMarksObtained = totSgpaMajorMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            totSgpaMajorMarks = totSgpaMajorMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                                        }
                                        if (result.semestersDetail[k].courses[s].paper.paperType == 4) {
                                            totSgpaGenedCredit = totSgpaGenedCredit + result.semestersDetail[k].courses[s].paper.credit;
                                            totSgpaGenedMarksObtained = totSgpaGenedMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                            totalGND = totalGND + result.semestersDetail[k].courses[s].fullMarks;
                                        }
                                        totalMarks = totalMarks + result.semestersDetail[k].courses[s].paper.fullMarks;
                                        totalMarksObtained = totalMarksObtained + result.semestersDetail[k].courses[s].totalMarksObtained;
                                        totalCredit = totalCredit + result.semestersDetail[k].courses[s].paper.credit;
                                        if (result.semestersDetail[k].resultOverallRemarks != 'P' && !failInSem.includes(result.semestersDetail[k].semester)) {
                                            failInSem.push(result.semestersDetail[k].semester);
                                        }
                                    }
                                    result.semestersDetail[k].totSgpaMajorCredit = totSgpaMajorCredit;
                                    result.semestersDetail[k].totSgpaGenedCredit = totSgpaGenedCredit;
                                    result.semestersDetail[k].totSgpaCredit = totSgpaCredit;
                                    result.semestersDetail[k].totSgpaMajorMarksObtained = totSgpaMajorMarksObtained;
                                    result.semestersDetail[k].totSgpaGenedMarksObtained = totSgpaGenedMarksObtained;
                                    result.semestersDetail[k].totSgpaMajorMarks = totSgpaMajorMarks;
                                    result.semestersDetail[k].totalMarks = totalMarks;
                                    result.semestersDetail[k].totalMarksObtained = totalMarksObtained;
                                    result.semestersDetail[k].totalCredit = totalCredit;
                                    result.semestersDetail[k].totalGND = totalGND;
                                    totCgpaMajorCredit = totCgpaMajorCredit + totSgpaMajorCredit;
                                    totCgpaGenedCredit = totCgpaGenedCredit + totSgpaGenedCredit;
                                    totCgpaMajorMarksObtained = totCgpaMajorMarksObtained + totSgpaMajorMarksObtained;
                                    totCgpaGenedMarksObtained = totCgpaGenedMarksObtained + totSgpaGenedMarksObtained;
                                    totGNDMarks = totGNDMarks + totalGND;
                                    totCgpaMajorMarks = totCgpaMajorMarks + totSgpaMajorMarks;
                                    totMarks = totMarks + totalMarks;
                                    totMarksObtained = totMarksObtained + totalMarksObtained;
                                    totCredit = totCredit + totalCredit;
                                }
                                if (failInSem.length > 0) {
                                    var nonSseSem; // = semester.filter(value => failInSem.includes(value));
                                    if (semester.length == 6) {
                                        nonSseSem = [1, 2, 3, 4].filter(function (value) { return failInSem.includes(value); });
                                    }
                                    else {
                                        nonSseSem = [1, 2].filter(function (value) { return failInSem.includes(value); });
                                    }
                                    if (nonSseSem.length > 0) {
                                        result.remarks = "REPEAT(" + failInSem.join("-") + ")";
                                    }
                                    else {
                                        result.remarks = "SSE(" + failInSem.join("-") + ")";
                                    }
                                }
                                else {
                                    result.remarks = "PASS";
                                }
                                var discipline = _this.disciplines.filter(function (x) { return x.id == result.semestersDetail[0].courses[0].paper.disciplineId; });
                                if (discipline.length > 0) {
                                    _this.discipline = discipline[0].name;
                                }
                                result.cgpa.totCgpaMajorCredit = totCgpaMajorCredit;
                                result.cgpa.totCgpaGenedCredit = totCgpaGenedCredit;
                                result.cgpa.totCgpaMajorMarksObtained = totCgpaMajorMarksObtained;
                                result.cgpa.totCgpaGenedMarksObtained = totCgpaGenedMarksObtained;
                                result.discipline = _this.discipline;
                                result.cgpa.totCgpaMajorMarks = totCgpaMajorMarks;
                                result.cgpa.totMarks = totMarks;
                                result.cgpa.totMarksObtained = totMarksObtained;
                                result.cgpa.totCredit = totCredit;
                                result.cgpa.totGNDMarks = totGNDMarks;
                                students.push(result);
                            }).catch(function (error) {
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_8 = _a.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8:
                        title = this.programTitle + ' Blocked Report';
                        this.consolidateStudentsResult = students;
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "studentConsolidateReport" }, title).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentsComponent.prototype.getStudent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var students, descipline, semester, i, e_9, csvTitle;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        students = [];
                        return [4 /*yield*/, this.studentsConsolidated()];
                    case 1:
                        _a.sent();
                        //  console.log(this.consolidateStudents);
                        if (!this.consolidateStudents)
                            return [2 /*return*/];
                        semester = [];
                        this.programWiseSemester = semester;
                        i = 0;
                        _a.label = 2;
                    case 2:
                        if (!(i < this.consolidateStudents.length)) return [3 /*break*/, 8];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 6, , 7]);
                        if (!(this.consolidateStudents[i].unqualified === false && this.consolidateStudents[i].blocked === false && this.consolidateStudents[i].migrated === false)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this._studentService.getEvaluation(this.consolidateStudents[i].id).toPromise().then(function (result) {
                                var totCgpaMajorCredit = 0;
                                var totCgpaGenedCredit = 0;
                                var totCgpaCredit = 0;
                                var totCgpaMajorMarksObtained = 0;
                                var totCgpaGenedMarksObtained = 0;
                                var totCgpaMarksObtained = 0;
                                var failInSem = [];
                                var totalGenedMarksObtained = 0;
                                var totalGenedMarks = 0;
                                var totalGndMarksObtained = 0;
                                var totalGndMarks = 0;
                                result.semestersDetail.sort(function (x, y) {
                                    return x.semester - y.semester;
                                });
                                if (_this.programs.length > 0) {
                                    var ppp = _this.programs.filter(function (x) { return x.id == result.academicProgramId; });
                                    result.stream = ppp[0].name.split('in')[0];
                                }
                                if (_this.academiaSessions.length > 0) {
                                    var sessionData = _this.academiaSessions.filter(function (x) { return x.id == result.academicSessionId; });
                                    result.session = sessionData[0].name;
                                    result.examYear = sessionData[0].end;
                                }
                                if (result.unqualified === false) {
                                    students.push(result);
                                }
                            }).catch(function (error) {
                            })];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_9 = _a.sent();
                        return [3 /*break*/, 7];
                    case 7:
                        i++;
                        return [3 /*break*/, 2];
                    case 8:
                        this.convocationStudentsResult = students;
                        csvTitle = 'Students';
                        setTimeout(function () {
                            _this._exportAsService.save({ type: "csv", elementId: "studentData" }, csvTitle).subscribe(function () {
                            });
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    return StudentsComponent;
}());
export { StudentsComponent };
