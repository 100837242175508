<div class="sessions_section d-flex">
    <div class="sessions_lists sidebar_left w-25 p-4">
        <div class="h5 m-0 d-flex justify-content-between border rounded align-items-center pl-2 overflow-hidden">
            <span>{{ 'Sessions' | translate }}</span>
            <a (click)="redirectTo({id:0})" class="p-1 text-22px text-color"><em class="fa fa-plus-square-o"></em></a>
        </div>
        <div class="sessions_list">
            <ul>
                <li *ngFor="let session of examSessions;" (click)="onItemClieck(session)"
                    [ngClass]="session==selectedSession?'blue lighten-4':''"
                    class="border-bottom p-2 d-flex justify-content-between align-items-center">
                    <span>{{session.name}}</span> <span class="">
                        <em (click)="redirectTo(session)" class="fa fa-pencil mr-2"></em>
                        <em [ngClass]="session.active?'active_row':''" [title]="session.active?'Active':''"
                            class="fa fa-circle row_icon" aria-hidden="true"></em></span>
                </li>
            </ul>
        </div>
    </div>
    <div class="exams w-75 p-4">
        <app-exams [session]="selectedSession"></app-exams>
    </div>
</div>