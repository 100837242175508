import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PaperModel } from '../models/paper.model';
import { StudentModel } from '../models/student.model';

@Injectable({
    providedIn: 'root'
})
export class studentFormBuilder{

    private form:any;
    private validationRules = {        
        id:['',''],
        academicProgramId: ['',Validators.required],
        name: ['',Validators.required],
        academicSessionId: ['',Validators.required],
        address: ['',Validators.required],
        batchStartYear: ['',Validators.required],
        bloodGroup: ['',''],
        caste: ['',Validators.required],
        classRollNumber: ['',Validators.required],
        currentSemester: ['',Validators.required],
        discontinued: ['',''],
        email: ['',''],
        emailSignature: ['',''],
        registrationNumber:['',Validators.required],
        examinationRollNumber: ['',''],
        gender: ['',Validators.required],
        mobile: ['',''],
        parentName: ['',''],
        percentile: ['',''],
        registrationYear: ['',''],
        dateOfBirth: ['',''],
        nadId: ['',''],
        aadharNumber: ['',''],
        unqualified: ['',''],
        nationality: ['',''],
        pwD: ['',''],
        thesis: ['',''],
        blocked: ['',''],
        migrated: ['',''],

    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:StudentModel){
        this.form.patchValue({
            id:response.id,
            academicProgramId:response.academicProgramId,
            name:response.name,
            registrationNumber:response.registrationNumber,
            academicSessionId:response.academicSessionId,
            address:response.address,
            batchStartYear:response.batchStartYear,
            bloodGroup:response.bloodGroup,
            caste:response.caste,
            classRollNumber:response.classRollNumber,
            currentSemester:response.currentSemester,
            discontinued:response.discontinued,
            email:response.email,
            emailSignature:response.emailSignature,
            examinationRollNumber:response.examinationRollNumber,
            gender:response.gender,
            mobile:response.mobile,
            parentName:response.parentName,
            percentile:response.percentile,
            registrationYear:response.registrationYear,
            unqualified:response.unqualified,
            dateOfBirth:response.dateOfBirth,
            nadId:response.nadId,
            aadharNumber:response.aadharNumber,
            nationality:response.nationality,
            pwD:response.pwD,
            thesis:response.thesis,
            blocked:response.blocked,
            migrated:response.migrated

        });
    }
}