import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges } from '@angular/core';
import { StudentService } from '../api-client/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../app/config';
import { AcademiaService } from '../api-client/academia.service';
import { UiService } from '../ui.service';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from '../api-client/exam.service';
var StudentComponent = /** @class */ (function () {
    function StudentComponent(_studentService, _route, _uiService, _academiaService, _exportAsService, _tosterService, _examService, _router) {
        var _this = this;
        this._studentService = _studentService;
        this._route = _route;
        this._uiService = _uiService;
        this._academiaService = _academiaService;
        this._exportAsService = _exportAsService;
        this._tosterService = _tosterService;
        this._examService = _examService;
        this._router = _router;
        this.showHeader = false;
        this.regno = "";
        this.batch = "";
        this.evaluatorType = 0;
        this.examinations = [];
        this.examinationId = "";
        this.studentPassExamination = [];
        this.env = config;
        this._studentServiceSubscription = _studentService.onStudentsUpdated.subscribe(function (_) {
            _this.studentHistory();
            _this.studentDetails();
        });
        this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(function (_) {
            _this.getExaminationsRespectiveSession();
        });
    }
    StudentComponent.prototype.ngOnChanges = function (changes) {
        this.getExaminationsRespectiveSession();
    };
    StudentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.env = config;
        this._route.params.subscribe(function (params) {
            _this.studentId = params.id;
            _this.studentPassInExam();
            _this.studentHistory();
            _this.studentDetails();
            //this.getExaminationsRespectiveSession();     
        });
    };
    StudentComponent.prototype.getExaminationsRespectiveSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var selectedSession;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedSession = this._academiaService.getActiveAcademicSession();
                        if (!selectedSession)
                            return [2 /*return*/];
                        return [4 /*yield*/, this._academiaService.getSessionWiseExams(selectedSession.id).toPromise().then(function (result) {
                                _this.examinations = result;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    StudentComponent.prototype.studentDetails = function () {
        var _this = this;
        if (this.studentId == null)
            return;
        this._studentService.getEvaluation(this.studentId)
            .subscribe(function (result) {
            for (var i = 0; i < result.semestersDetail.length; i++) {
                var lastAppeardInExaminationIds = [];
                for (var k = 0; k < result.semestersDetail[i].courses.length; k++) {
                    result.semestersDetail[i].courses[k].midTermMarksObtained = parseFloat(result.semestersDetail[i].courses[k].midTermMarksObtained).toFixed(2);
                    result.semestersDetail[i].courses[k].endTermMarksObtained = parseFloat(result.semestersDetail[i].courses[k].endTermMarksObtained).toFixed(2);
                    result.semestersDetail[i].courses[k].mid = false;
                    result.semestersDetail[i].courses[k].end = false;
                }
                result.semestersDetail[i].courses.sort(function (x, y) {
                    if (x.paper.paperType == y.paper.paperType) {
                        return x.paper.code.localeCompare(y.paper.code);
                    }
                    else {
                        return (x.paper.paperType - y.paper.paperType);
                    }
                });
            }
            result.semestersDetail.sort(function (x, y) {
                return (x.semester - y.semester);
            });
            _this.student = result;
            _this._uiService.changeAppTitle.next("Student: " + result.name);
            _this.studentProgram = _this._academiaService.getProgram(result.academicProgramId);
            _this.downloadPdfHeader = result.name + "(" + _this.studentProgram.name + ")";
        });
    };
    StudentComponent.prototype.studentHistory = function () {
        var _this = this;
        if (this.studentId == null)
            return;
        this._studentService.getHistory(this.studentId).subscribe(function (result) {
            for (var k = 0; k < result.length; k++) {
                if (result[k].active && result[k].examinationId) {
                    result[k].midTermMarksObtained = parseFloat(result[k].midTermMarksObtained).toFixed(2);
                    result[k].endTermMarksObtained = parseFloat(result[k].endTermMarksObtained).toFixed(2);
                    result[k].mid = false;
                    result[k].end = false;
                }
            }
            var data = [];
            result.sort(function (x, y) {
                return x.paper.code.localeCompare(y.paper.code);
            });
            _this.studentHistoryData = result;
            for (var i = 0; i < result.length; i++) {
                var sem = result[i].semester;
                if (!data[sem]) {
                    data[sem] = { semester: sem, remarks: result[i].resultRemarks, children: [] };
                }
                data[sem].children.push(result[i]);
            }
            _this.historyData = data;
        });
    };
    StudentComponent.prototype.export = function (type, elementId, name) {
        var _this = this;
        this.showHeader = true;
        var configs = { type: type, elementId: elementId };
        setTimeout(function () {
            _this._exportAsService.save(configs, name).subscribe(function () {
                _this.showHeader = false;
            });
        }, 100);
    };
    StudentComponent.prototype.exportCsv = function () {
        var headers = [];
        var fetchDate = [];
        var result = this.studentHistoryData;
        for (var i = 0; i < result.length; i++) {
            fetchDate.push({
                "semester": result[i].semester,
                "remarks": result[i].resultRemarks,
                "createdOn": result[i].createdOn,
                "Type": config.evaluatorType[result[i].type],
                "code": result[i].paper.code,
                "credit": result[i].paper.credit,
                "midTermAllocatedMarks": result[i].paper.midTermAllocatedMarks,
                "endTermAllocatedMarks": result[i].paper.endTermAllocatedMarks,
                "fullMarks": result[i].paper.fullMarks,
                "name": result[i].paper.name,
                "label": result[i].paper.label,
                "paperType": config.paperType[result[i].paper.paperType],
                "midTermMarksObtained": result[i].midTermMarksObtained,
                "endTermMarksObtained": result[i].endTermMarksObtained,
                "totalMarksObtained": result[i].totalMarksObtained,
                "grade": result[i].grade,
                "gradePoint": result[i].gradePoint
            });
        }
        for (var i = 0; i < config.exportCsvStudentHistory.length; i++) {
            headers.push({ align: "center", allowEmpty: false, editor: null, name: config.exportCsvStudentHistory[i], options: [], source: [], title: this.exportcsvHeader(config.exportCsvStudentHistory[i]), type: "text", width: 195 });
        }
        this._uiService.openDataPreviewWindow(fetchDate, headers, []);
    };
    StudentComponent.prototype.switchStudent = function () {
        var _this = this;
        if (this.regno == null || this.regno == "") {
            this._tosterService.error("Registration no. can not be blank.");
            return false;
        }
        if (this.batch == null || this.batch == "") {
            this._tosterService.error("Batch can not be blank.");
            return false;
        }
        var filter = {
            freeText: this.regno,
            academicSessionId: "",
            programIds: [],
            semester: 0,
            batchStartYear: this.batch,
            registrationYear: 0,
            startIndex: 0,
            pageSize: 0
        };
        this._academiaService.students(filter).subscribe(function (result) {
            if (result.items.length > 0) {
                _this._router.navigate(["/student/" + result.items[0].id]);
            }
            else {
                _this._tosterService.error("Student not found.");
            }
        });
    };
    StudentComponent.prototype.exportcsvHeader = function (key) {
        var title = key;
        title = title.replace(/([A-Z])/g, " $1");
        title = title.charAt(0).toUpperCase() + title.slice(1);
        return title;
    };
    StudentComponent.prototype.showEdit = function (sem, code, mode) {
        for (var i = 0; i < this.historyData.length; i++) {
            if (this.historyData[i]) {
                if (this.historyData[i].semester == sem) {
                    for (var k = 0; k < this.historyData[i].children.length; k++) {
                        if (this.historyData[i].children[k].paper.code == code) {
                            if (mode == 'mid') {
                                this.historyData[i].children[k].mid = true;
                            }
                            else if (mode == 'end') {
                                this.historyData[i].children[k].end = true;
                            }
                        }
                    }
                }
            }
        }
    };
    StudentComponent.prototype.showEditLatest = function (sem, code, mode) {
        for (var i = 0; i < this.student.semestersDetail.length; i++) {
            if (this.student.semestersDetail[i]) {
                if (this.student.semestersDetail[i].semester == sem) {
                    for (var k = 0; k < this.student.semestersDetail[i].courses.length; k++) {
                        if (this.student.semestersDetail[i].courses[k].paper.code == code) {
                            if (mode == 'mid') {
                                this.student.semestersDetail[i].courses[k].mid = true;
                            }
                            else if (mode == 'end') {
                                this.student.semestersDetail[i].courses[k].end = true;
                            }
                        }
                    }
                }
            }
        }
    };
    StudentComponent.prototype.modifyMarks = function (sem, code, mode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, k, filter, endAbsent, midAbsent, studentParams, studentParams;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < this.historyData.length)) return [3 /*break*/, 6];
                        if (!this.historyData[i]) return [3 /*break*/, 5];
                        if (!(this.historyData[i].semester == sem)) return [3 /*break*/, 5];
                        k = 0;
                        _a.label = 2;
                    case 2:
                        if (!(k < this.historyData[i].children.length)) return [3 /*break*/, 5];
                        if (!(this.historyData[i].children[k].paper.code == code && this.historyData[i].children[k].active)) return [3 /*break*/, 4];
                        if (this.evaluatorType == null || this.evaluatorType == 0) {
                            this._tosterService.error("Please select correction type.");
                            return [2 /*return*/];
                        }
                        if (this.examinationId == null || this.examinationId == null) {
                            this._tosterService.error("Please select examination.");
                            return [2 /*return*/];
                        }
                        filter = {
                            "freeText": code,
                            "doExactFreeTextMatch": true,
                            "academicSessionId": this.historyData[i].children[k].paper.academicSessionId,
                            "disciplineIds": [],
                            "levels": [],
                            "semester": sem,
                            "startIndex": 0,
                            "pageSize": 0
                        };
                        return [4 /*yield*/, this._academiaService.papers(filter).toPromise().then(function (result) {
                                if (result.items.length > 0) {
                                    _this.evaluators = result.items;
                                }
                            })];
                    case 3:
                        _a.sent();
                        endAbsent = false;
                        if (this.historyData[i].children[k].endTermMarksObtained > 0) {
                            endAbsent = false;
                        }
                        else {
                            endAbsent = true;
                        }
                        midAbsent = false;
                        if (this.historyData[i].children[k].midTermMarksObtained > 0) {
                            midAbsent = false;
                        }
                        else {
                            midAbsent = true;
                        }
                        if (mode == 'mid') {
                            this.historyData[i].children[k].mid = false;
                            studentParams = {
                                studentId: this.historyData[i].children[k].studentId,
                                paperId: this.historyData[i].children[k].paper.id,
                                //examinationId: this.historyData[i].children[k].examinationId,
                                examinationId: this.examinationId,
                                isMidTermMarks: true,
                                marks: this.historyData[i].children[k].midTermMarksObtained,
                                studentAbsent: midAbsent,
                                incomplete: this.historyData[i].children[k].hasIncomplete,
                                mode: this.evaluatorType //correction
                            };
                        }
                        else if (mode == 'end') {
                            this.historyData[i].children[k].end = false;
                            studentParams = {
                                studentId: this.historyData[i].children[k].studentId,
                                paperId: this.historyData[i].children[k].paper.id,
                                //examinationId: this.historyData[i].children[k].examinationId,
                                examinationId: this.examinationId,
                                isMidTermMarks: false,
                                marks: this.historyData[i].children[k].endTermMarksObtained,
                                studentAbsent: endAbsent,
                                incomplete: this.historyData[i].children[k].hasIncomplete,
                                mode: this.evaluatorType //correction
                            };
                        }
                        _a.label = 4;
                    case 4:
                        k++;
                        return [3 /*break*/, 2];
                    case 5:
                        i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    StudentComponent.prototype.modifyMarksLatest = function (sem, code, mode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, k, filter, endAbsent, midAbsent, studentParams, studentParams;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < this.student.semestersDetail.length)) return [3 /*break*/, 6];
                        if (!this.student.semestersDetail[i]) return [3 /*break*/, 5];
                        if (!(this.student.semestersDetail[i].semester == sem)) return [3 /*break*/, 5];
                        k = 0;
                        _a.label = 2;
                    case 2:
                        if (!(k < this.student.semestersDetail[i].courses.length)) return [3 /*break*/, 5];
                        if (!(this.student.semestersDetail[i].courses[k].paper.code == code && (this.student.semestersDetail[i].courses[k].mid || this.student.semestersDetail[i].courses[k].end))) return [3 /*break*/, 4];
                        if (this.evaluatorType == null || this.evaluatorType == 0) {
                            this._tosterService.error("Please select correction type.");
                            return [2 /*return*/];
                        }
                        if (this.examinationId == null || this.examinationId == null) {
                            this._tosterService.error("Please select examination.");
                            return [2 /*return*/];
                        }
                        filter = {
                            "freeText": code,
                            "doExactFreeTextMatch": true,
                            "academicSessionId": this.student.semestersDetail[i].courses[k].paper.academicSessionId,
                            "disciplineIds": [],
                            "levels": [],
                            "semester": sem,
                            "startIndex": 0,
                            "pageSize": 0
                        };
                        return [4 /*yield*/, this._academiaService.papers(filter).toPromise().then(function (result) {
                                if (result.items.length > 0) {
                                    _this.evaluators = result.items;
                                }
                            })];
                    case 3:
                        _a.sent();
                        endAbsent = false;
                        if (this.student.semestersDetail[i].courses[k].endTermMarksObtained > 0) {
                            endAbsent = false;
                        }
                        else {
                            endAbsent = true;
                        }
                        midAbsent = false;
                        if (this.student.semestersDetail[i].courses[k].midTermMarksObtained > 0) {
                            midAbsent = false;
                        }
                        else {
                            midAbsent = true;
                        }
                        if (mode == 'mid') {
                            this.student.semestersDetail[i].courses[k].mid = false;
                            studentParams = {
                                studentId: this.studentId,
                                paperId: this.student.semestersDetail[i].courses[k].paper.id,
                                //examinationId: this.historyData[i].children[k].examinationId,
                                examinationId: this.examinationId,
                                isMidTermMarks: true,
                                marks: this.student.semestersDetail[i].courses[k].midTermMarksObtained,
                                studentAbsent: midAbsent,
                                incomplete: false,
                                mode: this.evaluatorType //correction
                            };
                        }
                        else if (mode == 'end') {
                            this.student.semestersDetail[i].courses[k].end = false;
                            studentParams = {
                                studentId: this.studentId,
                                paperId: this.student.semestersDetail[i].courses[k].paper.id,
                                //examinationId: this.historyData[i].children[k].examinationId,
                                examinationId: this.examinationId,
                                isMidTermMarks: false,
                                marks: this.student.semestersDetail[i].courses[k].endTermMarksObtained,
                                studentAbsent: endAbsent,
                                incomplete: false,
                                mode: this.evaluatorType //correction
                            };
                        }
                        if (this.student.semestersDetail[i].courses[k].endTermMarksObtained == 0) {
                            this.editedValue = studentParams;
                            this.modalElement = document.getElementById('modalClick');
                            this.modalElement.click();
                        }
                        else {
                            this._examService.submitMarks(studentParams).subscribe(function (result) {
                                _this._tosterService.success("Marks updated");
                                _this.studentDetails();
                                _this.studentHistory();
                                return;
                            });
                        }
                        _a.label = 4;
                    case 4:
                        k++;
                        return [3 /*break*/, 2];
                    case 5:
                        i++;
                        return [3 /*break*/, 1];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    StudentComponent.prototype.confirm = function (e) {
        var _this = this;
        var studentParams = {
            studentId: this.editedValue.studentId,
            paperId: this.editedValue.paperId,
            examinationId: this.editedValue.examinationId,
            isMidTermMarks: false,
            marks: this.editedValue.marks,
            studentAbsent: e,
            incomplete: false,
            mode: this.editedValue.mode //correction
        };
        this.modalElement = document.getElementById('closeModal');
        this.modalElement.click();
        this._examService.submitMarks(studentParams).subscribe(function (result) {
            _this._tosterService.success("Marks updated");
            _this.studentDetails();
            _this.studentHistory();
            return;
        });
    };
    StudentComponent.prototype.studentPassInExam = function () {
        var _this = this;
        if (this.studentId == null)
            return;
        this._studentService.getStudentSemisterPassExam(this.studentId).subscribe(function (result) {
            _this.studentPassExamination = result;
        });
    };
    StudentComponent.prototype.getExamName = function (semesterName) {
        if (this.studentPassExamination.length > 0) {
            var exam = this.studentPassExamination.filter(function (x) { return x.semester == semesterName; });
            if (exam && exam.length > 0) {
                return exam[0].examinationName;
            }
        }
        else {
            return;
        }
    };
    StudentComponent.prototype.ngOnDestroy = function () {
        this._studentServiceSubscription.unsubscribe();
    };
    return StudentComponent;
}());
export { StudentComponent };
