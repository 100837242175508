import { Deseriazable } from './deserializable.model';

export class ProgramModel implements Deseriazable{
    
    id: string;
    disciplineId: string;
    name: string;
    academicLevel:number;
    numberOfSemesters:string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}