import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgramService } from '../api-client/program.service';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ExportAsService } from 'ngx-export-as';
import { config } from '../config';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-paper-wise-backlog-students',
  templateUrl: './paper-wise-backlog-students.component.html',
  styleUrls: ['./paper-wise-backlog-students.component.css']
})
export class PaperWiseBacklogStudentsComponent implements OnInit {

  @Input() paper: any;
  private _academiaServiceSubscription: Subscription;
  backlogStudents: any;
  academiaSessions: any;
  config = config;
  midTerm: boolean = false;
  endTerm: boolean = false;
  paperInDisciplineSession: any;
  paperCode: any;
  marksEntryHeader = config.marksEntryHeader;

  constructor(
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _uiService: UiService,
    private _exportAsService: ExportAsService,
    private _tosterService: ToastrService,
    private _uiSerice: UiService
  ) {
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.academiaSessions = this._academiaService.getAcademicSession();
      this.populateStudents();
    });
  }

  ngOnInit() {
    this.academiaSessions = this._academiaService.getAcademicSession();
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.populateStudents();
  }

  populateStudents() {
    var stud = [];
    if (this.paper == null) return;
    this.paperInDisciplineSession = this._uiService.paperInDisciplineSession;
    this._academiaService.getBacklogPaperWiseStudents(this.paper.id)
      .subscribe((result: any) => {
        for (var i = 0; i < result.length; i++) {
          if (result[i].blocked === false && result[i].migrated === false) {
            result[i].sessionName = this.sessionDetails(result[i].academicSessionId).name;
            var studentProgram = this._academiaService.getProgram(result[i].academicProgramId);
            result[i].studentMajor = studentProgram.name.split('in')[1].trim();
            if (result[i].studentMajor == 'H') {
              result[i].studentMajor = 'Hindi';
            } else if (result[i].studentMajor == 'Perform') {
              result[i].studentMajor = 'Performing Arts';
            }
            stud.push(result[i]);
          }
        }
        this.backlogStudents = stud;
      });
  }
  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
  }

  isEmpty() {
    return Object.keys(this.backlogStudents).length == 0;
  }

  redirectTo(student: any) {
    this._router.navigate(["student/" + student.id]);
  }

  sessionDetails(sessionId: any) {
    if (this.academiaSessions == null) return null;
    var items = this.academiaSessions.filter((x: any) => x.id == sessionId);
    if (items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  export(type: any, elementId: any, name: any, arg: any) {
    this.midTerm = false;
    this.endTerm = false;
    if (arg == 'mid') {
      this.midTerm = true;
    }
    if (arg == 'end') {
      this.endTerm = true;
    }

    var configs = { type: type, elementId: elementId };
    setTimeout(() => {
      this._exportAsService.save(configs, this.paperInDisciplineSession.discipline.name + " Backlog Student " + arg + ' Term ' + this.paper.code + "(" + this.paperInDisciplineSession.session.name + ")").subscribe(() => {
      });
    }, 10);

  }

  previewCsvData(fieldMaps: any, results: any) {
    this._uiService.indeterminateProgressTextSet.next('Arrange data...');
    var columns = [];
    var columnsWidth = {};
    var columnSources = [];

    columnSources['paperType'] = Object.keys(config.paperType).map((key) => {
      return { id: Number(key), name: config.paperType[key] };
    });

    columnSources['level'] = Object.keys(config.academicLevel).map((key) => {
      return { id: Number(key), name: config.academicLevel[key] };
    });

    columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map((key) => {
      return { id: Number(key), name: config.secondaryPaperType[key] };
    });

    columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map((x: any) => {
      return { id: x.id, name: x.name };
    });

    columnSources['disciplineId'] = this._academiaService.getDisciplines().map((x: any) => {
      return { id: x.id, name: x.name };
    });

    var arrangedData = results.map((value: any) => {
      var item = {};
      for (var key in fieldMaps) {
        var valueWidth = (value[fieldMaps[key]] + '').length * 15;
        item[key] = value[fieldMaps[key]];
        if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
          columnsWidth[key] = valueWidth;
        }
      }

      return item;
    });



    this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
    this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
    columnsWidth = null;
    this._uiService.indeterminateProgressTextSet.next(null);

    this._uiService.openDataPreviewWindow(arrangedData, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
        }
      }
    ]);
  }

  onImportPreviewCompleted(fields: any, arrangedData: any, columns: any) {
    var data = this._uiService.getPreviewModifiedData();
    var converted = data.map((value: any) => {
      var item = {};
      for (var i = 0; i < fields.length; i++) {
        item[fields[i]] = value[i];
      }
      return item;
    });
    this._uiService.closeDataPreview();
    var worker = new Worker('../workers/import-marks.worker', { name: 'marks-worker', type: 'module' });

    this._uiService.startWorker(worker, converted)
      .then(() => {
        //show success message, if required
      }).catch(e => {
        if (e.validation) {
          this.onImportValidationError(e.validation, converted, columns, fields);
        }
      });
  }

  onImportValidationError(validation: any, converted: any, columns: any, fields: any) {
    this._uiService.onDataPreviewReady = () => {
      var allFields = Object.keys(converted[0]);
      for (var i = 0; i < validation.length; i++) {
        var commonErrors = [];
        for (var field in validation[i]) {
          var fieldIndex = allFields.indexOf(field);
          if (fieldIndex <= 0) {
            commonErrors = commonErrors.concat(validation[i][field]);
          } else {
            this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
          }
        }
        if (commonErrors.length > 0) {
          this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
        }
      }
    };
    this._uiService.openDataPreviewWindow(converted, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(fields, converted, columns);
        }
      }
    ]);
  }

  mapCsvField(results: any) {
    this._uiService
      .mapCsvField(results.meta.fields, this.marksEntryHeader)
      .then((maps: any) => {
        this.previewCsvData(maps, results.data);
      }).catch(() => { });
  }

  uploadFile(fileEle: any) {
    this._uiService
      .parseCsv(fileEle.files[0])
      .then((results: any) => {
        fileEle.value = null;
        this.mapCsvField(results);
      }).catch(() => {
        fileEle.value = null;
      });
  }

  selectAll(element: any) {
    for (var i = 0; i < this.backlogStudents.length; i++) {
      if (element.checked) {
        this.backlogStudents[i].checked = true;
      } else {
        this.backlogStudents[i].checked = false;
      }
    }
  }

  selectIndividual(element: any, studentId: any) {
    for (var i = 0; i < this.backlogStudents.length; i++) {
      if (this.backlogStudents[i].id == studentId) {
        if (element.checked) {
          this.backlogStudents[i].checked = true;
        } else {
          this.backlogStudents[i].checked = false;
        }
      }
    }
  }

  async unlinkStudents() {
    var student = this.backlogStudents.filter((x: any) => x.checked == true);
    if (student.length == 0) {
      this._tosterService.error("Please select student(s)");
      return;
    }
    var count = 0;
    for (var i = 0; i < this.backlogStudents.length; i++) {
      if (this.backlogStudents[i].checked) {
        var data = {
          "studentId": this.backlogStudents[i].id,
          "paperCodes": [this.paper.code]
        };
        await this._academiaService.unsetStudentPaper(data).toPromise().then((result: any) => {
          count++;
        })
      }
    }
    this._tosterService.success("Student(s) unlinked");
    this.populateStudents();

  }

  linkStudents() {
    this._router.navigate([{
      outlets: { rightMainPopup: ['search-students', this.paper.id] }
    }]);
  }

}
