import { Component, OnInit } from '@angular/core';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-data-import-mapper',
  templateUrl: './data-import-mapper.component.html',
  styleUrls: ['./data-import-mapper.component.css']
})
export class DataImportMapperComponent implements OnInit {
  fieldMap:any;
  csvHeaders:any;
  csvTargetFields:any;

  constructor(
    private _uiService:UiService,
    private _router:Router,
    private _route:ActivatedRoute,
    private _toster: ToastrService,
    private _location:Location
  ) { }

  ngOnInit() {
    this.csvHeaders = this._uiService.csvHeaders;
    this.csvTargetFields = this._uiService.csvTargetFields;

    if(this.csvHeaders == null){
      this._uiService.closeCenterPopup();
    }else{
      this.fieldMap = [];
      this.csvHeaders.forEach((value:any, index:any) => {
        var converted = value.replace(/ /g, '');
        converted = converted.charAt(0).toLowerCase() + converted.slice(1);
        this.fieldMap.push({header:value, selectedValue:converted, checked:(false && value && value.length>0)});
      });
    }
  }

  onContinue(){
    var selectedItems = this.fieldMap.filter((x:any)=>x.checked);
    var maps = [];
    var error = null;
    for(var i=0; i<selectedItems.length; i++){
      var value = selectedItems[i];
      if(maps[value.selectedValue]){
        error = `Duplicate fields maped against ${value.selectedValue}.`;
        this._toster.error(error);        
        break;
      }

      if(value.selectedValue == null || value.selectedValue==''){
        error = `Targeted field not selected for ${value.header}.`;
        this._toster.error(error);
        break;
      }
      maps[value.selectedValue] = value.header;
    }

    var finalMaps = [];
    for(var i=0; i<this.csvTargetFields.length; i++){
      finalMaps[this.csvTargetFields[i]] = maps[this.csvTargetFields[i]]?maps[this.csvTargetFields[i]]:null;
    }
    console.log(finalMaps);
    if(error == null){
      this._uiService.closeCenterPopup();
      this._uiService.afterCsvMapDone({maps:finalMaps, error:error});
    }
  }

  convertToTitleCase(input:any)
  {
    var title = input;
    title = title.replace( /([A-Z])/g, " $1" );
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

}
