<div class="w-100">
    <div class="d-flex p-2">
        <input type="text" class="form-control" placeholder="Search faculty" (keyup)="filterEmployee($event.target)">
        <a (click)="employeeDetails({id:0})"><em aria-hidden="true" class="fa fa-plus-square-o p-2 text-22px"></em></a> <a (click)="exportCsv()"><em class="fa fa-download"></em></a>       
    </div>
    <div class="scroll_div">
        <div *ngFor="let employee of allEmployees" class="col-sm-12" >
            <div class="d-flex justify-content-between">
                <a (click)="assignEmployee(employee)"><span class="h5 m-0 pb-2 d-flex"><em *ngIf="employee.inDepartment" class="fa fa-check-square-o mr-1"></em>
                <em *ngIf="!employee.inDepartment" class="fa fa-square-o mr-1"></em> {{employee.name}}</span></a>
                
            </div>
        </div>
    </div>
</div>