import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { OrganizationService } from '../api-client/organization.service';
import { DepartmentModel } from '../models/department.model';
import { OrganizationFormBuilder } from './organization.form-builder';
import { ToastrService } from 'ngx-toastr';
import { DepartmentService } from '../api-client/department.service';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.css']
})
export class OrganizationDetailsComponent implements OnInit {

  organizationId: any;
  organization: any;
  organizations: any;
  editMode: boolean = false;
  submitted = false;
  model = new DepartmentModel();
  departmentForm: any;

  private _organizationServiceSubscription: Subscription;

  constructor(
    private _organizationSearvice: OrganizationService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _toster: ToastrService,
    private _departmentSearvice: DepartmentService,
    private _formBuilder: OrganizationFormBuilder,
    private _router: Router) {
    this._organizationServiceSubscription = _organizationSearvice.onDepartmentsUpdated.subscribe(_ => {
      this.organizations = this._organizationSearvice.getDepartments();
    })

  }

  get f() { return this.departmentForm.controls; }

  ngOnInit() {
    this.departmentForm = this._formBuilder.getForm();
    this.organizations = this._organizationSearvice.getDepartments();
    this._route.params.subscribe(params => {
      this.organizationId = params.id;
      this.resetForm();
        this._departmentSearvice.getDepartment(this.organizationId).subscribe((result:any)=>{
          this._formBuilder.setValues(result);
        })
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.departmentForm.invalid) {
      return;
    }

    this.model.deserialize(this.departmentForm.value);
    //console.log(this.model);return;
    this._organizationSearvice.saveDepartment(this.model)
      .subscribe(
        (response: DepartmentModel) => {
          this._toster.success("Department saved");
          this._organizationSearvice.notifyDepartmentUpdate(this.organizationId);
          this.editMode = true;
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

  editOrganization() {
    this.editMode = false;
  }

  resetForm(){
    this.submitted = false;
    this.departmentForm.reset();
  }

}
