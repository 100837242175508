import { environment } from "src/environments/environment";
declare const ENV_OVERWRITE: any;

let tempConfig = {
  apiEndPointBase: ENV_OVERWRITE.API_URL || environment.API_URL,

  paperType: { 0: 'None', 4: 'GenEd', 2: 'Major', 8: 'Sessional', 16: 'Core', 32: 'DSE', 64: 'GE', 128: 'AECC', 256: 'SEC', 304: 'Major (Core / SEC)', 512: 'VAC', 1024: 'MC', 2048: 'MDC', 4096: 'Summer Internship', 8192: 'Project Or Dissertation' },
  semesters: [{ id: 1, name: "Semester 1" }, { id: 2, name: "Semester 2" }, { id: 3, name: "Semester 3" }, { id: 4, name: "Semester 4" }, { id: 5, name: "Semester 5" }, { id: 6, name: "Semester 6" }, { id: 7, name: "Semester 7" }, { id: 8, name: "Semester 8" }, { id: -1, name: "Casual" }],
  secondaryPaperType: { 0: 'Nonsessional', 1: 'Sessional' },
  paginationPerPage: 40,
  academicLevel: { 24: 'Under Graduate', 212: 'Under Graduate (Honours & Research)', 40: 'Post Graduate', 64: 'Phd' },
  academicLevelShort: { 'UG': 24, 'PG': 40, 'PHD': 64 },
  employeeType: { 0: 'None', 2: 'Guest', 4: 'Permanent', 8: 'External' },
  adminEmail: "admin@gmail.com",
  gender: ['Male', 'Female', 'other'],
  bloodGroup: ['A+', 'O+', 'B+', 'AB+', 'A-', 'O-', 'B-', 'AB-'],
  caste: ['General', 'SC', 'ST', 'OBC-A', 'OBC-B'],
  sessionStatus: { true: 'Active', false: 'Inactive' },
  discontinued: { true: 'Yes', false: 'No' },
  studentCsvHeader: ["aadharNumber", "address", "academicSessionId", "academicProgramId", "batchStartYear", "bloodGroup", "caste", "classRollNumber", "currentSemester", "dateOfBirth",
    "email", "emailSignature", "examinationRollNumber", "gender", "mobile", "nadId", "name", "parentName", "registrationNumber", "registrationYear", "Discipline", "Stream"],
  paperCsvHeader: ["academicSessionId", "disciplineId", "paperType", "level", "secondaryPaperType", "code", "credit", "endTermAllocatedMarks", "endTermCredit", "fullMarks", "groupCode", "groupName", "label", "midTermAllocatedMarks", "midTermCredit", "name", "semester"],
  exportCsvPaper: ["semester", "groupCode", "code", "name", "credit", "fullMarks"],
  exportCsvStudent: ["currentSemester", "registrationNumber", "batchStartYear", "classRollNumber", "examinationRollNumber", "name"],
  exportCsvStudentHistory: ["semester", "remarks", "createdOn", "Type", "code", "credit", "midTermAllocatedMarks", "endTermAllocatedMarks", "fullMarks", "name", "label", "paperType", "endTermMarksObtained", "midTermMarksObtained", "totalMarksObtained", "grade", "gradePoint"],
  evaluatorType: { 0: 'None', 1: 'Imported', 2: 'Examiner', 4: 'Reviewer', 6: 'Backlog', 8: 'Correction', 24: 'Admin' },
  staffcSVHeader: ["name", "address", "bloodGroup", "discontinued", "email", "emailSignature", "gender", "mobile", "designation", "dateOfJoin", "type", "releaseDate"],
  examinerHeader: ["academicSessionId", "code", "endTermAllocatedMarks", "allowSubmitMidTermMarks" , "allowSubmitEndTermMarks", "examinationId", "examinerEmail", "mode"],
  //paperStudentHeader:["academicSessionId","registrationNumber","batchStartYear","classRollNumber","name","level","disciplineId","semester","paper_1_code","paper_1_name","paper_2_code","paper_2_name","paper_3_code","paper_3_name","paper_4_code","paper_4_name","paper_5_code","paper_5_name","paper_6_code","paper_6_name","paper_7_code","paper_7_name","paper_8_code","paper_8_name","paper_9_code","paper_9_name","paper_10_code","paper_10_name","paper_11_code","paper_11_name","paper_12_code","paper_12_name","paper_13_code","paper_13_name","paper_14_code","paper_14_name","paper_15_code","paper_15_name"],
  paperStudentHeader: ["registrationNumber", "batchStartYear", "paper_1_code", "paper_2_code", "paper_3_code", "paper_4_code", "paper_5_code", "paper_6_code", "paper_7_code", "paper_8_code", "paper_9_code", "paper_10_code", "paper_11_code", "paper_12_code", "paper_13_code", "paper_14_code", "paper_15_code"],
  marksEntryHeader: ["academicSessionId", "code", "level", "semester", "registrationNumber", "batchStartYear", "examinationRollNumber", "classRollNumber", "term", "fullMarks", "marksObtained"],
  tspaperCount: [1, 2, 3, 4, 5, 6, 7, 8],
  sem: { 1: 'First', 2: 'Second', 3: 'Third', 4: 'Fourth', 5: 'Fifth', 6: 'Sixth', 7: 'Seventh', 8: 'Eighth' },
  statisticCount: [1, 2]
}

export const config = tempConfig;
