<div class="p-3 vw-25" style="overflow-y: auto;">
    
    <div class="scroll_div">
        <div *ngFor="let employee of employees" class="col-sm-12" >
            <div class="d-flex justify-content-between">
                <a (change)="assignEmployee(employee,type)"><span class="h5 m-0 pb-2 d-flex">
                    <input type="checkbox" [(ngModel)]="employee.checked" class="mr-2" />
                   
                 {{employee.item.name}}</span></a>
                
            </div>
        </div>
     
    </div>
    <div class="center-popup-footer p-2 text-right grey lighten-3 ">
        <button class="btn btn-primary btn-sm d-flex align-items-center font-weight-500" (click)="onSelect()">
            Select
        </button>
    </div>
</div>


