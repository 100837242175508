import { Component, OnInit, OnDestroy } from '@angular/core';
import { UiService } from '../ui.service';
import { Subscription } from 'rxjs';
import { config } from '../config';
import { paperService } from '../api-client/paper.service';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ExportAsService } from 'ngx-export-as';
import { ExamService } from '../api-client/exam.service';

@Component({
  selector: 'app-marks-submission-status',
  templateUrl: './marks-submission-status.component.html',
  styleUrls: ['./marks-submission-status.component.css']
})
export class MarksSubmissionStatusComponent implements OnInit, OnDestroy {

  private _paperServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;

  paperId: any;
  papers = [];
  session: any;
  disciplines: any;
  filterDiscipline = "";
  filterSemester = 0;
  filterLevel = 0;
  filterSession = "";
  filterExam = "";
  filters = {};
  marksEntryHeader = config.marksEntryHeader;

  academiaSessions: any;
  env = config;
  selectedSession: any;
  exams: any;
  pdfName: any;
  apiPapers: any;

  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _examService: ExamService,
    private _tosterService: ToastrService,
    private _exportAsService: ExportAsService,
    private _router: Router,) {
    this._academiaServiceSubscription = this._academiaService.onAcademicSessionUpdate.subscribe(_ => {
      let activeSession = this._academiaService.getActiveAcademicSession();
      if (this.selectedSession == null || this.selectedSession.id != activeSession.id) {
        this.selectedSession = this._academiaService.getActiveAcademicSession();
        this.getExams(this.selectedSession);
      }
    });
    this._academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.disciplines = this._academiaService.getDisciplines();
    });
  }

  ngOnInit() {
    this._uiService.changeAppTitle.next("Marks Submission Status");
    let activeSession = this._academiaService.getActiveAcademicSession();
    if (this.selectedSession == null || (this.selectedSession && this.selectedSession.id != activeSession.id)) {
      this.selectedSession = this._academiaService.getActiveAcademicSession();
      this.getExams(this.selectedSession);
      this.disciplines = this._academiaService.getDisciplines();
    }
  }

  paperFilter() {
    if (this.filterExam == null || this.filterExam == "") {
      this._tosterService.error("Examination  can not be blank.");
      return;
    }
    if (this.filterDiscipline == null || this.filterDiscipline == "") {
      this._tosterService.error("Discipline can not be blank.");
      return;
    }

    this._examService.evaluatorsByDiscipline(this.filterExam, this.filterDiscipline).subscribe((result: any) => {
      // this.papers = result;
      this.apiPapers = result;
      var dis = this.disciplines.filter((x: any) => x.id == this.filterDiscipline);
      var exam = this.exams.filter((x: any) => x.id == this.filterExam);
      if (dis.length > 0 && exam.length > 0) {
        this.pdfName = dis[0].name + " | " + exam[0].name;
      }

      var grouped = this.groupBy(result, false, (relation: { examinerName: any; }) => relation.examinerName);
      this.papers = this.mapEntries(grouped);
    });
  }

  mapEntries(grouped: any) { return Array.from(grouped.entries()); }

  groupBy(list: any, checked: boolean, keyGetter: any) {
    const map = new Map();
    var temp = [];
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      const data = {
        item: item,
        isChecked: checked

      };
      temp.push(data);
      if (!collection) {
        map.set(key, [data]);
      } else {
        collection.push(data);
      }
    });
    return map;
  }

  getExams(session: any) {
    if (!session) {
      return;
    }
    this._academiaService.getSessionWiseExams(session.id).subscribe((result: any) => {
      this.exams = result;
      const activeExam = this.exams.filter((x: any) => x.active === true);
      if (activeExam && activeExam.length > 0) {
        this.filterExam = activeExam[0].id;
      }
    })
  }

  redirectTo(paper: any) {
    this._router.navigate(["paper/" + paper.paperId]);
    return;
  }

  exportPDF() {
    setTimeout(() => {
      this._exportAsService.save({ type: "pdf", elementId: "downloadPDF" }, this.pdfName).subscribe(() => {
      });
    }, 10);
  }

  previewCsvData(fieldMaps: any, results: any) {
    this._uiService.indeterminateProgressTextSet.next('Arrange data...');
    var columns = [];
    var columnsWidth = {};
    var columnSources = [];

    columnSources['paperType'] = Object.keys(config.paperType).map((key) => {
      return { id: Number(key), name: config.paperType[key] };
    });

    columnSources['level'] = Object.keys(config.academicLevel).map((key) => {
      return { id: Number(key), name: config.academicLevel[key] };
    });

    columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map((key) => {
      return { id: Number(key), name: config.secondaryPaperType[key] };
    });

    columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map((x: any) => {
      return { id: x.id, name: x.name };
    });

    columnSources['disciplineId'] = this._academiaService.getDisciplines().map((x: any) => {
      return { id: x.id, name: x.name };
    });

    var arrangedData = results.map((value: any) => {
      var item = {};
      for (var key in fieldMaps) {
        var valueWidth = (value[fieldMaps[key]] + '').length * 15;
        item[key] = value[fieldMaps[key]];
        if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
          columnsWidth[key] = valueWidth;
        }
      }

      return item;
    });



    this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
    this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
    columnsWidth = null;
    this._uiService.indeterminateProgressTextSet.next(null);

    this._uiService.openDataPreviewWindow(arrangedData, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
        }
      }
    ]);
  }

  onImportPreviewCompleted(fields: any, arrangedData: any, columns: any) {
    var data = this._uiService.getPreviewModifiedData();
    var converted = data.map((value: any) => {
      var item = {};
      for (var i = 0; i < fields.length; i++) {
        item[fields[i]] = value[i];
      }
      return item;
    });
    this._uiService.closeDataPreview();
    var worker = new Worker('../workers/import-backlog-marks.worker', { name: 'backlog-marks-worker', type: 'module' });

    this._uiService.startWorker(worker, { values: converted, examination: this.filterExam, token: sessionStorage.getItem("token") })
      .then(() => {
        //show success message, if required
      }).catch(e => {
        if (e.validation) {
          this.onImportValidationError(e.validation, converted, columns, fields);
        }
      });
  }

  onImportValidationError(validation: any, converted: any, columns: any, fields: any) {
    this._uiService.onDataPreviewReady = () => {
      var allFields = Object.keys(converted[0]);
      for (var i = 0; i < validation.length; i++) {
        var commonErrors = [];
        for (var field in validation[i]) {
          var fieldIndex = allFields.indexOf(field);
          if (fieldIndex <= 0) {
            commonErrors = commonErrors.concat(validation[i][field]);
          } else {
            this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
          }
        }
        if (commonErrors.length > 0) {
          this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
        }
      }
    };
    this._uiService.openDataPreviewWindow(converted, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(fields, converted, columns);
        }
      }
    ]);
  }

  mapCsvField(results: any) {
    this._uiService
      .mapCsvField(results.meta.fields, this.marksEntryHeader)
      .then((maps: any) => {
        this.previewCsvData(maps, results.data);
      }).catch(() => { });
  }

  uploadFile(fileEle: any) {
    this._uiService
      .parseCsv(fileEle.files[0])
      .then((results: any) => {
        fileEle.value = null;
        this.mapCsvField(results);
      }).catch(() => {
        fileEle.value = null;
      });
  }

  sendInvitation(getData: any) {
    let data = {
      evaluatorId: getData[1][0].item.examinerId,
      examinationId: getData[1][0].item.examinationId,
      mode: getData[1][0].item.type,
      forMidTermMarksEntry: true
    }
    this._examService.sendMarksEntryRequest(data).subscribe((result: any) => {
      var res = result.data;

    },
      error => {
        console.error(error.message);
      })
  }

  async sendMultiInvitation(flag: boolean, type: number) {
    var paper = this.papers.filter((x: any) => x[1][0].isChecked == true);
    var select = await paper.forEach(async (data: any, index: any) => {
      var checkType = data[1].filter((x: any) => x.item.type == type);
      if (checkType.length > 0) {
        let sub = {
          evaluatorId: data[1][0].item.examinerId,
          examinationId: data[1][0].item.examinationId,
          mode: type,
          forMidTermMarksEntry: flag
        };
        var send = await this._examService.sendMarksEntryRequest(sub).toPromise();
      }
      await this._tosterService.success("Request send successfully");
    });


  }

  selectAll(e: any) {
    var grouped = this.groupBy(this.apiPapers, e.target.checked, (relation: { examinerName: any; }) => relation.examinerName);
    this.papers = this.mapEntries(grouped);
  }

  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
  }

}

