<div class="d-flex">
    <div class="sidebar_left w-25 p-4 h-100vh z-depth-1">
        <div class="m-0">
            <span class="mb-2 d-block text-80 font-weight-500">Notify On</span>
            <input (keyup)="filterTemplate($event.target)" class="form-control" type="text" placeholder="">
        </div>
        <div class="sessions_list">
            <ul>
                <li *ngFor="let template of templates" (click)="getTemplate(template.id)" class="border-bottom p-2 d-flex justify-content-between align-items-center">{{template.subject}} </li>
            </ul>
        </div>
    </div>
    <form [formGroup]="templateForm" class=" w-75 p-4" (ngSubmit)="onSubmit()">
    <div class="exams">
        <span class="mb-2 d-block text-80 font-weight-500">Email Subject Line</span>
        <input class="form-control" type="text" formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" placeholder="">
        <div *ngIf="submitted && f.subject.errors" style="display: block;" class="invalid-feedback">
            <div *ngIf="f.subject.errors.required">Email subject field required</div>
        </div>
        <div class="mt-3">
            <span class="mb-2 d-block text-80 font-weight-500">Email Body</span>
            <!-- <span>[name]</span><span>[Department]</span><span>[Department]</span> -->
            <ck-editor name="editor1" [config]="config" formControlName="emailContent" skin="moono-lisa" language="en" [ngClass]="{ 'is-invalid': submitted && f.emailContent.errors }" [fullPage]="false"></ck-editor>
            <div *ngIf="submitted && f.emailContent.errors" style="display: block;" class="invalid-feedback">
                <div *ngIf="f.emailContent.errors.required">Email body field required</div>
            </div>
        </div>
        <div class="mt-3">
            <span class="mb-2 d-block text-80 font-weight-500">SMS Body</span>
            <textarea class="form-control" formControlName="smsContent" placeholder="Message"></textarea>
        </div>
        <div class="mt-3">
            <span class="mb-2 d-block text-80 font-weight-500">PDF Content</span>
            <textarea class="form-control" rows="8" formControlName="pdfContent" placeholder="PDF Content"></textarea>
        </div>
        <div class="text-right">
            <button class="btn btn-button btn-primary btn-sm mt-3 text-80 font-weight-500">Submit</button>
        </div>
    </div>
    <input type="hidden"  formControlName="id" >
    </form>
</div>