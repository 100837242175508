import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { DisciplineService } from '../api-client/discipline.service';
import { UtilityService } from '../api-client/utility.service';
import { paperService } from '../api-client/paper.service';
var ExaminerListComponent = /** @class */ (function () {
    function ExaminerListComponent(_uiService, _route, _router, _disciplineService, _utilityService, _paperService) {
        this._uiService = _uiService;
        this._route = _route;
        this._router = _router;
        this._disciplineService = _disciplineService;
        this._utilityService = _utilityService;
        this._paperService = _paperService;
        this.employees = [];
        this.examiners = [];
        this.reviewers = [];
        this.thirdExaminer = [];
    }
    ExaminerListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this._route.params.subscribe(function (params) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                this.employees = [];
                                this.disciplineId = params.id;
                                this.type = params.type;
                                this.paperId = params.paperId;
                                this.examId = params.examId;
                                if (!(this.paperId && this.examId)) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.selectExam(this.examId)];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2:
                                if (!this.disciplineId) return [3 /*break*/, 4];
                                return [4 /*yield*/, this.getDisciplineWiseEmployees(this.disciplineId)];
                            case 3:
                                _a.sent();
                                _a.label = 4;
                            case 4: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    ExaminerListComponent.prototype.getDisciplineWiseEmployees = function (disciplineId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._disciplineService.getEmployees(disciplineId).toPromise()];
                    case 1:
                        result = _a.sent();
                        return [4 /*yield*/, result.forEach(function (value, index) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.employees.push({ item: value, checked: false, allocatedMarks: '', uploadedMidMarks: false, uploadedEndMarks: '', uploadedEndTermMarks: false });
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.createData()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ExaminerListComponent.prototype.createData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.reviewers.length > 0 && this.type == 1) {
                    this.employees = this.employees.filter(function (gameToken) { return _this.reviewers.toString().includes(gameToken.item.id.toString()) == false; });
                }
                if (this.type == 2 || this.type == 3) {
                    this.employees = this.employees.filter(function (gameToken) { return _this.examiners.toString().includes(gameToken.item.id.toString()) == false; });
                }
                return [2 /*return*/];
            });
        });
    };
    ExaminerListComponent.prototype.selectExam = function (examId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.examiners = [];
                        this.reviewers = [];
                        this.thirdExaminer = [];
                        if (!examId)
                            return [2 /*return*/, null];
                        return [4 /*yield*/, this._paperService.getEvaluators(this.paperId, this.examId).toPromise()];
                    case 1:
                        result = _a.sent();
                        return [4 /*yield*/, result.forEach(function (data, index) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (data.type == 2) {
                                        this.examiners.push(data.examinerId);
                                    }
                                    else if (data.type == 4) {
                                        this.reviewers.push(data.examinerId);
                                    }
                                    else {
                                        this.thirdExaminer.push(data.examinerId);
                                    }
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ExaminerListComponent.prototype.assignEmployee = function (e, type) {
        var selectedEmp = this.employees.filter(function (e) { return e.checked == true; });
        if (type == 1) {
            this._utilityService.submittedEmpArray(selectedEmp);
        }
        if (type == 2) {
            this._utilityService.submittedReviewerArray(selectedEmp);
        }
        if (type == 104) {
            this._utilityService.submittedThirdExaminerArray(selectedEmp);
        }
    };
    ExaminerListComponent.prototype.onSelect = function () {
    };
    return ExaminerListComponent;
}());
export { ExaminerListComponent };
