import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { StaffModel } from '../models/staff.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EmployeeService = /** @class */ (function () {
    function EmployeeService(_http) {
        this._http = _http;
        this.onStudentsUpdated = new Subject();
    }
    EmployeeService.prototype.getEmployeess = function () {
        return this._employees;
    };
    EmployeeService.prototype.getEmployee = function (id) {
        if (this._employees == null)
            return null;
        var items = this._employees.filter(function (x) { return x.id == id; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    EmployeeService.prototype.resetPassword = function (email) {
        var data = { email: email };
        return this._http
            .post(config.apiEndPointBase + "auth/reset-password", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    EmployeeService.prototype.auth = function (data) {
        return this._http
            .post(config.apiEndPointBase + "auth/validate-join-date", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    EmployeeService.prototype.emailExists = function (data) {
        return this._http
            .post(config.apiEndPointBase + "auth/email-exists", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    EmployeeService.prototype.saveEmployee = function (data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "employee", data, {})
                .pipe(map(function (response) {
                return new StaffModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "employee", data, {})
                .pipe(map(function (response) {
                return new StaffModel().deserialize(response);
            }));
        }
    };
    EmployeeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.ɵɵinject(i1.HttpClient)); }, token: EmployeeService, providedIn: "root" });
    return EmployeeService;
}());
export { EmployeeService };
