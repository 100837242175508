<ng-template #notFound><span class="font-weight-600">{{ 'Please Select Exam' | translate }}</span></ng-template>
<div class="d-flex justify-content-between align-items-center mb-2">
    <!-- <div class="text-100 font-weight-500"><span>Selected session :</span> <span class="font-weight-600"> {{sessionId.name}}</span></div> -->
    <div class="d-flex justify-content-between align-items-center">
        <div class="mr-3 w25">
            <select (change)="selectExam($event.target.value)" class="form-control">
                <option value="">{{ 'Select Exam' | translate }}</option>
                <option *ngFor="let exam of exams" [value]="exam.id">{{exam.name}}</option>
            </select>
        </div>
        <div class="w-50 mr-2" *ngIf="progrems.length>0"><input type="text" class="form-control" placeholder="Exam name"
                [(ngModel)]="newExamData.name"></div>
        <div class="w-25 font-weight-500 text-80" *ngIf="progrems.length>0"><input type="checkbox" [(ngModel)]="newExamData.active">
            Active</div>
    </div>
    <div>
        <div class="sem-select d-flex align-items-center">
            <p class="m-0 mr-2 font-weight-500 text-80">{{ 'Select Semesters' | translate }} :</p>
            <ul class="d-flex">
                <li class="mr-2"><a (click)="showRow(3)" [ngClass]="(tabSelected==3)?'selectActive':''"
                        class="btn grey lighten-3 btn-sm text-100 border rounded font-weight-500 z-depth-0 ">{{ 'All' | translate }}</a>
                </li>
                <li class="mr-2"><a (click)="showRow(1)" [ngClass]="(tabSelected==1)?'selectActive':''"
                        class="btn grey lighten-3 btn-sm text-100 border rounded font-weight-500 z-depth-0">{{ 'Odd' | translate }}</a>
                </li>
                <li class="mr-2"><a (click)="showRow(2)" [ngClass]="(tabSelected==2)?'selectActive':''"
                        class="btn grey lighten-3 btn-sm text-100 border rounded font-weight-500 z-depth-0">{{ 'Even' | translate }}</a>
                </li>
                <!-- <li class=""><a (click)="showRow(0)" [ngClass]="(tabSelected==0)?'selectActive':''"
                        class="btn grey lighten-3 btn-sm text-100 border rounded font-weight-500 z-depth-0">{{ 'None' | translate }}</a>
                </li> -->
            </ul>
        </div>
    </div>
</div>
<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#regular" role="tab" aria-controls="regular"
            aria-selected="true">{{ 'Regular & Supplementary' | translate }}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="review-tab" data-toggle="tab" href="#review" role="tab" aria-controls="students"
            aria-selected="false">{{ 'Post Publication Review' | translate }}</a>
    </li>
</ul>

<div class="">
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="regular" role="tabpanel" aria-labelledby="regular-tab">
            <div *ngIf="this.progrems;else notFound;">
                <ng-container *ngFor="let degree of progrems | keyvalue;let i = index;">
                    <div class="mt-3">
                        <a class="d-flex justify-content-between text-100 grey lighten-3 border rounded p-2 align-items-center"
                            data-toggle="collapse" href="#degree_{{degree.value.id}}" role="button"
                            aria-expanded="false" aria-controls="degree_{{degree.value.id}}">
                            <h5 class="font-weight-600 text-80 m-0">{{degree.value.name}}</h5>
                            <em class="fa fa-caret-down"></em>
                        </a>
                    </div>

                    <div class="collapse multi-collapse" id="degree_{{degree.value.id}}">
                        <div class="mb-2 mt-2">
                            <div class="row align-items-center">
                                <div class="col-sm-2">
                                    <div><input (click)="copyData($event,'checkbox','sem',degree.value.id,'examination')"
                                            type="checkbox"></div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="d-flex justify-content-between align-items-center text-color">
                                        <input placeholder="Start Date"
                                            (dateTimeChange)="copyData($event,'text','start',degree.value.id,'examination')"
                                            class="w-100 form-control" type="text" [owlDateTime]="topStartDate"
                                            [owlDateTimeTrigger]="topStartDate" /><em class="fa fa-calendar ml-2"></em>
                                        <owl-date-time [pickerType]="'calendar'" #topStartDate></owl-date-time>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="d-flex justify-content-between align-items-center text-color">
                                        <input placeholder="End Date"
                                            (dateTimeChange)="copyData($event,'text','end',degree.value.id,'examination')"
                                            class="w-100 form-control" type="text" [owlDateTime]="topEndDate"
                                            [owlDateTimeTrigger]="topEndDate" /><em class="fa fa-calendar ml-2"></em>
                                        <owl-date-time [pickerType]="'calendar'" #topEndDate></owl-date-time>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="d-flex justify-content-between align-items-center text-color">
                                        <input placeholder="Result Publish Date"
                                            (dateTimeChange)="copyData($event,'text','publish',degree.value.id,'examination')"
                                            class="w-100 form-control" type="text" [owlDateTime]="topPublishDate"
                                            [owlDateTimeTrigger]="topPublishDate" /><em class="fa fa-calendar ml-2"></em>
                                        <owl-date-time [pickerType]="'calendar'" #topPublishDate></owl-date-time>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <div class="text-color"><em (click)="globalPopUp(degree.value.id,'examination')" data-toggle="modal"
                                        data-target="#globalPopUp" class="fa fa-file-text-o"></em></div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 text-80" *ngFor="let progrem of degree.value.children">
                            <h6 class="row_color p-2 text-100 font-weight-500">{{progrem.name}}</h6>
                            <div *ngFor="let sem of progrem.semesterWiseExamValues|keyvalue;let i=index;">
                                <div *ngIf="showRows.includes(i+1)" class="mb-2">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div class="d-flex align-items-center">
                                                <input [(ngModel)]="sem.value.checked" type="checkbox"
                                                    class="form-control w-auto mr-1">
                                                <span class="font-weight-500">{{ 'Semester' | translate }} -
                                                    {{sem.key}}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div>
                                                <input [(ngModel)]="sem.value.examination.start"
                                                    class="w-100 form-control" type="text" [owlDateTime]="startDate"
                                                    [owlDateTimeTrigger]="startDate" />
                                                <owl-date-time [pickerType]="'calendar'" #startDate></owl-date-time>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div>
                                                <input [(ngModel)]="sem.value.examination.end"
                                                    class="w-100 form-control" type="text" [owlDateTime]="endDate"
                                                    [owlDateTimeTrigger]="endDate" />
                                                <owl-date-time [pickerType]="'calendar'" #endDate></owl-date-time>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div>
                                                <input class="w-100 form-control"
                                                    [(ngModel)]="sem.value.examination.resultPublishDate" type="text"
                                                    [owlDateTime]="resDate" [owlDateTimeTrigger]="resDate" />
                                                <owl-date-time [pickerType]="'calendar'" #resDate></owl-date-time>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="text-color">
                                                <em (click)="openModal(sem.value)" data-toggle="modal"
                                                    data-target="#examinationRemarks" class="fa fa-file-text-o"></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </div>
        </div>
        <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
            <div *ngIf="this.progrems;else notFound;">
                <ng-container *ngFor="let degree of progrems | keyvalue;let i = index;">
                    <div class="mt-3">
                        <a class="d-flex justify-content-between text-100 grey lighten-3 border rounded p-2 align-items-center"
                            data-toggle="collapse" href="#degree_{{degree.value.id}}" role="button"
                            aria-expanded="false" aria-controls="degree_{{degree.value.id}}">
                            <h5 class="font-weight-600 text-80 m-0">{{degree.value.name}}</h5>
                            <em class="fa fa-caret-down"></em>
                        </a>
                    </div>
                    <div class="collapse multi-collapse" id="degree_{{degree.value.id}}">
                        <div class="mb-2 mt-2">
                            <div class="row align-items-center">
                                <div class="col-sm-2">
                                    <div><input (click)="copyData($event,'checkbox','sem',degree.value.id,'review')"
                                            type="checkbox"></div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="d-flex justify-content-between align-items-center text-color">
                                        <input placeholder="Start Date"
                                            (dateTimeChange)="copyData($event,'text','start',degree.value.id,'review')"
                                            class="w-100 form-control" type="text" [owlDateTime]="topStartDate"
                                            [owlDateTimeTrigger]="topStartDate" /><em class="fa fa-calendar ml-2"></em>
                                        <owl-date-time [pickerType]="'calendar'" #topStartDate></owl-date-time>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="d-flex justify-content-between align-items-center text-color">
                                        <input placeholder="End Date"
                                            (dateTimeChange)="copyData($event,'text','end',degree.value.id,'review')"
                                            class="w-100 form-control" type="text" [owlDateTime]="topEndDate"
                                            [owlDateTimeTrigger]="topEndDate" /><em class="fa fa-calendar ml-2"></em>
                                        <owl-date-time [pickerType]="'calendar'" #topEndDate></owl-date-time>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="d-flex justify-content-between align-items-center text-color">
                                        <input placeholder="Result Publish Date"
                                            (dateTimeChange)="copyData($event,'text','publish',degree.value.id,'review')"
                                            class="w-100 form-control" type="text" [owlDateTime]="topPublishDate"
                                            [owlDateTimeTrigger]="topPublishDate" /><em class="fa fa-calendar ml-2"></em>
                                        <owl-date-time [pickerType]="'calendar'" #topPublishDate></owl-date-time>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <div class="text-color"><em (click)="globalPopUp(degree.value.id,'review')" data-toggle="modal"
                                        data-target="#globalPopUp" class="fa fa-file-text-o"></em>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 text-80" *ngFor="let progrem of degree.value.children">
                            <h6 class="row_color p-2 text-100 font-weight-500">{{progrem.name}}</h6>
                            <div *ngFor="let sem of progrem.semesterWiseExamValues|keyvalue;let i=index;">
                                <div *ngIf="showRows.includes(i+1)" class="mb-2">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <div class="d-flex align-items-center">
                                                <input [(ngModel)]="sem.value.checked" type="checkbox"
                                                    class="form-control w-auto mr-1">
                                                <span class="font-weight-500">{{ 'Semester' | translate }} -
                                                    {{sem.key}}</span>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div>
                                                <input [(ngModel)]="sem.value.review.start" class="w-100 form-control"
                                                    type="text" [owlDateTime]="startDate"
                                                    [owlDateTimeTrigger]="startDate" />
                                                <owl-date-time [pickerType]="'calendar'" #startDate></owl-date-time>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div>
                                                <input [(ngModel)]="sem.value.review.end" class="w-100 form-control"
                                                    type="text" [owlDateTime]="endDate"
                                                    [owlDateTimeTrigger]="endDate" />
                                                <owl-date-time [pickerType]="'calendar'" #endDate></owl-date-time>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div>
                                                <input class="w-100 form-control"
                                                    [(ngModel)]="sem.value.review.resultPublishDate" type="text"
                                                    [owlDateTime]="resDate" [owlDateTimeTrigger]="resDate" />
                                                <owl-date-time [pickerType]="'calendar'" #resDate></owl-date-time>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">
                                            <div class="text-color">
                                                <em (click)="openModal(sem.value)" data-toggle="modal"
                                                    data-target="#reviewRemarks" class="fa fa-file-text-o"></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<button *ngIf="progrems.length>0" (click)="save()" class="btn btn-button btn-primary btn-sm mt-3">Save</button>
<!-- Modal -->
<div class="modal fade" id="reviewRemarks" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div *ngIf="selectedSemDetails" class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ 'Semester' | translate }} -
                    {{selectedSemDetails.semester}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea [(ngModel)]="selectedSemDetails.review.gradeCardRemarks" class="form-control"></textarea>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm font-weight-500"
                    data-dismiss="modal">{{ 'Close' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="examinationRemarks" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div *ngIf="selectedSemDetails" class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ 'Semester' | translate }} -
                    {{selectedSemDetails.semester}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea [(ngModel)]="selectedSemDetails.examination.gradeCardRemarks" class="form-control"></textarea>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm font-weight-500"
                    data-dismiss="modal">{{ 'Close' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="globalPopUp" tabindex="-1" role="dialog" aria-labelledby="globalPopUpLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="globalPopUpLabel">{{popUpHeading}}</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <textarea [(ngModel)]="popupVal" (change)="getGlobalPopUpVal($event,'remarks')" class="form-control"></textarea>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-sm font-weight-500"
                    data-dismiss="modal">{{ 'Close' | translate }}</button>
            </div>
        </div>
    </div>
</div>