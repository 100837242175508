import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { config } from '../../app/config';
import { SessionModel } from '../models/session.model';
import { StudentModel } from '../models/student.model';
import { DisciplineModel } from '../models/discipline.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AcademiaService = /** @class */ (function () {
    function AcademiaService(_http) {
        this._http = _http;
        this.onProgramsUpdated = new Subject();
        this.onAcademicSessionUpdate = new Subject();
        this.onDisciplinesUpdated = new Subject();
        this.loadInitialData();
    }
    AcademiaService.prototype.loadInitialData = function () {
        var _this = this;
        var _http = this._http;
        // Don't call these APIs if user not logged in
        var userToken = sessionStorage.getItem("token");
        if (userToken && userToken != "") {
            _http.get(config.apiEndPointBase + "academia/sessions").subscribe(function (result) {
                result.sort(function (x, y) {
                    return x.name.localeCompare(y.name);
                });
                _this._academicSession = result;
                var storedSelectedSession = localStorage.getItem("selectSession");
                if (storedSelectedSession) {
                    _this._selectedAcademicSession = JSON.parse(storedSelectedSession);
                }
                else {
                    var selectedSessions = result.filter(function (x) { return x.active; });
                    if (selectedSessions.length > 0) {
                        _this._selectedAcademicSession = selectedSessions[0];
                    }
                }
                _this.onAcademicSessionUpdate.next();
            });
            _http.get(config.apiEndPointBase + "academia/programs").subscribe(function (result) {
                result.sort(function (x, y) {
                    return x.name.localeCompare(y.name);
                });
                _this._programs = result;
                _this.onProgramsUpdated.next();
            });
            _http.get(config.apiEndPointBase + "academia/disciplines").subscribe(function (result) {
                result.sort(function (x, y) {
                    return x.name.localeCompare(y.name);
                });
                _this._disciplines = result;
                _this.onDisciplinesUpdated.next();
            });
        }
    };
    AcademiaService.prototype.getPrograms = function () {
        return this._programs;
    };
    AcademiaService.prototype.getProgram = function (id) {
        if (this._programs == null)
            return null;
        var items = this._programs.filter(function (x) { return x.id == id; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    AcademiaService.prototype.getDisciplines = function () {
        return this._disciplines;
    };
    AcademiaService.prototype.getDiscipline = function (id) {
        if (this._disciplines == null)
            return null;
        var items = this._disciplines.filter(function (x) { return x.id == id; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    AcademiaService.prototype.getAcademicSession = function () {
        return this._academicSession;
    };
    AcademiaService.prototype.getActiveAcademicSession = function () {
        return this._selectedAcademicSession;
    };
    AcademiaService.prototype.setSelectedSession = function (session) {
        if (this._selectedAcademicSession == null || this._selectedAcademicSession.id != session.id) {
            localStorage.setItem("selectSession", JSON.stringify(session));
            this._selectedAcademicSession = session;
            this.onAcademicSessionUpdate.next();
        }
    };
    AcademiaService.prototype.getSessionDetails = function (sessionId) {
        if (this._academicSession == null)
            return null;
        var items = this._academicSession.filter(function (x) { return x.id == sessionId; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    AcademiaService.prototype.getSessionWiseExams = function (sessionId) {
        return this._http.get(config.apiEndPointBase + "academia/examinations-in-session/" + sessionId);
    };
    AcademiaService.prototype.saveSession = function (data) {
        return this._http
            .post(config.apiEndPointBase + "academia/session", data, {})
            .pipe(map(function (response) {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return new SessionModel().deserialize(response);
        }));
    };
    AcademiaService.prototype.papers = function (data) {
        return this._http
            .post(config.apiEndPointBase + "academia/papers-with-active-examiners", data, {})
            .pipe(map(function (response) {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    };
    AcademiaService.prototype.students = function (data) {
        return this._http
            .post(config.apiEndPointBase + "academia/students", data, {})
            .pipe(map(function (response) {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    };
    AcademiaService.prototype.saveStudent = function (data) {
        return this._http
            .post(config.apiEndPointBase + "academia/session", data, {})
            .pipe(map(function (response) {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return new StudentModel().deserialize(response);
        }));
    };
    AcademiaService.prototype.updateStudentCurrentSemester = function (date) {
        var dateStr = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        return this._http
            .put(config.apiEndPointBase + "academia/students/update-current-semester/" + dateStr, {}, {})
            .pipe(map(function (response) {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    };
    AcademiaService.prototype.sessionWisepaperCopy = function (data) {
        return this._http
            .post(config.apiEndPointBase + "academia/papers/copy-from-session/" + data.sourceSessionId + "/" + data.targetSessionId, {}, {})
            .pipe(map(function (response) {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    };
    AcademiaService.prototype.saveDiscipline = function (data) {
        return this._http
            .post(config.apiEndPointBase + "academia/discipline", data, {})
            .pipe(map(function (response) {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return new DisciplineModel().deserialize(response);
        }));
    };
    AcademiaService.prototype.unsetStudentPaper = function (data) {
        return this._http
            .post(config.apiEndPointBase + "academia/unset-student-papers", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    AcademiaService.prototype.setStudentPaper = function (data) {
        return this._http
            .post(config.apiEndPointBase + "academia/set-student-papers", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    AcademiaService.prototype.getPaperWiseStudents = function (paperId) {
        return this._http.get(config.apiEndPointBase + "academia/students-in-paper/" + paperId);
    };
    AcademiaService.prototype.getBacklogPaperWiseStudents = function (paperId) {
        return this._http.get(config.apiEndPointBase + "academia/students-have-backlog-in-paper/" + paperId);
    };
    AcademiaService.prototype.getActiveExam = function () {
        return this._http.get(config.apiEndPointBase + "academia/examination/active/");
    };
    AcademiaService.prototype.examination = function () {
        return this._http.get(config.apiEndPointBase + "academia/examinations");
    };
    AcademiaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AcademiaService_Factory() { return new AcademiaService(i0.ɵɵinject(i1.HttpClient)); }, token: AcademiaService, providedIn: "root" });
    return AcademiaService;
}());
export { AcademiaService };
