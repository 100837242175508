import { Component, OnInit } from '@angular/core';
import { paperService } from '../api-client/paper.service';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ExportAsService } from 'ngx-export-as';
import { config } from '../../app/config';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-paper-student-map',
  templateUrl: './paper-student-map.component.html',
  styleUrls: ['./paper-student-map.component.css']
})
export class PaperStudentMapComponent implements OnInit {

  private _paperServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;
 
  paperId: any;
  papers: any;
  session: any;
  disciplines: any;
  filterKeyword = "";
  filterDiscipline = [];
  filterSemester = 0;
  filterLevel = 0;
  filterSession = "";
  filters = {};
  pageSize: number = config.paginationPerPage;
  totalItemsCount: number = 0;
  totalPageCount: number = 0;
  papersCurrentPage: any = 1;
  academiaSessions: any;
  env = config;
  paperCsvHeader = config.paperStudentHeader;
  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private exportAsService: ExportAsService,
    private _router: Router, ) {
    this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.academiaSessions = this._academiaService.getAcademicSession();
      this.disciplines = this._academiaService.getDisciplines();
    });
  }

  ngOnInit() {
    this._uiService.changeAppTitle.next("Paper Student Map");
    this.academiaSessions = this._academiaService.getAcademicSession();
    this.disciplines = this._academiaService.getDisciplines();
  }

  previewCsvData(fieldMaps:any, results:any)
  {
    this._uiService.indeterminateProgressTextSet.next('Arrange data...');
    var columns = [];
    var columnsWidth = {};
    var columnSources = []; 
    
    columnSources['level'] = Object.keys(config.academicLevel).map((key) => {
      return {id:Number(key),name:config.academicLevel[key]};
    });    

    columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map((x:any)=>{
      return {id:x.id, name:x.name};
    });

    columnSources['disciplineId'] = this._academiaService.getDisciplines().map((x:any)=>{
      return {id:x.id, name:x.name};
    });
    var arrangedData = results.map((value:any)=>{
      var item = {};
      for(var key in fieldMaps){
        var valueWidth = (value[fieldMaps[key]] + '').length*15;
        item[key] = value[fieldMaps[key]];
        if(!columnsWidth[key] || columnsWidth[key]<valueWidth){
          columnsWidth[key] = valueWidth;
        }        
      }
      console.log(item['academicSessionId']);      
      
      return item;
    });

    

    this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
    this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
    columnsWidth = null;
    this._uiService.indeterminateProgressTextSet.next(null);
    
    this._uiService.openDataPreviewWindow(arrangedData, columns, [
      {
          type: 'i',
          content: 'done_all',
          onclick: () => {
              this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
          }
      }
    ]);
  }

  onImportPreviewCompleted(fields:any, arrangedData:any, columns:any)
  {
    var data = this._uiService.getPreviewModifiedData();
    var converted = data.map((value:any)=>{
      var item = {};
      for(var i=0; i<fields.length; i++){
        item[fields[i]] = value[i];
      }
      return item;
    });
    this._uiService.closeDataPreview();
    let worker = new Worker('../workers/import-map-paper-student.worker', { name:'map-paper-student', type: 'module' });
    
    this._uiService.startWorker(worker, converted)
      .then(() => {
        //show success message, if required
      }).catch(e => {
        if(e.validation){
          this.onImportValidationError(e.validation, converted, columns, fields);
        }
      });
  }
  
  onImportValidationError(validation:any, converted:any, columns:any, fields:any){
    this._uiService.onDataPreviewReady = () => {
      var allFields = Object.keys(converted[0]);
      for(var i=0; i<validation.length; i++){
        var commonErrors = [];        
        for(var field in validation[i]){
          var fieldIndex = allFields.indexOf(field);
          if(fieldIndex<=0){
            commonErrors = commonErrors.concat(validation[i][field]);
          }else{
            this._uiService.setPreviewDataCellCommentAndStyle(i+1, fieldIndex+1, validation[i][field].join('\n'), null); 
          }          
        }        
        if(commonErrors.length>0){
          this._uiService.setPreviewDataCellCommentAndStyle(i+1, 1, commonErrors.join('\n'), null); 
        }
      }
    };
    this._uiService.openDataPreviewWindow(converted, columns, [
      {
          type: 'i',
          content: 'done_all',
          onclick: () => {
              this.onImportPreviewCompleted(fields, converted, columns);
          }
      }
    ]);
  }

  mapCsvField(results:any){
    this._uiService
    .mapCsvField(results.meta.fields, this.paperCsvHeader)
    .then((maps:any) => {
      this.previewCsvData(maps, results.data);
    }).catch(()=>{});
  }

  uploadFile(fileEle: any) {
    this._uiService
      .parseCsv(fileEle.files[0])
      .then((results:any)=>{
        fileEle.value = null;
        this.mapCsvField(results);
      }).catch(() => {
        fileEle.value = null;
      });
  }

}
