<div class="student_details_section d-flex">
    <div class="left_bg w-25 p-4">
        <h5>{{ 'Filters' | translate }} :</h5>
        <div class="row-filter mb-3">
            <input [(ngModel)]="filterKeyword" class="form-control" type="text" placeholder="Name/Reg. No">
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterSession" class="form-control">
                <option value="">{{ 'Select session' | translate }}</option>
                <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="convocationSession" class="form-control">
                <option value="">{{ 'Select Convocation Session' | translate }}</option>
                <option *ngFor="let session of academiaSessions" [value]="session.id">{{session.name}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select [(ngModel)]="filterSemester" class="form-control">
                <option value="0">{{ 'Select semester' | translate }}</option>
                <option *ngFor="let sem of config.semesters" [value]="sem.id">{{sem.name}}</option>
            </select>
        </div>
        <div class="row-filter mb-3" *ngIf="programs">
            <angular2-multiselect class="form-control" [data]="programs" [settings]="programSearchSettings"
                [(ngModel)]="filterProgram">
                <c-item>
                    <ng-template let-item="item">
                        {{item?.name}}
                    </ng-template>
                </c-item>
                <c-badge>
                    <ng-template let-item="item">
                        {{item?.name}}
                    </ng-template>
                </c-badge>
            </angular2-multiselect>
        </div>
        <div class="row-filter mb-3">
            <input [(ngModel)]="filterBatchStartYear" class="form-control" type="text" placeholder="Batch Year">
        </div>
        <div class="row-filter mb-3">
            <input [(ngModel)]="filterRegistrationYear" class="form-control" type="text"
                placeholder="Registration Year">
        </div>
        <div class="row-filter mb-3">
            <select class="form-control " [(ngModel)]="evaluatorType">
                <option value="">Select modification type</option>
                <option value="8">Correction</option>
                <option value="6">Backlog</option>
                <option value="4">Review</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select class="form-control " [(ngModel)]="examinationId">
                <option value="">Select examination</option>
                <option *ngFor="let exam of examinations" [value]="exam.id">{{exam.name}}</option>
            </select>
        </div>
        <div class="row-filter mb-3">
            <select class="form-control " [(ngModel)]="includeOnly">
                <option value=0>All</option>
                <option value=2>Blocked</option>
                <option value=4>Migrated</option>
                <option value=6>Blocked & Migrated</option>
            </select>
        </div>
        <div class="text-right">
            <button (click)="studentFilter()" class="btn btn-primary font-weight-500 text-80 p-1 pr-2 pl-2">{{ 'Apply' |
                translate }} <em aria-hidden="true" class="fa fa-arrow-right"></em></button>
        </div>

    </div>
    <div class="p-4 w-75">
        <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="m-0">Students</h4> -->
            <div class="reply_cont d-flex justify-content-between align-items-center">
                <div class="form-group m-0 mr-2 d-flex justify-content-between align-items-center font-weight-500">
                    <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                        <input type="file" (change)="uploadFile($event.target)" class="hidden-file" id="importStudents">
                    </em>
                    <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
                </div>
                <a class="ml-2 mr-2 d-flex justify-content-between align-items-center font-weight-500"
                    (click)="export()"><em class="fa fa-download p-2 rounded-circle"></em>
                    <span>{{ 'Export' | translate }}</span></a>
                <a class="ml-2 mr-2 d-flex justify-content-between align-items-center font-weight-500"
                    data-toggle="modal" data-target="#updateSem"><em class="fa fa-arrow-circle-o-up p-2 rounded-circle"
                        aria-hidden="true"></em>
                    <span>{{ 'Upgrade Semester' | translate }}</span></a>
                <a class="ml-2 mr-2 d-flex justify-content-between align-items-center font-weight-500" href="#"><em
                        class="fa fa-unlock p-2 rounded-circle" aria-hidden="true"></em>
                    <span>{{ 'Block/Unblock' | translate }}</span></a>
                <a class="ml-2 mr-2 d-flex justify-content-between align-items-center font-weight-500"
                    (click)="redirectTo({id:0})"><em class="fa fa-plus p-2 rounded-circle"></em>
                    <span>{{ 'Add' | translate }}</span></a>
                <a class="ml-2 mr-2 d-flex justify-content-between align-items-center font-weight-500"
                    routerLink="/paper-student-map"><em class="fa fa-sitemap p-2 rounded-circle"
                        aria-hidden="true"></em>
                    <span>{{ 'Paper Student' | translate }}</span></a>
                <a class="ml-1 d-flex justify-content-between align-items-center font-weight-500" (click)="relode()"><em
                        class="fa fa-refresh p-2 rounded-circle" aria-hidden="true"></em></a>
                <!-- Basic dropdown -->
                <section class="">
                    <a class="ml-1 d-flex justify-content-between align-items-center text-center font-weight-500"
                        data-toggle="dropdown" aria-haspopup="true">
                        <em class="fa fa-ellipsis-v p-2 rounded-circle" aria-expanded="false"></em>
                    </a>
                    <div class="dropdown-menu z-depth-1 p-0">
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center border-bottom p-2"
                            (click)="tabulation('ts')"><em class="fa fa-download p-2 rounded-circle border mr-2"></em>
                            TS</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center border-bottom p-2"
                            (click)="tabulation('rs')"><em class="fa fa-download p-2 rounded-circle border mr-2"></em>
                            RS</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center border-bottom p-2"
                            (click)="pprStudentsTabulation('ts')"><em
                                class="fa fa-download p-2 rounded-circle border mr-2"></em> PPR
                            TS</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center border-bottom p-2"
                            (click)="pprStudentsTabulation('rs')"><em
                                class="fa fa-download p-2 rounded-circle border mr-2"></em> PPR
                            RS</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center border-bottom text-center p-2"
                            (click)="gradeCard()"><em class="fa fa-download p-2 rounded-circle border mr-2"></em> Grade
                            Card</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center p-2"
                            (click)="consolidatedReport()"><em
                                class="fa fa-download p-2 rounded-circle border mr-2"></em> Consolidated Report</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center p-2"
                            (click)="convocationReport()"><em
                                class="fa fa-download p-2 rounded-circle border mr-2"></em>Convocation Report</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center p-2"
                            (click)="backlogStudentReport(1,3,5)"><em
                                class="fa fa-download p-2 rounded-circle border mr-2"></em>Backlog Reports in Odd
                            Semester</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center p-2"
                            (click)="backlogStudentReport(2,4,6)"><em
                                class="fa fa-download p-2 rounded-circle border mr-2"></em>Backlog Reports in Even
                            Semester</a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center p-2"
                            (click)="transcriptReport()"><em
                                class="fa fa-download p-2 rounded-circle border mr-2"></em>Transcript Report
                        </a>

                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center p-2"
                            (click)="nadReport()"><em class="fa fa-download p-2 rounded-circle border mr-2"></em>NAD
                            Report
                        </a>

                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center p-2"
                            (click)="statisticsReport()"><em
                                class="fa fa-download p-2 rounded-circle border mr-2"></em>Statistics
                            Report
                        </a>
                        <a class="dropdown-item d-flex justify-content-start align-items-center text-center p-2"
                        (click)="blockReport()"><em
                            class="fa fa-download p-2 rounded-circle border mr-2"></em>Blocked
                        Report
                    </a>
                    </div>
                    <!-- Basic dropdown -->
                </section>
            </div>
        </div>
        <div class="text-80">
            <div class="p-1 pr-2 pl-2 text-100 font-weight-500">
                <div class="row white p-2">

                    <div class="col-sm-2 d-flex align-items-start">
                        <input type="checkbox" id="all" class="mr-2 mt-3px">
                        <p class="m-0 font-weight-500">{{ 'Reg.no' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{ 'Reg.year' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{ 'Batch' | translate }}</p>
                    </div>
                    <div class="col-sm-3">
                        <p class="m-0 font-weight-500">{{ 'Name' | translate }}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-500">{{ 'Roll' | translate }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500">{{ 'Session' | translate }}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-500 text-center">{{ 'Action' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="p-1 pr-2 pl-2">
                <div *ngFor="let student of students | paginate: {id: 'studentsPagination',
                                                          itemsPerPage: pageSize,
                                                          currentPage: papersCurrentPage,
                                                          totalItems: totalItemsCount}; index as i;"
                     class="row item-row p-2">

                    <div class="col-sm-2 d-flex align-items-start" (click)="redirectTo(student)">
                        <input type="checkbox" id="all" class="mr-2 mt-3px">
                        <p class="m-0 font-weight-400">{{student.registrationNumber}}</p>
                    </div>
                    <div class="col-sm-1" (click)="redirectTo(student)">
                        <p class="m-0 font-weight-400">{{student.registrationYear}}</p>
                    </div>
                    <div class="col-sm-1" (click)="redirectTo(student)">
                        <p class="m-0 font-weight-400">{{student.batchStartYear}}</p>
                    </div>
                    <div class="col-sm-3" (click)="redirectTo(student)">
                        <p class="m-0 font-weight-400">{{student.name}}</p>
                    </div>
                    <div class="col-sm-1">
                        <p class="m-0 font-weight-400">{{student.classRollNumber}}</p>
                    </div>
                    <div class="col-sm-2">
                        <p class="m-0 font-weight-400">{{student.sessionName}}</p>
                    </div>
                    <div class="col-sm-2 text-center">
                       <a (click)="redirectTo(student)"> <em  class="fa fa-pencil mt-3px"></em></a>&nbsp;
                       
                            <a class="text-success" (click)="migrateBlock(2,false,student)" *ngIf="student && student?.blocked===true"> <em class="fa fa-unlock"></em></a>&nbsp;
                            <a class="text-danger" (click)="migrateBlock(2,true,student)" *ngIf="student && student?.blocked===false"> <em class="fa fa-ban"></em></a>&nbsp;
                            <a class="text-dark" (click)="migrateBlock(1,true,student)" *ngIf="student && student?.migrated===false"> <em class="fa fa-share-square"></em></a>&nbsp;
                            <a class="text-success" (click)="migrateBlock(1,false,student)" *ngIf="student && student?.migrated===true"> <em class="fa fa-undo"></em></a>&nbsp;
                             
                         
                    </div>
                </div>
            </div>
            <div class="d-flex p-0 pt-3 justify-content-between">
                <div class="font-weight-600">Total {{ totalItemsCount }} in {{ totalPageCount }} pages.</div>
                <pagination-controls id="studentsPagination" (pageChange)="onPageChange($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<div class="d-none">
    <table id="studentMarks" border="1">
        <tr>
            <th>Reg.No.</th>
            <th>ERN</th>
            <th>CRN</th>
            <th>Name</th>
            <ng-container *ngFor="let paper of config.tspaperCount;">
                <th>Paper {{paper}}</th>
                <th *ngIf="type=='ts'">Sem</th>
                <th *ngIf="type=='ts'">IA</th>
                <th *ngIf="type=='ts'">TM</th>
                <th *ngIf="type=='ts'">TOT</th>
                <th>GRD</th>
            </ng-container>
            <th>SGPA OVRL</th>
            <th>SGPA MAJOR</th>
            <th>REM</th>
        </tr>
        <ng-container *ngFor="let student of studentTabulation">
            <tr>
                <td>{{student.studentDetails.registrationNumber}}</td>
                <td>{{student.studentDetails.examinationRollNumber}}</td>
                <td>{{student.studentDetails.classRollNumber}}</td>
                <td>{{student.studentDetails.name}}</td>
                <!-- <ng-container *ngFor="let course of student.semesterDetails.courses"> -->
                <ng-container *ngFor="let paper of config.tspaperCount; index as i">
                    <ng-container *ngIf="student.semesterDetails.courses[i]; else blanktd">
                        <td>{{student.semesterDetails.courses[i].paper.code}}</td>
                        <td *ngIf="type=='ts'">{{student.semesterDetails.courses[i].endTermMarksObtained}}</td>
                        <td *ngIf="type=='ts'">{{student.semesterDetails.courses[i].midTermMarksObtained}}</td>
                        <td *ngIf="type=='ts'">{{student.semesterDetails.courses[i].graceMarksObtained}}</td>
                        <td *ngIf="type=='ts'">{{student.semesterDetails.courses[i].totalMarksObtained}}</td>
                        <td>{{student.semesterDetails.courses[i].grade}}</td>
                    </ng-container>
                </ng-container>
                <!-- </ng-container> -->
                <td>{{student.semesterDetails.sgpa.overall}}</td>
                <td>
                    <ng-container *ngFor="let avg of student.semesterDetails.sgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>{{student.semesterDetails.resultOverallRemarks}}</td>
            </tr>
        </ng-container>
    </table>
</div>
<ng-template #blanktd>
    <td></td>
    <td *ngIf="type=='ts'"></td>
    <td *ngIf="type=='ts'"></td>
    <td *ngIf="type=='ts'"></td>
    <td *ngIf="type=='ts'"></td>
    <td></td>
</ng-template>

<div class="d-none">
    <table id="studentPpr" border="1">
        <tr>
            <th>Mode</th>
            <th>Created Date</th>
            <th>Class Roll</th>
            <th>Registration Year</th>
            <th>Exam Roll</th>
            <th>Name</th>
            <th>Reg.No.</th>
            <ng-container *ngFor="let paper of config.tspaperCount;">
                <th>Paper {{paper}}</th>
                <th>Paper Label</th>
                <th *ngIf="pprType=='ts'">Sem</th>
                <th *ngIf="pprType=='ts'">IA</th>
                <th *ngIf="pprType=='ts'">TM</th>
                <th *ngIf="pprType=='ts'">TOT</th>
                <th>GRD</th>
            </ng-container>
            <th>SGPA MAJOR</th>
            <th>SGPA GNED</th>
            <th>SGPA OVRL</th>
            <th>REM</th>
            <th>GRADE CARD NO</th>
        </tr>
        <ng-container *ngFor="let student of pprTabulationStudents">
            <tr>
                <td>
                    <ng-container *ngIf="student.studentDetails.mode==4">Review</ng-container>
                    <ng-container *ngIf="student.studentDetails.mode==2">Original</ng-container>
                </td>
                <td>{{student.studentDetails.entryDate | date: 'd-MMM-yyyy'}}</td>
                <td>{{student.studentDetails.classRollNumber}}</td>
                <td>{{student.studentDetails.registrationYear}}</td>
                <td>{{student.studentDetails.examinationRollNumber}}</td>
                <td>{{student.studentDetails.name}}</td>
                <td>{{student.studentDetails.registrationNumber}}</td>
                <!-- <ng-container *ngFor="let course of student.semesterDetails.courses"> -->
                <ng-container *ngFor="let paper of config.tspaperCount; index as i">
                    <ng-container *ngIf="student.semesterDetails.courses[i]; else blankpprtd">
                        <td>{{student.semesterDetails.courses[i].paper.code}}</td>
                        <td>{{student.semesterDetails.courses[i].paper.label}}</td>
                        <td *ngIf="pprType=='ts'">{{student.semesterDetails.courses[i].endTermMarksObtained}}</td>
                        <td *ngIf="pprType=='ts'">{{student.semesterDetails.courses[i].midTermMarksObtained}}</td>
                        <td *ngIf="pprType=='ts'">{{student.semesterDetails.courses[i].graceMarksObtained}}</td>
                        <td *ngIf="pprType=='ts'">{{student.semesterDetails.courses[i].totalMarksObtained}}</td>
                        <td>{{student.semesterDetails.courses[i].grade}}</td>
                    </ng-container>
                </ng-container>
                <!-- </ng-container> -->

                <td>
                    <ng-container *ngFor="let avg of student.semesterDetails.sgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td></td>
                <td>{{student.semesterDetails.sgpa.overall}}</td>
                <td>{{student.semesterDetails.resultOverallRemarks}}</td>
                <td></td>
            </tr>
        </ng-container>
    </table>
</div>
<ng-template #blankpprtd>
    <td></td>
    <td></td>
    <td *ngIf="pprType=='ts'"></td>
    <td *ngIf="pprType=='ts'"></td>
    <td *ngIf="pprType=='ts'"></td>
    <td *ngIf="pprType=='ts'"></td>
    <td></td>
</ng-template>
<div class="d-none">
    <table id="studentGradeCard" border="1">
        <tr>
            <th>Results of the</th>
            <th>Exm YR</th>
            <th>Session</th>
            <th>Name</th>
            <th>Dept</th>
            <th>CRN</th>
            <th>EXR</th>
            <th>REG</th>
            <th>YR of Regd.</th>
            <th>Held in</th>

            <ng-container *ngFor="let paper of config.tspaperCount;">
                <th>Paper {{paper}}</th>
                <th>PAPER NAME {{paper}}</th>
                <th>GRD</th>
                <th>CP {{paper}}</th>
            </ng-container>
            <th>TOT CP</th>
            <th>SGPA-MJR</th>
            <th>SGPA-GENED</th>
            <th>SGPA-OVERALL</th>
            <th>CGPA-MJR</th>
            <th>CGPA-GENED</th>
            <th>CGPA-OVERALL</th>
            <th>REMARKS</th>
            <th>REMARKS (OVERALL)</th>
            <th>TOTAL MAJOR MARKS OBTAINED</th>
            <th>Max MJR Marks</th>
            <th>TOTAL GENED MARKS OBTAINED</th>
            <th>Max GENED Marks</th>
            <th>TOTAL MARKS OBTAINED</th>
            <th>Max Marks</th>
            <th>Pub Dt</th>
            <th>Mode</th>
            <th>PPR</th>
        </tr>
        <tr *ngFor="let student of gradeCards">
            <td>{{student.studentDetails.title}}</td>
            <td>{{student.studentDetails.examYear | date:'yyyy'}}</td>
            <td>{{student.studentDetails.examSession}}</td>
            <td>{{student.studentDetails.name}}</td>
            <td>{{student.studentDetails.discipline}}</td>
            <td>{{student.studentDetails.classRollNumber}}</td>
            <td>{{student.studentDetails.examinationRollNumber}}</td>
            <td>{{student.studentDetails.registrationNumber}}</td>
            <td>{{student.studentDetails.registrationYear}}</td>
            <td></td>
            <!-- <ng-container *ngFor="let course of student.semesterDetails.courses"> -->
            <ng-container *ngFor="let paper of config.tspaperCount; index as i">
                <ng-container *ngIf="student.semesterDetails.courses[i]; else blnktd">
                    <td>{{student.semesterDetails.courses[i].paper.code}}</td>
                    <td>{{student.semesterDetails.courses[i].paper.name}}</td>
                    <td>{{student.semesterDetails.courses[i].grade}}</td>
                    <td>{{student.semesterDetails.courses[i].paper.credit}}</td>
                </ng-container>
            </ng-container>
            <!-- </ng-container> -->
            <td>{{student.studentDetails.totPaperCredit}}</td>

            <td>
                <ng-container *ngFor="let avg of student.semesterDetails.sgpa.typeWiseAverage">
                    <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                </ng-container>
            </td>
            <td>
                <ng-container *ngFor="let avg of student.semesterDetails.sgpa.typeWiseAverage">
                    <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                </ng-container>
            </td>
            <td>{{student.semesterDetails.sgpa.overall}}</td>
            <td>
                <ng-container *ngFor="let avg of student.studentDetails.cgpa.typeWiseAverage">
                    <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                </ng-container>
            </td>
            <td>
                <ng-container *ngFor="let avg of student.studentDetails.cgpa.typeWiseAverage">
                    <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                </ng-container>
            </td>
            <td>{{student.studentDetails.cgpa.overall}}</td>
            <td>{{student.semesterDetails.resultOverallRemarks}}</td>
            <td>{{student.studentDetails.unqualified? 'F' : 'P'}}</td>
            <td>{{student.studentDetails.fullMajorMarksObtain}}</td>
            <td>{{student.studentDetails.fullMajorMarks}}</td>
            <td>{{student.studentDetails.totGenedMarksObtained}}</td>
            <td>{{student.studentDetails.totGenedMarks}}</td>
            <td>{{student.studentDetails.totalMarksObtain}}</td>
            <td>{{student.studentDetails.totalMarks}}</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </table>
</div>
<ng-template #blnktd>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</ng-template>

<table id="exportStudents" border="1" class="d-none">
    <tr>
        <td>Reg.No</td>
        <td>Reg.Year</td>
        <td>Batch</td>
        <td>CRN</td>
        <td>ERN</td>
        <!-- <td>Current Sem</td> -->
        <td>Name</td>
        <td>Address</td>
        <td>Email</td>
        <td>Mobile</td>
        <td>Caste</td>
        <td>Gender</td>
        <td>DOB</td>
        <td>AadharNumber</td>
        <td>Nad Id</td>
        <td>Parent Name</td>
    </tr>
    <tr *ngFor="let student of exportsStudents">
        <td>{{student.registrationNumber}}</td>
        <td>{{student.registrationYear}}</td>
        <td>{{student.batchStartYear}}</td>
        <td>{{student.classRollNumber}}</td>
        <td>{{student.examinationRollNumber}}</td>
        <!-- <td>{{student.currentSemester}}</td> -->
        <td>{{student.name}}</td>
        <td>{{student.address}}</td>
        <td>{{student.email}}</td>
        <td>{{student.mobile}}</td>
        <td>{{student.caste}}</td>
        <td>{{student.gender}}</td>
        <td>{{student.dateOfBirth}}</td>
        <td>{{student.aadharNumber}}</td>
        <td>{{student.nadId}}</td>
        <td>{{student.parentName}}</td>
    </tr>
</table>

<div class="d-none">
    <table id="studentConsolidateReport" border="1">
        <tr>
            <th>EXR NO.</th>
            <th>NAME</th>
            <th>REG NO</th>
            <th>CLR NO</th>
            <th>MAJOR SUBJECT</th>
            <ng-container *ngFor="let semester of programWiseSemester; let k = index">
                <ng-container *ngFor="let paper of config.tspaperCount;">
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                </ng-container>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
            </ng-container>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <ng-container *ngFor="let semester of programWiseSemester;">
                <ng-container *ngFor="let paper of config.tspaperCount;">
                    <th>PAPER{{paper}}</th>
                    <th>PAPER{{paper}}NAME</th>
                    <th>Sem</th>
                    <th>IA</th>
                    <th>TM</th>
                    <th>TOT</th>
                    <th>GRD</th>
                    <th>CREDIT</th>
                </ng-container>
                <th>SGPA MAJOR</th>
                <th>TOTAL MAJOR CREDIT</th>
                <th>SGPA GEN ED</th>
                <th>TOTAL GEN ED CREDIT</th>
                <th>TOTAL CREDIT</th>
                <th>OVERALL SGPA</th>
                <th>REMARKS</th>
                <th>GRADE CARD NO</th>
                <th>DATE OF PUBLICATION</th>
            </ng-container>
            <th>TOTAL MAJOR MARKS OBTAINED</th>
            <th>TOTAL GEND MARKS OBTAINED</th>

            <th>TOTAL MARKS OBTAINED</th>
            <th>TOTAL MAJOR MARKS</th>
            <th>TOTAL MARKS</th>
            <th>TOTAL GEND MARKS</th>

            <th>TOTAL CREDIT MAJOR</th>
            <th>MAJOR CGPA</th>
            <th>TOTAL CREDIT GEN ED</th>
            <th>GEN ED CGPA</th>
            <th>OVERALL CREDIT</th>
            <th>OVERALL CGPA</th>
            <th>Remarks</th>
        </tr>
        <ng-container *ngFor="let student of consolidateStudentsResult">
            <tr>
                <td>{{student.examinationRollNumber}}</td>
                <td>{{student.name}}</td>
                <td>{{student.registrationNumber}}</td>
                <td>{{student.classRollNumber}}</td>
                <td>{{student.discipline}}</td>
                <ng-container *ngFor="let semester of programWiseSemester;index as s;">
                    <ng-container *ngIf="student.semestersDetail[s]; else conblanksemtd">
                        <ng-container *ngFor="let paper of config.tspaperCount; index as i">
                            <ng-container *ngIf="student.semestersDetail[s].courses[i]; else conblanktd">
                                <td>{{student.semestersDetail[s].courses[i].paper.code}}</td>
                                <td>{{student.semestersDetail[s].courses[i].paper.name}}</td>
                                <td>{{student.semestersDetail[s].courses[i].endTermMarksObtained}}</td>
                                <td>{{student.semestersDetail[s].courses[i].midTermMarksObtained}}</td>
                                <td>{{student.semestersDetail[s].courses[i].graceMarksObtained}}</td>
                                <td>{{student.semestersDetail[s].courses[i].totalMarksObtained}}</td>
                                <td>{{student.semestersDetail[s].courses[i].grade}}</td>
                                <td>{{student.semestersDetail[s].courses[i].paper.credit}}</td>
                            </ng-container>
                        </ng-container>
                        <td>
                            <ng-container *ngFor="let avg of student.semestersDetail[s].sgpa.typeWiseAverage">
                                <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                            </ng-container>
                        </td>
                        <td>{{student.semestersDetail[s].totSgpaMajorCredit}}</td>
                        <td>
                            <ng-container *ngFor="let avg of student.semestersDetail[s].sgpa.typeWiseAverage">
                                <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                            </ng-container>
                        </td>
                        <td>{{student.semestersDetail[s].totSgpaGenedCredit}}</td>
                        <td>{{student.semestersDetail[s].totSgpaCredit}}</td>
                        <td>{{student.semestersDetail[s].sgpa.overall}}</td>
                        <td>{{student.semestersDetail[s].resultOverallRemarks}}</td>
                        <td>GRADE CARD NO</td>
                        <td></td>
                    </ng-container>
                </ng-container>
                <td>{{student.cgpa.totCgpaMajorMarksObtained}}</td>
                <td>{{student.cgpa.totCgpaGenedMarksObtained}}</td>

                <td>{{student.cgpa.totMarksObtained}}</td>
                <td>{{student.cgpa.totCgpaMajorMarks}}</td>
                <td>{{student.cgpa.totMarks}}</td>
                <td>{{student.cgpa.totGNDMarks}}</td>
                <td>{{student.cgpa.totCgpaMajorCredit}}</td>
                <td>
                    <ng-container *ngFor="let avg of student.cgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>{{student.cgpa.totCgpaGenedCredit}}</td>
                <td>
                    <ng-container *ngFor="let avg of student.cgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>{{student.cgpa.totCredit}}</td>
                <td>{{student.cgpa.overall}}</td>
                <td>{{student.remarks}}</td>
            </tr>
        </ng-container>
    </table>
</div>
<ng-template #conblanktd>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</ng-template>

<ng-template #staticblank>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</ng-template>
<ng-template #conblanksemtd>
    <ng-container *ngFor="let paper of config.tspaperCount; index as i">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </ng-container>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</ng-template>

<ng-template #conblankstatic>
    <ng-container *ngFor="let paper of config.statisticCount; index as i">
        <td></td>
        <td></td>

    </ng-container>

</ng-template>

<ng-template #conblanksemtrans>
    <ng-container *ngFor="let paper of config.tspaperCount; index as i">
        <td></td>
        <td></td>
        <td></td>
        <td></td>

    </ng-container>
    <td></td>
    <td></td>
    <td></td>

</ng-template>

<ng-template #conblanksemtd2>
    <td></td>
    <td></td>
    <td></td>
</ng-template>

<ng-template #conblanktranscript>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</ng-template>

<ng-template #abcd>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>

    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>

    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>

    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>

    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>

    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>

    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>


    <td></td>
</ng-template>

<div class="d-none">
    <table id="statisticsReport" border="1">
        <tr>
            <th>Department</th>
            <th>Course</th>
            <ng-container *ngFor="let semester of programWiseSemester; let k = index">
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
            </ng-container>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <th></th>
            <th></th>

            <ng-container *ngFor="let semester of programWiseSemester;">
                <th>Number of Students Appeared</th>
                <th>Number of Students Passed</th>
            </ng-container>

        </tr>
        <ng-container *ngFor="let student of statisticsData">
            <tr>
                <td>{{depf(student.program.name)}}</td>
                <td>{{agef(student.program.name)}}</td>
                <td>{{student.studentsAppearedCount.I }}</td>
                <td>{{student.studentsPassCount.I }}</td>

                <td>{{student.studentsAppearedCount.II }}</td>
                <td>{{student.studentsPassCount.II }}</td>

                <td>{{student.studentsAppearedCount.III }}</td>
                <td>{{student.studentsPassCount.III }}</td>

                <td>{{student.studentsAppearedCount.IV }}</td>
                <td>{{student.studentsPassCount.IV }}</td>

                <td>{{student.studentsAppearedCount.V }}</td>
                <td>{{student.studentsPassCount.V }}</td>

                <td>{{student.studentsAppearedCount.VI }}</td>
                <td>{{student.studentsPassCount.VI }}</td>

            </tr>
        </ng-container>
    </table>
</div>

<div class="d-none">
    <table id="dataConvocation" style="border: 1px;">
        <tr>
            <th>EXM YR</th>
            <th>Session</th>
            <th>REG</th>
            <th>YR of REG</th>
            <th>Stream</th>
            <th>Dept</th>
            <th>CRN</th>
            <th>REGN No</th>
            <th>ERN </th>
            <th>Name</th>
            <ng-container *ngFor="let semester of programWiseSemester; let k = index">
                <!-- <ng-container *ngFor="let paper of config.tspaperCount;">
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                </ng-container> -->
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <!-- <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th> -->
            </ng-container>
            <!-- <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th> -->
        </tr>
        <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <ng-container *ngFor="let semester of programWiseSemester;">
                <!-- <ng-container *ngFor="let paper of config.tspaperCount;">
                    <th>PAPER{{paper}}</th>
                    <th>PAPER{{paper}}NAME</th>
                    <th>Sem</th>
                    <th>IA</th>
                    <th>TM</th>
                    <th>TOT</th>
                    <th>GRD</th>
                    <th>CREDIT</th>
                </ng-container> -->
                <th>SGPA-MJR</th>
                <th>SGPA GenEd</th>
                <!-- <th>TOTAL MAJOR CREDIT</th>
                
                <th>TOTAL GEN ED CREDIT</th>
                <th>TOTAL CREDIT</th> -->
                <th>SGPA-Overall</th>
                <!-- <th>REMARKS</th> -->
                <!-- <th>GRADE CARD NO</th> -->
                <!-- <th>DATE OF PUBLICATION</th> -->
            </ng-container>
            <th>TOTAL MAJOR MARKS OBTAINED</th>
            <th>Max MJR MARKS </th>
            <th>TOTAL GENED MARKS OBTAINED </th>
            <th>Max GENED MARKS </th>
            <th>TOTAL MARKS OBTAINED</th>
            <th> Max MARKS</th>
            <th>CGPA-MJR</th>
            <th>CGPA-GENED </th>
            <th>CGPA-Overall</th>
            <th>Convocation Date</th>
            <th>GC Serial No</th>
            <th>Certificate No</th>
            <!-- <th>OVERALL CGPA</th>
            <th>Remarks</th> -->
        </tr>
        <ng-container *ngFor="let student of convocationStudentsResult">
            <tr>
                <td>{{student.examYear | date:'yyyy'}}</td>
                <td>{{student.session}}</td>
                <td>{{student.registrationNumber}}</td>
                <td>{{student.registrationYear}}</td>

                <td>{{student.stream}}</td>
                <td>{{student.discipline}}</td>
                <td>{{student.classRollNumber}}</td>
                <td>{{student.registrationNumber}}</td>
                <td>{{student.examinationRollNumber}}</td>
                <td>{{student.name}}</td>



                <ng-container *ngFor="let semester of programWiseSemester;index as s;">
                    <ng-container *ngIf="student.semestersDetail[s]; else conblanksemtd2">
                        <!-- <ng-container *ngFor="let paper of config.tspaperCount; index as i">
                            <ng-container *ngIf="student.semestersDetail[s].courses[i]; else conblanktd">
                                <td>{{student.semestersDetail[s].courses[i].paper.code}}</td>
                                <td>{{student.semestersDetail[s].courses[i].paper.name}}</td>
                                <td>{{student.semestersDetail[s].courses[i].endTermMarksObtained}}</td>
                                <td>{{student.semestersDetail[s].courses[i].midTermMarksObtained}}</td>
                                <td>{{student.semestersDetail[s].courses[i].graceMarksObtained}}</td>
                                <td>{{student.semestersDetail[s].courses[i].totalMarksObtained}}</td>
                                <td>{{student.semestersDetail[s].courses[i].grade}}</td>
                                <td>{{student.semestersDetail[s].courses[i].paper.credit}}</td>
                            </ng-container>
                        </ng-container> -->
                        <td>
                            <ng-container *ngFor="let avg of student.semestersDetail[s].sgpa.typeWiseAverage">
                                <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                            </ng-container>
                        </td>
                        <!-- <td>{{student.semestersDetail[s].totSgpaMajorCredit}}</td> -->
                        <td>
                            <ng-container *ngFor="let avg of student.semestersDetail[s].sgpa.typeWiseAverage">
                                <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                            </ng-container>
                        </td>


                        <!-- <td>{{student.semestersDetail[s].totSgpaGenedCredit}}</td>
                        <td>{{student.semestersDetail[s].totSgpaCredit}}</td> -->
                        <td>{{student.semestersDetail[s].sgpa.overall}}</td>
                        <!-- <td>{{student.semestersDetail[s].resultOverallRemarks}}</td> -->
                        <!-- <td>GRADE CARD NO</td> -->
                        <!-- <td></td> -->
                    </ng-container>
                </ng-container>


                <td>{{student.fullMajorMarksObtain}}</td>
                <td>{{student.fullMajorMarks}}</td>

                <td>{{student.totalGndMarksObtained}}</td>
                <td>{{student.totalGndMarks}}</td>


                <td>{{student.totalMarksObtain}}</td>
                <td>{{student.totalMarks}}</td>
                <td>
                    <ng-container *ngFor="let avg of student.cgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngFor="let avg of student.cgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>{{student.cgpa.overall}}</td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </ng-container>
    </table>
</div>


<div class="d-none">
    <table id="studentBacklogReport" border="1">
        <tr>
            <th>EXR NO.</th>
            <th>NAME</th>
            <th>REG NO</th>
            <th>CLR NO</th>
            <th>MAJOR SUBJECT</th>
            <ng-container *ngFor="let semester of programWiseSemester; let k = index">
                <ng-container *ngFor="let paper of config.tspaperCount;">
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                </ng-container>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
            </ng-container>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <ng-container *ngFor="let semester of programWiseSemester;">
                <ng-container *ngFor="let paper of config.tspaperCount;">
                    <th>PAPER{{paper}}</th>
                    <th>PAPER{{paper}}NAME</th>
                    <th>Sem</th>
                    <th>IA</th>
                    <th>TM</th>
                    <th>TOT</th>
                    <th>GRD</th>
                    <th>CREDIT</th>
                </ng-container>
                <th>SGPA MAJOR</th>
                <th>TOTAL MAJOR CREDIT</th>
                <th>SGPA GEN ED</th>
                <th>TOTAL GEN ED CREDIT</th>
                <th>TOTAL CREDIT</th>
                <th>OVERALL SGPA</th>
                <th>REMARKS</th>
                <th>GRADE CARD NO</th>
                <th>DATE OF PUBLICATION</th>
            </ng-container>
            <th>TOTAL MAJOR MARKS OBTAINED</th>
            <th>TOTAL GEND MARKS OBTAINED</th>
            <th>TOTAL CREDIT MAJOR</th>
            <th>MAJOR CGPA</th>
            <th>TOTAL CREDIT GEN ED</th>
            <th>GEN ED CGPA</th>
            <th>OVERALL CGPA</th>
            <th>Remarks</th>
        </tr>
        <ng-container *ngFor="let student of backlogStudentsResult">
            <tr>
                <td>{{student.examinationRollNumber}}</td>
                <td>{{student.name}}</td>
                <td>{{student.registrationNumber}}</td>
                <td>{{student.classRollNumber}}</td>
                <td>{{student.discipline}}</td>
                <ng-container *ngFor="let semester of programWiseSemester;index as s;">
                    <ng-container *ngIf="student.backlogSemesterDetails[s]; else conblanksemtd">
                        <ng-container *ngFor="let paper of config.tspaperCount; index as i">
                            <ng-container *ngIf="student.backlogSemesterDetails[s].backCourses[i]; else conblanktd">
                                <!-- <ng-container *ngIf="s+1 == student.semestersDetail[s].semester; then proTem else abcd">
                                    <ng-template #proTem> -->
                                <td>{{student.backlogSemesterDetails[s].backCourses[i].paper.code}}</td>
                                <td>{{student.backlogSemesterDetails[s].backCourses[i].paper.name}}</td>
                                <td>{{student.backlogSemesterDetails[s].backCourses[i].endTermMarksObtained}}</td>
                                <td>{{student.backlogSemesterDetails[s].backCourses[i].midTermMarksObtained}}</td>
                                <td>{{student.backlogSemesterDetails[s].backCourses[i].graceMarksObtained}}</td>
                                <td>{{student.backlogSemesterDetails[s].backCourses[i].totalMarksObtained}}</td>
                                <td>{{student.backlogSemesterDetails[s].backCourses[i].grade}}</td>
                                <td>{{student.backlogSemesterDetails[s].backCourses[i].paper.credit}}</td>
                                <!-- </ng-template>
                            </ng-container> -->
                            </ng-container>
                        </ng-container>
                        <td>
                            <ng-container *ngFor="let avg of student.backlogSemesterDetails[s].sgpa.typeWiseAverage">
                                <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                            </ng-container>
                        </td>
                        <td>{{student.backlogSemesterDetails[s].totSgpaMajorCredit}}</td>
                        <td>
                            <ng-container *ngFor="let avg of student.backlogSemesterDetails[s].sgpa.typeWiseAverage">
                                <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                            </ng-container>
                        </td>
                        <td>{{student.backlogSemesterDetails[s].totSgpaGenedCredit}}</td>
                        <td>{{student.backlogSemesterDetails[s].totSgpaCredit}}</td>
                        <td>{{student.backlogSemesterDetails[s].sgpa.overall}}</td>
                        <td>{{student.backlogSemesterDetails[s].resultOverallRemarks}}</td>
                        <td>GRADE CARD NO</td>
                        <td></td>
                    </ng-container>
                </ng-container>
                <td>{{student.cgpa.totCgpaMajorMarksObtained}}</td>
                <td>{{student.cgpa.totCgpaGenedMarksObtained}}</td>
                <td>{{student.cgpa.totCgpaMajorCredit}}</td>
                <td>
                    <ng-container *ngFor="let avg of student.cgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>{{student.cgpa.totCgpaGenedCredit}}</td>
                <td>
                    <ng-container *ngFor="let avg of student.cgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>{{student.cgpa.overall}}</td>
                <td>{{student.remarks}}</td>
            </tr>
        </ng-container>
    </table>
</div>

<div class="d-none">
    <table id="transcriptReport" border="1">
        <tr>
            <th>Name of the Candidate</th>
            <th>Course of Study</th>
            <th>Subject</th>
            <th>REGISTRATION NO</th>
            <th>REGISTRATION Year</th>
            <th>ERN</th>
            <ng-container *ngFor="let semester of programWiseSemester; let k = index">
                <ng-container *ngFor="let paper of config.tspaperCount;">
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                    <th>{{commonMethodsSemName.semName((k+1))}}</th>
                </ng-container>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
                <th>{{commonMethodsSemName.semName((k+1))}}</th>
            </ng-container>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
        <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <ng-container *ngFor="let semester of programWiseSemester;">
                <ng-container *ngFor="let paper of config.tspaperCount;">
                    <th>Paper{{paper}}Code</th>
                    <th>Paper{{paper}}Name</th>
                    <th>Paper{{paper}}Credit</th>
                    <th>Paper{{paper}}Grd</th>
                </ng-container>
                <th>SGPA MJR</th>
                <th>SGPA GENED</th>
                <th>SGPA OVERALL</th>
            </ng-container>
            <th>CGPA MJR</th>
            <th>CGPA GENED </th>
            <th>CGPA OVERALL</th>
        </tr>
        <ng-container *ngFor="let student of transcriptResult">
            <tr>
                <td>{{student.name}}</td>
                <td>{{student.courseStudy}}</td>
                <td>{{student.discipline}}</td>
                <td>{{student.registrationNumber}}</td>
                <td>{{student.registrationYear}}</td>
                <td>{{student.examinationRollNumber}}</td>

                <ng-container *ngFor="let semester of programWiseSemester;index as s;">
                    <ng-container *ngIf="student.semestersDetail[s]; else conblanksemtrans">
                        <ng-container *ngFor="let paper of config.tspaperCount; index as i">
                            <ng-container *ngIf="student.semestersDetail[s].courses[i]; else conblanktranscript">
                                <td>{{student.semestersDetail[s].courses[i].paper.code}}</td>
                                <td>{{student.semestersDetail[s].courses[i].paper.name}}</td>
                                <td>{{student.semestersDetail[s].courses[i].paper.credit}}</td>
                                <td>{{student.semestersDetail[s].courses[i].grade}}</td>
                            </ng-container>
                        </ng-container>
                        <td>
                            <ng-container *ngFor="let avg of student.semestersDetail[s].sgpa.typeWiseAverage">
                                <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngFor="let avg of student.semestersDetail[s].sgpa.typeWiseAverage">
                                <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                            </ng-container>
                        </td>
                        <td>{{student.semestersDetail[s].sgpa.overall}}</td>
                    </ng-container>
                </ng-container>
                <td>
                    <ng-container *ngFor="let avg of student.cgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==CBCS_MAJOR || avg.key==2">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>
                    <ng-container *ngFor="let avg of student.cgpa.typeWiseAverage">
                        <ng-container *ngIf="avg.key==4">{{avg.value}}</ng-container>
                    </ng-container>
                </td>
                <td>{{student.cgpa.overall}}</td>
            </tr>
        </ng-container>
    </table>
</div>

<div class="d-none">
    <table id="studentNADReport" border="1">
        <tr>
            <td>Explaination</td>
            <td>AI Course Code</td>
            <td>Program Name</td>

            <td>Award Event ID</td>
            <td>Certificate Upload Mode</td>
            <td>Type of data</td>
            <td>Year of Exam</td>
            <td>Month of Exam</td>

            <td>Registration Number</td>
            <td>Code of Student</td>
            <td>AADHAAR Id</td>
            <td>NADID</td>
            <td>Student's Name</td>
            <td>Student's DOB</td>
            <td>Student's Gender</td>
            <td>Student's Category</td>
            <td>Student's Nationality</td>
            <td>PWD</td>
            <td>Student's Email Id</td>
            <td>Student's Mobile No</td>
            <td>Subject Name</td>
            <td>Thesis</td>
            <td>Result Description_NAD</td>
            <td>Certificate Sl. No</td>

            <td>Grade / CGPA / Class / Division</td>
            <td>Convocation Date</td>
            <td>Name of Award Image File</td>

        </tr>
        <tr *ngFor="let student of nadStudentsResult">
            <td></td>
            <td>{{student.courseName}}</td>
            <td>{{student.programTitle}}</td>

            <td>0_Onetime_ConvocationCertificate</td>
            <td>C</td>
            <td>O</td>
            <td>{{student.examYear }}</td>
            <td>{{student.session | date:'MMM'}}</td>

            <td>{{student.registrationNumber}}</td>
            <td>{{student.examinationRollNumber}}</td>
            <td>{{student.aadharNumber}}</td>
            <td>{{student.nadId}}</td>
            <td>{{student.name}}</td>
            <td>{{student.dateOfBirth}}</td>
            <td>{{student.gender}}</td>
            <td>{{student.caste}}</td>
            <td></td>
            <td></td>

            <td>{{student.email}}</td>
            <td>{{student.mobile}}</td>
            <td>{{student.discipline}}</td>
            <td></td>
            <td>{{student.remarks}}</td>
            <td></td>
            <td>{{student.cgpa.overall}}</td>
            <td></td>
            <td></td>

        </tr>
    </table>

</div>

<div class="modal fade" id="updateSem" tabindex="-1" role="dialog" aria-labelledby="m1" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="m1">Update Semester On Date</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="text-muted col-sm-2">{{ 'Date' | translate }}</div>
                    <div class="col-sm-10"><input type="text" class="w-100 form-control" [(ngModel)]="semDate"
                            [owlDateTime]="date" [owlDateTimeTrigger]="date" />
                        <owl-date-time [pickerType]="'calendar'" #date></owl-date-time>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" (click)="updateStudentCurrentSemester()" class="btn btn-primary">Update</button>
            </div>
        </div>
    </div>
</div>