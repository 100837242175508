import { Component, OnInit } from '@angular/core';
import { config } from '../../app/config';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethods } from '../common-methods';
import { OrganizationService } from '../api-client/organization.service';
import { EmployeeService } from '../api-client/employee.service';
import { AcademiaService } from '../api-client/academia.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string = null;
  step1: boolean = true;
  step2: boolean = false;
  password: string;
  doj: string;
  returnUrl: string;
  constructor(
    private _router: Router,
    private _uiService: UiService,
    private _toster:ToastrService,
    private _employeeService: EmployeeService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this._uiService.showHeader.next(false);
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/home';
  }

  next(){
    if (CommonMethods.validateEmail(this.email)==false) {
      this._toster.error("Invalid Email.");
      return false;
    }
    var data = { "email": this.email };
    this._employeeService.emailExists(data).subscribe((result: any) => {
      if (result) {
        this.step1 = false;
        this.step2 = true;
      } else {
        this._toster.error("Email not exist in database.");
        return false;
      }
    });
  }

  auth() { 
    if(this.password=="" || this.password==null){
      this._toster.error("Password can not be blank.");
        return false;
    }
    if(this.doj=="" || this.doj==null){
      this._toster.error("Date of join can not be blank.");
        return false;
    }
    this.doj = this.dateConvert(this.doj);
    if (this.email) {
      var data = { email: this.email, password: this.password, joiningDate: this.doj };
      this._employeeService.auth(data).subscribe((result: any) => {
        if (result.token) {
          // localStorage.setItem("token", result.token);
          sessionStorage.setItem("token", result.token);
          this._toster.success("Login Success!!");
          this._uiService.showHeader.next(true);
          this._academiaService.loadInitialData();
          this._router.navigate([this.returnUrl], { replaceUrl: true });
          return false;
        }else{
          this._toster.error("Login failure");
          return false;
        }
      });
    } else {
      this._toster.error("Email Id not match.");
      return false;
    }    
  }

  validateCredentials(data:any){
    this._employeeService.auth(data).subscribe((result:any)=>{

    })
  }

  reset(){
    this._router.navigate(["/reset-password"]);
    return false;
  }


  dateConvert(str:any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

}
