<!--navbar end-->
<div class="d-flex flex-wrap m-5">
    <div class="card p-3 m-3 rounded text-80 font-weight-500 z-depth-1 text-center" style="width: 10%;" *ngFor="let item of menuItems">
        <div class="text-center"><em class="fa {{item.icon}}"></em></div>
        <a [routerLink]="item.url">{{item.title | translate}}</a>
    </div>
</div>
<div class="flex-wrap m-5">
    <h5 class="text-center text-90 font-weight-600">Steps Befor Examination</h5>
    <ul class="list-group">
        <li class="list-group-item">1) Import students for 1st semester</li>
        <li class="list-group-item">2) Create session for odd semester, not required for even</li>
        <li class="list-group-item">3) Copy papers from previous session</li>
        <li class="list-group-item">4) Check for missing papers & add missing papers</li>
        <li class="list-group-item">5) Import mapping file for paper against student</li>
        <li class="list-group-item">6) Create examination with respective session</li>
        <li class="list-group-item">7) Configure paper
            <ul class="list-group">
                <li style="border: none; font-size: 12px;" class="list-group-item">a) Session specific papername, full marks, credit etc</li>
                <li style="border: none; font-size: 12px;" class="list-group-item">b) Import examiner & reviewer </li>
                <li style="border: none; font-size: 12px;" class="list-group-item">c) Review paper student assign</li>
            </ul>
        </li>
      </ul>
</div>


<!--main-menu end-->