<ng-template #na><span> NA</span></ng-template>
<div class="student_details_section d-flex">
    <div class="left_bg w-25">
        <app-student-summary [displayDetailIcon]="false"></app-student-summary>
    </div>
    <div class="p-4 w-75 flex-grow-1">
        <div *ngIf="studentProgram" class="font-weight-600 p-2">{{studentProgram.name}}</div>

        <div class="form-row mb-4">
              <div class="col"><input type="text" class="form-control" [(ngModel)]="regno"
                    placeholder="Registration no."></div>
              <div class="col"><input type="text" class="form-control" [(ngModel)]="batch"
                    placeholder="Batch start year"></div>
              <div class="col"></div>
              <div class="col text-right">
                    <button (click)="switchStudent()"
                    class="btn btn-primary font-weight-500 text-80 p-1 pr-2 pl-2">Switch Student</button>
                  </div>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#student_latest_marks" role="tab"
                    aria-controls="student_latest_marks" aria-selected="true">{{ 'Student Latest Marks' | translate
                    }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="students-tab" data-toggle="tab" href="#student_history_marks" role="tab"
                    aria-controls="student_history_marks" aria-selected="false">{{ 'Student History Marks' | translate
                    }} </a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="student_latest_marks" role="tabpanel"
                aria-labelledby="structure-tab">
                <ng-template *ngIf="!student">
                    <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>{{ 'No record found' | translate }}</strong></p>
                </ng-template>
                <ng-container *ngIf="student">
                    <div class="d-flex justify-content-end mb-1">
                        <div class="col-sm-3 mt-1">
                            <select class="form-control " [(ngModel)]="evaluatorType">
                                <option value="0">Select modification type</option>
                                <option value="8">Correction</option>
                                <option value="6">Backlog</option>
                                <option value="4">Review</option>
                            </select>
                        </div>
                        <div class="col-sm-3 mt-1">
                            <select class="form-control " [(ngModel)]="examinationId">
                                <option value="">Select examination</option>
                                <option *ngFor="let exam of examinations" [value]="exam.id">{{exam.name}}</option>
                            </select>
                        </div>
                        <div class="reply_cont d-flex align-items-ceter"><a
                                (click)="export('csv','studentLatestMarks','latestMarks')"
                                class="ml-2 mr-2 font-weight-500"><em
                                    class="fa fa-file-excel-o p-2 rounded-circle"></em><span>Export CSV</span></a><a
                                (click)="export('pdf','studentLatestMarks','latestMarks')"
                                class="ml-2 mr-2 font-weight-500"><em aria-hidden="true"
                                    class="fa fa-file-pdf-o p-2 rounded-circle"></em><span>Export
                                    PDF</span></a></div>
                    </div>

                    <div *ngIf="student.cgpa.overall" class="d-flex p-2 white justify-content-between">
                        <div class="font-weight-600">
                            <p class="m-0">
                                <span>{{ 'CGPA Overall' | translate }}:</span>
                                {{student.cgpa.overall | number:'.2'}}
                            </p>
                        </div>
                        <!-- <div class="mr-4">
                                    <span class="h5">Percentile:</span>
                                    <p *ngIf="student.percentile;else na" class="m-0 h6 pl-3 pr-3 pt-1 pb-1">{{student.percentile}}</p>
                                </div> -->
                        <div *ngFor="let cgpa of student.cgpa.typeWiseAverage">
                            <span class="badge text-white mr-2 paper_{{env.paperType[cgpa.key]}}">
                                {{env.paperType[cgpa.key]}}
                            </span>
                            <ng-container><span class="font-weight-600">{{cgpa.value | number:'.2'}}</span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="row grey lighten-2">
                        <div class="col-sm-3"></div>
                        <div class="col-sm-3">
                            <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-600 text-center"
                                title="Mid Term Marks Obtained">
                                {{ 'Allocated Marks' | translate }}</p>
                        </div>
                        <div class="col-sm-3">
                            <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-600 text-center"
                                title="Mid Term Marks Obtained">
                                {{ 'Obtained Marks' | translate }}</p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-600"></p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-600"></p>
                        </div>
                    </div>
                    <div class="row grey lighten-2 mt-1">
                        <div class="col-sm-3">
                            <p class="m-0 h5 pl-3 pt-1 pb-1 font-weight-500">{{ 'Code' | translate }}</p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Credit' | translate }}</p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center" title="Mid Term Marks Obtained">
                                {{ 'Mid' | translate }}</p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center" title="End Term Marks Obtained">
                                {{ 'End' | translate }}</p>
                        </div>
                        <div class="col-sm-1" title="Full Marks Obtained">
                            <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Total' | translate }}</p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center" title="Mid Term Marks Obtained">
                                {{ 'Mid' | translate }}</p>
                        </div>
                        <div class="col-sm-1">
                            <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center" title="End Term Marks Obtained">
                                {{ 'End' | translate }}</p>
                        </div>
                        <div class="col-sm-1" title="Full Marks Obtained">
                            <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Total' | translate }}</p>
                        </div>
                        <div class="col-sm-2">
                            <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center d-flex justify-content-between">
                                <span>{{ 'Grade' | translate }}</span>
                                <span>{{ 'Grade Point' | translate }}</span>
                            </p>
                        </div>
                    </div>
                    <div *ngFor="let semester of student.semestersDetail">
                        <ng-container *ngIf="semester.key==-1">
                            <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>{{ 'Casual' | translate }}</strong></p>
                        </ng-container>
                        <ng-container *ngIf="semester.key!=-1">
                            <div class="d-flex justify-content-between">
                                <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>{{ 'Semester' | translate }} :
                                        {{semester.semester}} |
                                        {{ 'Remarks' | translate }}:
                                        {{semester.resultOverallRemarks}} <span
                                            *ngIf="semester.resultOverallRemarks == 'P' ">({{getExamName(semester.semester)}})
                                        </span> </strong></p>
                                <p class="m-0 h6 pl-3 pr-3 pt-1 d-flex"><strong>{{ 'SGPA Overall' | translate }} -
                                    </strong>
                                    <span class="ml-1" *ngIf="semester.sgpa.overall;else na">
                                        {{semester.sgpa.overall | number:'.2'}}</span>
                                    <span class="ml-1" *ngFor="let sgpa of semester.sgpa.typeWiseAverage">
                                        <span class="badge text-white mr-2 paper_{{env.paperType[sgpa.key]}}">
                                            {{env.paperType[sgpa.key]}}
                                        </span>
                                        <ng-container>{{sgpa.value | number:'.2'}}
                                        </ng-container>
                                    </span>
                                </p>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let course of semester.courses">
                            <div class="row item-row resultremarks_{{course.resultRemarks}}">
                                <ng-container *ngIf="course.paper.name;else na">
                                    <div class="col-sm-3">
                                        <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1 breakpoint" title="{{course.paper.name}}">
                                            <span
                                                class="badge text-white mr-2 paper_{{env.paperType[course.paper.paperType]}}">
                                                {{env.paperType[course.paper.paperType]}}
                                            </span>
                                            <ng-container *ngIf="course.paper.code;else elseBlock">{{course.paper.code}}
                                            </ng-container>
                                            <ng-template #elseBlock>
                                                <span *ngIf="course.paper.label">{{course.paper.label}}</span>
                                            </ng-template>
                                            <!--  {{course.paper.name  | slice:0:10}} <span
                                                    *ngIf="course.paper.name && course.paper.name.length>10">...</span> -->
                                        </p>
                                    </div>
                                    <div class="col-sm-1">
                                        <p class="m-0 h6 pt-1 pb-1 text-center">{{course.paper.credit}}</p>
                                    </div>
                                    <div class="col-sm-1">
                                        <p class="m-0 h6 pt-1 pb-1 text-center">{{course.paper.midTermAllocatedMarks}}
                                        </p>
                                    </div>
                                    <div class="col-sm-1">
                                        <p class="m-0 h6 pt-1 pb-1 text-center">{{course.paper.endTermAllocatedMarks}}
                                        </p>
                                    </div>
                                    <div class="col-sm-1">
                                        <p class="m-0 h6 pt-1 pb-1 text-center">{{course.paper.fullMarks}}</p>
                                    </div>
                                    <div class="col-sm-1">
                                        <p class="m-0 h6 pt-1 pb-1 text-center">
                                            <span *ngIf="!course.mid"
                                                (click)="showEditLatest(semester.semester,course.paper.code,'mid')">{{course.midTermMarksObtained
                                                | number:'.2'}}</span>
                                            <span class="d-flex justify-content-between" *ngIf="course.mid"><input
                                                    *ngIf="course.mid" [(ngModel)]="course.midTermMarksObtained"
                                                    style="width: 35px;" type="text"><em
                                                    (click)="modifyMarksLatest(semester.semester,course.paper.code,'mid')"
                                                    class="fa fa-save"></em></span>
                                        </p>
                                    </div>
                                    <div class="col-sm-1">
                                        <p class="m-0 h6 pt-1 pb-1 text-center">
                                            <span *ngIf="!course.end"
                                                (click)="showEditLatest(semester.semester,course.paper.code,'end')">{{course.endTermMarksObtained
                                                | number:'.2'}}</span>
                                            <span class="d-flex justify-content-between" *ngIf="course.end"><input
                                                    style="width: 35px;" type="text"
                                                    [(ngModel)]="course.endTermMarksObtained"><em
                                                    (click)="modifyMarksLatest(semester.semester,course.paper.code,'end')"
                                                    class="fa fa-save"></em></span>
                                        </p>
                                    </div>
                                    <div class="col-sm-1">
                                        <p class="m-0 h6 pt-1 pb-1 text-center">
                                            {{course.totalMarksObtained | number:'.2'}} <em
                                                class="fa fa-history text-primary" title="History"
                                                aria-hidden="true"></em></p>
                                    </div>
                                    <div class="col-sm-2">
                                        <p *ngIf="course.grade"
                                            class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center d-flex justify-content-between">
                                            <span>{{course.grade}}</span>
                                            <span>{{course.gradePoint}}</span>
                                        </p>
                                        <p *ngIf="!course.grade" class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center">
                                            {{ 'NA' | translate }}</p>
                                    </div>
                                </ng-container>
                            </div>

                            <!-- <div class="modal fade modal-position" id="modalClick" tabindex="-1" role="dialog" attr.aria-labelledby="reactivateModal{{course.id}}" aria-hidden="true" >
                                <div class="modal-dialog">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeReactivateModal{{course.id}}">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body modal-conten-part">
                                      <div class="modal-headin-part">
                                        <h2>Confirmation</h2>
                                        <p> Are you sure you want to activate ? </p>
                                      </div>
                                      <div class="popup-body-content-part">
                                        <div class="popup-in-content-right">
                                          <button type="button" class="btn btn-danger can-btn" data-dismiss="modal" aria-label="Close">
                                            Cancel
                                          </button>
                                          <button  type="button" class="btn btn-primary">Yes, Activate it!</button>
                                        </div>
                                        <div class="clearfix"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div> -->
                        </ng-container>
                    </div>
                </ng-container>



                <table id="studentLatestMarks" class="d-none" *ngIf="student">
                    <tr>
                        <th *ngIf="showHeader" colspan="10">{{downloadPdfHeader}}</th>
                    </tr>
                    <tr>
                        <th>{{ 'CGPA' | translate }}: {{student.cgpa.overall | number:'.2'}}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            <ng-container *ngFor="let cgpa of student.cgpa.typeWiseAverage">{{env.paperType[cgpa.key]}}
                                {{cgpa.value | number:'.2'}} </ng-container>
                        </th>
                    </tr>
                    <tr>
                        <th></th>
                        <th>Allocated Marks</th>
                        <th></th>
                        <th></th>
                        <th>Obtained Marks</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>Code</th>
                        <th>Credit</th>
                        <th>Mid</th>
                        <th>End</th>
                        <th>Total</th>
                        <th>Mid</th>
                        <th>End</th>
                        <th>Total</th>
                        <th>Grade</th>
                        <th>Grade Point</th>
                    </tr>
                    <ng-container *ngFor="let semester of student.semestersDetail">
                        <tr>
                            <td *ngIf="semester.key==-1">Casual</td>
                            <td *ngIf="semester.key!=-1">Semester {{semester.semester}} | {{ 'Remarks' | translate }}:
                                {{semester.resultOverallRemarks}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{{ 'SGPA' | translate }} - <ng-container *ngIf="semester.sgpa.overall">
                                    {{semester.sgpa.overall}}</ng-container>
                            </td>
                            <td>
                                <ng-container *ngFor="let sgpa of semester.sgpa.typeWiseAverage">
                                    {{env.paperType[sgpa.key]}} - {{sgpa.value | number:'.2'}} </ng-container>
                            </td>
                        </tr>
                        <ng-container *ngFor="let course of semester.courses">
                            <tr>
                                <td>{{env.paperType[course.paper.paperType]}} <ng-container
                                        *ngIf="course.paper.code;else elseBlck">{{course.paper.code}}
                                    </ng-container>
                                    <ng-template #elseBlck>{{course.paper.label}}</ng-template>
                                </td>
                                <td>{{course.paper.credit}}</td>
                                <td>{{course.paper.midTermAllocatedMarks}}</td>
                                <td>{{course.paper.endTermAllocatedMarks}}</td>
                                <td>{{course.paper.fullMarks}}</td>
                                <td>{{course.midTermMarksObtained | number:'.2'}}</td>
                                <td>{{course.endTermMarksObtained | number:'.2'}}</td>
                                <td>{{course.totalMarksObtained | number:'.2'}}</td>
                                <ng-container *ngIf="course.grade">
                                    <td>{{course.grade}}</td>
                                    <td>{{course.gradePoint}}</td>
                                </ng-container>
                                <ng-container *ngIf="!course.grade">
                                    <td></td>
                                    <td>NA</td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
            <div class="tab-pane fade" id="student_history_marks" role="tabpanel" aria-labelledby="students-tab">
                <ng-template *ngIf="!historyData">
                    <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>{{ 'No record found' | translate }}</strong></p>
                </ng-template>
                <ng-container *ngIf="historyData">
                    <div class="d-flex justify-content-end">
                        <div class="col-sm-3 mt-1">
                            <select class="form-control " [(ngModel)]="evaluatorType">
                                <option value="0">Select modification type</option>
                                <option value="8">Correction</option>
                                <option value="6">Backlog</option>
                                <option value="4">Review</option>
                            </select>
                        </div>
                        <div class="col-sm-3 mt-1">
                            <select class="form-control " [(ngModel)]="examinationId">
                                <option value="">Select examination</option>
                                <option *ngFor="let exam of examinations" [value]="exam.id">{{exam.name}}</option>
                            </select>
                        </div>
                        <div class="reply_cont d-flex align-items-ceter"><a (click)="exportCsv()"
                                class="ml-2 mr-2 font-weight-500"><em
                                    class="fa fa-file-excel-o p-2 rounded-circle"></em><span>Export CSV</span></a><a
                                (click)="export('pdf','exportStudentMarksHistory','historyMarks')"
                                class="ml-2 mr-2 font-weight-500"><em aria-hidden="true"
                                    class="fa fa-file-pdf-o p-2 rounded-circle"></em><span>Export
                                    PDF</span></a></div>
                    </div>
                    <div id="exportStudentMarksHistory">
                        <div *ngIf="showHeader" class="text-center h5">{{downloadPdfHeader}}</div>
                        <div class="row grey lighten-2 m-0 mt-1">
                            <div class="col-sm-2">
                                <p class="m-0 h5 pl-3 pt-1 pb-1 font-weight-500">{{ 'Code' | translate }}</p>
                            </div>
                            <div class="col-sm-2">
                                <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Date' | translate }}</p>
                            </div>
                            <div class="col-sm-1">
                                <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Remarks' | translate }}</p>
                            </div>
                            <div class="col-sm-1">
                                <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">
                                    {{ 'Type' | translate }}</p>
                            </div>

                            <div class="col-sm-1">
                                <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center" title="Mid Term Marks Obtained">
                                    {{ 'Mid' | translate }}</p>
                            </div>
                            <div class="col-sm-1">
                                <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center" title="End Term Marks Obtained">
                                    {{ 'End' | translate }}</p>
                            </div>
                            <div class="col-sm-1" title="Full Marks Obtained">
                                <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Total' | translate }}</p>
                            </div>
                            <div class="col-sm-1">
                                <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Grade' | translate }}</p>
                            </div>
                            <div class="col-sm-2">
                                <p class="m-0 h5 pt-1 pb-1 font-weight-500 text-center">{{ 'Grade Point' | translate }}
                                </p>
                            </div>
                        </div>
                        <div class="w-100 row p-0 m-0" *ngFor="let semester of historyData|keyvalue">
                            <div class="row">
                                <div class="col-sm-12">
                                    <p class="h6 m-0 h6 pl-3 pr-3 pt-1"><strong>Semester: {{semester.key}}</strong></p>
                                </div>
                            </div>
                            <div class="row item-row w-100 p-0 m-0" *ngFor="let student of semester.value.children"
                                [ngClass]="!student.active?'text-danger':''">
                                <div class="col-sm-2">
                                    <p>
                                        <span
                                            class="badge text-white mr-2 paper_{{env.paperType[student.paper.paperType]}}">
                                            {{env.paperType[student.paper.paperType]}}
                                        </span>
                                        <ng-container *ngIf="student.paper.code;else elseBlock">{{student.paper.code}}
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            <span *ngIf="student.paper.label">{{student.paper.label}}</span>
                                        </ng-template>
                                    </p>
                                </div>
                                <div class="col-sm-2">
                                    <p class="m-0 h6 pt-1 pb-1 text-center">{{student.createdOn | date:'d-MMM-yy'}}</p>
                                </div>
                                <div class="col-sm-1">
                                    <p class="m-0 h6 pt-1 pb-1 text-center">{{student.resultRemarks}}</p>
                                </div>
                                <div class="col-sm-1">
                                    <p class="m-0 h6 pt-1 pb-1 text-center">{{env.evaluatorType[student.type]}}</p>
                                </div>
                                <div class="col-sm-1">
                                    <p *ngIf="student.active" class="m-0 h6 pt-1 pb-1 text-center">
                                        <span *ngIf="!student.mid"
                                            (click)="showEdit(student.semester,student.paper.code,'mid')">{{student.midTermMarksObtained}}</span>
                                        <span class="d-flex justify-content-between" *ngIf="student.mid"><input
                                                [(ngModel)]="student.midTermMarksObtained" style="width: 35px;"
                                                type="text"><em
                                                (click)="modifyMarks(student.semester,student.paper.code,'mid')"
                                                class="fa fa-save"></em></span>
                                    </p>
                                    <p *ngIf="!(student.active)" class="m-0 h6 pt-1 pb-1 text-center">
                                        {{student.midTermMarksObtained}}
                                    </p>
                                </div>
                                <div class="col-sm-1">
                                    <p *ngIf="student.active" class="m-0 h6 pt-1 pb-1 text-center">
                                        <span *ngIf="!student.end"
                                            (click)="showEdit(student.semester,student.paper.code,'end')">{{student.endTermMarksObtained}}</span>
                                        <span class="d-flex justify-content-between" *ngIf="student.end"><input
                                                [(ngModel)]="student.endTermMarksObtained" style="width: 35px;"
                                                type="text"><em
                                                (click)="modifyMarks(student.semester,student.paper.code,'end')"
                                                class="fa fa-save"></em></span>
                                    </p>
                                    <p *ngIf="!(student.active)" class="m-0 h6 pt-1 pb-1 text-center">
                                        {{student.endTermMarksObtained}}
                                    </p>
                                </div>
                                <div class="col-sm-1">
                                    <p class="m-0 h6 pt-1 pb-1 text-center">{{student.totalMarksObtained}}</p>
                                </div>
                                <div class="col-sm-1">
                                    <p class="m-0 h6 pt-1 pb-1 text-center">{{student.grade}}</p>
                                </div>
                                <div class="col-sm-2">
                                    <p class="m-0 h6 pt-1 pb-1 text-center">{{student.gradePoint}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <span class="cross" data-toggle="modal" data-target="#reactivateModal" id="modalClick"></span>
    <div class="modal fade modal-position cutom-deign-modal" id="reactivateModal" tabindex="-1" role="dialog"
        aria-labelledby="reactivateModal" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body modal-conten-part">
                    <div class="modal-headin-part">
                        <h3 style="font-size: 1.2rem;">The student is absent in this paper?</h3>

                    </div>
                    <div class="popup-body-content-part">
                        <div class="popup-in-content-right text-right">
                            <button (click)="confirm(true)" type="button" class="btn btn-success can-btn"
                                style="margin-right: 10px;">Yes</button>
                            <button (click)="confirm(false)" type="button" class="btn btn-danger">No</button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="modal fade modal-position" id="modalClick" tabindex="-1" role="dialog" aria-labelledby="reactivateModal" aria-hidden="true" >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closeReactivateModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body modal-conten-part">
              <div class="modal-headin-part">
                <h2>Confirmation</h2>
                <p> Are you sure you want to activate ? </p>
              </div>
              <div class="popup-body-content-part">
                <div class="popup-in-content-right">
                  <button type="button" class="btn btn-danger can-btn" data-dismiss="modal" aria-label="Close">
                    Cancel
                  </button>
                  <button  type="button" class="btn btn-primary">Yes, Activate it!</button>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
</div>