<div class="blue p-2 h4 m-0 text-white">
    {{ 'Import map field' | translate }}
</div>
<div class="center-popup-details p-3 h-75">
    <div class="d-flex align-items-center mb-2" *ngFor="let item of fieldMap">
        <div class="text-right mr-2">
            <input type="checkbox" [(ngModel)]="item.checked"/>
        </div>
        <div class="text-80 w-25 font-weight-500">{{ item.header }}</div>
        <div class="">
            <select class="form-control" [(ngModel)]="item.selectedValue">
                <option value="">{{ '--Select--' | translate }}</option>
                <option *ngFor="let field of csvTargetFields" [value]="field">
                    {{convertToTitleCase(field) | translate}}
                </option>
            </select>
        </div>
    </div>
</div>
<div class="center-popup-footer p-2 text-right grey lighten-3 d-flex justify-content-end">
    <button class="btn btn-primary btn-sm d-flex align-items-center font-weight-500" (click)="onContinue()">
        {{ 'Continue' | translate }} <em class="fa fa-arrow-circle-o-right text-22px ml-2" aria-hidden="true"></em>
    </button>
</div>