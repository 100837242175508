/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-employee.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./search-employee.component";
import * as i4 from "@angular/router";
import * as i5 from "../ui.service";
import * as i6 from "../api-client/organization.service";
import * as i7 from "../api-client/department.service";
var styles_SearchEmployeeComponent = [i0.styles];
var RenderType_SearchEmployeeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchEmployeeComponent, data: {} });
export { RenderType_SearchEmployeeComponent as RenderType_SearchEmployeeComponent };
function View_SearchEmployeeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "fa fa-check-square-o mr-1"]], null, null, null, null, null))], null, null); }
function View_SearchEmployeeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "fa fa-square-o mr-1"]], null, null, null, null, null))], null, null); }
function View_SearchEmployeeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignEmployee(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "span", [["class", "h5 m-0 pb-2 d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchEmployeeComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchEmployeeComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(8, null, [" ", ""]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.inDepartment; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_v.context.$implicit.inDepartment; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_2); }); }
export function View_SearchEmployeeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "d-flex p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["class", "form-control"], ["placeholder", "Search faculty"], ["type", "text"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.filterEmployee($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.employeeDetails({ id: 0 }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fa fa-plus-square-o p-2 text-22px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.exportCsv() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "em", [["class", "fa fa-download"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "scroll_div"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SearchEmployeeComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allEmployees; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_SearchEmployeeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-employee", [], null, null, null, View_SearchEmployeeComponent_0, RenderType_SearchEmployeeComponent)), i1.ɵdid(1, 245760, null, 0, i3.SearchEmployeeComponent, [i4.ActivatedRoute, i4.Router, i5.UiService, i6.OrganizationService, i7.DepartmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SearchEmployeeComponentNgFactory = i1.ɵccf("app-search-employee", i3.SearchEmployeeComponent, View_SearchEmployeeComponent_Host_0, {}, {}, []);
export { SearchEmployeeComponentNgFactory as SearchEmployeeComponentNgFactory };
