<div class="examiner-allocation-section p-4">
    <div class="d-flex text-90 font-weight-500 grey lighten-3">
        <div class="w-25 p-2">Department</div>
        <div class="w-50 p-2 d-flex justify-content-between align-items-center">
            <span>Hod</span>
            <span>Papers</span>
        </div>
        <div class="w-25 p-2 text-center">Status</div>
    </div>
    <div class="d-flex text-80 font-weight-500 item-row">
        <div class="w-25 p-2">
            <label class="m-0 d-flex justify-content-between align-items-center">
                <input name="" type="checkbox" class="pr-2">
                <span>Bengali</span>
            </label>
        </div>
        <div class="w-50 p-2 d-flex justify-content-between align-items-center">
            <span>Prof. Sandip Kumar Mandal</span>
            <span class="cursor-pointer unique-color pl-1 pr-1 text-white rounded">4</span>
        </div>
        <div class="w-25 p-2 text-center">
            <span class="badge gray-badge p-1 font-weight-500">2/10</span>
        </div>
    </div>
    <div class="d-flex text-80 font-weight-500 item-row">
        <div class="w-25 p-2">
            <label class="m-0 d-flex justify-content-between align-items-center">
                <input name="" type="checkbox" class="pr-2">
                <span>English</span>
            </label>
        </div>
        <div class="w-50 p-2 d-flex justify-content-between align-items-center">
            <span>Prof. Sandip Kumar Mandal</span>
            <span class="cursor-pointer unique-color pl-1 pr-1 text-white rounded">4</span>
        </div>
        <div class="w-25 p-2 text-center">
            <span class="badge gray-badge p-1 font-weight-500">4/10</span>
        </div>
    </div>
    <div class="d-flex text-80 font-weight-500 item-row">
        <div class="w-25 p-2">
            <label class="m-0 d-flex justify-content-between align-items-center">
                <input name="" type="checkbox" class="pr-2">
                <span>History</span>
            </label>
        </div>
        <div class="w-50 p-2 d-flex justify-content-between align-items-center">
            <span>Prof. Sandip Kumar Mandal</span>
            <span class="cursor-pointer unique-color pl-1 pr-1 text-white rounded">4</span>
        </div>
        <div class="w-25 p-2 text-center">
            <span class="badge green-badge p-1 font-weight-500">7/10</span>
        </div>
    </div>
    <div class="d-flex text-80 font-weight-500 item-row">
        <div class="w-25 p-2">
            <label class="m-0 d-flex justify-content-between align-items-center">
                <input name="" type="checkbox" class="pr-2">
                <span>Geography</span>
            </label>
        </div>
        <div class="w-50 p-2 d-flex justify-content-between align-items-center">
            <span>Prof. Sandip Kumar Mandal</span>
            <span class="cursor-pointer unique-color pl-1 pr-1 text-white rounded">4</span>
        </div>
        <div class="w-25 p-2 text-center">
            <span class="badge green-badge p-1 font-weight-500">10/10</span>
        </div>
    </div>
</div>