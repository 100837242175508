<ng-template #noRecordFound>
  <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>{{ 'No record found' | translate }}</strong></p>
</ng-template>
<ng-container *ngIf="!isEmpty(); else noRecordFound">
  <div class="d-flex justify-content-end">
    <!-- <h4>Papers</h4> -->
    <div class="reply_cont d-flex align-items-ceter">
      <a class="ml-2 mr-2 font-weight-500" (click)="exportCsv()"><em class="fa fa-file-excel-o p-2 rounded-circle"></em>
        <span>{{ 'Export CSV' | translate }}</span></a>
      <a class="ml-2 mr-2 font-weight-500" (click)="export('pdf','paper_structure','paper_structure')"><em
          class="fa fa-file-pdf-o p-2 rounded-circle" aria-hidden="true"></em> <span>{{ 'Export PDF' | translate
          }}</span></a>

    </div>
  </div>
  <div id="paper_structure">
    <h5 *ngIf="showHeader" class="text-center p-2">{{heading}}</h5>
    <div class="row">
      <div class="col-sm-2">
        <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Group' | translate }}</p>
      </div>
      <div class="col-sm-2">
        <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Code' | translate }}</p>
      </div>
      <div class="col-sm-5">
        <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500">{{ 'Name' | translate }}</p>
      </div>
      <div class="col-sm-1">
        <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500 text-center">{{ 'Credit' | translate }}</p>
      </div>
      <div class="col-sm-2">
        <p class="m-0 h5 pl-3 pr-3 pt-1 pb-1 font-weight-500 text-center">{{ 'Full Marks' | translate }}</p>
      </div>
    </div>
    <div *ngFor="let semester of papers|keyvalue">
      <p class="m-0 h6 pl-3 pr-3 pt-1"><strong>{{ 'Semester' | translate }} : {{semester.key}}</strong></p>
      <div class="row item-row" *ngFor="let paper of semester.value" (click)="openSummary(paper)">
        <div class="col-sm-2">
          <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1">{{paper.groupCode}}</p>
        </div>
        <div class="col-sm-2">
          <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1">{{paper.code}}</p>
        </div>
        <div class="col-sm-5">
          <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1">{{paper.name}} </p>
        </div>
        <div class="col-sm-1">
          <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center">{{paper.credit}}</p>
        </div>
        <div class="col-sm-2">
          <p class="m-0 h6 pl-3 pr-3 pt-1 pb-1 text-center">{{paper.fullMarks}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
