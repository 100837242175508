import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProgramModel } from '../models/program.model';
import { CustomValidator } from '../customvalidation';
import { TemplateModel } from '../models/template.model';

@Injectable({
    providedIn: 'root'
})
export class TemplateFormBuilder{

    private form:any;
    private validationRules = {        
        id:['',''],
        subject: ['',Validators.required],
        emailContent: ['',Validators.required],
        smsContent:['',''],
        tag:['',''],
        params:['',''],
        pdfContent:['','']
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:TemplateModel){
        this.form.patchValue({
            id:response.id,
            subject:response.subject,
            emailContent:response.emailContent,
            smsContent:response.smsContent,
            tag:response.tag,
            params:response.params,
            pdfContent:response.pdfContent
        });
    }
}