import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiService } from '../ui.service';
import { ToastrService } from 'ngx-toastr';
import { EmployeeService } from '../api-client/employee.service';
import { OrganizationService } from '../api-client/organization.service';
import { CommonMethods } from '../common-methods';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  email: string = null;
  constructor(
    private _router: Router,
    private _uiService: UiService,
    private _employeeService: EmployeeService,
    private _organizationService: OrganizationService,
    private _toster: ToastrService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this._uiService.showHeader.next(false);
  }

  send() {
    if (CommonMethods.validateEmail(this.email)==false) {
      this._toster.error("Invalid Email.");
      return false;
    }
    document.getElementById("showLoader").style.display = "block";
    var data = { "email": this.email };
    this._employeeService.emailExists(data).subscribe((result: any) => {
      if (result) {
        this._employeeService.resetPassword(this.email).subscribe((result: any) => {
          document.getElementById("showLoader").style.display = "none";
          this._toster.success("Password has been sent to your email address.");
          this._router.navigateByUrl("login");
          return false;
        })
      } else {
        this._toster.error("Email not exist in database.");
        return false;
      }
    });
  }
  login(){
    this._router.navigate(["/login"])
  }

}
