import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { SessionModel } from '../models/session.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private _sessions: any;

  public onSessionsUpdated = new Subject();

  constructor(private _http: HttpClient) {
    
  }

  getSession(id: any) {
    return this._http.get(config.apiEndPointBase + "session/"+id);
  }

  saveSession(data: SessionModel): Observable<SessionModel> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "session", data, {})
        .pipe(map((response: any) => {
          return new SessionModel().deserialize(response);
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "session", data, {})
        .pipe(map((response: any) => {
          return new SessionModel().deserialize(response);
        }));
    }
  }
}
