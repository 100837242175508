<ng-template #na><span> NA</span></ng-template>
<form [formGroup]="studentForm" (ngSubmit)="onSubmit()">
    <div class="p-3 vw-25" style="overflow-y: auto;">
        <div class="d-flex icon-set justify-content-around p-2 mb-3">
            <div *ngIf="displayDetailIcon" (click)="redirectToStudentDetails(student)" class="text-color">
                <em class="fa fa-graduation-cap"></em>
                {{ 'Detail' | translate }}
            </div>
            <div class="text-dark" *ngIf="student && student?.migrated===false">
                <a (click)="migrateBlock(1,true)"> <em class="fa fa-share-square"></em></a>
                {{ 'Left away' | translate }}
            </div>
            <div class="text-success" *ngIf="student && student?.migrated===true">
                <a (click)="migrateBlock(1,false)"> <em class="fa fa-undo"></em></a>
                {{ 'UnMigrated' | translate }}
            </div>
            <div class="text-danger" *ngIf="student && student?.blocked===false">
               <a (click)="migrateBlock(2,true)"> <em class="fa fa-ban"></em></a>
                {{ 'Block' | translate }}
            </div>
            <div class="text-success" *ngIf="student && student?.blocked===true">
                <a (click)="migrateBlock(2,false)"> <em class="fa fa-unlock"></em></a>
                 {{ 'UnBlock' | translate }}
             </div>
            <div (click)="editPaper()" *ngIf="editMode" class="text-default">
                <em class="fa fa-pencil"></em>
                {{ 'Edit' | translate }}
            </div>
            <div *ngIf="!editMode" class="text-default">
                <button class="btn text-100 p-0 z-depth-0 text-default"><em class="fa fa-save"></em>
                    <span>{{ 'Save' | translate }}</span></button>
            </div>
        </div>
        <div class="scroll_div">
            <div class="h4 m-0">
                <div class=""><input type="text" class="w-100 form-control" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" ></div>
                <div *ngIf="submitted && f.name.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">{{ 'Name is required' | translate }}</div>
                </div>
            </div>

            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Registration no.' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="registrationNumber"
                        [ngClass]="{ 'is-invalid': submitted && f.registrationNumber.errors }" >
                </div>
                <div *ngIf="submitted && f.registrationNumber.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.registrationNumber.errors.required">{{ 'Registration number code is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Class roll no.' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="classRollNumber"
                        [ngClass]="{ 'is-invalid': submitted && f.classRollNumber.errors }" ></div>
                <div *ngIf="submitted && f.classRollNumber.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.classRollNumber.errors.required">{{ 'Class roll number is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Registration year.' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="registrationYear"
                        [ngClass]="{ 'is-invalid': submitted && f.registrationYear.errors }" >
                </div>
                <div *ngIf="submitted && f.registrationYear.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.registrationYear.errors.required">{{ 'Registration year is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Batch start year' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="batchStartYear"
                        [ngClass]="{ 'is-invalid': submitted && f.batchStartYear.errors }" ></div>
                <div *ngIf="submitted && f.batchStartYear.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.batchStartYear.errors.required">{{ 'Batch start year is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Session' | translate }}:</div>
                <div class=""><select  formControlName="academicSessionId" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.academicSessionId.errors }">
                        <option value="">{{ 'Select Session' | translate }}Select Session</option>
                        <option *ngFor="let session of sessions" [value]="session.id">{{session.name}}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.academicSessionId.errors" style="display: block;"
                        class="invalid-feedback">
                        <div *ngIf="f.academicSessionId.errors.required">{{ 'Session is required' | translate }}</div>
                    </div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Program' | translate }}:</div>
                <div class=""><select  formControlName="academicProgramId" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.academicProgramId.errors }">
                        <option value="">{{ 'Select Program' | translate }}</option>
                        <option *ngFor="let program of programs" [value]="program.id">{{program.name}}</option>
                    </select>
                    <div *ngIf="submitted && f.academicProgramId.errors" class="invalid-feedback">
                        <div *ngIf="f.academicProgramId.errors.required">{{ 'Program is required' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Current Semester' | translate }}</div>
                <div class="">
                        <select  formControlName="currentSemester" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.currentSemester.errors }">
                        <option value="">{{ 'Select Semester' | translate }}</option>
                        <option *ngFor="let sem of settings.semesters" [value]="sem.id">{{sem.name}}</option>
                    </select></div>
                <div *ngIf="submitted && f.currentSemester.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.currentSemester.errors.required">{{ 'Semester is required' | translate }}</div>
                </div>
            </div>

            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Parent Name' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="parentName"
                        ></div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Gender' | translate }}</div>
                <div class="">
                        <select  formControlName="gender" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                        <option value="">{{ 'Select gender' | translate }}</option>
                        <option *ngFor="let gn of settings.gender" [value]="gn">{{gn}}</option>
                    </select></div>
                <div *ngIf="submitted && f.gender.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.gender.errors.required">{{ 'Gender is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Caste' | translate }}</div>
                <div class="">
                        <select  formControlName="caste" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.caste.errors }">
                        <option value="">{{ 'Select caste' | translate }}</option>
                        <option *ngFor="let caste of settings.caste" [value]="caste">{{caste}}</option>
                    </select>
                </div>
                <div *ngIf="submitted && f.caste.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.caste.errors.required">{{ 'Caste is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Blood Group' | translate }}</div>
                <div class="">
                        <select  formControlName="bloodGroup" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.bloodGroup.errors }">
                        <option value="">{{ 'Select blood group' | translate }}</option>
                        <option *ngFor="let blood of settings.bloodGroup" [value]="blood">{{blood}}</option>
                    </select></div>
                <div *ngIf="submitted && f.bloodGroup.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.bloodGroup.errors.required">{{ 'Blood group is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Address' | translate }}</div>
                <div class=""><textarea class="w-100 form-control" formControlName="address"
                        [ngClass]="{ 'is-invalid': submitted && f.address.errors }" ></textarea>
                </div>
                <div *ngIf="submitted && f.address.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.address.errors.required">{{ 'Address is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Email' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" ></div>
                <div *ngIf="submitted && f.email.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">{{ 'Email is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Mobile' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="mobile"
                        >
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Date of Birth' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="dateOfBirth" [owlDateTime]="dateOfBirth" [owlDateTimeTrigger]="dateOfBirth" />
                    <owl-date-time [pickerType]="'calendar'" #dateOfBirth></owl-date-time>
                    
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'NAD' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="nadId"
                        >
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Aadhar Number' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="aadharNumber"
                        >
                </div>
            </div> 
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Nationality' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="nationality"
                        >
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'PWD' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="pwD"
                        >
                </div>
            </div> 
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Thesis' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="thesis"
                        >
                </div>
            </div>           
        </div>
    </div>
    <input type="hidden" formControlName="id">
</form>