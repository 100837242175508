import { FormBuilder, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var DisciplineFormBuilder = /** @class */ (function () {
    function DisciplineFormBuilder(fb) {
        this.fb = fb;
        this.validationRules = {
            id: ['', ''],
            departmentId: ['', Validators.required],
            name: ['', Validators.required]
        };
        this.form = fb.group(this.validationRules);
    }
    DisciplineFormBuilder.prototype.getForm = function () {
        return this.form;
    };
    DisciplineFormBuilder.prototype.setValues = function (response) {
        this.form.patchValue({
            id: response.id,
            departmentId: response.departmentId,
            name: response.name
        });
    };
    DisciplineFormBuilder.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisciplineFormBuilder_Factory() { return new DisciplineFormBuilder(i0.ɵɵinject(i1.FormBuilder)); }, token: DisciplineFormBuilder, providedIn: "root" });
    return DisciplineFormBuilder;
}());
export { DisciplineFormBuilder };
