import { FormBuilder, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var staffFormBuilder = /** @class */ (function () {
    function staffFormBuilder(fb) {
        this.fb = fb;
        this.validationRules = {
            id: ['', ''],
            name: ['', Validators.required],
            address: ['', Validators.required],
            bloodGroup: ['', Validators.required],
            discontinued: ['', Validators.required],
            email: ['', Validators.required],
            emailSignature: ['', ''],
            gender: ['', Validators.required],
            mobile: ['', ''],
            type: ['', Validators.required],
            designation: ['', ''],
            dateOfJoin: ['', ''],
            releaseDate: ['', ''],
        };
        this.form = fb.group(this.validationRules);
    }
    staffFormBuilder.prototype.getForm = function () {
        return this.form;
    };
    staffFormBuilder.prototype.setValues = function (response) {
        this.form.patchValue({
            id: response.id,
            name: response.name,
            address: response.address,
            bloodGroup: response.bloodGroup,
            discontinued: response.discontinued,
            email: response.email,
            emailSignature: response.emailSignature,
            gender: response.gender,
            mobile: response.mobile,
            designation: response.designation,
            dateOfJoin: response.dateOfJoin,
            type: response.type,
            releaseDate: response.releaseDate,
        });
    };
    staffFormBuilder.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function staffFormBuilder_Factory() { return new staffFormBuilder(i0.ɵɵinject(i1.FormBuilder)); }, token: staffFormBuilder, providedIn: "root" });
    return staffFormBuilder;
}());
export { staffFormBuilder };
