import { Deseriazable } from './deserializable.model';

export class StaffModel implements Deseriazable{
    
    id: string;
    name: string;
    address: string;
    bloodGroup: string;
    discontinued: string;
    email: string;
    emailSignature: string;
    gender: string;
    mobile: string;
    designation: string;
    dateOfJoin:string;
    type:string;
    releaseDate:string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}