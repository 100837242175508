import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../../app/config';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DepartmentService = /** @class */ (function () {
    function DepartmentService(_http) {
        this._http = _http;
    }
    DepartmentService.prototype.getDisciplines = function (departmentId) {
        return this._http.get(config.apiEndPointBase + "department/" + departmentId + "/disciplines");
    };
    DepartmentService.prototype.getEmployees = function (departmentId) {
        return this._http.get(config.apiEndPointBase + "department/" + departmentId + "/employees");
    };
    DepartmentService.prototype.getEmployee = function (employeeId) {
        return this._http.get(config.apiEndPointBase + "employee/" + employeeId);
    };
    DepartmentService.prototype.getDepartment = function (departmentId) {
        return this._http.get(config.apiEndPointBase + "department/" + departmentId);
    };
    DepartmentService.prototype.linkUnlinkEmployee = function (data, type) {
        return this._http
            .put(config.apiEndPointBase + "department/" + data.departmentId + "/employee/" + type + "/" + data.employeeId, {}, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    DepartmentService.prototype.markeAsHod = function (data) {
        return this._http
            .put(config.apiEndPointBase + "department/" + data.departmentId + "/mark-hod/" + data.employeeId, {}, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    DepartmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DepartmentService_Factory() { return new DepartmentService(i0.ɵɵinject(i1.HttpClient)); }, token: DepartmentService, providedIn: "root" });
    return DepartmentService;
}());
export { DepartmentService };
