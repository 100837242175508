import { OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { SessionModel } from '../models/session.model';
import { ToastrService } from 'ngx-toastr';
import { SessionFormBuilder } from '../session-details/session.form-builder';
import { SessionService } from '../api-client/session.service';
import { NotifyService } from '../api-client/notify.service';
import { config } from '../config';
var SessionDetailsComponent = /** @class */ (function () {
    function SessionDetailsComponent(_academiaService, _route, _uiService, _sessionService, _toster, _formBuilder, _router, _notifyService) {
        this._academiaService = _academiaService;
        this._route = _route;
        this._uiService = _uiService;
        this._sessionService = _sessionService;
        this._toster = _toster;
        this._formBuilder = _formBuilder;
        this._router = _router;
        this._notifyService = _notifyService;
        this.session = {};
        this.editMode = false;
        this.submitted = false;
        this.model = new SessionModel();
        this.settings = config;
    }
    Object.defineProperty(SessionDetailsComponent.prototype, "f", {
        get: function () { return this.sessionForm.controls; },
        enumerable: true,
        configurable: true
    });
    SessionDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sessionForm = this._formBuilder.getForm();
        this._route.params.subscribe(function (params) {
            _this.sessionId = params.id;
            _this.resetForm();
            _this.getSession(params.id);
        });
        this._notifyService.onSessionInfoUpdateNotify.subscribe(function (session) {
            if (session.id == _this.sessionId) {
                _this._toster.error("Someone update same session");
                _this._formBuilder.setValues(session);
                return;
            }
        });
    };
    SessionDetailsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.sessionForm.invalid) {
            return;
        }
        this.model.deserialize(this.sessionForm.value);
        if (!this.model.active) {
            this.model.active = false;
        }
        this._sessionService.saveSession(this.model)
            .subscribe(function (response) {
            _this._toster.success("Session saved");
            _this._notifyService.sessionUpdated(_this.model);
            _this.editMode = true;
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    SessionDetailsComponent.prototype.editSession = function () {
        this.editMode = false;
    };
    SessionDetailsComponent.prototype.getSession = function (sessionId) {
        var _this = this;
        this._sessionService.getSession(sessionId).subscribe(function (result) {
            _this._formBuilder.setValues(result);
        });
    };
    SessionDetailsComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.sessionForm.reset();
    };
    return SessionDetailsComponent;
}());
export { SessionDetailsComponent };
