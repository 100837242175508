import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { paperService } from '../api-client/paper.service';
var SearchStudentsComponent = /** @class */ (function () {
    function SearchStudentsComponent(_academiaService, _route, _router, _uiService, _paperService, _exportAsService, _tosterService) {
        var _this = this;
        this._academiaService = _academiaService;
        this._route = _route;
        this._router = _router;
        this._uiService = _uiService;
        this._paperService = _paperService;
        this._exportAsService = _exportAsService;
        this._tosterService = _tosterService;
        this._route.params.subscribe(function (args) {
            _this.paperId = args.id;
            _this.studentsInPaper();
            _this.paperDetails();
        });
    }
    SearchStudentsComponent.prototype.ngOnInit = function () {
    };
    SearchStudentsComponent.prototype.paperDetails = function () {
        var _this = this;
        this._paperService.getPaper(this.paperId).subscribe(function (result) {
            _this.paper = result;
            _this.academicSessionId = result.academicSessionId;
            _this.currentSemester = result.semester;
        });
    };
    SearchStudentsComponent.prototype.studentSearch = function () {
        var _this = this;
        if (this.freeText == "" || this.freeText == null) {
            this._tosterService.error("Student reg.no or name can not be blank.");
            return;
        }
        var filters = {
            "freeText": this.freeText,
            "academicSessionId": this.academicSessionId,
            "programIds": [],
            //"semester": this.currentSemester,
            "semester": 0,
            "batchStartYear": 0,
            "registrationYear": 0,
            "startIndex": 0,
            "pageSize": 0
        };
        this._academiaService.students(filters).subscribe(function (result) {
            for (var i = 0; i < result.items.length; i++) {
                var student = _this.studentsInpaper.filter(function (x) { return x.id == result.items[i].id; });
                if (student.length > 0) {
                    result.items[i].assigned = true;
                }
            }
            _this.students = result.items;
        });
    };
    SearchStudentsComponent.prototype.studentsInPaper = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._academiaService.getPaperWiseStudents(this.paperId).toPromise().then(function (result) {
                            _this.studentsInpaper = result;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SearchStudentsComponent.prototype.assignStudent = function (student) {
        var _this = this;
        var filter = {
            "studentId": student.id,
            "paperCodes": [this.paper.code]
        };
        this._academiaService.setStudentPaper(filter).subscribe(function (result) {
            _this._tosterService.success("Student set in this paper");
            _this.studentsInPaper();
            _this.studentSearch();
            return;
        });
    };
    return SearchStudentsComponent;
}());
export { SearchStudentsComponent };
