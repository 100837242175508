import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { paperService } from '../api-client/paper.service';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../app/config';
import { UiService } from '../ui.service';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-papers',
  templateUrl: './papers.component.html',
  styleUrls: ['./papers.component.css']
})
export class PapersComponent implements OnInit, OnDestroy {

  private _paperServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;

  paperId: any;
  papers: any;
  session: any;
  disciplines: any;
  filterKeyword = "";
  filterDiscipline = [];
  filterSemester = 0;
  filterLevel = 0;
  filterSession = "";
  filters = {};
  pageSize: number = config.paginationPerPage;
  totalItemsCount: number = 0;
  totalPageCount: number = 0;
  papersCurrentPage: any = 1;
  academiaSessions: any;
  env = config;
  paperCsvHeader = config.paperCsvHeader;
  exportPapers: any;
  fromSession = "";
  toSession = "";
  disciplineSearchSettings = {
    singleSelection: false,
    text: "Select Discipline",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    badgeShowLimit: 3
  };
  copyPaper = false;
  paperCsvData = [];

  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _tosterService: ToastrService,
    private _exportAsService: ExportAsService,
    private _router: Router, ) {
    this._academiaServiceSubscription = _academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.academiaSessions = this._academiaService.getAcademicSession();
      this.disciplines = this._academiaService.getDisciplines();
    });
  }

  ngOnInit() {
    this._uiService.changeAppTitle.next("Papers");
    this.academiaSessions = this._academiaService.getAcademicSession();
    this.disciplines = this._academiaService.getDisciplines();
    this.paperFilter();
  }


  paperFilter() {
    var levels = [];
    if (this.filterLevel) {
      levels.push(Number(this.filterLevel));
    }
    this.filters = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "disciplineIds": this.filterDiscipline.map(x => x.id),
      "semester": this.filterSemester,
      "levels": levels,
      "startIndex": (this.papersCurrentPage - 1) * this.pageSize,
      "pageSize": this.pageSize
    };
    this._academiaService.papers(this.filters).subscribe((result: any) => {
      this.exportPapers = result.items;
      for (var i = 0; i < result.items.length; i++) {
        const session = this.sessionDetails(result.items[i].academicSessionId);
        if(session){
          result.items[i].sessionName = session.name;
        }
      }
      this.papers = result.items;
      this.totalItemsCount = result.totalCount;
      this.totalPageCount = Math.ceil(this.totalItemsCount / this.pageSize);
    })
  }

  sessionDetails(sessionId: any) {
    if (this.academiaSessions == null) return null;
    var items = this.academiaSessions.filter((x: any) => x.id == sessionId);
    if (items && items.length > 0) {
      return items[0];
    } else {
      return null;
    }
  }

  onPageChange(pageIndex: any) {
    this.papersCurrentPage = pageIndex;
    this.paperFilter();
  }

  redirectTo(paper: any) {
    //this._router.navigate(["paper/" + paper.id]);
    this._router.navigate([{
      outlets: { rightMainPopup: ['paper-summary', paper.id] }
    }]);
  }

  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
  }

  previewCsvData(fieldMaps: any, results: any) {
    this._uiService.indeterminateProgressTextSet.next('Arrange data...');
    var columns = [];
    var columnsWidth = {};
    var columnSources = [];

    columnSources['paperType'] = Object.keys(config.paperType).map((key) => {
      return { id: Number(key), name: config.paperType[key] };
    });

    columnSources['level'] = Object.keys(config.academicLevel).map((key) => {
      return { id: Number(key), name: config.academicLevel[key] };
    });

    columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map((key) => {
      return { id: Number(key), name: config.secondaryPaperType[key] };
    });

    columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map((x: any) => {
      return { id: x.id, name: x.name };
    });

    columnSources['disciplineId'] = this._academiaService.getDisciplines().map((x: any) => {
      return { id: x.id, name: x.name };
    });
    var arrangedData = results.map((value: any) => {
      var item = {};
      for (var key in fieldMaps) {
        var valueWidth = (value[fieldMaps[key]] + '').length * 15;
        item[key] = value[fieldMaps[key]];
        if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
          columnsWidth[key] = valueWidth;
        }
      }

      if (item['level'] && item['level'].length == 2) {
        var levelName = Object.values(config.academicLevelShort);
        var levelKey = Object.keys(config.academicLevelShort);
        if (levelKey.indexOf(item['level']) > -1) {
          item['level'] = levelName[levelKey.indexOf(item['level'])];
        }
      }

      if (!item['secondaryPaperType']) {
        item['secondaryPaperType'] = 0;
      }
      if (!item['midTermCredit']) {
        item['midTermCredit'] = 0;
      }
      if (!item['endTermCredit']) {
        item['endTermCredit'] = 0;
      }

      return item;
    });



    this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
    this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
    columnsWidth = null;
    this._uiService.indeterminateProgressTextSet.next(null);

    this._uiService.openDataPreviewWindow(arrangedData, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
        }
      }
    ]);
  }

  onImportPreviewCompleted(fields: any, arrangedData: any, columns: any) {
    var data = this._uiService.getPreviewModifiedData();
    var converted = data.map((value: any) => {
      var item = {};
      for (var i = 0; i < fields.length; i++) {
        item[fields[i]] = value[i];
      }
      return item;
    });
    this._uiService.closeDataPreview();
    let worker = new Worker('../workers/import-papers.worker', { name: 'paper-worker', type: 'module' });

    this._uiService.startWorker(worker, converted)
      .then(() => {
        //show success message, if required
      }).catch(e => {
        if (e.validation) {
          this.onImportValidationError(e.validation, converted, columns, fields);
        }
      });
  }

  onImportValidationError(validation: any, converted: any, columns: any, fields: any) {
    this._uiService.onDataPreviewReady = () => {
      var allFields = Object.keys(converted[0]);
      for (var i = 0; i < validation.length; i++) {
        var commonErrors = [];
        for (var field in validation[i]) {
          var fieldIndex = allFields.indexOf(field);
          if (fieldIndex <= 0) {
            commonErrors = commonErrors.concat(validation[i][field]);
          } else {
            this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
          }
        }
        if (commonErrors.length > 0) {
          this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
        }
      }
    };
    this._uiService.openDataPreviewWindow(converted, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(fields, converted, columns);
        }
      }
    ]);
  }

  mapCsvField(results: any) {
    this._uiService
      .mapCsvField(results.meta.fields, this.paperCsvHeader)
      .then((maps: any) => {
        this.previewCsvData(maps, results.data);
      }).catch(() => { });
  }

  uploadFile(fileEle: any) {
    this._uiService
      .parseCsv(fileEle.files[0])
      .then((results: any) => {
        fileEle.value = null;
        this.mapCsvField(results);
      }).catch(() => {
        fileEle.value = null;
      });
  }

  exportCsvPaper() {
    var levels = [];
    if (this.filterSemester == 0 || this.filterSemester == null) {
      this._tosterService.error("Semester can not be blank");
      return;
    }
    if (this.filterDiscipline.length == 0) {
      this._tosterService.error("Discipline can not be blank");
      return;
    }

    if (this.filterLevel) {
      levels.push(Number(this.filterLevel));
    }
    this.filters = {
      "freeText": this.filterKeyword,
      "academicSessionId": this.filterSession,
      "disciplineIds": this.filterDiscipline.map(x => x.id),
      "semester": this.filterSemester,
      "levels": levels,
      "startIndex": 0,
      "pageSize": 0
    };
    this._academiaService.papers(this.filters).subscribe((result: any) => {
      for (var i = 0; i < result.items.length; i++) {
        result.items[i].name = result.items[i].name.replace(/,/g, "-");
        result.items[i].sessionName = this.sessionDetails(result.items[i].academicSessionId).name;
      }
      this.paperCsvData = result.items;
      setTimeout(() => {
        this._exportAsService.save({ type: "csv", elementId: "paperData" }, "papers").subscribe(() => {
        });
      }, 100);
    });
  }

  updateSemester() {
    if (this.fromSession == "" || this.fromSession == null) {
      this._tosterService.error("Select from session");
      return;
    }
    if (this.toSession == "" || this.toSession == null) {
      this._tosterService.error("Select to session");
      return;
    }
    var data = { sourceSessionId: this.fromSession, targetSessionId: this.toSession };
    this._academiaService.sessionWisepaperCopy(data).subscribe((result: any) => {
      this._tosterService.success("Papers copied");
      return;
    })
  }

  relode() {
    this.paperFilter();
  }
  copy() {
    this.copyPaper = true;
  }
}
