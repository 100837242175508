<ng-template #na><span> NA</span></ng-template>
<form [formGroup]="disciplineForm" (ngSubmit)="onSubmit()">
    <div class="p-3 vw-25">
        <div class="d-flex icon-set justify-content-around p-2 mb-2">
            <div class="text-danger">
                <em class="fa fa-trash"></em>
                {{ 'Delete' | translate }}
            </div>
            <div class="text-warning">
                <em class="fa fa-ban"></em>
                {{ 'Block' | translate }}
            </div>
            <div (click)="editDiscipline()" *ngIf="editMode" class="text-default">
                <em class="fa fa-pencil"></em>
                {{ 'Edit' | translate }}
            </div>
            <div *ngIf="!editMode" class="text-default">
                <button class="btn text-100 p-0 z-depth-0 text-default"><em class="fa fa-save"></em>
                    <span>{{ 'Save' | translate }}</span></button>

            </div>
        </div>

        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'Name' | translate }}:</div>
            <div class=""><input type="text" class="w-100  form-control" formControlName="name"
                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">{{ 'Name is required' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'Departments' | translate }}:</div>
            <div class=""><select formControlName="departmentId" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.departmentId.errors }">
                    <option value="">{{ 'Select Department' | translate }}</option>
                    <option *ngFor="let department of departments" [value]="department.id">{{department.name}}</option>
                </select>
                <div *ngIf="submitted && f.departmentId.errors" class="invalid-feedback">
                    <div *ngIf="f.departmentId.errors.required">{{ 'Department is required' | translate }}</div>
                </div>
            </div>
        </div>
    </div>
    <input type="hidden" formControlName="id">
</form>