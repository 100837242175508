import * as tslib_1 from "tslib";
import { OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AcademiaService } from '../api-client/academia.service';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from '../api-client/exam.service';
import { config } from '../config';
var ExamsComponent = /** @class */ (function () {
    function ExamsComponent(_router, _toster, _examService, _academiaService) {
        this._router = _router;
        this._toster = _toster;
        this._examService = _examService;
        this._academiaService = _academiaService;
        this.progrems = [];
        this.examDetails = null;
        this.selectedSemDetails = null;
        this.popupVal = null;
        this.showRows = [1, 2, 3, 4, 5, 6, 7, 8];
        this.tabSelected = 3;
        this.newExamData = { name: null, active: false, academicSessionId: null, programs: [], id: null };
    }
    ExamsComponent.prototype.ngOnInit = function () {
        this.examDetails = null;
    };
    ExamsComponent.prototype.ngOnChanges = function (changes) {
        if (this.session) {
            this.getExams(this.session);
        }
    };
    ExamsComponent.prototype.getExams = function (session) {
        var _this = this;
        this.progrems = [];
        this.showRows = [1, 2, 3, 4, 5, 6, 7, 8];
        this.tabSelected = 3;
        this.newExamData = { name: null, active: false, academicSessionId: null, programs: [], id: null };
        this.examDetails = null;
        this._academiaService.getSessionWiseExams(session.id).subscribe(function (result) {
            _this.exams = result;
            _this.exams.push({ name: "Add New", active: false, academicSessionId: session.id, programs: [], id: 0 });
        });
    };
    ExamsComponent.prototype.openModal = function (ele) {
        this.selectedSemDetails = ele;
    };
    ExamsComponent.prototype.selectExam = function (exam) {
        var _this = this;
        if (!exam) {
            this.progrems = [];
            this.showRows = [1, 2, 3, 4, 5, 6, 7, 8];
            this.tabSelected = 3;
            return null;
        }
        this.examDetails = null;
        this._examService.getExam(exam).subscribe(function (result) {
            _this.newExamData.name = null;
            _this.newExamData.id = null;
            _this.newExamData.active = false;
            _this.examDetails = result;
            if (result != null && result.id) {
                _this.newExamData.name = result.name;
                _this.newExamData.id = result.id;
                _this.newExamData.active = result.active;
            }
            var getProgrems = _this._academiaService.getPrograms();
            var academicLevelData = Object.entries(config.academicLevel).map(function (_a) {
                var _b = tslib_1.__read(_a, 2), key = _b[0], value = _b[1];
                return ({ key: key, value: value });
            });
            var data = [];
            for (var i = 0; i < getProgrems.length; i++) {
                var academicLevel = getProgrems[i].academicLevel;
                if (!data[academicLevel]) {
                    var filterData = academicLevelData.filter(function (x) { return x.key == academicLevel; });
                    var title = filterData[0].value;
                    data[academicLevel] = { id: academicLevel, name: title, children: [] };
                }
                data[academicLevel].children.push({
                    id: getProgrems[i].id,
                    name: getProgrems[i].name,
                    disciplineId: getProgrems[i].disciplineId,
                    academicLevel: getProgrems[i].academicLevel,
                    numberOfSemesters: getProgrems[i].numberOfSemesters,
                    semesterWiseExamValues: _this.matchExamDetailsValue(getProgrems[i])
                });
            }
            _this.progrems = data;
        });
    };
    ExamsComponent.prototype.matchExamDetailsValue = function (progremInfo) {
        if (!progremInfo)
            return null;
        //if (!this.examDetails) return null;
        var semesterWiseExamValues = {};
        var progrems = [];
        if (this.examDetails) {
            progrems = this.examDetails.programs
                .filter(function (x) { return x.academicProgramId == progremInfo.id; });
        }
        for (var i = 1; i <= progremInfo.numberOfSemesters; i++) {
            if (progrems != null && progrems.length > 0) {
                var filteredSemesters = progrems[0].semesters.filter(function (x) { return x.semester == i; });
                if (filteredSemesters.length > 0) {
                    semesterWiseExamValues[i] = filteredSemesters[0];
                    semesterWiseExamValues[i].checked = true;
                    if (semesterWiseExamValues[i].examination.start) {
                        semesterWiseExamValues[i].examination.start = new Date(semesterWiseExamValues[i].examination.start);
                    }
                    if (semesterWiseExamValues[i].examination.end) {
                        semesterWiseExamValues[i].examination.end = new Date(semesterWiseExamValues[i].examination.end);
                    }
                    if (semesterWiseExamValues[i].examination.resultPublishDate) {
                        semesterWiseExamValues[i].examination.resultPublishDate = new Date(semesterWiseExamValues[i].examination.resultPublishDate);
                    }
                    if (semesterWiseExamValues[i].examination.gradeCardRemarks) {
                        semesterWiseExamValues[i].examination.gradeCardRemarks = semesterWiseExamValues[i].examination.gradeCardRemarks;
                    }
                    if (semesterWiseExamValues[i].review.start) {
                        semesterWiseExamValues[i].review.start = new Date(semesterWiseExamValues[i].review.start);
                    }
                    if (semesterWiseExamValues[i].review.end) {
                        semesterWiseExamValues[i].review.end = new Date(semesterWiseExamValues[i].review.end);
                    }
                    if (semesterWiseExamValues[i].review.resultPublishDate) {
                        semesterWiseExamValues[i].review.resultPublishDate = new Date(semesterWiseExamValues[i].review.resultPublishDate);
                    }
                    if (semesterWiseExamValues[i].review.gradeCardRemarks) {
                        semesterWiseExamValues[i].review.gradeCardRemarks = semesterWiseExamValues[i].review.gradeCardRemarks;
                    }
                }
                else {
                    semesterWiseExamValues[i] = {
                        checked: false,
                        examination: { start: null, end: null, gradeCardRemarks: null, resultPublishDate: null },
                        review: { start: null, end: null, gradeCardRemarks: null, resultPublishDate: null },
                        semester: i,
                    };
                }
            }
            else {
                semesterWiseExamValues[i] = {
                    checked: false,
                    examination: { start: null, end: null, gradeCardRemarks: null, resultPublishDate: null },
                    review: { start: null, end: null, gradeCardRemarks: null, resultPublishDate: null },
                    semester: i,
                };
            }
        }
        return semesterWiseExamValues;
    };
    ExamsComponent.prototype.showRow = function (arg) {
        if (arg == 1) {
            this.tabSelected = 1;
            this.showRows = [1, 3, 5, 7];
        }
        else if (arg == 2) {
            this.tabSelected = 2;
            this.showRows = [2, 4, 6, 8];
        }
        else if (arg == 3) {
            this.tabSelected = 3;
            this.showRows = [1, 2, 3, 4, 5, 6, 7, 8];
        }
    };
    ExamsComponent.prototype.save = function () {
        var _this = this;
        var program = [];
        var error = [];
        //console.log(this.progrems);
        this.progrems.forEach(function (item) {
            var children = item.children;
            for (var j = 0; j < children.length; j++) {
                var semesters = [];
                var semesterWiseExamValues = children[j].semesterWiseExamValues;
                for (var key in semesterWiseExamValues) {
                    if (semesterWiseExamValues[key].checked && _this.showRows.includes(parseInt(key))) {
                        semesters.push({
                            checked: semesterWiseExamValues[key].checked,
                            semester: semesterWiseExamValues[key].semester,
                            examination: {
                                start: semesterWiseExamValues[key].examination.start,
                                end: semesterWiseExamValues[key].examination.end,
                                gradeCardRemarks: semesterWiseExamValues[key].examination.gradeCardRemarks,
                                resultPublishDate: semesterWiseExamValues[key].examination.resultPublishDate
                            },
                            review: {
                                start: semesterWiseExamValues[key].review.start,
                                end: semesterWiseExamValues[key].review.end,
                                gradeCardRemarks: semesterWiseExamValues[key].review.gradeCardRemarks,
                                resultPublishDate: semesterWiseExamValues[key].review.resultPublishDate
                            },
                        });
                    }
                }
                if (semesters.length > 0) {
                    program.push({ academicProgramId: children[j].id, semesters: semesters });
                }
            }
        });
        if (this.newExamData.name == "" || this.newExamData.name == null) {
            this._toster.error("Exam name can not be blank");
            return;
        }
        /* for (var k = 0; k < program.length; k++) {
          var semester = program[k].semesters;
          if (semester.length == 0) {
            error.push("Semester not checked");
            break;
          }
        } */
        //console.log(program);return;
        this.newExamData.academicSessionId = this.session.id;
        this.newExamData.programs = program;
        this._examService.save(this.newExamData).subscribe(function (response) {
            _this._toster.success("Examination saved");
        });
        //console.log(this.newExamData);
    };
    ExamsComponent.prototype.copyData = function (ele, fieldType, fieldName, degree, type) {
        var _this = this;
        this.progrems.forEach(function (item, index) {
            if (index == degree) {
                var children = item.children;
                for (var j = 0; j < children.length; j++) {
                    var semesterWiseExamValues = children[j].semesterWiseExamValues;
                    for (var key in semesterWiseExamValues) {
                        if (_this.showRows.includes(parseInt(key))) {
                            if (fieldType == 'checkbox') {
                                if (ele.target.checked) {
                                    _this.progrems[index].children[j].semesterWiseExamValues[key].checked = true;
                                }
                                else {
                                    _this.progrems[index].children[j].semesterWiseExamValues[key].checked = false;
                                }
                            }
                            else {
                                if (type == 'examination') {
                                    if (fieldName == 'start') {
                                        _this.progrems[index].children[j].semesterWiseExamValues[key].examination.start = new Date(ele.value);
                                    }
                                    if (fieldName == 'end') {
                                        _this.progrems[index].children[j].semesterWiseExamValues[key].examination.end = new Date(ele.value);
                                    }
                                    if (fieldName == 'publish') {
                                        _this.progrems[index].children[j].semesterWiseExamValues[key].examination.resultPublishDate = new Date(ele.value);
                                    }
                                    if (fieldName == 'remarks') {
                                        _this.progrems[index].children[j].semesterWiseExamValues[key].examination.gradeCardRemarks = ele.target.value;
                                    }
                                }
                                if (type == 'review') {
                                    if (fieldName == 'start') {
                                        _this.progrems[index].children[j].semesterWiseExamValues[key].review.start = new Date(ele.value);
                                    }
                                    if (fieldName == 'end') {
                                        _this.progrems[index].children[j].semesterWiseExamValues[key].review.end = new Date(ele.value);
                                    }
                                    if (fieldName == 'publish') {
                                        _this.progrems[index].children[j].semesterWiseExamValues[key].review.resultPublishDate = new Date(ele.value);
                                    }
                                    if (fieldName == 'remarks') {
                                        _this.progrems[index].children[j].semesterWiseExamValues[key].review.gradeCardRemarks = ele.target.value;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
    };
    ExamsComponent.prototype.globalPopUp = function (id, tab) {
        this.tabHeading = null;
        this.popUpHeading = null;
        this.tabHeading = id;
        if (tab == 'review') {
            this.popUpHeading = "Post Publication Review (" + config.academicLevel[id] + " )";
        }
        else {
            this.popUpHeading = "Regular & Supplementary (" + config.academicLevel[id] + " )";
        }
        this.tabType = tab;
        this.popupVal = null;
    };
    ExamsComponent.prototype.getGlobalPopUpVal = function (ele, type) {
        this.copyData(ele, 'text', type, this.tabHeading, this.tabType);
    };
    return ExamsComponent;
}());
export { ExamsComponent };
