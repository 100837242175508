import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PaperModel } from '../models/paper.model';

@Injectable({
    providedIn: 'root'
})
export class paperFormBuilder{

    private form:any;
    private validationRules = {        
        id:['',''],
        disciplineId: ['',Validators.required],
        name: ['',Validators.required],
        academicSessionId: ['',Validators.required],
        code: ['',Validators.required],
        credit: ['',Validators.required],
        endTermAllocatedMarks: ['',Validators.required],
        fullMarks: ['',Validators.required],
        groupCode: ['',Validators.required],
        groupName: ['',''],
        label: ['',''],
        level: ['',''],
        midTermAllocatedMarks: ['',Validators.required],
        paperType: ['',Validators.required],
        secondaryPaperType: ['',''],
        semester: ['',Validators.required],
        endTermCredit: ['',''], 
        midTermCredit: ['',''],

    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:PaperModel){
        this.form.patchValue({
            id:response.id,
            disciplineId:response.disciplineId,
            name:response.name,
            academicSessionId:response.academicSessionId,
            code:response.code,
            credit:response.credit,
            endTermAllocatedMarks:response.endTermAllocatedMarks,
            fullMarks:response.fullMarks,
            groupCode:response.groupCode,
            groupName:response.groupName,
            label:response.label,
            level:response.level,
            midTermAllocatedMarks:response.midTermAllocatedMarks,
            paperType:response.paperType,
            secondaryPaperType:response.secondaryPaperType,
            semester:response.semester,
            endTermCredit:response.endTermCredit,
            midTermCredit:response.midTermCredit
        });
    }
}