import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ProgramModel } from '../models/program.model';
import { ProgramFormBuilder } from './program.form-builder';
import { ProgramService } from '../api-client/program.service';
import { ToastrService } from 'ngx-toastr';
import { config } from '../config';

@Component({
  selector: 'app-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.css']
})
export class ProgramDetailsComponent implements OnInit, OnDestroy {


  programId: any;
  programs: any;
  program = {};
  disciplines: any;
  editMode: boolean = false;
  submitted = false;
  model = new ProgramModel();
  programForm: any;
  academicLevels = config.academicLevel;

  private _academiaServiceProgramSubscription: Subscription;
  private _academiaServiceDisciplineSubscription: Subscription;

  constructor(
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _toster: ToastrService,
    private _programService: ProgramService,
    private _formBuilder: ProgramFormBuilder,
    private _router: Router) {
    this._academiaServiceDisciplineSubscription = this._academiaService.onDisciplinesUpdated.subscribe(_ => {
      this.disciplines = this._academiaService.getDisciplines();
    });
  }

  get f() { return this.programForm.controls; }

  ngOnInit() {
    console.log(config.academicLevel);
    this.programForm = this._formBuilder.getForm();
    this.disciplines = this._academiaService.getDisciplines();
    this._route.params.subscribe(params => {
      this.programId = params.id;
      this.resetForm();
      this.getProgram(params.id);
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.programForm.invalid) {
      return;
    }

    this.model.deserialize(this.programForm.value);
    this._programService.saveProgram(this.model)
      .subscribe(
        (response: ProgramModel) => {
          this._toster.success("Program saved");
          this.editMode = true;
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

  editProgram() {
    this.editMode = false;
  }

  getProgram(ProgramId: any) {
    this._programService.getProgram(ProgramId).subscribe((result: any) => {
      this._formBuilder.setValues(result);
    })
  }

  ngOnDestroy(): void {
    this._academiaServiceDisciplineSubscription.unsubscribe();
  }

  resetForm() {
    this.submitted = false;
    this.programForm.reset();
  }

}
