import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AcademiaService } from '../api-client/academia.service';
import { Subscription } from 'rxjs';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent implements OnInit, OnDestroy {

  examSessions: any;
  selectedSession: any = null;

  private _academiaServiceSubscription: Subscription;

  constructor(
    private _router: Router,
    private _uiService: UiService,
    private _academiaService: AcademiaService
  ) {
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.examSessions = this._academiaService.getAcademicSession();
    });
  }

  ngOnInit() {
    this._uiService.changeAppTitle.next("Session");
    this.examSessions = this._academiaService.getAcademicSession();
  }

  onItemClieck(item: any) {
    this.selectedSession = item;
    this._uiService.changeAppTitle.next("Session: "+item.name);
  }


  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
  }

  redirectTo(session: any) {
    //this._router.navigate(["paper/" + paper.id]);
    this._router.navigate([{
      outlets: { rightMainPopup: ['session-details', session.id] }
    }]);
  }

}
