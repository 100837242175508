import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { config } from '../../app/config';
import { ProgramModel } from '../models/program.model';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProgramService = /** @class */ (function () {
    function ProgramService(_http) {
        this._http = _http;
    }
    ProgramService.prototype.getSessionWisePapers = function (programId, sessionId) {
        return this._http.get(config.apiEndPointBase + "program/" + programId + "/papers-in-session/" + sessionId);
    };
    ProgramService.prototype.getSessionWiseStudents = function (programId, sessionId) {
        return this._http.get(config.apiEndPointBase + "program/" + programId + "/students-in-session/" + sessionId);
    };
    ProgramService.prototype.getProgremWiseStudentsPursue = function (programId, startIndex, pageSize) {
        return this._http.get(config.apiEndPointBase + "program/" + programId + "/students-pursue?startIndex=" + startIndex + "&pageSize=" + pageSize);
    };
    ProgramService.prototype.getProgram = function (id) {
        return this._http.get(config.apiEndPointBase + "program/" + id);
    };
    ProgramService.prototype.saveProgram = function (data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "program", data, {})
                .pipe(map(function (response) {
                return new ProgramModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "program", data, {})
                .pipe(map(function (response) {
                return new ProgramModel().deserialize(response);
            }));
        }
    };
    ProgramService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProgramService_Factory() { return new ProgramService(i0.ɵɵinject(i1.HttpClient)); }, token: ProgramService, providedIn: "root" });
    return ProgramService;
}());
export { ProgramService };
