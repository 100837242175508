import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject,Observable, of  } from 'rxjs';
import { config } from '../../app/config';
import { ProgramModel } from '../models/program.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  
  constructor(private _http: HttpClient) { 
    
  }

  getSessionWisePapers(programId:string,sessionId:string)
  {
    return this._http.get(config.apiEndPointBase + "program/"+programId+"/papers-in-session/"+sessionId);
  }

  getSessionWiseStudents(programId:string,sessionId:string)
  {
    return this._http.get(config.apiEndPointBase + "program/"+programId+"/students-in-session/"+sessionId);
  }

  getProgremWiseStudentsPursue(programId:string,startIndex:number, pageSize:number)
  {
    return this._http.get(`${config.apiEndPointBase}program/${programId}/students-pursue?startIndex=${startIndex}&pageSize=${pageSize}`);
  }

  getProgram(id: any) {
    return this._http.get(config.apiEndPointBase + "program/"+id);
  }

  saveProgram(data: ProgramModel): Observable<ProgramModel> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "program", data, {})
        .pipe(map((response: any) => {
          return new ProgramModel().deserialize(response);
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "program", data, {})
        .pipe(map((response: any) => {
          return new ProgramModel().deserialize(response);
        }));
    }
  }
  
}
