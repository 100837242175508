import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { error } from 'protractor';
import { Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class WebServiceInterceptor implements HttpInterceptor {
    activeTimer: any = null;
    constructor(
        public router: Router,
        private toastr: ToastrService) { };

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.activeTimer) {
            clearTimeout(this.activeTimer);
        }

        this.activeTimer = setTimeout(() => {
            sessionStorage.removeItem("token");
            sessionStorage.clear();
            window.location.reload();
        }, 60000*3000);


        req = req.clone({
            setHeaders: {
                Authorization: "Bearer " + sessionStorage.getItem("token")
            }
        });

        document.getElementById("showLoader").style.display = "block";
        return next.handle(req).pipe(
            catchError(err => {
                //console.log(1122,err.status);           
                if (err.status == 403) {
                    //localStorage.removeItem("token");
                    //this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } });
                } else {
                    //console.log(err);
                    this.toastr.error("Somthing is wrong. Please check network connection and try again.");
                }
                return of(null);
            }),
            finalize(() => {
                document.getElementById("showLoader").style.display = "none";
            })
        );
    }
}
