import { OnInit } from '@angular/core';
import { UtilityService } from '../api-client/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TemplateModel } from '../models/template.model';
import { TemplateFormBuilder } from './template.form-builder';
import { UiService } from '../ui.service';
var NotificationTemplateComponent = /** @class */ (function () {
    function NotificationTemplateComponent(_templateService, _route, _uiService, _toster, _formBuilder, _router) {
        this._templateService = _templateService;
        this._route = _route;
        this._uiService = _uiService;
        this._toster = _toster;
        this._formBuilder = _formBuilder;
        this._router = _router;
        this.submitted = false;
        this.model = new TemplateModel();
        this.config = {
            toolbar: [
                { name: 'document', items: ['Source'] },
                { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
                //{ name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
                //{ name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
                '/',
                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
                { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
                { name: 'insert', items: ['Table'] },
                '/',
                { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
                { name: 'colors', items: ['TextColor', 'BGColor'] },
            ]
        };
    }
    NotificationTemplateComponent.prototype.ngOnInit = function () {
        this.templateForm = this._formBuilder.getForm();
        this._uiService.changeAppTitle.next("Notification Template");
        this.getTemplates();
    };
    Object.defineProperty(NotificationTemplateComponent.prototype, "f", {
        get: function () { return this.templateForm.controls; },
        enumerable: true,
        configurable: true
    });
    NotificationTemplateComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.templateForm.controls['id'].value) {
            this._toster.error("You can not add new template.");
            return false;
        }
        this.submitted = true;
        if (this.templateForm.invalid) {
            return;
        }
        this.model.deserialize(this.templateForm.value);
        this._templateService.save(this.model)
            .subscribe(function (response) {
            _this._toster.success("Template save");
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    NotificationTemplateComponent.prototype.getTemplate = function (templateId) {
        this._formBuilder.setValues(this._templateService.getTemplate(templateId));
    };
    NotificationTemplateComponent.prototype.getTemplates = function () {
        var _this = this;
        this._templateService.getTemplates().subscribe(function (result) {
            _this.templates = result;
        });
    };
    NotificationTemplateComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.templateForm.reset();
    };
    NotificationTemplateComponent.prototype.filterTemplate = function (ele) {
        var items;
        if (ele.value) {
            items = this.templates.filter(function (x) { return x.subject.toLowerCase().includes(ele.value.toLowerCase()); });
            this.templates = items;
        }
        else {
            this.getTemplates();
        }
    };
    NotificationTemplateComponent.prototype.copyFrom = function (arg) {
    };
    return NotificationTemplateComponent;
}());
export { NotificationTemplateComponent };
