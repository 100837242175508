import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { StudentService } from '../api-client/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { config } from '../../app/config';
import { AcademiaService } from '../api-client/academia.service';
import { UiService } from '../ui.service';
import { ExportAsService } from 'ngx-export-as';
import { ToastrService } from 'ngx-toastr';
import { ExamService } from '../api-client/exam.service';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit, OnChanges, OnDestroy {

  private _studentServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;

  studentId: any;
  student: any;
  historyData: any;
  studentProgram: any;
  env: any;
  showHeader: boolean = false;
  downloadPdfHeader: string;
  studentHistoryData: any;
  regno = "";
  batch = "";
  evaluators: any;
  evaluatorType = 0;
  examinations = [];
  examinationId = "";
  public modalCloseElement: any;
  public modalElement: HTMLElement;
  editedValue: {
    studentId: any; paperId: any;
    //examinationId: this.historyData[i].children[k].examinationId,
    examinationId: string; isMidTermMarks: boolean; marks: any; studentAbsent: boolean; incomplete: boolean; mode: number; //correction
  };
  studentPassExamination: any = [];

  constructor(
    private _studentService: StudentService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _academiaService: AcademiaService,
    private _exportAsService: ExportAsService,
    private _tosterService: ToastrService,
    private _examService: ExamService,
    private _router: Router,) {
    this.env = config;
    this._studentServiceSubscription = _studentService.onStudentsUpdated.subscribe(_ => {
      this.studentHistory();
      this.studentDetails();
    });
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.getExaminationsRespectiveSession();
    });
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.getExaminationsRespectiveSession();
  }

  ngOnInit() {
    this.env = config;
    this._route.params.subscribe(params => {
      this.studentId = params.id;
      this.studentPassInExam();
      this.studentHistory();
      this.studentDetails();
      //this.getExaminationsRespectiveSession();     
    })
  }

  async getExaminationsRespectiveSession() {
    var selectedSession = this._academiaService.getActiveAcademicSession();
    if (!selectedSession) return;
    await this._academiaService.getSessionWiseExams(selectedSession.id).toPromise().then((result: any) => {
      this.examinations = result;
    })
  }

  studentDetails() {
    if (this.studentId == null) return;
    this._studentService.getEvaluation(this.studentId)
      .subscribe((result: any) => {
        for (var i = 0; i < result.semestersDetail.length; i++) {
          var lastAppeardInExaminationIds = [];
          for (var k = 0; k < result.semestersDetail[i].courses.length; k++) {
            result.semestersDetail[i].courses[k].midTermMarksObtained = parseFloat(result.semestersDetail[i].courses[k].midTermMarksObtained).toFixed(2);
            result.semestersDetail[i].courses[k].endTermMarksObtained = parseFloat(result.semestersDetail[i].courses[k].endTermMarksObtained).toFixed(2);
            result.semestersDetail[i].courses[k].mid = false;
            result.semestersDetail[i].courses[k].end = false;
          }
          result.semestersDetail[i].courses.sort((x: any, y: any) => {
            if (x.paper.paperType == y.paper.paperType) {
              return x.paper.code.localeCompare(y.paper.code);
            } else {
              return (x.paper.paperType - y.paper.paperType);
            }
          });
        }
        result.semestersDetail.sort((x: any, y: any) => {
          return (x.semester - y.semester);
        });
        this.student = result;
        this._uiService.changeAppTitle.next("Student: " + result.name);
        this.studentProgram = this._academiaService.getProgram(result.academicProgramId);
        this.downloadPdfHeader = result.name + "(" + this.studentProgram.name + ")";
      });
  }

  studentHistory() {
    if (this.studentId == null) return;
    this._studentService.getHistory(this.studentId).subscribe((result: any) => {
      for (var k = 0; k < result.length; k++) {
        if (result[k].active && result[k].examinationId) {
          result[k].midTermMarksObtained = parseFloat(result[k].midTermMarksObtained).toFixed(2);
          result[k].endTermMarksObtained = parseFloat(result[k].endTermMarksObtained).toFixed(2);
          result[k].mid = false;
          result[k].end = false;
        }
      }
      var data = [];
      result.sort((x: any, y: any) => {
        return x.paper.code.localeCompare(y.paper.code);
      });
      this.studentHistoryData = result;
      for (var i = 0; i < result.length; i++) {
        var sem = result[i].semester;
        if (!data[sem]) {
          data[sem] = { semester: sem, remarks: result[i].resultRemarks, children: [] };
        }
        data[sem].children.push(result[i]);
      }
      this.historyData = data;

    })
  }

  export(type: any, elementId: any, name: any) {
    this.showHeader = true;
    var configs = { type: type, elementId: elementId };
    setTimeout(() => {
      this._exportAsService.save(configs, name).subscribe(() => {
        this.showHeader = false;
      });
    }, 100);
  }

  exportCsv() {
    var headers = [];
    var fetchDate = [];
    var result = this.studentHistoryData;
    for (var i = 0; i < result.length; i++) {
      fetchDate.push({
        "semester": result[i].semester,
        "remarks": result[i].resultRemarks,
        "createdOn": result[i].createdOn,
        "Type": config.evaluatorType[result[i].type],
        "code": result[i].paper.code,
        "credit": result[i].paper.credit,
        "midTermAllocatedMarks": result[i].paper.midTermAllocatedMarks,
        "endTermAllocatedMarks": result[i].paper.endTermAllocatedMarks,
        "fullMarks": result[i].paper.fullMarks,
        "name": result[i].paper.name,
        "label": result[i].paper.label,
        "paperType": config.paperType[result[i].paper.paperType],
        "midTermMarksObtained": result[i].midTermMarksObtained,
        "endTermMarksObtained": result[i].endTermMarksObtained,
        "totalMarksObtained": result[i].totalMarksObtained,
        "grade": result[i].grade,
        "gradePoint": result[i].gradePoint
      });
    }
    for (var i = 0; i < config.exportCsvStudentHistory.length; i++) {
      headers.push({ align: "center", allowEmpty: false, editor: null, name: config.exportCsvStudentHistory[i], options: [], source: [], title: this.exportcsvHeader(config.exportCsvStudentHistory[i]), type: "text", width: 195 });
    }
    this._uiService.openDataPreviewWindow(fetchDate, headers, []);
  }

  switchStudent() {
    if (this.regno == null || this.regno == "") {
      this._tosterService.error("Registration no. can not be blank.");
      return false;
    }

    if (this.batch == null || this.batch == "") {
      this._tosterService.error("Batch can not be blank.");
      return false;
    }

    var filter = {
      freeText: this.regno,
      academicSessionId: "",
      programIds: [],
      semester: 0,
      batchStartYear: this.batch,
      registrationYear: 0,
      startIndex: 0,
      pageSize: 0
    }

    this._academiaService.students(filter).subscribe((result: any) => {
      if (result.items.length > 0) {
        this._router.navigate(["/student/" + result.items[0].id]);
      } else {
        this._tosterService.error("Student not found.");
      }
    });
  }

  exportcsvHeader(key: any) {
    var title = key;
    title = title.replace(/([A-Z])/g, " $1");
    title = title.charAt(0).toUpperCase() + title.slice(1);
    return title;
  }

  showEdit(sem: any, code: any, mode: any) {
    for (var i = 0; i < this.historyData.length; i++) {
      if (this.historyData[i]) {
        if (this.historyData[i].semester == sem) {
          for (var k = 0; k < this.historyData[i].children.length; k++) {
            if (this.historyData[i].children[k].paper.code == code) {
              if (mode == 'mid') {
                this.historyData[i].children[k].mid = true;
              } else if (mode == 'end') {
                this.historyData[i].children[k].end = true;
              }
            }
          }
        }
      }
    }
  }

  showEditLatest(sem: any, code: any, mode: any) {
    for (var i = 0; i < this.student.semestersDetail.length; i++) {
      if (this.student.semestersDetail[i]) {
        if (this.student.semestersDetail[i].semester == sem) {
          for (var k = 0; k < this.student.semestersDetail[i].courses.length; k++) {
            if (this.student.semestersDetail[i].courses[k].paper.code == code) {
              if (mode == 'mid') {
                this.student.semestersDetail[i].courses[k].mid = true;
              } else if (mode == 'end') {
                this.student.semestersDetail[i].courses[k].end = true;
              }
            }
          }
        }
      }
    }
  }

  async modifyMarks(sem: any, code: any, mode: any) {
    for (var i = 0; i < this.historyData.length; i++) {
      if (this.historyData[i]) {
        if (this.historyData[i].semester == sem) {
          for (var k = 0; k < this.historyData[i].children.length; k++) {
            if (this.historyData[i].children[k].paper.code == code && this.historyData[i].children[k].active) {
              if (this.evaluatorType == null || this.evaluatorType == 0) {
                this._tosterService.error("Please select correction type.");
                return;
              }

              if (this.examinationId == null || this.examinationId == null) {
                this._tosterService.error("Please select examination.");
                return;
              }

              var filter = {
                "freeText": code,
                "doExactFreeTextMatch": true,
                "academicSessionId": this.historyData[i].children[k].paper.academicSessionId,
                "disciplineIds": [],
                "levels": [],
                "semester": sem,
                "startIndex": 0,
                "pageSize": 0
              }
              await this._academiaService.papers(filter).toPromise().then((result: any) => {
                if (result.items.length > 0) {
                  this.evaluators = result.items;
                }
              });
              //if (!this.evaluators) return;
              //console.log(this.evaluators[0].evaluators[0].examinerId);
              var endAbsent = false;
              if (this.historyData[i].children[k].endTermMarksObtained > 0) {
                endAbsent = false;
              } else {
                endAbsent = true;
              }
              var midAbsent = false;
              if (this.historyData[i].children[k].midTermMarksObtained > 0) {
                midAbsent = false;
              } else {
                midAbsent = true;
              }
              if (mode == 'mid') {
                this.historyData[i].children[k].mid = false;
                var studentParams = {
                  studentId: this.historyData[i].children[k].studentId,
                  paperId: this.historyData[i].children[k].paper.id,
                  //examinationId: this.historyData[i].children[k].examinationId,
                  examinationId: this.examinationId,
                  isMidTermMarks: true,
                  marks: this.historyData[i].children[k].midTermMarksObtained,
                  studentAbsent: midAbsent,
                  incomplete: this.historyData[i].children[k].hasIncomplete,
                  mode: this.evaluatorType //correction
                }
              } else if (mode == 'end') {
                this.historyData[i].children[k].end = false;
                var studentParams = {
                  studentId: this.historyData[i].children[k].studentId,
                  paperId: this.historyData[i].children[k].paper.id,
                  //examinationId: this.historyData[i].children[k].examinationId,
                  examinationId: this.examinationId,
                  isMidTermMarks: false,
                  marks: this.historyData[i].children[k].endTermMarksObtained,
                  studentAbsent: endAbsent,
                  incomplete: this.historyData[i].children[k].hasIncomplete,
                  mode: this.evaluatorType //correction
                }
              }
              // this._examService.submitMarks(studentParams).subscribe((result:any)=>{
              //   this._tosterService.success("Marks updated");
              //   this.studentHistory();
              //   this.studentDetails();
              //   return;
              // })
            }
          }
        }
      }
    }
  }

  async modifyMarksLatest(sem: any, code: any, mode: any) {
    for (var i = 0; i < this.student.semestersDetail.length; i++) {
      if (this.student.semestersDetail[i]) {
        if (this.student.semestersDetail[i].semester == sem) {
          for (var k = 0; k < this.student.semestersDetail[i].courses.length; k++) {
            if (this.student.semestersDetail[i].courses[k].paper.code == code && (this.student.semestersDetail[i].courses[k].mid || this.student.semestersDetail[i].courses[k].end)) {
              if (this.evaluatorType == null || this.evaluatorType == 0) {
                this._tosterService.error("Please select correction type.");
                return;
              }

              if (this.examinationId == null || this.examinationId == null) {
                this._tosterService.error("Please select examination.");
                return;
              }

              var filter = {
                "freeText": code,
                "doExactFreeTextMatch": true,
                "academicSessionId": this.student.semestersDetail[i].courses[k].paper.academicSessionId,
                "disciplineIds": [],
                "levels": [],
                "semester": sem,
                "startIndex": 0,
                "pageSize": 0
              }
              await this._academiaService.papers(filter).toPromise().then((result: any) => {
                if (result.items.length > 0) {
                  this.evaluators = result.items;
                }
              });
              //if (!this.evaluators) return;
              //console.log(this.evaluators[0].evaluators[0].examinerId);
              var endAbsent = false;
              if (this.student.semestersDetail[i].courses[k].endTermMarksObtained > 0) {
                endAbsent = false;
              } else {
                endAbsent = true;
              }
              var midAbsent = false;
              if (this.student.semestersDetail[i].courses[k].midTermMarksObtained > 0) {
                midAbsent = false;
              } else {
                midAbsent = true;
              }
              if (mode == 'mid') {
                this.student.semestersDetail[i].courses[k].mid = false;
                var studentParams = {
                  studentId: this.studentId,
                  paperId: this.student.semestersDetail[i].courses[k].paper.id,
                  //examinationId: this.historyData[i].children[k].examinationId,
                  examinationId: this.examinationId,
                  isMidTermMarks: true,
                  marks: this.student.semestersDetail[i].courses[k].midTermMarksObtained,
                  studentAbsent: midAbsent,
                  incomplete: false,
                  mode: this.evaluatorType //correction
                }
              } else if (mode == 'end') {
                this.student.semestersDetail[i].courses[k].end = false;
                var studentParams = {
                  studentId: this.studentId,
                  paperId: this.student.semestersDetail[i].courses[k].paper.id,
                  //examinationId: this.historyData[i].children[k].examinationId,
                  examinationId: this.examinationId,
                  isMidTermMarks: false,
                  marks: this.student.semestersDetail[i].courses[k].endTermMarksObtained,
                  studentAbsent: endAbsent,
                  incomplete: false,
                  mode: this.evaluatorType //correction
                }
              }
              if (this.student.semestersDetail[i].courses[k].endTermMarksObtained == 0) {
                this.editedValue = studentParams;
                this.modalElement = document.getElementById('modalClick');
                this.modalElement.click();
              } else {
                this._examService.submitMarks(studentParams).subscribe((result: any) => {
                  this._tosterService.success("Marks updated");
                  this.studentDetails();
                  this.studentHistory();
                  return;
                })
              }

            }
          }
        }
      }
    }
  }

  confirm(e: any) {
    var studentParams = {
      studentId: this.editedValue.studentId,
      paperId: this.editedValue.paperId,
      examinationId: this.editedValue.examinationId,
      isMidTermMarks: false,
      marks: this.editedValue.marks,
      studentAbsent: e,
      incomplete: false,
      mode: this.editedValue.mode //correction
    }
    this.modalElement = document.getElementById('closeModal');
    this.modalElement.click();
    this._examService.submitMarks(studentParams).subscribe((result: any) => {
      this._tosterService.success("Marks updated");
      this.studentDetails();
      this.studentHistory();
      return;
    })

  }

  studentPassInExam() {
    if (this.studentId == null) return;
    this._studentService.getStudentSemisterPassExam(this.studentId).subscribe((result: any) => {
      this.studentPassExamination = result;
    })
  }

  getExamName(semesterName: any) {
    if (this.studentPassExamination.length > 0) {
      const exam = this.studentPassExamination.filter((x: any) => x.semester == semesterName);
      if (exam && exam.length > 0) {
        return exam[0].examinationName;
      }
    } else {
      return;
    }
  }

  ngOnDestroy(): void {
    this._studentServiceSubscription.unsubscribe();
  }


}
