import { FormBuilder, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var paperFormBuilder = /** @class */ (function () {
    function paperFormBuilder(fb) {
        this.fb = fb;
        this.validationRules = {
            id: ['', ''],
            disciplineId: ['', Validators.required],
            name: ['', Validators.required],
            academicSessionId: ['', Validators.required],
            code: ['', Validators.required],
            credit: ['', Validators.required],
            endTermAllocatedMarks: ['', Validators.required],
            fullMarks: ['', Validators.required],
            groupCode: ['', Validators.required],
            groupName: ['', ''],
            label: ['', ''],
            level: ['', ''],
            midTermAllocatedMarks: ['', Validators.required],
            paperType: ['', Validators.required],
            secondaryPaperType: ['', ''],
            semester: ['', Validators.required],
            endTermCredit: ['', ''],
            midTermCredit: ['', ''],
        };
        this.form = fb.group(this.validationRules);
    }
    paperFormBuilder.prototype.getForm = function () {
        return this.form;
    };
    paperFormBuilder.prototype.setValues = function (response) {
        this.form.patchValue({
            id: response.id,
            disciplineId: response.disciplineId,
            name: response.name,
            academicSessionId: response.academicSessionId,
            code: response.code,
            credit: response.credit,
            endTermAllocatedMarks: response.endTermAllocatedMarks,
            fullMarks: response.fullMarks,
            groupCode: response.groupCode,
            groupName: response.groupName,
            label: response.label,
            level: response.level,
            midTermAllocatedMarks: response.midTermAllocatedMarks,
            paperType: response.paperType,
            secondaryPaperType: response.secondaryPaperType,
            semester: response.semester,
            endTermCredit: response.endTermCredit,
            midTermCredit: response.midTermCredit
        });
    };
    paperFormBuilder.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function paperFormBuilder_Factory() { return new paperFormBuilder(i0.ɵɵinject(i1.FormBuilder)); }, token: paperFormBuilder, providedIn: "root" });
    return paperFormBuilder;
}());
export { paperFormBuilder };
