import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Papa } from 'ngx-papaparse';
import { ToastrService } from 'ngx-toastr';
import { config } from '../app/config';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@angular/router";
import * as i3 from "ngx-papaparse";
var UiService = /** @class */ (function () {
    function UiService(_toster, _router, _papa) {
        this._toster = _toster;
        this._router = _router;
        this._papa = _papa;
        this.showHeader = new Subject();
        this.changeAppTitle = new Subject();
        this.detailOutletUpdateForId = new Subject();
        this.quickItemsSet = new Subject();
        this.indeterminateProgressTextSet = new Subject();
        this.assignEmployeeInDepartmentDiscpline = new Subject();
        this.assignEmployeeInDiscpline = new Subject();
        this.departmentEmployeesSet = new Subject();
    }
    UiService.prototype.setQuickItems = function (items) {
        this.quickItemsSet.next(items);
    };
    UiService.prototype.parseCsv = function (file) {
        var _this = this;
        var counter = 0;
        var items = [];
        var errors = [];
        var meta = null;
        this.indeterminateProgressTextSet.next('Reading csv...');
        return new Promise(function (resolve, reject) {
            _this._papa.parse(file, {
                header: true,
                dynamicTyping: true,
                worker: true,
                delimiter: ',',
                skipEmptyLines: true,
                step: function (results, parser) {
                    //console.log(results);
                    if (results.errors.length > 0) {
                        errors.push(results.errors[0]);
                        parser.abort();
                        return;
                    }
                    items.push(results.data);
                    if (meta == null) {
                        meta = results.meta;
                    }
                    counter++;
                    _this.indeterminateProgressTextSet.next(counter + " row(s) read from csv...");
                },
                complete: function () {
                    if (errors && errors.length > 0) {
                        _this._toster.error('Unable to parse this file. Please upload valid CSV. ' + errors[0].message);
                        if (reject)
                            reject(errors[0].message);
                    }
                    else {
                        resolve({ data: items, errors: errors, meta: meta });
                    }
                    _this.indeterminateProgressTextSet.next(null);
                }
            });
        });
    };
    UiService.prototype.mapCsvField = function (headers, fields) {
        var _this = this;
        this.csvHeaders = headers;
        this.csvTargetFields = fields;
        return new Promise(function (resolve, reject) {
            _this.afterCsvMapDone = function (fieldMap) {
                if (fieldMap.error) {
                    if (reject)
                        reject(fieldMap.error);
                }
                else {
                    resolve(fieldMap.maps);
                }
            };
            _this._router.navigate([{
                    outlets: { centerPopUp: ['data-import-mapper'] }
                }]);
        });
    };
    UiService.prototype.replaceCsvValueWithId = function (sources, data) {
        for (var i = 0; i < data.length; i++) {
            for (var key in sources) {
                if (data[i][key]) {
                    var values = sources[key].filter(function (x) { return x.name == data[i][key]; });
                    if (values.length > 0) {
                        data[i][key] = values[0].id;
                    }
                }
            }
        }
    };
    UiService.prototype.createDataPreviewColumns = function (fieldMaps, columnsWidth, columns, columnSources) {
        for (var key in fieldMaps) {
            var title = key;
            title = title.replace(/([A-Z])/g, " $1");
            title = title.charAt(0).toUpperCase() + title.slice(1);
            if (columnsWidth[key] < title.length * 15) {
                columnsWidth[key] = title.length * 15;
            }
            if (columnSources[key]) {
                columns.push({
                    title: title,
                    type: 'dropdown',
                    width: columnsWidth[key],
                    source: columnSources[key]
                });
            }
            else {
                columns.push({
                    title: title,
                    width: columnsWidth[key],
                });
            }
        }
    };
    UiService.prototype.openDataPreviewWindow = function (data, columns, actionButtons) {
        this.previewData = { data: data, columns: columns, actionButtons: actionButtons };
        this._router.navigate(['data-preview']);
    };
    UiService.prototype.setIndeterminateProgressText = function (worker, text) {
        this.indeterminateProgressTextSet.next(text);
    };
    UiService.prototype.showWorkerError = function (worker, error) {
        this._toster.error(error);
        worker.terminate();
    };
    UiService.prototype.showWorkerCompleteMessage = function (worker, error) {
        this._toster.success(error);
        worker.terminate();
    };
    UiService.prototype.startWorker = function (worker, initParams) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            worker.postMessage({ method: 'init', params: initParams, authToken: sessionStorage.getItem("token"), config: config });
            worker.onmessage = function (event) {
                var data = event.data;
                if (data.method && _this[data.method]) {
                    _this[data.method](worker, data.params);
                    if (data.method == 'showWorkerCompleteMessage') {
                        resolve(null);
                    }
                }
                else if (data.method && data.method == 'errors') {
                    reject(data.params);
                    worker.terminate();
                }
            };
            worker.onerror = function (event) {
                console.error('worker on error', event);
                worker.terminate();
                reject(event);
            };
        });
    };
    UiService.prototype.setEmployeeDepartmentDiscpline = function (items) {
        this.assignEmployeeInDepartmentDiscpline.next(items);
    };
    UiService.prototype.setEmployeeDiscpline = function (items) {
        this.assignEmployeeInDiscpline.next(items);
    };
    UiService.prototype.setDepartmentWiseEmployee = function (items) {
        this.departmentEmployees = items;
        //this.departmentEmployeesSet.next(items);
    };
    UiService.prototype.setDepartmentId = function (id) {
        this.departmentId = id;
    };
    UiService.prototype.setpaperInDisciplineSession = function (ele) {
        this.paperInDisciplineSession = ele;
    };
    UiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiService_Factory() { return new UiService(i0.ɵɵinject(i1.ToastrService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Papa)); }, token: UiService, providedIn: "root" });
    return UiService;
}());
export { UiService };
