import { Component, OnInit } from '@angular/core';
import { UiService } from '../ui.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private _uiService:UiService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._uiService.changeAppTitle.next("Dashboard");
  }

  redirectTo(arg:any){
    this._router.navigate([arg]);
    return;
  }

}
