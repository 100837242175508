import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../../app/config';
import { DepartmentModel } from '../models/department.model';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OrganizationService = /** @class */ (function () {
    function OrganizationService(_http) {
        this._http = _http;
        this.onDepartmentsUpdated = new Subject();
        this.notifyDepartmentUpdate(null);
    }
    OrganizationService.prototype.notifyDepartmentUpdate = function (departmentId) {
        var _this = this;
        this._http.get(config.apiEndPointBase + "organization/departments").subscribe(function (result) {
            result.sort(function (x, y) {
                return x.name.localeCompare(y.name);
            });
            _this._departments = result;
            _this.onDepartmentsUpdated.next(departmentId);
        });
    };
    OrganizationService.prototype.getDepartments = function () {
        return this._departments;
    };
    OrganizationService.prototype.getDepartment = function (id) {
        if (this._departments == null)
            return null;
        var items = this._departments.filter(function (x) { return x.id == id; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    OrganizationService.prototype.saveDepartment = function (data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "department", data, {})
                .pipe(map(function (response) {
                return new DepartmentModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "department", data, {})
                .pipe(map(function (response) {
                return new DepartmentModel().deserialize(response);
            }));
        }
    };
    OrganizationService.prototype.employees = function (data) {
        return this._http
            .post(config.apiEndPointBase + "organization/employees", data, {})
            .pipe(map(function (response) {
            if (response.hasError) {
                throw new Error(response.message);
            }
            return response;
        }));
    };
    OrganizationService.prototype.addStudent = function (student) {
        return new Observable(function (observer) {
            setTimeout(function () {
                //console.log(student);
                observer.next({ me: 'hello' });
                observer.complete();
            }, 100);
        });
    };
    OrganizationService.prototype.hods = function () {
        return this._http
            .post(config.apiEndPointBase + "organization/hods", {}, {})
            .pipe(map(function (response) {
            response.sort(function (x, y) {
                return x.name.localeCompare(y.name);
            });
            return response;
        }));
    };
    OrganizationService.prototype.sendNotification = function (data) {
        return this._http
            .post(config.apiEndPointBase + "organization/employees", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    OrganizationService.prototype.saveRole = function (data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "organization/role", data, {})
                .pipe(map(function (response) {
                return response;
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "organization/role", data, {})
                .pipe(map(function (response) {
                return response;
            }));
        }
    };
    OrganizationService.prototype.setRole = function (data) {
        return this._http
            .put(config.apiEndPointBase + "organization/role/" + data.roleId + "/set", data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    OrganizationService.prototype.unsetRole = function (data) {
        return this._http
            .put(config.apiEndPointBase + "organization/role/" + data.roleId + "/unset/" + data.employeeId, data, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    OrganizationService.prototype.getCapabilities = function () {
        return this._http.get(config.apiEndPointBase + "organization/capabilities", {});
    };
    OrganizationService.prototype.getCapability = function (code) {
        return this._http.get(config.apiEndPointBase + "organization/capability/has/" + code, {});
    };
    OrganizationService.prototype.getMyCapabilities = function () {
        return this._http.get(config.apiEndPointBase + "organization/my-capabilities", {});
    };
    OrganizationService.prototype.getRoles = function () {
        return this._http.get(config.apiEndPointBase + "organization/roles", {});
    };
    OrganizationService.prototype.getRole = function (roleId) {
        return this._http.get(config.apiEndPointBase + "organization/role/" + roleId, {});
    };
    OrganizationService.prototype.getMyRoles = function () {
        return this._http.get(config.apiEndPointBase + "organization/my-roles", {});
    };
    OrganizationService.prototype.getEmployeeRoles = function (employeeId) {
        return this._http.get(config.apiEndPointBase + "organization/roles/" + employeeId, {});
    };
    OrganizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrganizationService_Factory() { return new OrganizationService(i0.ɵɵinject(i1.HttpClient)); }, token: OrganizationService, providedIn: "root" });
    return OrganizationService;
}());
export { OrganizationService };
