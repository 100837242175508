import { environment } from '../environments/environment';
var ɵ0 = {
    scriptPath: '/assets/papaparse.min.js'
};
var AppModule = /** @class */ (function () {
    function AppModule() {
        if (ENV_OVERWRITE) {
            for (var key in ENV_OVERWRITE) {
                environment[key] = ENV_OVERWRITE[key];
            }
        }
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
