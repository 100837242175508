import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from '../api-client/organization.service';
import { DepartmentService } from '../api-client/department.service';

@Component({
  selector: 'app-reviewers',
  templateUrl: './reviewers.component.html',
  styleUrls: ['./reviewers.component.css']
})
export class ReviewersComponent implements OnInit {

  employees: any;
  private _paramDepartmentId: any;

  private _organizationServiceSubscription: Subscription;
  private _paramsChangeSubscriber: Subscription
  
  constructor(
    private _route: ActivatedRoute,
    private _organizationService: OrganizationService,
    private _departmentService: DepartmentService    
  ) { }

  ngOnInit() {
    this._paramsChangeSubscriber = this._route.params.subscribe(params => {
      this._paramDepartmentId = params.id;
      this.refreshEmployeeInfo();
    });
    this._organizationServiceSubscription = this._organizationService.onDepartmentsUpdated.subscribe(_ => {
      this.refreshEmployeeInfo();
    });
  }

  private refreshEmployeeInfo() {
    
    this._departmentService.getEmployees(this._paramDepartmentId)
      .subscribe(employees => this.employees = employees);
  }

  ngOnDestroy(): void {
    this._paramsChangeSubscriber.unsubscribe();
    this._organizationServiceSubscription.unsubscribe();
  }

}
