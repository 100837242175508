/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./paper-student-map.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./paper-student-map.component";
import * as i4 from "../api-client/paper.service";
import * as i5 from "../api-client/academia.service";
import * as i6 from "@angular/router";
import * as i7 from "../ui.service";
import * as i8 from "ngx-export-as";
var styles_PaperStudentMapComponent = [i0.styles];
var RenderType_PaperStudentMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaperStudentMapComponent, data: {} });
export { RenderType_PaperStudentMapComponent as RenderType_PaperStudentMapComponent };
export function View_PaperStudentMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "form-group m-0 mr-2 d-flex justify-content-between align-items-center font-weight-500"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "em", [["class", "fa fa-upload position-relative ml-2 p-2 rounded-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["class", "hidden-file"], ["id", "importPapers"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadFile($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [["class", "m-0"], ["for", "importPapers"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("Import")); _ck(_v, 4, 0, currVal_0); }); }
export function View_PaperStudentMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-paper-student-map", [], null, null, null, View_PaperStudentMapComponent_0, RenderType_PaperStudentMapComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaperStudentMapComponent, [i4.paperService, i5.AcademiaService, i6.ActivatedRoute, i7.UiService, i8.ExportAsService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaperStudentMapComponentNgFactory = i1.ɵccf("app-paper-student-map", i3.PaperStudentMapComponent, View_PaperStudentMapComponent_Host_0, {}, {}, []);
export { PaperStudentMapComponentNgFactory as PaperStudentMapComponentNgFactory };
