import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '../customvalidation';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var ProgramFormBuilder = /** @class */ (function () {
    function ProgramFormBuilder(fb) {
        this.fb = fb;
        this.validationRules = {
            id: ['', ''],
            disciplineId: ['', Validators.required],
            numberOfSemesters: ['', [Validators.required, CustomValidator.numeric]],
            academicLevel: ['', Validators.required],
            name: ['', Validators.required]
        };
        this.form = fb.group(this.validationRules);
    }
    ProgramFormBuilder.prototype.getForm = function () {
        return this.form;
    };
    ProgramFormBuilder.prototype.setValues = function (response) {
        this.form.patchValue({
            id: response.id,
            disciplineId: response.disciplineId,
            name: response.name,
            academicLevel: response.academicLevel,
            numberOfSemesters: response.numberOfSemesters
        });
    };
    ProgramFormBuilder.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProgramFormBuilder_Factory() { return new ProgramFormBuilder(i0.ɵɵinject(i1.FormBuilder)); }, token: ProgramFormBuilder, providedIn: "root" });
    return ProgramFormBuilder;
}());
export { ProgramFormBuilder };
