import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { ProgramService } from '../api-client/program.service';
import { AcademiaService } from '../api-client/academia.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UiService } from '../ui.service';
import { config } from '../config';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit, OnChanges, OnDestroy {


  @Input() programId: any;
  private _academiaServiceSubscription: Subscription;

  heading: any;
  showHeader:boolean = false;
  csvPapers = [];
  papers = {};
  constructor(
    private _progmramService: ProgramService,
    private _academiaService: AcademiaService,
    private _router: Router,
    private _uiSerice: UiService,
    private _exportAsService: ExportAsService
  ) {
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.populateCourses();
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.populateCourses();
  }

  populateCourses() {
    var selectedSession = this._academiaService.getActiveAcademicSession();
    if (this.programId == null) return;
    var sessionName = this._academiaService.getActiveAcademicSession().name;
    var programName = this._academiaService.getProgram(this.programId).name;
    this.heading = programName + " Of " + sessionName;

    this._progmramService.getSessionWisePapers(this.programId, selectedSession.id)
      .subscribe((response: any) => {
        this.papers = {};
        var result = response.items;
        this.csvPapers = result;
        var k = -1;
        for (var i = 0; i < result.length; i++) {
          if (!this.papers[result[i].semester]) {
            this.papers[result[i].semester] = [];
          }
          this.papers[result[i].semester].push(result[i]);
        }
        this._uiSerice.setQuickItems(result.map((paper: any) => {
          return {
            text: paper.code + paper.label,
            icon: 'fa fa-info',
            action: () => {
              this._router.navigateByUrl("/paper/" + paper.id);
            }
          };
        }));
      });
  }

  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
  }

  isEmpty() {
    return Object.keys(this.papers).length == 0;
  }

  openSummary(paper: any) {

    this._router.navigate([{
      outlets: { rightMainPopup: ['paper-summary', paper.id] }
    }]);
  }

  export(type: any, elementId: any, name: any) {
    this.showHeader = true;
    var configs = { type: type, elementId: elementId };
    setTimeout(()=>{
      this._exportAsService.save(configs, name).subscribe(() => {
        this.showHeader = false;
      });
    },10);
    
  }

  exportCsv() {
    var headers = [];
    var fetchDate = [];
    var result = this.csvPapers;
    for(var i=0;i<result.length;i++){
      fetchDate.push({ "semester": result[i].semester, "groupCode": result[i].groupCode, "code": result[i].code, "name": result[i].name, "credit": result[i].credit, "fullMarks": result[i].fullMarks });
    }    
    for (var i = 0; i < config.exportCsvPaper.length; i++) {
      headers.push({ align: "center", allowEmpty: false, editor: null, name: config.exportCsvPaper[i], options: [], source: [], title: this.exportcsvHeader(config.exportCsvPaper[i]), type: "text", width: 195 });
    }
    this._uiSerice.openDataPreviewWindow(fetchDate, headers, []);
  }

  exportcsvHeader(key: any) {
    var title = key;
    title = title.replace(/([A-Z])/g, " $1");
    title = title.charAt(0).toUpperCase() + title.slice(1);
    return title;
  }

}
