<!-- <a routerLink="/home">Home</a>
<a routerLink="/about">About</a> -->
<div>
    <header class="head-main position-fixed w-100">
        <div class="navbar navbar-dark lighten-2 box-shadow p-1">
            <div class="container-fluid d-flex justify-content-between">
                <section class="d-flex align-items-center justify-content-between">
                    <a href="javascript:void(0);" class="navbar-brand position-relative mr-3" title="Menu"
                        (click)="toggleNavMenu()">
                        <img src="assets/images/presidency_university_logo.png" alt="" style="height: 80px;">
                    </a>
                    <div class="h4 m-0">
                        {{ title | translate}}
                    </div>
                </section>
                <div class="d-flex align-items-center">
                    <div class="dashboard-menu d-flex justify-content-center align-items-center font-weight-600 mr-2"><a
                            class="text-color" (click)="redirectTo('home')"><i class="fa fa-home"
                                aria-hidden="true"></i> Dashboard</a></div>
                    <!-- Basic dropdown -->
                    <section class="section-preview position-relative">
                        <button class="btn dropdown-toggle font-weight-500" type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" *ngIf="selectedSession"><span
                                class="mr-2 text-color">{{ 'Language' | translate }}:</span>
                            {{langName}}</button>

                        <div class="dropdown-menu z-depth-1">
                            <a class="dropdown-item font-weight-500"
                                (click)="changeLanguage('en_US', 'English')">{{ 'English' | translate }}</a>
                            <a class="dropdown-item font-weight-500"
                                (click)="changeLanguage('bn_IN', 'Bengali')">{{ 'Bengali' | translate }}</a>
                        </div>
                        <!-- Basic dropdown -->
                    </section>
                    <!-- Basic dropdown -->
                    <section class="section-preview position-relative">
                        <button class="btn dropdown-toggle font-weight-500" type="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" *ngIf="selectedSession"><span
                                class="mr-2 text-color">{{ 'Session' | translate }}:</span>
                            {{selectedSession.name}}</button>

                        <div class="dropdown-menu z-depth-1">
                            <a *ngFor="let session of sessions" class="dropdown-item font-weight-500"
                                (click)="selectSession(session)">{{session.name}} <em *ngIf="session.active"
                                    class="fa fa-circle active_row"></em></a>
                            <a (click)="redirectTo('sessions')" class="border-top dropdown-item font-weight-500">
                                {{ 'Manage Session' | translate }} <em class="fa fa-pencil mr-2"></em></a>
                        </div>
                        <!-- Basic dropdown -->
                    </section>
                    <section class="section-preview position-relative">
                        <a (click)="logout()" title="logout">
                            <em class="fa fa-sign-out" aria-hidden="true"></em>
                        </a>
                    </section>
                </div>
            </div>

        </div>

    </header>
</div>