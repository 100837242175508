import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { DisciplineService } from '../api-client/discipline.service';
import { UtilityService } from '../api-client/utility.service';
import { paperService } from '../api-client/paper.service';

@Component({
  selector: 'app-examiner-list',
  templateUrl: './examiner-list.component.html',
  styleUrls: ['./examiner-list.component.css']
})
export class ExaminerListComponent implements OnInit {
  disciplineId: any;
  employees: any = [];
  type: any;
  selectedExaminerArray: any;
  selectedReviewerArray: any;
  paperId: any;
  examId: any;
  examiners: any = [];
  reviewers: any = [];
  thirdExaminer: any = [];

  constructor(
    private _uiService: UiService,
    private _route: ActivatedRoute,
    private _router: Router, private _disciplineService: DisciplineService,
    private _utilityService: UtilityService,
    private _paperService: paperService,) {

  }

  async ngOnInit() {
    this._route.params.subscribe(async params => {
      this.employees = [];
      this.disciplineId = params.id;
      this.type = params.type;
      this.paperId = params.paperId;
      this.examId = params.examId;
      if (this.paperId && this.examId) {
        await this.selectExam(this.examId);
      }
      if (this.disciplineId)
        await this.getDisciplineWiseEmployees(this.disciplineId);
    })
  }

  async getDisciplineWiseEmployees(disciplineId: any) {
    var result: any = await this._disciplineService.getEmployees(disciplineId).toPromise();
    await result.forEach(async (value: any, index: any) => {
      this.employees.push({ item: value, checked: false, allocatedMarks: '', uploadedMidMarks: false, uploadedEndMarks: '', uploadedEndTermMarks: false });
    });

    await this.createData();
  }

  async createData() {
    if (this.reviewers.length > 0 && this.type == 1) {
      this.employees = this.employees.filter((gameToken) => this.reviewers.toString().includes(gameToken.item.id.toString()) == false);
    }
    if (this.type == 2 || this.type == 3) {
      this.employees = this.employees.filter((gameToken) => this.examiners.toString().includes(gameToken.item.id.toString()) == false);
    }

  }

  async selectExam(examId: any) {
    this.examiners = [];
    this.reviewers = [];
    this.thirdExaminer = [];
    if (!examId) return null;
    var result: any = await this._paperService.getEvaluators(this.paperId, this.examId).toPromise();
    await result.forEach(async (data: any, index: any) => {
      if (data.type == 2) {
        this.examiners.push(data.examinerId);
      } else if (data.type == 4) {
        this.reviewers.push(data.examinerId);
      } else {
        this.thirdExaminer.push(data.examinerId);
      }
    });
  }

  assignEmployee(e: any, type: any) {
    var selectedEmp = this.employees.filter((e) => e.checked == true);
    if (type == 1) {
      this._utilityService.submittedEmpArray(selectedEmp);
    }
    if (type == 2) {
      this._utilityService.submittedReviewerArray(selectedEmp);
    }
    if (type == 104) {
      this._utilityService.submittedThirdExaminerArray(selectedEmp);
    }

  }
  onSelect() {

  }

}
