import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class CustomLanguageLoader implements TranslateLoader{

    constructor(
        private _http: HttpClient,
        private _toster:ToastrService,
        private _papa: Papa){};

    getTranslation(lang: string): Observable<any> {
        return this._http
            .get('assets/lang/' + lang + '.csv', {responseType: 'text'})
            .pipe(
                map(
                    data => {
                        var values = this._papa.parse(data).data;
                        var valuesTransform = {};
                        for(var i=1; i<values.length; i++){
                            valuesTransform[values[i][0] + ''] = values[i][1]+ '';
                        }
                        return valuesTransform;
                    },
                    catchError((error) => {
                        this._toster.error(error);
                        return error;
                    })
                )
            );
    }
}
