import { Deseriazable } from './deserializable.model';

export class TemplateModel implements Deseriazable{
    
    id: string;
    tag: string;
    subject: Text;
    emailContent:Text;
    smsContent:string;
    params:string;
    pdfContent:string


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}