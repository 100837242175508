import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { WebServiceInterceptor } from '../app/api-client/web-service-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { LoginComponent } from './login/login.component';
import { OrganizationComponent } from './organization/organization.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { DepartmentUsersComponent } from './department-users/department-users.component';
import { TreeviewModule } from 'ngx-treeview';
import { DepartmentComponent } from './department/department.component';
import { ProgramComponent } from './program/program.component';
import { SemesterWiseStudentsComponent } from './semester-wise-students/semester-wise-students.component';
import { CoursesComponent } from './courses/courses.component';
import { HttpClientModule } from '@angular/common/http';
import { ApiClientModule } from './api-client/api-client.module';
import { BlankComponent } from './blank/blank.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { StudentComponent } from './student/student.component';
import { StudentSummaryComponent } from './student-summary/student-summary.component';
import { PaperSummaryComponent } from './paper-summary/paper-summary.component';
import { PaperComponent } from './paper/paper.component';
import { ExaminersComponent } from './examiners/examiners.component';
import { ReviewersComponent } from './reviewers/reviewers.component';
import { PaperWiseStudentsComponent } from './paper-wise-students/paper-wise-students.component';
import { StudentsListComponent } from './students-list/students-list.component';
import { SessionsComponent } from './sessions/sessions.component';
import { ExamsComponent } from './exams/exams.component';
import { PapersComponent } from './papers/papers.component';
import { StudentsComponent } from './students/students.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SessionDetailsComponent } from './session-details/session-details.component';
import { DisciplineDetailsComponent } from './discipline-details/discipline-details.component';
import { ProgramDetailsComponent } from './program-details/program-details.component';
import { OrganizationDetailsComponent } from './organization-details/organization-details.component';
import { StaffDetailsComponent } from './staff-details/staff-details.component';
import { DataImportMapperComponent } from './data-import-mapper/data-import-mapper.component';
import { DataPreviewComponent } from './data-preview/data-preview.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomLanguageLoader } from './utility/custom-language-loader';
import { ExportAsModule } from 'ngx-export-as';
import { SearchEmployeeComponent } from './search-employee/search-employee.component';
import { ExaminerAllocationStatusComponent } from './examiner-allocation-status/examiner-allocation-status.component';
import { MarksSubmissionStatusComponent } from './marks-submission-status/marks-submission-status.component';
import { LoggedInUserStatusComponent } from './logged-in-user-status/logged-in-user-status.component';
import { InviteExaminerAllocationComponent } from './invite-examiner-allocation/invite-examiner-allocation.component';
import { NotificationTemplateComponent } from './notification-template/notification-template.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivilegesComponent } from './privileges/privileges.component';
import { HodWiseDisciplineComponent } from './hod-wise-discipline/hod-wise-discipline.component';
import { NgxPopper } from 'angular-popper';
import { StaffsComponent } from './staffs/staffs.component';
import { PaperStudentMapComponent } from './paper-student-map/paper-student-map.component';
import { SearchStudentsComponent } from './search-students/search-students.component';
import { PaperWiseBacklogStudentsComponent } from './paper-wise-backlog-students/paper-wise-backlog-students.component';
import { ExaminerListComponent } from './examiner-list/examiner-list.component';
import { environment } from '../environments/environment';
declare const ENV_OVERWRITE: any;

@NgModule({
  declarations: [
    AppComponent,
    HeaderMenuComponent,
    HomeBannerComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    LoginComponent,
    DepartmentComponent,
    OrganizationComponent,
    ProgramComponent,
    SemesterWiseStudentsComponent,
    CoursesComponent,
    DepartmentUsersComponent,
    BlankComponent,
    MainMenuComponent,
    StudentComponent,
    StudentSummaryComponent,
    PaperSummaryComponent,
    PaperComponent,
    ExaminersComponent,
    ReviewersComponent,
    PaperWiseStudentsComponent,
    StudentsListComponent,
    SessionsComponent,
    ExamsComponent,
    PapersComponent,
    StudentsComponent,
    SessionDetailsComponent,
    DisciplineDetailsComponent,
    ProgramDetailsComponent,
    OrganizationDetailsComponent,
    StaffDetailsComponent,
    DataImportMapperComponent,
    DataPreviewComponent,
    SearchEmployeeComponent,
    ExaminerAllocationStatusComponent,
    MarksSubmissionStatusComponent,
    LoggedInUserStatusComponent,
    InviteExaminerAllocationComponent,
    NotificationTemplateComponent,
    ResetPasswordComponent,
    HodWiseDisciplineComponent,
    PrivilegesComponent,
    StaffsComponent,
    PaperStudentMapComponent,
    SearchStudentsComponent,
    PaperWiseBacklogStudentsComponent,
    ExaminerListComponent

  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    GoogleChartsModule.forRoot(),
    TreeviewModule.forRoot(),
    NgxPopper,
    AngularMultiSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FilterPipeModule,
    AngularFontAwesomeModule,
    HttpClientModule,
    CKEditorModule,
    ApiClientModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: CustomLanguageLoader }
    }),
    ExportAsModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'us' },
    { provide: HTTP_INTERCEPTORS, useClass: WebServiceInterceptor, multi: true },
    {
      provide: 'PapaParseGlobalConfig',
      useValue: <any>{
        scriptPath: '/assets/papaparse.min.js'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    if (ENV_OVERWRITE) {
      for (const key in ENV_OVERWRITE) {
        environment[key] = ENV_OVERWRITE[key];
      }
    }
  }
}
