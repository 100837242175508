<ng-template #na><span> NA</span></ng-template>
<form [formGroup]="staffForm" (ngSubmit)="onSubmit()">
    <div class="p-3 vw-25">
        <div class="d-flex icon-set justify-content-around p-2 mb-3">

            <div class="text-danger">
                <em class="fa fa-trash"></em>
                {{ 'Delete' | translate }}
            </div>
            <div class="text-warning">
                <em class="fa fa-ban"></em>
                {{ 'Block' | translate }}
            </div>
            <div (click)="editPaper()" *ngIf="editMode" class="text-default">
                <em class="fa fa-pencil"></em>
                {{ 'Edit' | translate }}
            </div>
            <div *ngIf="!editMode" class="text-default">
                <button class="btn text-100 p-0 z-depth-0 text-default"><em class="fa fa-save"></em>
                    <span>{{ 'Save' | translate }}</span></button>

            </div>
        </div>
        <div class="scroll_div">
            <div class="pt-2 info-text">
                <div class="text-muted">Faculty Name</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }"></div>
                <div *ngIf="submitted && f.name.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">{{ 'Name is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Gender' | translate }}</div>
                <div class="">
                    <select formControlName="gender" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                        <option value="">{{ 'Select gender' | translate }}</option>
                        <option *ngFor="let gn of settings.gender" [value]="gn">{{gn}}</option>
                    </select></div>
                <div *ngIf="submitted && f.gender.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.gender.errors.required">{{ 'Gender is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Blood Group' | translate }}</div>
                <div class="">
                    <select formControlName="bloodGroup" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.bloodGroup.errors }">
                        <option value="">{{ 'Select blood group' | translate }}</option>
                        <option *ngFor="let blood of settings.bloodGroup" [value]="blood">{{blood}}</option>
                    </select></div>
                <div *ngIf="submitted && f.bloodGroup.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.bloodGroup.errors.required">{{ 'Blood group is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Address' | translate }}</div>
                <div class=""><textarea class="w-100 form-control" formControlName="address"
                        [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                </div>
                <div *ngIf="submitted && f.address.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.address.errors.required">{{ 'Address is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Email' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"></div>
                <div *ngIf="submitted && f.email.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">{{ 'Email is required' | translate }}</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Mobile' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="mobile">
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Designation' | translate }}</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="designation">
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">Date Of join</div>
                <div class=""><input type="text" class="w-100 form-control" formControlName="dateOfJoin"
                        [owlDateTime]="parmanent" [owlDateTimeTrigger]="parmanent" />
                    <owl-date-time [pickerType]="'calendar'" #parmanent></owl-date-time>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">Discontinued</div>
                <div class="">
                    <select formControlName="discontinued" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.discontinued.errors }">
                        <option value="">Select </option>
                        <option *ngFor="let gn of settings.discontinued | keyvalue" [value]="gn.key">{{gn.value}}
                        </option>
                    </select></div>
                <div *ngIf="submitted && f.discontinued.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.discontinued.errors.required">Discontinued required</div>
                </div>
            </div>
            <div class="pt-2 info-text">
                <div class="text-muted">Faculty</div>
                <div class="">
                    <select formControlName="type" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                        <option value="">Select </option>
                        <option *ngFor="let gn of settings.employeeType | keyvalue" [value]="gn.key">{{gn.value}}
                        </option>
                    </select></div>
                <div *ngIf="submitted && f.type.errors" style="display: block;" class="invalid-feedback">
                    <div *ngIf="f.type.errors.required">Required</div>
                </div>
            </div>

            <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Email Signature' | translate }}</div>
                <div class=""><textarea class="w-100 form-control" formControlName="emailSignature"></textarea>
                </div>
            </div>

        </div>
    </div>
    <input type="hidden" formControlName="id">
</form>