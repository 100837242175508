import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StaffModel } from '../models/staff.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AcademiaService } from '../api-client/academia.service';
import { staffFormBuilder } from './staff.form-builder';
import { DepartmentService } from '../api-client/department.service';
import { EmployeeService } from '../api-client/employee.service';
import { config } from '../config';

@Component({
  selector: 'app-staff-details',
  templateUrl: './staff-details.component.html',
  styleUrls: ['./staff-details.component.css']
})
export class StaffDetailsComponent implements OnInit {

  private _departmentServiceSubscription: Subscription;

  staffId: any;
  editMode: boolean = false;
  submitted = false;
  model = new StaffModel();
  staffForm: any;
  settings = config;
  constructor(
    private _route: ActivatedRoute,
    private _toster: ToastrService,
    private _departmentService: DepartmentService,
    private _formBuilder: staffFormBuilder,
    private _employeeSearvice: EmployeeService,
    private _router: Router) {
  }

  ngOnInit() {
    this.staffForm = this._formBuilder.getForm();
    this._route.params.subscribe(params => {
      this.staffId = params.id;
      this.resetForm();
      this.staffDetails();
    })
  }

  get f() { return this.staffForm.controls; }

  staffDetails() {
    if (this.staffId == null) return;
    this._departmentService.getEmployee(this.staffId)
      .subscribe((result: any) => {
        this._formBuilder.setValues(result);
      });
  }

  ngOnDestroy(): void {
    
  }

  editPaper() {
    this.editMode = false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.staffForm.invalid) {
      return;
    }

    this.model.deserialize(this.staffForm.value);
    this._employeeSearvice.saveEmployee(this.model)
      .subscribe(
        (response: StaffModel) => {
          this._toster.success("Faculty saved");
          this.editMode = true;
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

  resetForm() {
    this.submitted = false;
    this.staffForm.reset();
  }

}
