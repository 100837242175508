import { OnInit, OnChanges, OnDestroy } from '@angular/core';
import { StudentService } from '../api-client/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentModel } from '../models/student.model';
import { ToastrService } from 'ngx-toastr';
import { AcademiaService } from '../api-client/academia.service';
import { studentFormBuilder } from './student.form-builder';
import { NotifyService } from '../api-client/notify.service';
import { config } from '../config';
var StudentSummaryComponent = /** @class */ (function () {
    function StudentSummaryComponent(_studentService, _route, _toster, _academiaService, _formBuilder, _router, _notifyService) {
        var _this = this;
        this._studentService = _studentService;
        this._route = _route;
        this._toster = _toster;
        this._academiaService = _academiaService;
        this._formBuilder = _formBuilder;
        this._router = _router;
        this._notifyService = _notifyService;
        this.displayDetailIcon = true;
        this.student = {};
        this.editMode = false;
        this.submitted = false;
        this.model = new StudentModel();
        this.settings = config;
        this._academiaSessionServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(function (_) {
            _this.sessions = _this._academiaService.getAcademicSession();
        });
        this._academiaProgramServiceSubscription = _academiaService.onProgramsUpdated.subscribe(function (_) {
            _this.programs = _this._academiaService.getPrograms();
        });
        this._studentServiceSubscription = _studentService.onStudentsUpdated.subscribe(function (_) {
            _this.studentDetails();
        });
    }
    Object.defineProperty(StudentSummaryComponent.prototype, "f", {
        get: function () { return this.studentForm.controls; },
        enumerable: true,
        configurable: true
    });
    StudentSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.studentForm = this._formBuilder.getForm();
        this.sessions = this._academiaService.getAcademicSession();
        this.programs = this._academiaService.getPrograms();
        this._route.params.subscribe(function (params) {
            _this.studentId = params.id;
            _this.resetForm();
            _this.studentDetails();
        });
        this._notifyService.onStudentInfUpdateNotify.subscribe(function (student) {
            if (student.id == _this.studentId) {
                //this._toster.error("Someone update same student");
                _this._formBuilder.setValues(student);
                return;
            }
        });
    };
    StudentSummaryComponent.prototype.ngOnChanges = function (changes) {
        this.studentDetails();
    };
    StudentSummaryComponent.prototype.studentDetails = function () {
        var _this = this;
        if (this.studentId == null)
            return;
        this._studentService.getStudent(this.studentId)
            .subscribe(function (result) {
            _this.student = result;
            _this._formBuilder.setValues(result);
        });
    };
    StudentSummaryComponent.prototype.redirectToStudentDetails = function (student) {
        this._router.navigateByUrl("/student/" + student.id);
    };
    StudentSummaryComponent.prototype.ngOnDestroy = function () {
        this._studentServiceSubscription.unsubscribe();
        this._academiaSessionServiceSubscription.unsubscribe();
        this._academiaProgramServiceSubscription.unsubscribe();
    };
    StudentSummaryComponent.prototype.editPaper = function () {
        this.editMode = false;
    };
    StudentSummaryComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.studentForm.invalid) {
            this._toster.error("Validation Error");
            return;
        }
        this.model.deserialize(this.studentForm.value);
        if (this.model.blocked === null) {
            this.model.blocked = false;
        }
        if (this.model.migrated === null) {
            this.model.migrated = false;
        }
        if (this.model.discontinued === null) {
            this.model.discontinued = false;
        }
        this._studentService.saveStudent(this.model)
            .subscribe(function (response) {
            _this._toster.success("Student saved");
            _this._notifyService.studentUpdated(_this.model);
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    StudentSummaryComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.studentForm.reset();
    };
    StudentSummaryComponent.prototype.migrateBlock = function (typy, student) {
        var _this = this;
        this.submitted = true;
        if (typy === 1) {
            this.studentForm.get('migrated').setValue(student);
        }
        else {
            this.studentForm.get('blocked').setValue(student);
        }
        this.model.deserialize(this.studentForm.value);
        console.log(this.model);
        this._studentService.saveStudent(this.model)
            .subscribe(function (response) {
            if (typy === 1) {
                if (student === true) {
                    _this._toster.success("Student migrated successfully");
                }
                else {
                    _this._toster.success("Student unmigrated successfully");
                }
            }
            else {
                if (student === true) {
                    _this._toster.success("Student block successfully");
                }
                else {
                    _this._toster.success("Student unblock successfully");
                }
            }
            _this._notifyService.studentUpdated(_this.model);
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    return StudentSummaryComponent;
}());
export { StudentSummaryComponent };
