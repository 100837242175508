import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { DisciplineModel } from '../models/discipline.model';

@Injectable({
  providedIn: 'root'
})
export class DisciplineService {
  private _disciplines: any;

  public onDisciplinesUpdated = new Subject();

  constructor(private _http: HttpClient) {
    
  }

  getDiscipline(id: any) {
    return this._http.get(config.apiEndPointBase + "discipline/"+id);
  }

  saveDiscipline(data: DisciplineModel): Observable<DisciplineModel> {
    if (data.id) {
      return this._http
        .put(config.apiEndPointBase + "discipline", data, {})
        .pipe(map((response: any) => {
          return new DisciplineModel().deserialize(response);
        }));
    } else {
      return this._http
        .post(config.apiEndPointBase + "discipline", data, {})
        .pipe(map((response: any) => {
          return new DisciplineModel().deserialize(response);
        }));
    }
  }

  getEmployees(disciplineId:any){
    return this._http.get(config.apiEndPointBase + "discipline/"+disciplineId+"/employees");
  }

  linkUnlinkEmployee(data:any,type:any): Observable<any> {
      return this._http
        .put(config.apiEndPointBase + "discipline/"+data.disciplineId+"/employee/"+type+"/"+data.employeeId, {}, {})
        .pipe(map((response: any) => {
          return response;
        }));
  }
}
