import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { config } from '../config';
import { paperService } from '../api-client/paper.service';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from '../api-client/organization.service';
import { CommonMethods } from '../common-methods';

@Component({
  selector: 'app-staffs',
  templateUrl: './staffs.component.html',
  styleUrls: ['./staffs.component.css']
})
export class StaffsComponent implements OnInit {

  private _paperServiceSubscription: Subscription;
  private _academiaServiceSubscription: Subscription;

  staffs: any;
  allEmp: any;
  session: any;
  disciplines: any;
  programs: any;
  filterKeyword = "";
  filterProgram = [];
  filterSession = "";
  filterSemester = 0;
  filterRegistrationYear: any = null;
  filterBatchStartYear: any = null;
  filters = {};
  pageSize: number = config.paginationPerPage;
  totalItemsCount: number = 0;
  totalPageCount: number = 0;
  papersCurrentPage: any = 1;
  academiaSessions: any;
  staffcSVHeader = config.staffcSVHeader;  

  constructor(
    private _paperService: paperService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _translate: TranslateService,
    private _organizationService: OrganizationService,
    private _router: Router, ) {
    
  }

  ngOnInit() {
    this._uiService.changeAppTitle.next("Faculties");    
    this.staffFilter();
    this.allEmployee();
  }

  staffFilter() {
    this.filters = {
      "freeText": this.filterKeyword,
      "startIndex": (this.papersCurrentPage - 1) * this.pageSize,
      "pageSize": this.pageSize
    };
    this._organizationService.employees(this.filters).subscribe((result: any) => {      
      this.staffs = result.items;
      this.totalItemsCount = result.totalCount;
      this.totalPageCount = Math.ceil(this.totalItemsCount / this.pageSize);
    })
  }

  onPageChange(pageIndex: any) {
    this.papersCurrentPage = pageIndex;
    this.staffFilter();
    this.allEmployee();
  }

  redirectTo(staff: any) {
    //this._router.navigate(["student/"+student.id]);
    this._router.navigate([{
      outlets: { rightMainPopup: ['staff-details', staff.id] }
    }]);
  }

  ngOnDestroy(): void {

  }

  previewCsvData(fieldMaps: any, results: any) {
    this._uiService.indeterminateProgressTextSet.next('Arrange data...');
    var columns = [];
    var columnSources = [];
    var columnsWidth = {};
    
    var arrangedData = results.map((value: any) => {
      var item = {};
      for (var key in fieldMaps) {
        var valueWidth = (value[fieldMaps[key]] + '').length * 15;
        item[key] = value[fieldMaps[key]];
        if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
          columnsWidth[key] = valueWidth;
        }
      }
      item['type']=4;
      item['discontinued']= false;
      //debugger;
      return item;
    });

    this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
    this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
    columnsWidth = null;

    this._uiService.indeterminateProgressTextSet.next(null);

    this._uiService.openDataPreviewWindow(arrangedData, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
        }
      }
    ]);
  }

  onImportPreviewCompleted(fields: any, arrangedData: any, columns: any) {
    var data = this._uiService.getPreviewModifiedData();
    var converted = data.map((value: any) => {
      var item = {};
      for (var i = 0; i < fields.length; i++) {
        item[fields[i]] = value[i];
      }
      
      return item;
    });
    this._uiService.closeDataPreview();
    var worker = new Worker('../workers/import-staffs.worker', { type: 'module' });
    
    this._uiService.startWorker(worker, converted)
      .then(() => {
        //show success message, if required
      }).catch(e => {
        if (e.validation) {
          this.onImportValidationError(e.validation, converted, columns, fields);
        }
      });
  }

  onImportValidationError(validation: any, converted: any, columns: any, fields: any) {
    this._uiService.onDataPreviewReady = () => {
      var allFields = Object.keys(converted[0]);
      for (var i = 0; i < validation.length; i++) {
        var commonErrors = [];
        for (var field in validation[i]) {
          var fieldIndex = allFields.indexOf(field);
          if (fieldIndex <= 0) {
            commonErrors = commonErrors.concat(validation[i][field]);
          } else {
            this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
          }
        }
        if (commonErrors.length > 0) {
          this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
        }
      }
    };
    this._uiService.openDataPreviewWindow(converted, columns, [
      {
        type: 'i',
        content: 'done_all',
        onclick: () => {
          this.onImportPreviewCompleted(fields, converted, columns);
        }
      }
    ]);
  }

  mapCsvField(results: any) {
    this._uiService
      .mapCsvField(results.meta.fields, this.staffcSVHeader)
      .then((maps: any) => {
        this.previewCsvData(maps, results.data);
      }).catch(() => { });
  }

  uploadFile(fileEle: any) {
    this._uiService
      .parseCsv(fileEle.files[0])
      .then((results: any) => {
        fileEle.value = null;
        this.mapCsvField(results);
      }).catch(() => {
        fileEle.value = null;
      });
  }

  relode() {
    this.staffFilter();
    this.allEmployee();
  }

  allEmployee(){
    var params = {
      "freeText": "",
      "startIndex": 0,
      "pageSize": 0
    };
    this._organizationService.employees(params).subscribe((result: any) => {
      this.allEmp = result.items;
    });
  }

  exportCsv() {
    var headers = [];
    var fetchDate = [];
    var result = this.allEmp;
     for (var i = 0; i < this.allEmp.length; i++) {
      fetchDate.push({ "name": result[i].name, 
                       "address": result[i].address,
                       "bloodGroup":result[i].bloodGroup, 
                       "discontinued": result[i].discontinued, 
                       "email": result[i].email, 
                       "emailSignature": result[i].emailSignature,
                       "gender": result[i].gender,
                       "mobile": result[i].mobile,
                       "designation": result[i].designation,
                       "dateOfJoin": CommonMethods.usDateToDb(result[i].dateOfJoin),
                       "type": result[i].type,
                       "releaseDate": CommonMethods.usDateToDb(result[i].releaseDate)                     
                      });
    } 
     for (var i = 0; i < config.staffcSVHeader.length; i++) {
      headers.push({ align: "center", allowEmpty: false, editor: null, name: config.staffcSVHeader[i], options: [], source: [], title: CommonMethods.exportcsvHeader(config.staffcSVHeader[i]), type: "text", width: 195 });
    }
    this._uiService.openDataPreviewWindow(fetchDate, headers, []);
  }

}
