import { Component, OnInit, OnChanges } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { Subscription } from 'rxjs';
import { SessionModel } from '../models/session.model';
import { ToastrService } from 'ngx-toastr';
import { SessionFormBuilder } from '../session-details/session.form-builder';
import { SessionService } from '../api-client/session.service';
import { NotifyService } from '../api-client/notify.service';
import {config} from '../config';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.css']
})
export class SessionDetailsComponent implements OnInit {


  sessionId: any;
  academiaSessions: any;
  session = {};
  editMode: boolean = false;
  submitted = false;
  model = new SessionModel();
  sessionForm: any;
  settings = config;

  private _academiaServiceSubscription: Subscription;

  constructor(
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _uiService: UiService,
    private _sessionService: SessionService,
    private _toster: ToastrService,
    private _formBuilder: SessionFormBuilder,
    private _router: Router,
    private _notifyService:NotifyService) {
  }

  get f() { return this.sessionForm.controls; }

  ngOnInit() {
    this.sessionForm = this._formBuilder.getForm();
    this._route.params.subscribe(params => {
      this.sessionId = params.id;
      this.resetForm();
      this.getSession(params.id);
    });
    this._notifyService.onSessionInfoUpdateNotify.subscribe((session)=>{
      if(session.id==this.sessionId){
        this._toster.error("Someone update same session");
        this._formBuilder.setValues(session);
        return;
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.sessionForm.invalid) {
      return;
    }
    
    this.model.deserialize(this.sessionForm.value);
    if(!this.model.active){
      this.model.active = false;
    }
    this._sessionService.saveSession(this.model)
      .subscribe(
        (response: SessionModel) => {
          this._toster.success("Session saved");
          this._notifyService.sessionUpdated(this.model);
          this.editMode = true;
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

  editSession() {
    this.editMode = false;
  }

  getSession(sessionId:any){
    this._sessionService.getSession(sessionId).subscribe((result:any)=>{
      this._formBuilder.setValues(result);
    })
  }

  resetForm(){
    this.submitted = false;
    this.sessionForm.reset();
  }

}
