/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./examiner-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./examiner-list.component";
import * as i5 from "../ui.service";
import * as i6 from "@angular/router";
import * as i7 from "../api-client/discipline.service";
import * as i8 from "../api-client/utility.service";
import * as i9 from "../api-client/paper.service";
var styles_ExaminerListComponent = [i0.styles];
var RenderType_ExaminerListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExaminerListComponent, data: {} });
export { RenderType_ExaminerListComponent as RenderType_ExaminerListComponent };
function View_ExaminerListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "a", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.assignEmployee(_v.context.$implicit, _co.type) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "span", [["class", "h5 m-0 pb-2 d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "input", [["class", "mr-2"], ["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.context.$implicit.checked = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(7, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(9, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(10, null, [" ", ""]))], function (_ck, _v) { var currVal_7 = _v.context.$implicit.checked; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 9).ngClassValid; var currVal_5 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = _v.context.$implicit.item.name; _ck(_v, 10, 0, currVal_8); }); }
export function View_ExaminerListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "p-3 vw-25"], ["style", "overflow-y: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "scroll_div"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExaminerListComponent_1)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "center-popup-footer p-2 text-right grey lighten-3 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-primary btn-sm d-flex align-items-center font-weight-500"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSelect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Select "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.employees; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ExaminerListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-examiner-list", [], null, null, null, View_ExaminerListComponent_0, RenderType_ExaminerListComponent)), i1.ɵdid(1, 114688, null, 0, i4.ExaminerListComponent, [i5.UiService, i6.ActivatedRoute, i6.Router, i7.DisciplineService, i8.UtilityService, i9.paperService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExaminerListComponentNgFactory = i1.ɵccf("app-examiner-list", i4.ExaminerListComponent, View_ExaminerListComponent_Host_0, {}, {}, []);
export { ExaminerListComponentNgFactory as ExaminerListComponentNgFactory };
