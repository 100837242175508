/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reviewers.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./reviewers.component";
import * as i3 from "@angular/router";
import * as i4 from "../api-client/organization.service";
import * as i5 from "../api-client/department.service";
var styles_ReviewersComponent = [i0.styles];
var RenderType_ReviewersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReviewersComponent, data: {} });
export { RenderType_ReviewersComponent as RenderType_ReviewersComponent };
export function View_ReviewersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["reviewers works!"]))], null, null); }
export function View_ReviewersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reviewers", [], null, null, null, View_ReviewersComponent_0, RenderType_ReviewersComponent)), i1.ɵdid(1, 245760, null, 0, i2.ReviewersComponent, [i3.ActivatedRoute, i4.OrganizationService, i5.DepartmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReviewersComponentNgFactory = i1.ɵccf("app-reviewers", i2.ReviewersComponent, View_ReviewersComponent_Host_0, {}, {}, []);
export { ReviewersComponentNgFactory as ReviewersComponentNgFactory };
