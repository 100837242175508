import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { ProgramService } from '../api-client/program.service';
import { AcademiaService } from '../api-client/academia.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { config } from '../../app/config';
import { UiService } from '../ui.service';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-semester-wise-students',
  templateUrl: './semester-wise-students.component.html',
  styleUrls: ['./semester-wise-students.component.css']
})
export class SemesterWiseStudentsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() programId: any;
  private _academiaServiceSubscription: Subscription;

  pageSize: number = config.paginationPerPage;
  totalItemsCount: number = 0;
  totalPageCount: number = 0;
  currentPage: any = 1;
  filters: any;
  heading: any;
  showHeader:boolean = false;
  csvStudents = [];

  students = {};
  constructor(
    private _progmramService: ProgramService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _uiSerice: UiService,
    private _exportAsService: ExportAsService
  ) {
    this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(_ => {
      this.currentPage = 1;
      this.populateStudents();
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.currentPage = 1;
    this.populateStudents();
  }

  populateStudents() {
    if (this.programId == null) return;
    var sessionName = this._academiaService.getActiveAcademicSession().name;
    var programName = this._academiaService.getProgram(this.programId).name;
    this.heading = programName + " Of " + sessionName;
    this.filters = {
      "freeText": "",
      "academicSessionId": this._academiaService.getActiveAcademicSession().id,
      "programIds": [this.programId],
      "semester": 0,
      "batchStartYear": 0,
      "registrationYear": 0,
      "startIndex": (this.currentPage - 1) * this.pageSize,
      "pageSize": this.pageSize
    };

    this._academiaService.students(this.filters).subscribe((response: any) => {
      var result = response.items;
      this.csvStudents = result;
      this.students = result;
      this.totalItemsCount = response.totalCount;
      this.totalPageCount = Math.ceil(this.totalItemsCount / this.pageSize);

      this._uiSerice.setQuickItems(result.map((student: any) => {
        return {
          text: student.name,
          icon: 'fa fa-user-o',
          action: () => {
            this._router.navigateByUrl("/student/" + student.id);
          }
        };
      }));
    })
    /* this._progmramService.getProgremWiseStudentsPursue(this.programId, (this.currentPage - 1) * this.pageSize, this.pageSize)
      .subscribe((response: any) => {
        var result = response.items;
        this.students = result;
        this.totalItemsCount = response.totalCount;
        this.totalPageCount = Math.ceil(this.totalItemsCount / this.pageSize);
        
        this._uiSerice.setQuickItems(result.map((student: any) => {
          return {
            text: student.name,
            icon: 'fa fa-user-o',
            action: () => {
              this._router.navigateByUrl("/student/" + student.id);
            }
          };
        }));
      }); */
  }

  onPageChange(pageIndex: any) {
    this.currentPage = pageIndex;
    this.populateStudents();
  }

  ngOnDestroy(): void {
    this._academiaServiceSubscription.unsubscribe();
  }

  isEmpty() {
    return Object.keys(this.students).length == 0;
  }

  openSummary(student: any) {

    this._router.navigate([{
      outlets: { rightMainPopup: ['student-summary', student.id] }
    }]);
  }

  export(type: any, elementId: any, name: any) {
    this.showHeader = true;
    var configs = { type: type, elementId: elementId };
    setTimeout(()=>{
      this._exportAsService.save(configs, name).subscribe(() => {
        this.showHeader = false;
      });
    })
    
  }

  exportCsv() {
    var headers = [];
    var fetchDate = [];
    var result = this.csvStudents;
    for (var i = 0; i < result.length; i++) {
      fetchDate.push({ "currentSemester": result[i].currentSemester, "registrationNumber": result[i].registrationNumber,"batchStartYear":result[i].batchStartYear, "classRollNumber": result[i].classRollNumber, "name": result[i].name, "examinationRollNumber": result[i].examinationRollNumber });
    }
    for (var i = 0; i < config.exportCsvPaper.length; i++) {
      headers.push({ align: "center", allowEmpty: false, editor: null, name: config.exportCsvStudent[i], options: [], source: [], title: this.exportcsvHeader(config.exportCsvStudent[i]), type: "text", width: 195 });
    }
    this._uiSerice.openDataPreviewWindow(fetchDate, headers, []);
  }

  exportcsvHeader(key: any) {
    var title = key;
    title = title.replace(/([A-Z])/g, " $1");
    title = title.charAt(0).toUpperCase() + title.slice(1);
    return title;
  }

}
