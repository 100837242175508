import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ExportAsService } from 'ngx-export-as';
import { config } from '../config';
import { ToastrService } from 'ngx-toastr';
var PaperWiseStudentsComponent = /** @class */ (function () {
    function PaperWiseStudentsComponent(_academiaService, _route, _router, _uiService, _exportAsService, _tosterService, _uiSerice) {
        var _this = this;
        this._academiaService = _academiaService;
        this._route = _route;
        this._router = _router;
        this._uiService = _uiService;
        this._exportAsService = _exportAsService;
        this._tosterService = _tosterService;
        this._uiSerice = _uiSerice;
        this.config = config;
        this.midTerm = false;
        this.endTerm = false;
        this.marksEntryHeader = config.marksEntryHeader;
        this._academiaServiceSubscription = _academiaService.onAcademicSessionUpdate.subscribe(function (_) {
            _this.academiaSessions = _this._academiaService.getAcademicSession();
            _this.populateStudents();
        });
    }
    PaperWiseStudentsComponent.prototype.ngOnInit = function () {
        this.academiaSessions = this._academiaService.getAcademicSession();
    };
    PaperWiseStudentsComponent.prototype.ngOnChanges = function (changes) {
        this.populateStudents();
    };
    PaperWiseStudentsComponent.prototype.populateStudents = function () {
        var _this = this;
        var stud = [];
        if (this.paper == null)
            return;
        this.paperInDisciplineSession = this._uiService.paperInDisciplineSession;
        this._academiaService.getPaperWiseStudents(this.paper.id)
            .subscribe(function (result) {
            for (var i = 0; i < result.length; i++) {
                if (result[i].blocked === false && result[i].migrated === false) {
                    result[i].sessionName = _this.sessionDetails(result[i].academicSessionId).name;
                    var studentProgram = _this._academiaService.getProgram(result[i].academicProgramId);
                    result[i].studentMajor = studentProgram.name.split('in')[1].trim();
                    if (result[i].studentMajor == 'H') {
                        result[i].studentMajor = 'Hindi';
                    }
                    else if (result[i].studentMajor == 'Perform') {
                        result[i].studentMajor = 'Performing Arts';
                    }
                    stud.push(result[i]);
                }
            }
            _this.students = stud;
        });
    };
    PaperWiseStudentsComponent.prototype.ngOnDestroy = function () {
        this._academiaServiceSubscription.unsubscribe();
    };
    PaperWiseStudentsComponent.prototype.isEmpty = function () {
        return Object.keys(this.students).length == 0;
    };
    PaperWiseStudentsComponent.prototype.redirectTo = function (student) {
        this._router.navigate(["student/" + student.id]);
    };
    PaperWiseStudentsComponent.prototype.sessionDetails = function (sessionId) {
        if (this.academiaSessions == null)
            return null;
        var items = this.academiaSessions.filter(function (x) { return x.id == sessionId; });
        if (items.length > 0) {
            return items[0];
        }
        else {
            return null;
        }
    };
    PaperWiseStudentsComponent.prototype.export = function (type, elementId, name, arg) {
        var _this = this;
        this.midTerm = false;
        this.endTerm = false;
        if (arg == 'mid') {
            this.midTerm = true;
        }
        if (arg == 'end') {
            this.endTerm = true;
        }
        var configs = { type: type, elementId: elementId };
        setTimeout(function () {
            _this._exportAsService.save(configs, _this.paperInDisciplineSession.discipline.name + " " + arg + " Term " + _this.paper.code + "(" + _this.paperInDisciplineSession.session.name + ")").subscribe(function () {
            });
        }, 10);
    };
    PaperWiseStudentsComponent.prototype.previewCsvData = function (fieldMaps, results) {
        var _this = this;
        this._uiService.indeterminateProgressTextSet.next('Arrange data...');
        var columns = [];
        var columnsWidth = {};
        var columnSources = [];
        columnSources['paperType'] = Object.keys(config.paperType).map(function (key) {
            return { id: Number(key), name: config.paperType[key] };
        });
        columnSources['level'] = Object.keys(config.academicLevel).map(function (key) {
            return { id: Number(key), name: config.academicLevel[key] };
        });
        columnSources['secondaryPaperType'] = Object.keys(config.secondaryPaperType).map(function (key) {
            return { id: Number(key), name: config.secondaryPaperType[key] };
        });
        columnSources['academicSessionId'] = this._academiaService.getAcademicSession().map(function (x) {
            return { id: x.id, name: x.name };
        });
        columnSources['disciplineId'] = this._academiaService.getDisciplines().map(function (x) {
            return { id: x.id, name: x.name };
        });
        var arrangedData = results.map(function (value) {
            var item = {};
            for (var key in fieldMaps) {
                var valueWidth = (value[fieldMaps[key]] + '').length * 15;
                item[key] = value[fieldMaps[key]];
                if (!columnsWidth[key] || columnsWidth[key] < valueWidth) {
                    columnsWidth[key] = valueWidth;
                }
            }
            return item;
        });
        this._uiService.replaceCsvValueWithId(columnSources, arrangedData);
        this._uiService.createDataPreviewColumns(fieldMaps, columnsWidth, columns, columnSources);
        columnsWidth = null;
        this._uiService.indeterminateProgressTextSet.next(null);
        this._uiService.openDataPreviewWindow(arrangedData, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: function () {
                    _this.onImportPreviewCompleted(Object.keys(fieldMaps), arrangedData, columns);
                }
            }
        ]);
    };
    PaperWiseStudentsComponent.prototype.onImportPreviewCompleted = function (fields, arrangedData, columns) {
        var _this = this;
        var data = this._uiService.getPreviewModifiedData();
        var converted = data.map(function (value) {
            var item = {};
            for (var i = 0; i < fields.length; i++) {
                item[fields[i]] = value[i];
            }
            return item;
        });
        this._uiService.closeDataPreview();
        var worker = new Worker('../workers/import-marks.worker', { name: 'marks-worker', type: 'module' });
        this._uiService.startWorker(worker, converted)
            .then(function () {
            //show success message, if required
        }).catch(function (e) {
            if (e.validation) {
                _this.onImportValidationError(e.validation, converted, columns, fields);
            }
        });
    };
    PaperWiseStudentsComponent.prototype.onImportValidationError = function (validation, converted, columns, fields) {
        var _this = this;
        this._uiService.onDataPreviewReady = function () {
            var allFields = Object.keys(converted[0]);
            for (var i = 0; i < validation.length; i++) {
                var commonErrors = [];
                for (var field in validation[i]) {
                    var fieldIndex = allFields.indexOf(field);
                    if (fieldIndex <= 0) {
                        commonErrors = commonErrors.concat(validation[i][field]);
                    }
                    else {
                        _this._uiService.setPreviewDataCellCommentAndStyle(i + 1, fieldIndex + 1, validation[i][field].join('\n'), null);
                    }
                }
                if (commonErrors.length > 0) {
                    _this._uiService.setPreviewDataCellCommentAndStyle(i + 1, 1, commonErrors.join('\n'), null);
                }
            }
        };
        this._uiService.openDataPreviewWindow(converted, columns, [
            {
                type: 'i',
                content: 'done_all',
                onclick: function () {
                    _this.onImportPreviewCompleted(fields, converted, columns);
                }
            }
        ]);
    };
    PaperWiseStudentsComponent.prototype.mapCsvField = function (results) {
        var _this = this;
        this._uiService
            .mapCsvField(results.meta.fields, this.marksEntryHeader)
            .then(function (maps) {
            _this.previewCsvData(maps, results.data);
        }).catch(function () { });
    };
    PaperWiseStudentsComponent.prototype.uploadFile = function (fileEle) {
        var _this = this;
        this._uiService
            .parseCsv(fileEle.files[0])
            .then(function (results) {
            fileEle.value = null;
            _this.mapCsvField(results);
        }).catch(function () {
            fileEle.value = null;
        });
    };
    PaperWiseStudentsComponent.prototype.selectAll = function (element) {
        for (var i = 0; i < this.students.length; i++) {
            if (element.checked) {
                this.students[i].checked = true;
            }
            else {
                this.students[i].checked = false;
            }
        }
    };
    PaperWiseStudentsComponent.prototype.selectIndividual = function (element, studentId) {
        for (var i = 0; i < this.students.length; i++) {
            if (this.students[i].id == studentId) {
                if (element.checked) {
                    this.students[i].checked = true;
                }
                else {
                    this.students[i].checked = false;
                }
            }
        }
    };
    PaperWiseStudentsComponent.prototype.unlinkStudents = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var student, count, i, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        student = this.students.filter(function (x) { return x.checked == true; });
                        if (student.length == 0) {
                            this._tosterService.error("Please select student(s)");
                            return [2 /*return*/];
                        }
                        count = 0;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < this.students.length)) return [3 /*break*/, 4];
                        if (!this.students[i].checked) return [3 /*break*/, 3];
                        data = {
                            "studentId": this.students[i].id,
                            "paperCodes": [this.paper.code]
                        };
                        return [4 /*yield*/, this._academiaService.unsetStudentPaper(data).toPromise().then(function (result) {
                                count++;
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this._tosterService.success("Student(s) unlinked");
                        this.populateStudents();
                        return [2 /*return*/];
                }
            });
        });
    };
    PaperWiseStudentsComponent.prototype.linkStudents = function () {
        this._router.navigate([{
                outlets: { rightMainPopup: ['search-students', this.paper.id] }
            }]);
    };
    return PaperWiseStudentsComponent;
}());
export { PaperWiseStudentsComponent };
