<div class="status-tab text-justify p-2 pr-2">
        <div class="d-flex justify-content-between align-items-center">
            <div class="h4 m-0 pt-2 pb-2">Logged in User</div>            
        </div>
        <div class="">
            <ul class="">
                <li class="text-100 d-flex justify-content-between align-items-center p-0 pt-2 pb-2">
                    <span>Exeminer 1</span>
                    <em aria-hidden="true" class="fa fa-circle row_icon text-80 active_row" title="Active"></em>
                </li>
                <li class="text-100 d-flex justify-content-between align-items-center p-0 pt-2 pb-2">
                    <span>Exeminer 2</span>
                    <em aria-hidden="true" class="fa fa-circle row_icon text-80" title=""></em>
                </li>
                <li class="text-100 d-flex justify-content-between align-items-center p-0 pt-2 pb-2">
                    <span>Exeminer 3</span>
                    <em aria-hidden="true" class="fa fa-circle row_icon text-80" title=""></em>
                </li>
                <li class="text-100 d-flex justify-content-between align-items-center p-0 pt-2 pb-2">
                    <span>Exeminer 4</span>
                    <em aria-hidden="true" class="fa fa-circle row_icon text-80" title=""></em>
                </li>
            </ul>
        </div>
    </div>