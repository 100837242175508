<ng-template #notactivesession><span class="font-weight-600 d-block blue-grey lighten-5 p-2 text-100 text-center">Please
    Select Active Session Paper</span>
</ng-template>
<ng-template #nodatafound><span class="font-weight-600 d-block blue-grey lighten-5 p-2 text-100 text-center">No Result
    Found</span></ng-template>
<div class="examiner_section mt-3">
  <div class="d-flex justify-content-first">
    <!-- <div class="text-100 font-weight-500"><span>Selected session :</span> <span class="font-weight-600"> {{sessionId.name}}</span></div> -->
    <div class="">
      <select (change)="selectExam($event.target.value)" class="form-control">
        <option value="">Select Exam ({{selectedSession?.name}})</option>
        <option *ngFor="let exam of exams" [value]="exam?.id" [selected]="exam?.id==activeExam?.id">{{exam.name}}
        </option>
      </select>
    </div>
  </div>

  <div class="sec_row mt-3">
    <!-- <h5 class="h5 font-weight-600">{{ 'Examiners' | translate }}</h5> -->
    <h5 class="h5 font-weight-600"> <a class="text-color text-22px" (click)="addExaminer(1)">
        <em class="fa fa-plus-square-o"></em>
      </a> <a *ngIf="selectedExaminerArray.length>0" class="text-color text-22px ml-3" (click)="saveExaminer()">
        <em class="fa fa-save"></em>
      </a> <span class="ml-2">{{ 'Examiners' | translate }}</span> </h5>
    <div class="row_color p-2 text-100 font-weight-500">
      <div class="row">
        <div class="col-sm-3">
          {{ 'Examiner Name' | translate }}
        </div>
        <div class="col-sm-3">
          {{ 'Allocated Marks' | translate }}
        </div>
        <div class="col-sm-3">
          {{ 'Upload Midterm Marks' | translate }}
        </div>
        <div class="col-sm-3">
          {{ 'Upload Endterm Marks' | translate }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="selectedExaminerArray.length>0">
      <div *ngFor="let employ of selectedExaminerArray; let i = index;" class="row mt-1">
        <div class="col-sm-3">
          {{employ?.item?.name}}
        </div>
        <div class="col-sm-3">
          <input type="text" [(ngModel)]="employ.uploadedEndMarks" class="form-control p-2"
            placeholder="Uploaded End Marks">
        </div>
        <div class="col-sm-3">
          <input type="checkbox" (click)="selectedItem(employ,i)" [(ngModel)]="employ.uploadedMidMarks"
            class="m-2" />Midterm
        </div>
        <div class="col-sm-2">
          <input type="checkbox" (click)="selectedEndTermItem(employ,i)" [(ngModel)]="employ.uploadedEndTermMarks"
            class="m-2" />Endterm
        </div>

        <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeEmp(employ)"
            class='text-danger fa fa-trash'></i></div>
      </div>


    </ng-container>
    <ng-container *ngIf="examiners.length>0; else nodatafound">
      <div class="white p-2 text-100 font-weight-500">
        <div *ngFor="let examiner of examiners" class="row">
          <div class="col-sm-3">
            {{examiner.examinerName}}
          </div>
          <div class="col-sm-3">
            {{examiner.alocatedEndTermMarks}}
          </div>
          <div class="col-sm-3">
            <ng-container>
              <div *ngIf="examiner.allowSubmitMidTermMarks && !examiner.midTermMarksSubmitedOn" class="form-group m-0 mr-2 d-flex font-weight-500">
                <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                  <input type="file" (change)="uploadFile($event.target,examiner)" class="hidden-file"
                    id="importStudents">
                </em>
                <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
              </div>
            </ng-container>
            <ng-container *ngIf="examiner.midTermMarksSubmitedOn && examiner.allowSubmitMidTermMarks">
              Import on {{examiner.midTermMarksSubmitedOn | date:'dd-MMM-yyyy'}} <em
                (click)="downloadCsv(examiner.examinationId,examiner.paperId,examiner.examinerId,examiner.type,'mid')"
                class="text-primary fa fa-download"></em>
            </ng-container>
            <!-- <ng-container *ngIf="!examiner.midTermMarksSubmitedOn">
              <em
                (click)="updateDate(examiner.paperId,examiner.examinationId,2,'mid',examiner.examinerId)"
                class="text-primary fa fa-download"></em>
            </ng-container> -->
          </div>
          <div class="col-sm-2">
            <ng-container>
              <div *ngIf="examiner.allowSubmitEndTermMarks && !examiner.endTermMarksSubmitedOn" class="form-group m-0 mr-2 d-flex font-weight-500">
                <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                  <input type="file" (change)="uploadFile($event.target,examiner)" class="hidden-file"
                    id="importStudents">
                </em>
                <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
              </div>
            </ng-container>
            <ng-container *ngIf="examiner.endTermMarksSubmitedOn && examiner.allowSubmitEndTermMarks">
              Import on {{examiner.endTermMarksSubmitedOn | date:'dd-MMM-yyyy'}} <em
                (click)="downloadCsv(examiner.examinationId,examiner.paperId,examiner.examinerId,examiner.type,'end')"
                class="text-primary fa fa-download"></em>
            </ng-container>
            <!-- <ng-container *ngIf="!examiner.endTermMarksSubmitedOn">
              <em
                (click)="updateDate(examiner.paperId,examiner.examinationId,4,'end',examiner.examinerId)"
                class="text-primary fa fa-download"></em>
            </ng-container> -->
          </div>
          <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeData(examiner)"
              class='text-danger fa fa-trash'></i></div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="sec_row mt-3">
    <h5 class="h5 font-weight-600"><a class="text-color text-22px" (click)="addExaminer(2)">
        <em class="fa fa-plus-square-o"></em>
      </a> <a *ngIf="selectedReviewerArray.length>0" class="text-color text-22px ml-3" (click)="saveReviewer()">
        <em class="fa fa-save"></em>
      </a><span class="ml-2">{{ 'Reviewers' | translate }}</span></h5>
    <div class="row_color p-2 text-100 font-weight-500">
      <div class="row">
        <div class="col-sm-4">
          {{ 'Reviewer Name' | translate }}
        </div>
        <div class="col-sm-3">
          {{ 'Allocated Marks' | translate }}
        </div>
        <div class="col-sm-5">

        </div>
        <!-- <div class="col-sm-3">
          {{ 'Upload Midterm Marks' | translate }}
        </div>
        <div class="col-sm-3">
          {{ 'Upload Endterm Marks' | translate }}
        </div> -->
      </div>
    </div>
    <ng-container *ngIf="selectedReviewerArray.length>0">
      <div *ngFor="let employ of selectedReviewerArray; let i = index;" class="row mt-1">
        <div class="col-sm-4">
          {{employ?.item?.name}}
        </div>
        <div class="col-sm-3">
          <input type="text" [(ngModel)]="employ.uploadedEndMarks" class="form-control p-2"
            placeholder="Uploaded End Marks">
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeReviewer(employ)"
            class='text-danger fa fa-trash'></i></div>
      </div>
    </ng-container>
    <ng-container *ngIf="reviewers.length>0; else nodatafound">
      <div class="white p-2 text-100 font-weight-500">
        <div *ngFor="let reviewer of reviewers" class="row">
          <div class="col-sm-4">
            {{reviewer.examinerName}}
          </div>
          <div class="col-sm-3">
            {{reviewer.alocatedEndTermMarks}}
          </div>
          <div class="col-sm-4"></div>
          <!-- <div class="col-sm-3">
            {{reviewer.midTermMarksSubmitedOn | date:'dd/MM/yyy'}}
          </div>
          <div class="col-sm-2">
            <ng-container>
              <div class="form-group m-0 mr-2 d-flex font-weight-500">
                <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                  <input type="file" (change)="uploadFile($event.target,reviewer)" class="hidden-file"
                    id="importStudents">
                </em>
                <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
              </div>
            </ng-container>
            <ng-container *ngIf="reviewer.endTermMarksSubmitedOn">
              Import on {{reviewer.endTermMarksSubmitedOn | date:'dd-MMM-yyyy'}} <em
                (click)="downloadCsv(reviewer.examinationId,reviewer.paperId,reviewer.examinerId,reviewer.type,'end')"
                class="text-primary fa fa-download"></em>
            </ng-container>
          </div> -->
          <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeData(reviewer)"
              class='text-danger fa fa-trash'></i></div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="sec_row mt-3">
    <h5 class="h5 font-weight-600"><a class="text-color text-22px" (click)="addExaminer(104)">
        <em class="fa fa-plus-square-o"></em>
      </a> <a *ngIf="selectedThirdExaminerArray.length>0" class="text-color text-22px ml-3"
        (click)="saveThirdExaminer()">
        <em class="fa fa-save"></em>
      </a><span class="ml-2">{{ 'Third Examiners' | translate }}</span></h5>

    <div class="row_color p-2 text-100 font-weight-500">
      <div class="row">
        <div class="col-sm-4">
          {{ 'Third Examiner Name' | translate }}
        </div>
        <div class="col-sm-3">
          {{ 'Allocated Marks' | translate }}
        </div>
        <div class="col-sm-5"></div>
        <!-- <div class="col-sm-3">
          {{ 'Upload Midterm Marks' | translate }}
        </div>
        <div class="col-sm-3">
          {{ 'Upload Endterm Marks' | translate }}
        </div> -->
      </div>
    </div>
    <ng-container *ngIf="selectedThirdExaminerArray.length>0">
      <div *ngFor="let employ of selectedThirdExaminerArray; let i = index;" class="row mt-1">
        <div class="col-sm-4">
          {{employ?.item?.name}}
        </div>
        <div class="col-sm-3">
          <input type="text" [(ngModel)]="employ.uploadedEndMarks" class="form-control p-2"
            placeholder="Uploaded End Marks">
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeThirdExaminer(employ)"
            class='text-danger fa fa-trash'></i></div>
      </div>
    </ng-container>
    <ng-container *ngIf="thirdExaminers.length>0; else nodatafound">
      <div class="white p-2 text-100 font-weight-500">
        <div *ngFor="let thirdExaminer of thirdExaminers" class="row">
          <div class="col-sm-4">
            {{thirdExaminer?.examinerName}}
          </div>
          <div class="col-sm-3">
            {{thirdExaminer?.alocatedEndTermMarks}}
          </div>
          <!-- <div class="col-sm-3">
            {{thirdExaminer?.midTermMarksSubmitedOn | date:'dd/MM/yyy'}}
          </div>
          <div class="col-sm-2">
            <ng-container>
              <div class="form-group m-0 mr-2 d-flex font-weight-500">
                <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                  <input type="file" (change)="uploadFile($event.target,thirdExaminer)" class="hidden-file"
                    id="importStudents">
                </em>
                <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
              </div>
            </ng-container>
            <ng-container *ngIf="thirdExaminer.endTermMarksSubmitedOn">
              Import on {{thirdExaminer?.endTermMarksSubmitedOn | date:'dd-MMM-yyyy'}} <em
                (click)="downloadCsv(thirdExaminer.examinationId,thirdExaminer.paperId,thirdExaminer.examinerId,thirdExaminer.type,'end')"
                class="text-primary fa fa-download"></em>
            </ng-container>
          </div> -->
          <div class="col-sm-4"></div>
          <div class="col-sm-1 d-flex justify-content-center align-items-center"><i (click)="removeData(thirdExaminer)"
            class='text-danger fa fa-trash'></i></div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="d-none">
  <table id="studentMarks">
    <tr>
      <th>Session</th>
      <th>Paper Code</th>
      <th>Examination</th>
      <th>Examiner</th>
      <th>Mode</th>
      <th>Term</th>
      <th>Reg. No.</th>
      <th>Batch</th>
      <th>Marks Obtained</th>
    </tr>
    <tr *ngFor="let student of students">
      <td>{{student.session}}</td>
      <td>{{student.code}}</td>
      <td>{{student.examinationName}}</td>
      <td>{{student.examinerName}}</td>
      <td>{{student.mode}}</td>
      <td>{{student.term}}</td>
      <td>{{student.registrationNumber}}</td>
      <td>{{student.batchStartYear}}</td>
      <td>{{student.marks}}</td>
    </tr>
  </table>
</div>
