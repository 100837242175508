import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SessionModel } from '../models/session.model';

@Injectable({
    providedIn: 'root'
})
export class SessionFormBuilder{

    private form:any;
    private validationRules = {        
        id:['',''],
        start: ['',Validators.required],
        end: ['',Validators.required],
        name: ['',Validators.required],
        active:['','']
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:SessionModel){
        this.form.patchValue({
            id:response.id,
            name:response.name,
            start:response.start,
            end:response.end,
            active:response.active
        });
    }
}