<h4>{{ 'Students' | translate }}</h4>
<ng-template #nsf>{{ 'No Student Found' | translate }}</ng-template>
<div class="text-80">
    <div class="d-flex justify-content-end align-items-center">
        <!-- <h4 class="m-0">Students</h4> -->
        <div class="reply_cont d-flex justify-content-between align-items-center">
            <!-- <div class="form-group m-0 mr-2 d-flex justify-content-between align-items-center font-weight-500">
                <em class="fa fa-upload position-relative ml-2 p-2 rounded-circle">
                    <input type="file" (change)="uploadFile($event.target)" class="hidden-file" id="importStudents">                    
                </em>                
                <label for="importStudents" class="m-0">{{ 'Import' | translate }}</label>
            </div> --> 
            <a class="ml-2 mr-2 font-weight-500" (click)="export('csv','student','student','mid')"><em class="fa fa-download p-2 rounded-circle"></em> <span>{{ 'Export Mid Term' | translate }}</span></a>
            <a class="ml-2 mr-2 font-weight-500" (click)="export('csv','student','student','end')"><em class="fa fa-download p-2 rounded-circle"></em> <span>{{ 'Export End Term' | translate }}</span></a>
            <a class="ml-2 mr-2 font-weight-500" (click)="unlinkStudents()"><em class="fa fa-chain-broken p-2 rounded-circle"></em><span>Unlink Students</span></a>
            <a class="ml-2 mr-2 font-weight-500" (click)="linkStudents()"><em class="fa fa-chain-broken p-2 rounded-circle"></em><span>Link Students</span></a>
        </div>
    </div>
    <div class="row_color p-1 pr-2 pl-2 text-100">
        <div class="row">
            <div class="col-sm-3 d-flex align-items-start">
                <input type="checkbox" (click)="selectAll($event.target)" id="all" class="mr-2 mt-3px">
                <p class="m-0 font-weight-400">{{ 'Name' | translate }}</p>
            </div>
            <div class="col-sm-2">
                <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Reg.no' | translate }}</p>
            </div>
            <div class="col-sm-3">
                <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Batch Start Year' | translate }}</p>
            </div>            
            <div class="col-sm-2">
                <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Class roll' | translate }}</p>
            </div>
            <div class="col-sm-2">
                <p class="m-0 pt-1 pb-1 font-weight-500">{{ 'Session' | translate }}</p>
            </div>
        </div>
    </div>    
    
    <div class="p-1 pr-2 pl-2">
        <div *ngIf="students; else nsf">
            <div *ngFor="let student of students; index as i;" class="row">
                <div class="col-sm-3 d-flex align-items-start">
                    <input type="checkbox" [checked]="student.checked" (click)="selectIndividual($event.target,student.id)" class="mr-2 mt-3px">
                    <p (click)="redirectTo(student)" class="m-0 font-weight-400">{{student.name}}</p>
                </div>
                <div class="col-sm-2">
                    <p class="m-0 pt-1 pb-1 font-weight-400">{{student.registrationNumber}}</p>
                </div>
                <div class="col-sm-3">
                    <p class="m-0 pt-1 pb-1 font-weight-400">{{student.batchStartYear}}</p>
                </div>               
                <div class="col-sm-2">
                    <p class="m-0 pt-1 pb-1 font-weight-400">{{student.classRollNumber}}</p>
                </div>
                <div class="col-sm-2">
                    <p class="m-0 pt-1 pb-1 font-weight-400">{{student.sessionName}}</p>
                </div>
            </div>
        </div>
    </div>
    <table class="d-none" id="student">
        <tr>
            <th>Course</th>
            <th>Academic Session Id</th>
            <th>Code</th>
            <th>Major</th>
            <th>Semester</th>
            <th>Term</th>
            <th>Registration Number</th>
            <th>Batch Start Year</th>
            <th>Examination Roll Number</th>            
            <th>Class Roll Number</th>            
            <th *ngIf="midTerm">Full Marks</th>
            <th *ngIf="endTerm">Full Marks</th>
            <th>Marks Obtained</th>
        </tr>
        <ng-container *ngFor="let student of students; index as i;">
            <tr>
                <th>{{config.academicLevel[paper.level]}}</th>
                <th>{{paperInDisciplineSession.session.name}}</th>
                <th>{{paper.code}}</th>
                <th>{{student.studentMajor}}</th>
                <th>{{paper.semester}}</th>
                <th *ngIf="midTerm">Mid</th>
                <th *ngIf="endTerm">End</th>
                <th>{{student.registrationNumber}}</th>
                <th>{{student.batchStartYear}}</th>
                <th>{{student.examinationRollNumber}}</th>               
                <th>{{student.classRollNumber}}</th> 
                <th *ngIf="midTerm">{{paper.midTermAllocatedMarks}}</th>
                <th *ngIf="endTerm">{{paper.endTermAllocatedMarks}}</th>
                <th></th>
            </tr>
        </ng-container>
    </table>
</div>