import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { OrganizationService } from '../api-client/organization.service';
import { DisciplineModel } from '../models/discipline.model';
import { ToastrService } from 'ngx-toastr';
import { DisciplineFormBuilder } from './discipline.form-builder';
import { DisciplineService } from '../api-client/discipline.service';

@Component({
  selector: 'app-discipline-details',
  templateUrl: './discipline-details.component.html',
  styleUrls: ['./discipline-details.component.css']
})
export class DisciplineDetailsComponent implements OnInit, OnDestroy {

  disciplineId: any;
  disciplines: any;
  discipline = {};
  departments: any;
  editMode: boolean = false;
  submitted = false;
  model = new DisciplineModel();
  disciplineForm: any;

  private _disciplineServiceSubscription: Subscription;
  private _organizationServiceSubscription: Subscription;

  constructor(
    private _disciplineService: DisciplineService,
    private _academiaService: AcademiaService,
    private _route: ActivatedRoute,
    private _organizationService: OrganizationService,
    private _uiService: UiService,
    private _toster: ToastrService,
    private _formBuilder: DisciplineFormBuilder,
    private _router: Router) {
    this._organizationServiceSubscription = this._organizationService.onDepartmentsUpdated.subscribe(_ => {
      this.departments = this._organizationService.getDepartments();
    });
  }


  ngOnInit() {
    this.disciplineForm = this._formBuilder.getForm();
    this.departments = this._organizationService.getDepartments();
    this._route.params.subscribe(params => {
      this.disciplineId = params.id;
      this.resetForm();
      this.disciplineForm.controls['departmentId'].setValue(this._uiService.departmentId);      
      this._disciplineService.getDiscipline(this.disciplineId).subscribe((result: any) => {
        this._formBuilder.setValues(result);
      });
    })
  }

  get f() { return this.disciplineForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.disciplineForm.invalid) {
      return;
    }

    this.model.deserialize(this.disciplineForm.value);
    this._disciplineService.saveDiscipline(this.model)
      .subscribe(
        (response: DisciplineModel) => {
          this._toster.success("Discipline saved");
          this.editMode = true;
          return false;
        },
        error => {
          this._toster.error(error.message);
        }
      )
  }

  editDiscipline() {
    this.editMode = false;
  }

  ngOnDestroy(): void {
    this._organizationServiceSubscription.unsubscribe();
  }

  resetForm() {
    this.submitted = false;
    this.disciplineForm.reset();
  }

}
