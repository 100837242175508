import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { OrganizationService } from '../api-client/organization.service';
import { DepartmentModel } from '../models/department.model';
import { OrganizationFormBuilder } from './organization.form-builder';
import { ToastrService } from 'ngx-toastr';
import { DepartmentService } from '../api-client/department.service';
var OrganizationDetailsComponent = /** @class */ (function () {
    function OrganizationDetailsComponent(_organizationSearvice, _route, _uiService, _toster, _departmentSearvice, _formBuilder, _router) {
        var _this = this;
        this._organizationSearvice = _organizationSearvice;
        this._route = _route;
        this._uiService = _uiService;
        this._toster = _toster;
        this._departmentSearvice = _departmentSearvice;
        this._formBuilder = _formBuilder;
        this._router = _router;
        this.editMode = false;
        this.submitted = false;
        this.model = new DepartmentModel();
        this._organizationServiceSubscription = _organizationSearvice.onDepartmentsUpdated.subscribe(function (_) {
            _this.organizations = _this._organizationSearvice.getDepartments();
        });
    }
    Object.defineProperty(OrganizationDetailsComponent.prototype, "f", {
        get: function () { return this.departmentForm.controls; },
        enumerable: true,
        configurable: true
    });
    OrganizationDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.departmentForm = this._formBuilder.getForm();
        this.organizations = this._organizationSearvice.getDepartments();
        this._route.params.subscribe(function (params) {
            _this.organizationId = params.id;
            _this.resetForm();
            _this._departmentSearvice.getDepartment(_this.organizationId).subscribe(function (result) {
                _this._formBuilder.setValues(result);
            });
        });
    };
    OrganizationDetailsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.departmentForm.invalid) {
            return;
        }
        this.model.deserialize(this.departmentForm.value);
        //console.log(this.model);return;
        this._organizationSearvice.saveDepartment(this.model)
            .subscribe(function (response) {
            _this._toster.success("Department saved");
            _this._organizationSearvice.notifyDepartmentUpdate(_this.organizationId);
            _this.editMode = true;
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    OrganizationDetailsComponent.prototype.editOrganization = function () {
        this.editMode = false;
    };
    OrganizationDetailsComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.departmentForm.reset();
    };
    return OrganizationDetailsComponent;
}());
export { OrganizationDetailsComponent };
