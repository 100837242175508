/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-preview.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./data-preview.component";
import * as i3 from "../ui.service";
import * as i4 from "@angular/router";
import * as i5 from "ngx-toastr";
import * as i6 from "@angular/common";
var styles_DataPreviewComponent = [i0.styles];
var RenderType_DataPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataPreviewComponent, data: {} });
export { RenderType_DataPreviewComponent as RenderType_DataPreviewComponent };
export function View_DataPreviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { jexcelContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["jexcelContainer", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_DataPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-data-preview", [], null, null, null, View_DataPreviewComponent_0, RenderType_DataPreviewComponent)), i1.ɵdid(1, 4308992, null, 0, i2.DataPreviewComponent, [i3.UiService, i4.Router, i4.ActivatedRoute, i5.ToastrService, i6.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataPreviewComponentNgFactory = i1.ɵccf("app-data-preview", i2.DataPreviewComponent, View_DataPreviewComponent_Host_0, {}, {}, []);
export { DataPreviewComponentNgFactory as DataPreviewComponentNgFactory };
