import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PaperModel } from '../models/paper.model';
import { StaffModel } from '../models/staff.model';

@Injectable({
    providedIn: 'root'
})
export class staffFormBuilder{

    private form:any;
    private validationRules = {        
        id:['',''],
        name: ['',Validators.required],
        address: ['',Validators.required],
        bloodGroup: ['',Validators.required],
        discontinued: ['',Validators.required],
        email: ['',Validators.required],
        emailSignature: ['',''],
        gender: ['',Validators.required],
        mobile: ['',''],
        type:['',Validators.required],
        designation: ['',''],
        dateOfJoin: ['',''],
        releaseDate: ['',''],

    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:StaffModel){
        this.form.patchValue({
            id:response.id,
            name:response.name,
            address:response.address,
            bloodGroup:response.bloodGroup,
            discontinued:response.discontinued,
            email:response.email,
            emailSignature:response.emailSignature,
            gender:response.gender,
            mobile:response.mobile,
            designation:response.designation,
            dateOfJoin:response.dateOfJoin,
            type:response.type,
            releaseDate:response.releaseDate,
        });
    }
}