import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { config } from '../config';
import { map } from 'rxjs/operators';
import { DisciplineModel } from '../models/discipline.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DisciplineService = /** @class */ (function () {
    function DisciplineService(_http) {
        this._http = _http;
        this.onDisciplinesUpdated = new Subject();
    }
    DisciplineService.prototype.getDiscipline = function (id) {
        return this._http.get(config.apiEndPointBase + "discipline/" + id);
    };
    DisciplineService.prototype.saveDiscipline = function (data) {
        if (data.id) {
            return this._http
                .put(config.apiEndPointBase + "discipline", data, {})
                .pipe(map(function (response) {
                return new DisciplineModel().deserialize(response);
            }));
        }
        else {
            return this._http
                .post(config.apiEndPointBase + "discipline", data, {})
                .pipe(map(function (response) {
                return new DisciplineModel().deserialize(response);
            }));
        }
    };
    DisciplineService.prototype.getEmployees = function (disciplineId) {
        return this._http.get(config.apiEndPointBase + "discipline/" + disciplineId + "/employees");
    };
    DisciplineService.prototype.linkUnlinkEmployee = function (data, type) {
        return this._http
            .put(config.apiEndPointBase + "discipline/" + data.disciplineId + "/employee/" + type + "/" + data.employeeId, {}, {})
            .pipe(map(function (response) {
            return response;
        }));
    };
    DisciplineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisciplineService_Factory() { return new DisciplineService(i0.ɵɵinject(i1.HttpClient)); }, token: DisciplineService, providedIn: "root" });
    return DisciplineService;
}());
export { DisciplineService };
