import { OnInit, OnDestroy } from '@angular/core';
import { AcademiaService } from '../api-client/academia.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { OrganizationService } from '../api-client/organization.service';
import { DisciplineModel } from '../models/discipline.model';
import { ToastrService } from 'ngx-toastr';
import { DisciplineFormBuilder } from './discipline.form-builder';
import { DisciplineService } from '../api-client/discipline.service';
var DisciplineDetailsComponent = /** @class */ (function () {
    function DisciplineDetailsComponent(_disciplineService, _academiaService, _route, _organizationService, _uiService, _toster, _formBuilder, _router) {
        var _this = this;
        this._disciplineService = _disciplineService;
        this._academiaService = _academiaService;
        this._route = _route;
        this._organizationService = _organizationService;
        this._uiService = _uiService;
        this._toster = _toster;
        this._formBuilder = _formBuilder;
        this._router = _router;
        this.discipline = {};
        this.editMode = false;
        this.submitted = false;
        this.model = new DisciplineModel();
        this._organizationServiceSubscription = this._organizationService.onDepartmentsUpdated.subscribe(function (_) {
            _this.departments = _this._organizationService.getDepartments();
        });
    }
    DisciplineDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.disciplineForm = this._formBuilder.getForm();
        this.departments = this._organizationService.getDepartments();
        this._route.params.subscribe(function (params) {
            _this.disciplineId = params.id;
            _this.resetForm();
            _this.disciplineForm.controls['departmentId'].setValue(_this._uiService.departmentId);
            _this._disciplineService.getDiscipline(_this.disciplineId).subscribe(function (result) {
                _this._formBuilder.setValues(result);
            });
        });
    };
    Object.defineProperty(DisciplineDetailsComponent.prototype, "f", {
        get: function () { return this.disciplineForm.controls; },
        enumerable: true,
        configurable: true
    });
    DisciplineDetailsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        if (this.disciplineForm.invalid) {
            return;
        }
        this.model.deserialize(this.disciplineForm.value);
        this._disciplineService.saveDiscipline(this.model)
            .subscribe(function (response) {
            _this._toster.success("Discipline saved");
            _this.editMode = true;
            return false;
        }, function (error) {
            _this._toster.error(error.message);
        });
    };
    DisciplineDetailsComponent.prototype.editDiscipline = function () {
        this.editMode = false;
    };
    DisciplineDetailsComponent.prototype.ngOnDestroy = function () {
        this._organizationServiceSubscription.unsubscribe();
    };
    DisciplineDetailsComponent.prototype.resetForm = function () {
        this.submitted = false;
        this.disciplineForm.reset();
    };
    return DisciplineDetailsComponent;
}());
export { DisciplineDetailsComponent };
