import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DisciplineModel } from '../models/discipline.model';

@Injectable({
    providedIn: 'root'
})
export class DisciplineFormBuilder{

    private form:any;
    private validationRules = {        
        id:['',''],
        departmentId: ['',Validators.required],
        name: ['',Validators.required]
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:DisciplineModel){
        this.form.patchValue({
            id:response.id,
            departmentId:response.departmentId,
            name:response.name
        });
    }
}