import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from '../ui.service';
import { OrganizationService } from '../api-client/organization.service';
import { DepartmentService } from '../api-client/department.service';
import { Subscribable, Subscriber, Subscription } from 'rxjs';
import { config } from '../config';
 
@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.css']
})
export class SearchEmployeeComponent implements OnInit, OnDestroy {


  allEmployees: any;
  assignEmployees = [];
  departmentEmployees: any;
  discplineEmployees: any;
  departmentId: any;

  private _paramsChangeSubscriber: Subscription;
  private _organizationServiceSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _uiService: UiService,
    private _organizationService: OrganizationService,
    private _departmentService: DepartmentService
  ) {
    
  }

  ngOnInit() {    
    this.getEmployees();
    
  }

  getEmployees() {
    this.employeesInDepartment();    
    var params = {
      "freeText": "",
      "startIndex": 0,
      "pageSize": 0
    };
    this._organizationService.employees(params).subscribe((result: any) => {
      this.allEmployees = [];
      //console.log(1122,this.departmentEmployees);
      if (!this.departmentEmployees) return;
      for (var i = 0; i < result.items.length; i++) {
        result.items[i].inDepartment = false;
        this.departmentEmployees.forEach((x: any) => {
          if (x.id == result.items[i].id) {
            result.items[i].inDepartment = true;
          }
        });
      }
      this.allEmployees = result.items;      
    })
  }

  filterEmployee(ele: any) {
    var items: any;
    if (ele.value) {
      items = this.allEmployees.filter((x: any) => x.name.toLowerCase().includes(ele.value.toLowerCase()));
      this.allEmployees = items;
    } else {
      this.getEmployees();
    }
  }

  employeesInDepartment() {
    //console.log();
    this.departmentEmployees = this._uiService.departmentEmployees;
    //console.log(this.departmentEmployees);
    /* this._uiService.departmentEmployeesSet.subscribe((items: any) => {
      this.departmentEmployees = items;
      console.log(items);
    }); */
  }

  assignEmployee(employee: any) {    
    return this._uiService.setEmployeeDepartmentDiscpline(employee);
  }

  employeeDetails(employee: any) {
    this._router.navigate([{
      outlets: { rightMainPopup: ['staff-details', employee.id] }
    }]);
  }

  exportCsv() {
    var headers = [];
    var fetchDate = [];
    var result = this.allEmployees;
     for (var i = 0; i < this.allEmployees.length; i++) {
      fetchDate.push({ "name": result[i].name, 
                       "address": result[i].address,
                       "bloodGroup":result[i].bloodGroup, 
                       "discontinued": result[i].discontinued, 
                       "email": result[i].email, 
                       "emailSignature": result[i].emailSignature,
                       "gender": result[i].gender,
                       "mobile": result[i].mobile,
                       "designation": result[i].designation,
                       "dateOfJoin": result[i].dateOfJoin,
                       "type": result[i].type,
                       "releaseDate": result[i].releaseDate                      
                      });
    } 
     for (var i = 0; i < config.staffcSVHeader.length; i++) {
      headers.push({ align: "center", allowEmpty: false, editor: null, name: config.staffcSVHeader[i], options: [], source: [], title: this.exportcsvHeader(config.staffcSVHeader[i]), type: "text", width: 195 });
    }
    this._uiService.openDataPreviewWindow(fetchDate, headers, []);
  }

  ngOnDestroy(): void {
    //this._organizationServiceSubscription.unsubscribe();
    //this._paramsChangeSubscriber.unsubscribe();
  }

  exportcsvHeader(key: any) {
    var title = key;
    title = title.replace(/([A-Z])/g, " $1");
    title = title.charAt(0).toUpperCase() + title.slice(1);
    return title;
  }

}
