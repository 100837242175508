<div class="programme_section d-flex">
  <div class="programme_left w-25 p-4 position-relative">
    <a (click)="programDetails({value:0})" class="add-absolute p-1 text-22px text-color">
      <em class="fa fa-plus-square-o"></em>
    </a>
    <ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand">
      <div class="form-inline row-item" [class.selected]="selectedId==item.value">
        <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" class="fa"
          [class.fa-caret-right]="item.collapsed" [class.fa-caret-down]="!item.collapsed"></i>
        <a *ngIf="item.children"><span class="font-weight-600">{{item.text}}</span></a>
        <div *ngIf="!item.children" class="d-flex justify-content-between w-100"><a  (click)="onItemClieck(item)">{{item.text}}</a> <span><em (click)="programDetails(item)" class="fa fa-pencil"></em></span> </div>
      </div>
    </ng-template>

    <div class="left_tree p-3">  
      <ngx-treeview [config]="config" [items]="rootItem" [itemTemplate]="itemTemplate">
      </ngx-treeview>
    </div>
  </div>
  <div class="programme_right w-75 p-4">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#structure" role="tab" aria-controls="structure"
          aria-selected="true">{{ 'Structure' | translate }}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="students-tab" data-toggle="tab" href="#students" role="tab" aria-controls="students"
          aria-selected="false">{{ 'Students' | translate }}</a>
      </li>      
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="structure" role="tabpanel" aria-labelledby="structure-tab">
        <app-courses [programId]="selectedProgramId"></app-courses>
      </div>
      <div class="tab-pane fade" id="students" role="tabpanel" aria-labelledby="students-tab">
        <app-semester-wise-students [programId]="selectedProgramId"></app-semester-wise-students>
      </div>      
    </div>
  </div>
</div>