import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProgramModel } from '../models/program.model';
import { CustomValidator } from '../customvalidation';

@Injectable({
    providedIn: 'root'
})
export class ProgramFormBuilder{

    private form:any;
    private validationRules = {        
        id:['',''],
        disciplineId: ['',Validators.required],
        numberOfSemesters: ['',[Validators.required,CustomValidator.numeric]],
        academicLevel:['',Validators.required],
        name: ['',Validators.required]
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:ProgramModel){
        this.form.patchValue({
            id:response.id,
            disciplineId:response.disciplineId,
            name:response.name,
            academicLevel:response.academicLevel,
            numberOfSemesters:response.numberOfSemesters
        });
    }
}