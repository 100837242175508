<ng-template #not_found>
  Department not exists
</ng-template>

<ng-container *ngIf="department != null; else not_found">
  <div class="department_right p-4">
    <div class="d-flex justify-content-between align-items-center">
      <ul class="nav nav-tabs border-0" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" (click)="getDepartmentWiseEmployees()" id="home-tab" data-toggle="tab"
            href="#structure" role="tab" aria-controls="structure" aria-selected="true">All</a>
        </li>
        <li *ngFor="let discipline of disciplines;let i=index;" (click)="getDisciplineWiseEmployees(discipline)"
          class="nav-item">
          <a class="nav-link" (click)="disciplineDetails(discipline)" id="students-tab" data-toggle="tab"
            href="#students" role="tab" aria-controls="students" aria-selected="false">{{discipline.name}}
            <em class="fa fa-pencil"></em></a>
        </li>
        <li class="nav-item"><a class="nav-link" (click)="disciplineDetails({id:0})"><i class="fa fa-plus-square-o p-1"
              aria-hidden="true"></i> Add Discpline</a>
        </li>
      </ul>
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex text-80 font-weight-500 mr-2">
          <span class="color_guest"></span>
          <span>Guest</span>
        </div>
        <div class="d-flex text-80 font-weight-500 mr-2">
          <span class="color_permanent"></span>
          <span>Permanent</span>
        </div>
        <div class="d-flex text-80 font-weight-500">
          <span class="color_external"></span>
          <span>External</span>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <ul class="d-flex flex-wrap w-100 justify-content-start p-0 m-0 mt-3">
        <li *ngFor="let employee of employees" class="w-25 p-1">
          <div [ngClass]="(employee.type==8)?'external':'permanent'" class="card">
            <div class="card-body text-dark w-100 p-2">
              <div *ngIf="employee.headOfDepartment" class="ribbon-active">HOD</div>
              <div class="h4 text-100 m-0 pb-2" title="{{employee.name}}">{{employee.name}}</div>
              <div class="h5 text-100 m-0 pb-2" *ngIf="employee.designation">{{employee.designation}}&nbsp;</div>
              <div class="h5 text-100 m-0 pb-2" *ngIf="!employee.designation && employee.type==8 ">External&nbsp;</div>
              <div class="h6 m-0 pb-2"><a href="mailTo:{{employee.email}}" title="{{employee.email}}">{{employee.email |
                  slice:0:28}}</a></div>
              <div class="h6 m-0 pb-2"><em class="fa fa-phone"></em>
                {{employee.mobile}}&nbsp;</div>
              <div class="h6 m-0 pb-2"><em class="fa fa-calendar"></em>
                {{employee.dateOfJoin | date:'dd-MMM-yyyy'}}</div>

            </div>
            <div class="card-footer d-flex justify-content-between p-0 pr-2">
              <a (click)="employeeDetails(employee)" class="text-dark">
                <i class="fa fa-edit p-2" aria-hidden="true"></i>Edit
              </a>
              <a (click)="unlinkEmployee(employee,'unlink')" class="text-dark">
                <i class="fa fa-chain-broken p-2" aria-hidden="true"></i>Unlink
              </a>
              <a *ngIf="!employee.headOfDepartment && employee.type==4" class="text-dark" (click)="markAsHod(employee)">
                <i class="fa fa-tags p-2"></i>HOD</a>
            </div>
          </div>
        </li>
        <li>
          <div
            class="card-block position-relative border white lighten-5 rounded d-flex justify-content-start align-items-start d-block-m p-4">
            <a (click)="searchEmployee()"><em class="fa fa-link" aria-hidden="true"></em></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
