import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UiService } from '../ui.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

declare var jexcel: any;

@Component({
  selector: 'app-data-preview',
  templateUrl: './data-preview.component.html',
  styleUrls: ['./data-preview.component.css']
})
export class DataPreviewComponent implements OnInit, AfterViewInit {
  
  @ViewChild('jexcelContainer', {static:true}) jexcelContainer:any;

  jexcelInstance:any;

  constructor(
    private _uiService:UiService,
    private _router:Router,
    private _route:ActivatedRoute,
    private _toster: ToastrService,
    private _location:Location
  ) { }

  private toColumnName(num:any) {
    for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
      ret = String.fromCharCode(parseInt(((num % b) / a) + '') + 65) + ret;
    }
    return ret;
  }
  ngOnInit() {
    this._uiService.getPreviewModifiedData = () => {
      return this.jexcelInstance.getData();
    };
    this._uiService.closeDataPreview = ()=>{
      this._location.back();
    };
    if(this._uiService.previewData == null){
      this._location.back();
    }
    this._uiService.setPreviewDataCellCommentAndStyle = this.setPreviewDataCellCommentAndStyle.bind(this);
  }

  setPreviewDataCellCommentAndStyle(row:number, col:number, comments:any, style:any){
    var cellAddress = this.toColumnName(col) + row;
    if(comments!=null){
      this.jexcelInstance.setComments(cellAddress, comments);
      this.jexcelInstance.setValue(this.toColumnName(this._uiService.previewData.columns.length)+row,comments,true);
    }
  }

  ngAfterViewInit(): void {
    if(!this._uiService.previewData) return;
    this._uiService.previewData.actionButtons.unshift({
      type: 'i',
      content: 'keyboard_backspace',
      onclick: () => {
        this._location.back();
      }
    })

    if(this.jexcelInstance){
      this.jexcelContainer.nativeElement.html('');
      this.jexcelInstance = null;
    }

    let columns = this._uiService.previewData.columns;
    if(columns.filter((x:any)=>x.name=="Import Remarks").length==0){
      columns.push({align: "center", allowEmpty: false, editor: null, name: "Import Remarks", options: [], source: [], title: "Import Remarks", type: "text", width: 180});
    }     
    //console.log(columns);

    this.jexcelInstance = jexcel(this.jexcelContainer.nativeElement,{
      allowInsertColumn:false,
      allowDeleteColumn:false,
      data: this._uiService.previewData.data,
      columns: columns,
      //minDimensions:[20,100],
      toolbar: this._uiService.previewData.actionButtons
    });

    if(this._uiService.onDataPreviewReady){
      this._uiService.onDataPreviewReady();
    }
    this._uiService.previewData=null;
  }

}
