<form [formGroup]="programForm" (ngSubmit)="onSubmit()">
    <div class="p-3 vw-25">
        <div class="d-flex icon-set justify-content-around p-2 mb-2">
            <div class="text-danger">
                <em class="fa fa-trash"></em>
                {{ 'Delete' | translate }}
            </div>
            <div class="text-warning">
                <em class="fa fa-ban"></em>
                {{ 'Block' | translate }}
            </div>
            <div (click)="editProgram()" *ngIf="editMode" class="text-default">
                <em class="fa fa-pencil"></em>
                {{ 'Edit' | translate }}
            </div>
            <div *ngIf="!editMode" class="text-default">
                <button class="btn text-100 p-0 z-depth-0 text-default"><em class="fa fa-save"></em>
                    <span>{{ 'Save' | translate }}</span></button>

            </div>
        </div>
        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'Discipline' | translate }}:</div>
            <div class=""><select formControlName="disciplineId" [ngClass]="{ 'is-invalid': submitted && f.disciplineId.errors }"
                    class="form-control">
                    <option value="">{{ 'Select Discipline' | translate }}</option>
                    <option *ngFor="let discipline of disciplines" [value]="discipline.id">{{discipline.name}}</option>
                </select>
                <div *ngIf="submitted && f.disciplineId.errors" class="invalid-feedback">
                    <div *ngIf="f.disciplineId.errors.required">{{ 'Discipline is required' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'Name' | translate }}:</div>
            <div class=""><input type="text" class="w-100  form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">{{ 'Name is required' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="pt-2 info-text">
            <div class="text-muted">{{ 'Number of Semesters' | translate }}:</div>
            <div class=""><input type="text" class="w-100  form-control"  formControlName="numberOfSemesters" [ngClass]="{ 'is-invalid': submitted && f.numberOfSemesters.errors }" />
                <div *ngIf="submitted && f.numberOfSemesters.errors" class="invalid-feedback">
                    <div *ngIf="f.numberOfSemesters.errors.required">{{ 'Number of semester is required' | translate }}</div>
                    <div *ngIf="f.numberOfSemesters.errors.invalidNumber">{{ 'Number of semester must be numeric' | translate }}</div>
                </div>
            </div>
        </div>
        <div class="pt-2 info-text">
                <div class="text-muted">{{ 'Academic level' | translate }}:</div>
                <div class=""><select formControlName="academicLevel" [ngClass]="{ 'is-invalid': submitted && f.academicLevel.errors }"
                        class="form-control">
                        <option value="">{{ 'Select academic level' | translate }}</option>
                        <option *ngFor="let academicLevel of academicLevels | keyvalue" [value]="academicLevel.key">{{academicLevel.value}}</option>
                    </select>
                    <div *ngIf="submitted && f.academicLevel.errors" class="invalid-feedback">
                        <div *ngIf="f.academicLevel.errors.required">{{ 'Academic level is required' | translate }}</div>
                    </div>
                </div>
            </div>
        
    </div>
    <input type="hidden"  formControlName="id" >
</form>