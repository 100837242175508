import { Deseriazable } from './deserializable.model';

export class SessionModel implements Deseriazable{
    
    id: string;
    start: string;
    end: string;
    name:string;
    active:boolean;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}